import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Typography,
  Grid,
  Box,
  ButtonGroup,
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { useDebounce } from 'use-debounce';

import Breadcrumbs from 'components/breadcrumbs';
import Table, { HeaderTable } from './components/table';
import { ContextSearch } from 'components/search';
import { crudBreadcrumbs } from './common/breadcrumbs';
import FallbackSection from 'components/fallback/fallback-section';
import LoadingSkeleton from 'components/loading-skeleton';
import { VariantsEnum } from 'components/fallback/types';

import {
  useDeleteCrud,
  useGetAllCrudItems,
} from 'services/queryHooks/crud-hooks';
import { useSnackbarContext } from 'hooks/useSnackbarContext';

import { TypeCrud } from './config';
import { palette } from 'theme/components';
import { DEFAULT_CRUD_PAGINATION, notification } from './constants';

interface Props {
  type: TypeCrud;
  headers: HeaderTable[];
}
function Crud({ type, headers }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [confirmationDialogId, setConfirmationDialogId] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [crudEnabled, setCrudEnabled] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<any>(
    DEFAULT_CRUD_PAGINATION.page
  );
  const [inputValueDebounce] = useDebounce(inputValue, 500);
  const snackbarContext = useSnackbarContext();

  const {
    data: allItems,
    isLoading: isLoadingItems,
    isFetching: isFetching,
  }: any = useGetAllCrudItems(
    { typeCrud: type, enabled: crudEnabled, searchTitle: inputValueDebounce, showAll: true },
    { itemsPerPage: DEFAULT_CRUD_PAGINATION.itemsPerPage, page: currentPage }
  );

  const { mutate: mutateDeleteCrud, isLoading: isLoadingDeleteCrud } =
    useDeleteCrud(type);

  const displayNotification = (type: 'success' | 'error', title: string) => {
    snackbarContext?.addSnackbar({
      key: crypto.randomUUID(),
      title: title,
      severity: type,
    });
  };

  useEffect(() => {
    setCurrentPage(DEFAULT_CRUD_PAGINATION.page);
    setInputValue('');
  }, [type]);

  const renderDeleteDialog = (
    <Dialog
      open={confirmationDialogId}
      onClose={() => setConfirmationDialogId(false)}
    >
      <DialogTitle>
        <Typography variant="h5">{t(`${type}.dialog.title.delete`)}</Typography>
      </DialogTitle>
      <DialogContentText>{t(`${type}.dialog.description`)}</DialogContentText>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'right',
          '&.MuiDialogActions-root': { pt: 2, pb: 0 },
        }}
      >
        <Button
          disableElevation
          variant="text"
          disabled={false}
          onClick={() => {
            setConfirmationDialogId(false);
          }}
        >
          <Typography variant="caption" color={palette.primary.main}>
            <strong>{t('common.cancel')}</strong>
          </Typography>
        </Button>
        <Button
          disableElevation
          variant="text"
          disabled={false}
          onClick={() => {
            mutateDeleteCrud(confirmationDialogId);
            setConfirmationDialogId(false);
          }}
        >
          <Typography variant="caption" color={palette.primary.main}>
            <strong> {t('common.agree')}</strong>
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={crudBreadcrumbs()} />
      </Box>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">{t(`${type}.title`)}</Typography>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-start" mt={1}>
          <Button
            disableElevation
            variant="contained"
            onClick={() => navigate(`/administration/crud/${type}/new`)}
          >
            {t(`${type}.button.createNew`)}
          </Button>
        </Grid>

        <Grid item container xs={12} my={3}>
          <Grid item xs={10} sm={7} md={9}>
            <ButtonGroup disableElevation>
              <Button
                variant={crudEnabled ? 'contained' : 'outlined'}
                onClick={() => {
                  setCrudEnabled(true);
                  setCurrentPage(DEFAULT_CRUD_PAGINATION.page);
                }}
              >
                {t('crud.button.enable')}
              </Button>
              <Button
                variant={!crudEnabled ? 'contained' : 'outlined'}
                onClick={() => {
                  setCrudEnabled(false);
                  setCurrentPage(DEFAULT_CRUD_PAGINATION.page);
                }}
              >
                {t('crud.button.disable')}
              </Button>
            </ButtonGroup>
          </Grid>

          <Grid
            item
            xs={2}
            sm={5}
            md={3}
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <ContextSearch
              value={inputValue}
              placeholder={t('crud.search')}
              onChange={(value: string) => {
                setInputValue(value);
                setCurrentPage(DEFAULT_CRUD_PAGINATION.page);
              }}
            />
          </Grid>
        </Grid>

        {isLoadingItems && isFetching && (
          <Grid item xs={12} mt={5}>
            <LoadingSkeleton itemsCount={1} height={650} />{' '}
          </Grid>
        )}

        <Grid item xs={12}>
          {Boolean(allItems?.items?.length) &&
            !isLoadingItems &&
            !isFetching && (
              <Table
                data={allItems?.items}
                headers={headers}
                onClickPreviousPage={() => setCurrentPage(currentPage - 1)}
                onClickNextPage={() => setCurrentPage(currentPage + 1)}
                disabledPreviousPage={allItems?.pagination?.page <= 1}
                disabledNextButton={!allItems?.pagination?.hasMore}
                onDeleteConfirmationDialogOpen={setConfirmationDialogId}
                onEditOpen={(id: any) =>
                  navigate(`/administration/crud/${type}/${id || ''}`)
                }
              />
            )}
          {!Boolean(allItems?.items?.length) && !isLoadingItems && !isFetching && (
            <FallbackSection
              variant={VariantsEnum.NOITEMS}
              message={
                <>
                  {!inputValue && t(`${type}.emptyMessage`)}
                  {inputValue && (
                    <>
                      Няма открити резултати за
                      <Typography
                        pl={2}
                        component="span"
                        sx={{ color: palette.primary[700] }}
                      >
                        "{inputValue}"
                      </Typography>
                    </>
                  )}
                </>
              }
            />
          )}
        </Grid>
      </Grid>
      {renderDeleteDialog}
      {isLoadingDeleteCrud &&
        displayNotification('success', t(notification(type)?.success.delete))}
    </>
  );
}

Crud.defaultProps = {};

export default Crud;
