import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useUserContext } from 'hooks/useUserContext';
import { getMeetingsToolConfiguration } from 'api/api';
import { MeetingExternalSystem } from 'api/types';

interface TableContextProps {
  disableNavigate: boolean,
  setDisableNavigate: Function,
  meetingToolConfig?: MeetingExternalSystem
};

const TableContext = createContext<TableContextProps>({
  disableNavigate: false,
  setDisableNavigate: Function,
  meetingToolConfig: undefined
});

export const TableContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const userRoles = useUserContext()?.userRole;

  const [disableNavigate, setDisableNavigate] = useState(false);
  const [meetingToolConfig, setMeetingToolConfig] = useState();

  const loadMeetingToolConfig = () => {
    if (userRoles?.isStudent || userRoles?.isTeacher) {
      getMeetingsToolConfiguration()
        .then((configuration) => {
          setMeetingToolConfig(configuration.externalSystem)
        });
    }
  };

  useEffect(() => {
    loadMeetingToolConfig();
  }, []);

  const contextValue = useMemo(() => ({
    disableNavigate,
    setDisableNavigate,
    meetingToolConfig
  }), [disableNavigate, meetingToolConfig]);

  return (
    <TableContext.Provider value={contextValue}>{children}</TableContext.Provider>
  )
};

export const useTableContext = () => useContext(TableContext);
