import { BORDER_RADIUS_LARGE } from 'theme/constants';

export const iframeConfig = {
  default: {
    width: '100%',
    height: '100%',
    border: '0',
    borderRadius: BORDER_RADIUS_LARGE,
  },
  fullscreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: '2000',
  },
};

export const MOZAIK_TOKEN_URL = 'https://mozaweb.bg/api/v1/jwt/auth';
export const MOZAIK_TOKEN_DIGITAL_LESSONS =
  'https%3A%2F%2Fwww.mozaweb.bg%2Fbg%2Flexikon.php%3Fcmd%3Dgetlist%26let%3DMICROCURRICULUM%26active_menu%3Delesson';
export const MOZAIK_DIGITAL_LESSONS =
  'https://www.mozaweb.com/bg/lexikon.php?cmd=getlist&let=MICROCURRICULUM&active_menu=elesson';
