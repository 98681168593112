import styled from '@emotion/styled';
import { Button, InputBase } from '@mui/material';

export const SelectInputStyled = styled(InputBase)(({ theme, selected }: any) => {
  const { palette, shadows, spacing } = theme;

  return {
    '& .MuiInputBase-input': {
      background: palette.background.default,
      color: `${palette.primary.main} !important`,
      padding: spacing(2),
      boxShadow: shadows[4],
      textTransform: 'uppercase',
      fontWeight: `${700} !important`,
      "&:focus":{
        background: palette.background.default,
      },
    },
    '& .MuiSvgIcon-root': {
      ...(!selected ? { color: palette.primary.main }: {}),
      marginRight: spacing(2)
    }
  };
});

export const ItemButton = styled(Button)({
  textTransform: 'none',
  pointerEvents: 'none'
});
