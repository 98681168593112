import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import NewsList from 'views/public/components/news';

function NewsSection({ news, onLoadMore }: any) {
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg">
      <Typography variant="h2">{t('landingPage.section.news.title')}</Typography>
      <NewsList news={news} onLoadMore={onLoadMore} />
    </Container>
  );
}

NewsSection.defaultProps = {
};

export default NewsSection;
