import styled from "@emotion/styled";
import { Dialog, DialogActions, Grid } from "@mui/material";

export const StickyGridStyled = styled(Grid)(({ theme, trigger }: any) => {
  const { palette } = theme;

  return {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: trigger ? palette.common.white : 'transparent',
    borderRadius: '0',
    backgroundClip: 'content-box',
    boxShadow: 'none',
    paddingTop: '0 !important'
  }
});

export const DialogStyled = styled(Dialog)(({
    '& .MuiPaper-root': {
      padding: 0
    }
  }
));

export const DialogActionsStyled = styled(DialogActions)(({ theme }: any) => {
  return {
    padding: `${theme.spacing(1)} !important`,
    '& .MuiButton-root': {
      fontSize: '14px'
    }
  }
});