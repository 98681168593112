import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { format, getYear, isMonday, isWeekend, nextMonday, previousMonday, setDay } from 'date-fns';
import { bg } from 'date-fns/locale';

import { IconButtonLeftStyled, IconButtonRightStyled, RootStyled } from './styles';

type Props = {
  selectedDate: Date,
  handleWeekChange: Function,
};

const WeekSelector = ({ selectedDate, handleWeekChange }: Props) => {
  const handlePrevWeek = () => {
    let prevWeekMonday = previousMonday(selectedDate);
    if (!isMonday(selectedDate)) {
      prevWeekMonday = previousMonday(prevWeekMonday);
    }
    
    handleWeekChange(prevWeekMonday);
  };

  const handleNextWeek = () => {
    const nextWeekMonday = nextMonday(selectedDate);
    handleWeekChange(nextWeekMonday);
  };

  const getWeekFormatted = (date: Date) => {
    const dateFormat = 'dd MMMM';
    const options = { locale: bg };
  
    return `${format(setDay(date, 1), dateFormat, options)} - ${format(setDay(date, 5), dateFormat, options)}, ${getYear(date)}`;
  };

  return (
    <RootStyled
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <IconButtonLeftStyled size="small" onClick={handlePrevWeek}>
        <KeyboardArrowLeft sx={{ fontSize: 40 }} />
      </IconButtonLeftStyled>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={bg}>
        <DatePicker
          closeOnSelect
          value={new Date(selectedDate)}
          onChange={(date: Date | null) => handleWeekChange(date)}
          shouldDisableDate={(date) => isWeekend(date)}
          rifmFormatter={() => getWeekFormatted(selectedDate)}
          InputAdornmentProps={{ position: 'start', variant: 'standard' }}
          renderInput={(params) => <TextField {...params} variant='filled' />}
          reduceAnimations
        />
      </LocalizationProvider>
      <IconButtonRightStyled size="small" onClick={handleNextWeek}>
        <KeyboardArrowRight sx={{ fontSize: 40 }} />
      </IconButtonRightStyled>
    </RootStyled>
  );
};

export default WeekSelector;
