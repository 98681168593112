import React from 'react';
import {
  RootStyled,
  ImageContainerStyled,
  IconStyled,
  MetaStyled,
  TitleContainerStyled,
  InteractiveTitleStyled,
  TitleStyled,
  SeparatorStyled,
  SubTitleStyled,
  FooterStyled
} from './styles';

function Card({ size, image, imageIcon, meta, title, subtitle, footer, onTitleClick }: any) {

  return (
    <RootStyled>
      {
        image && (
          <ImageContainerStyled>
            <img src={image.src} alt={image.alt} />
            {
              imageIcon && (
                <IconStyled>
                  <img src={imageIcon.src} alt={imageIcon.alt} />
                </IconStyled>
              )
            }
          </ImageContainerStyled>
        )
      }
      {
        meta && (
          <MetaStyled size={size}>
            {meta}
          </MetaStyled>
        )
      }
      <TitleContainerStyled size={size} hasMeta={Boolean(meta)}>
        {
          title && (
            <>
              {
                Boolean(onTitleClick) && (
                  <InteractiveTitleStyled
                    type="submit"
                    onClick={(e: any) => {
                      e.preventDefault();
                      onTitleClick();
                    }}
                  >
                    <TitleStyled size={size}>
                      {title}
                    </TitleStyled>
                  </InteractiveTitleStyled>
                )
              }
              {
                Boolean(onTitleClick) || (<TitleStyled>{title}</TitleStyled>)
              }
              {
                size === 'large' && (
                  <SeparatorStyled />
                )
              }
            </>
          )
        }
        {
          subtitle && (
            <SubTitleStyled size={size}>
              {subtitle}
            </SubTitleStyled>
          )
        }
      </TitleContainerStyled>
      {footer && (
        <FooterStyled size={size}>
          {footer}
        </FooterStyled>
      )}
    </RootStyled>
  );
}

Card.defaultProps = {
};

export default Card;
