import styled from '@emotion/styled';
import { Menu, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { BORDER_RADIUS } from 'theme/constants';

export const LoadingButtonStyled = styled(LoadingButton)(({ theme, backgroundcolor, hoverstyles, menu = 0 }: any) => {
  return {
    backgroundColor: backgroundcolor,
    '&:hover': hoverstyles,
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    '&:after': {
      opacity: menu,
      content: "''",
      position: 'absolute',
      zIndex: -1,
      top: `calc(100% - ${BORDER_RADIUS}px)`,
      width: `${BORDER_RADIUS * 1.2}px`,
      height: `${BORDER_RADIUS * 1.2}px`,
      right: 0,
      borderRight: `1px solid ${backgroundcolor}`,
    },
    '&:before': {
      opacity: menu,
      content: "''",
      position: 'absolute',
      zIndex: -1,
      top: `calc(100% - ${BORDER_RADIUS}px)`,
      width: `${BORDER_RADIUS * 1.2}px`,
      height: `${BORDER_RADIUS * 1.2}px`,
      left: 0,
      borderLeft: `1px solid ${backgroundcolor}`,
    },
    ...(menu ? {
      position: 'relative',
      boxShadow: 'none',
    } : {})
  };
});

export const MenuStyled = styled(Menu)(({ theme, color, anchorEl }: any) => {
  const anchorElDimensions = anchorEl.getBoundingClientRect();
  const { shadows, palette } = theme;
  return {
    '& .MuiPaper-root': {
      border: `1px solid ${color}`,
      borderTop: 0,
      width: anchorElDimensions?.width,
      borderRadius: `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`,
      background: palette.common.white,
      boxShadow: shadows[4],
      boxSizing: 'border-box',
      left: `${anchorElDimensions?.left}px !important`,
      transition: 'none !important'
    }
  };
});