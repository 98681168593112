import { Apps } from '@mui/icons-material';
import { NavLinks } from 'system-settings-types';
import { Roles, UserGroups, AllUserRoles } from 'enums/userRoles';
import { AuthManager } from 'authentication/auth-manager';
import logoMozaik from 'components/header/backgrounds/Logo_mozaik.svg';
import { ImgStyled } from 'components/header/styles';
import { ModalTypes } from 'contexts/GlobalModalContext';

export const WEEKLY_SCHEDULE_ROLES = [...UserGroups.Teacher, Roles.Student, Roles.Parent];
const LOGO_MOZAIK = <ImgStyled src={logoMozaik} alt="logo_mozaik" />;

export const navLinks: () => NavLinks = () => ({
  administration: {
    id: 'administration',
    mobileLabel: 'Други',
    icon: <Apps />,
    roles: [
      Roles.Student,
      Roles.Parent,
      Roles.AcademicTeacher,
      Roles.Director,
      Roles.Publisher,
      Roles.SundaySchools,
      ...UserGroups.Teacher,
      ...UserGroups.RUO,
      ...UserGroups.Admin,
    ],
    children: {
      'administration-applications': {
        label: 'Приложения',
        id: 'administration-applications',
        href: 'administration/applications',
        roles: [
          Roles.Student,
          Roles.Parent,
          Roles.AcademicTeacher,
          Roles.Director,
          ...UserGroups.Teacher,
          ...UserGroups.RUO,
          ...UserGroups.Admin,
        ],
      },
      'administration-instructions': {
        label: 'Указания',
        id: 'administration-instructions',
        href: 'administration/instructions',
        roles: [
          Roles.Student,
          Roles.Parent,
          Roles.AcademicTeacher,
          Roles.Director,
          Roles.Publisher,
          ...UserGroups.Teacher,
          ...UserGroups.RUO,
          ...UserGroups.Admin,
        ],
      },
      'administration-license': {
        label: 'Лицензи',
        id: 'administration-license',
        href: 'administration/license',
        roles: [...UserGroups.Teacher, Roles.Director],
      },
      oer: {
        id: 'oer',
        href: 'https://oer.mon.bg',
        label: 'Още ресурси',
        external: true,
        roles: [
          ...UserGroups.Teacher,
          ...UserGroups.Admin,
          ...UserGroups.RUO,
          Roles.Student,
          Roles.Parent,
          Roles.Director,
          Roles.AcademicTeacher,
          Roles.Publisher,
          Roles.SundaySchools
        ],
      },
      content: {
        id: 'content',
        label: 'Управляване на съдържанието',
        roles: [...UserGroups.Admin],
        children: {
          'administration-news': {
            label: 'Новини',
            id: 'administration-news',
            href: 'administration/crud/news',
            roles: [...UserGroups.Admin],
          },
          'administration-notifications': {
            label: 'Блокиращи известия',
            id: 'administration-notifications',
            href: 'administration/crud/notifications',
            roles: [...UserGroups.Admin],
          },
        },
      },
    },
  },
  weeklySchedule: {
    id: 'weekly-schedule',
    href: '/weekly-schedule',
    label: 'Програма',
    roles: WEEKLY_SCHEDULE_ROLES,
  },
  mySubjects: {
    id: 'my-subjects',
    href: '/my-subjects',
    label: 'Предмети',
    roles: [Roles.Teacher, Roles.Student, Roles.Parent],
  },
  homeworks: {
    id: 'homeworks',
    href: '/homeworks',
    label: 'Домашни',
    roles: [Roles.Teacher, Roles.Student, Roles.Parent],
  },
  createContent: {
    id: 'create-content',
    label: 'Създай съдържание',
    roles: [
      ...UserGroups.Teacher,
      Roles.Director,
      ...UserGroups.Admin,
      ...UserGroups.RUO,
      Roles.AcademicTeacher,
      Roles.Publisher,
    ],
    children: {
      'create-content-software': {
        id: 'create-content-software',
        label: 'Софтуер за създаване',
        href: '/content',
        roles: [
          ...UserGroups.Teacher,
          Roles.Director,
          ...UserGroups.Admin,
          ...UserGroups.RUO,
          Roles.AcademicTeacher,
        ],
      },
      'create-content-assessment-module': {
        id: 'create-content-assessment-module',
        label: 'Модул за оценка',
        href: '/assessment/',
        external: true,
        roles: [
          ...UserGroups.Teacher,
          Roles.Director,
          ...UserGroups.Admin,
          Roles.AcademicTeacher,
          Roles.Publisher,
        ],
      },
      'upload-content': {
        id: 'upload-content',
        openDialog: ModalTypes.UploadContent,
        label: 'Качи съдържание',
        roles: [
          ...UserGroups.Teacher,
        ],
      },
    },
  },
  myGrades: {
    id: 'my-grades',
    href: '/my-grades',
    label: 'Оценки',
    roles: [Roles.Student, Roles.Parent],
  },
  library: {
    id: 'library-assigned-contents',
    href: '/library',
    label: 'Библиотека',
    roles: [
      ...UserGroups.Admin,
      ...UserGroups.RUO,
      Roles.Director,
      Roles.AcademicTeacher,
      Roles.Publisher,
      Roles.SundaySchools
    ]
  },
  libraryTeacher: {
    id: 'library-assigned-contents-teacher',
    label: 'Библиотека',
    roles: [
      ...UserGroups.Teacher,
    ],
    children: {
      'library': {
        id: 'library',
        label: 'Библиотека',
        href: '/library',
        roles: [
          ...UserGroups.Teacher,
        ],
      },
      'assigned-contents': {
        id: 'assigned-contents',
        label: 'Насочено съдържание',
        href: '/assigned-contents',
        roles: [
          ...UserGroups.Teacher,
        ],
      },
      ...(AuthManager.getUser().hasSpecialities === 'true' ? {
        'study-aids': {
          id: 'study-aids',
          label: 'Учебни помагала',
          href: '/study-aids',
          roles: [Roles.Teacher],
        }
      } : {})
    }
  },
  libraryStudent: {
    id: 'library',
    label: 'Библиотека',
    href: `/library/${AuthManager.getUser()?.class?.[0] || ''}`,
    roles: [Roles.Student, Roles.Parent],
    children: (AuthManager.getUser().hasSpecialities === 'true' ? {
      'library-child': {
        id: 'library-child',
        label: 'Библиотека',
        href: `/library/${AuthManager.getUser()?.class?.[0] || ''}`,
        roles: [Roles.Student, Roles.Parent],
      },
      'study-aids': {
        id: 'study-aids',
        label: 'Учебни помагала',
        href: '/study-aids',
        roles: [Roles.Student, Roles.Parent],
      }
    } : undefined)
  },
  mozaik: {
    id: 'mozaik',
    href: '/mozaik',
    label: LOGO_MOZAIK,
    roles: AllUserRoles,
  },
  configuration: {
    id: 'configuration',
    href: '/configuration',
    label: 'Конфигуриране',
    roles: [Roles.Director]
  }
});

// as we have some pages that are not children of other, but they are semantically part of a section,
// we have to map them here:

export const activeNavigationHierarchy: any = {
  subject: 'my-subjects'
}
