import { StarRounded } from '@mui/icons-material';
import { palette } from '../theme/components';
import { Box, Typography, TypographyProps } from '@mui/material';

type Props = {
  value: string | number;
  of: string | number;
  usersCount: string | number;
  TypographyProps?: TypographyProps;
  [key: string]: any;
};

function Rating({ value, of, usersCount, TypographyProps, ...propsToPropagate }: Props) {
  const userTranslation = (usersCount ===  1) ? "потребител" : "потребителя";
  const ratingText = (usersCount === 0) ? `${value}/${of}` : `${value}/${of} от ${usersCount} ${userTranslation}`;

  return (
    <Box display="flex" alignItems="center" gap={0.5} {...propsToPropagate}>
      <StarRounded sx={{ fontSize: 18, color: value ? palette.warning.main : palette.grey[300] }} />
      <Typography fontWeight="600" variant="subtitle1" {...TypographyProps}>
        {ratingText}
      </Typography>
    </Box>
  );
}

Rating.defaultProps = {};

export default Rating;
