export const downloadFile = (file: Blob | string, fileName: string) => {
  let objectURL = '';
  if (typeof file === 'string') {
    objectURL = file;
  } else {
    objectURL = window.URL.createObjectURL(file)
  }
  
  const link = document.createElement('a');
  link.href = objectURL;
  link.download = fileName;
  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    // window.URL.revokeObjectURL(objectURL.data.blob());
    window.URL.revokeObjectURL(objectURL);
  }, 100);
};