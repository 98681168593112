import { Fragment, ReactElement, SyntheticEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import { ChevronLeft, Close, KeyboardArrowRight, Menu as MenuIcon } from '@mui/icons-material';
import {
  CollapseMenuStyled,
  CustomLogoHolderStyled,
  MainHeaderStyled,
  MobileLinkStyled,
  MobileSubmenuStyled,
  NavigationLinkInnerStyled,
  NavigationLinksStyled,
  NavigationLinkStyled,
  NavigationStyled,
  TopBarActionsStyled
} from './styles';
import whiteLogo from './backgrounds/Logo_white.svg';
import colorLogo from './backgrounds/Logo_color.svg';
import RCS from '../responsive-control-system';
import { INavLinkNormalised } from 'system-settings-types';
import { activeNavigationHierarchy } from 'system-settings';
import { useGlobalModalContext } from 'hooks/useGlobalModalContext';

type Props = {
  activeNavigation: string;
  onNavigate: (event: SyntheticEvent<Element, Event>, value: any) => void;
  topBarActions?: ReactElement;
  variant: string | number;
  customLogo?: ReactElement | null;
  navigationItems: INavLinkNormalised[];
};

const variantLogos: any = {
  1: whiteLogo,
  6: colorLogo,
};

function MainHeader({
  activeNavigation, onNavigate, topBarActions, variant, customLogo, navigationItems,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const globalModalContext = useGlobalModalContext();

  const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');

  const [firstLevelAnchorEl, setFirstLevelAnchorEl] = useState(null);
  const [secondLevelAnchorEl, setSecondLevelAnchorEl] = useState(null);
  const openFirstLevel = Boolean(firstLevelAnchorEl);
  const openSecondLevel = Boolean(secondLevelAnchorEl);

  const handleFirstLevelMenuClick = (event: any, id: string) => {
    setActiveMenu(id);
    setFirstLevelAnchorEl(event.currentTarget);
  };

  const handleSecondLevelMenuClick = (event: any) => {
    setSecondLevelAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setFirstLevelAnchorEl(null);
    setSecondLevelAnchorEl(null);
  };

  const desktopNavigation = (
    <NavigationLinksStyled>
      {navigationItems.map(({ id, href, label, external, children, icon, openDialog }: any) => {
        const propsToPropagate = {
          key: id,
          variant: variant,
          active: id === activeNavigation || id === activeNavigationHierarchy[activeNavigation] || children?.find(
            (x: any) => x.href?.substring(1)?.split('/')?.[0] === activeNavigation),
        };

        if (external) {
          return (
            <NavigationLinkInnerStyled
              target='_blank'
              href={href}
              {...propsToPropagate}
            >
              {label}
            </NavigationLinkInnerStyled>
          );
        }

        if (children) {
          return (
            <Fragment key={id}>
              <NavigationLinkInnerStyled
                {...propsToPropagate}
                aria-controls={openFirstLevel ? 'first-level-submenu' : undefined}
                aria-haspopup='true'
                aria-expanded={openFirstLevel ? 'true' : undefined}
                onClick={(e: any) => handleFirstLevelMenuClick(e, id)}
              >
                {label}
                {icon}
              </NavigationLinkInnerStyled>
              <Menu
                id='first-level-submenu'
                anchorEl={firstLevelAnchorEl}
                open={openFirstLevel && activeMenu === id}
                onClose={handleMenuClose}
              >
                <MenuList>
                  {
                    children.map((childFirstLevelItem: any) => {
                      return ([
                        childFirstLevelItem.children && (
                          <Divider />
                        ),
                        <MenuItem
                          key={childFirstLevelItem.id}
                          aria-controls={openSecondLevel ? 'second-level-submenu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={openSecondLevel ? 'true' : undefined}
                          sx={{ alignItems: 'center' }}
                          onClick={(e) => {
                            if (childFirstLevelItem.children) {
                              handleSecondLevelMenuClick(e);
                            } else {
                              if (childFirstLevelItem.external) {
                                window.open(childFirstLevelItem.href, '_blank');
                              } else if (childFirstLevelItem.openDialog) {
                                globalModalContext?.setModalType(childFirstLevelItem.openDialog);
                              } else {
                                navigate(childFirstLevelItem.href);
                              }

                              handleMenuClose();
                            }
                          }}
                        >
                          {childFirstLevelItem.icon && (
                            <ListItemIcon>
                              {childFirstLevelItem.icon}
                            </ListItemIcon>
                          )}
                          <ListItemText>{childFirstLevelItem.label}</ListItemText>
                          {childFirstLevelItem.children && (
                            <KeyboardArrowRight fontSize='small' sx={{ color: 'text.secondary' }} />
                          )}
                        </MenuItem>,
                        childFirstLevelItem.children && (
                          <Menu
                            key={`second-level-submenu${childFirstLevelItem.id}`}
                            id='second-level-submenu'
                            anchorEl={secondLevelAnchorEl}
                            open={openSecondLevel}
                            onClose={handleMenuClose}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          >
                            <MenuList>
                              {childFirstLevelItem.children.map((childSecondLevelItem: any) => {
                                return (
                                  <MenuItem
                                    key={childSecondLevelItem.id}
                                    onClick={() => {
                                      navigate(childSecondLevelItem.href);
                                      handleMenuClose();
                                    }}
                                  >
                                    {childSecondLevelItem.icon && (
                                      <ListItemIcon>
                                        {childSecondLevelItem.icon}
                                      </ListItemIcon>
                                    )}
                                    <ListItemText>{childSecondLevelItem.label}</ListItemText>
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        ),
                      ]);
                    })
                  }
                </MenuList>
              </Menu>
            </Fragment>
          );
        }

        return (
          <NavigationLinkStyled key={id} to={href}>
            <NavigationLinkInnerStyled
              {...propsToPropagate}
            >
              {label}
            </NavigationLinkInnerStyled>
          </NavigationLinkStyled>
        );
      })}
    </NavigationLinksStyled>
  );

  const mobileNavigation = navigationItems.map(({ id, href, label, external, children, mobileLabel }) => {
    const childrenRender = (children: any) => {
      if (!children) return;
      return (
        <MobileSubmenuStyled key={id}>
          {children.map((childItem: any) => (
            <Fragment key={childItem.id}>
              <MobileLinkStyled
                onClick={(ev: any) => {
                  if (childItem.children) {
                    return;
                  }

                  if (childItem.external) {
                    window.open(childItem.href, '_blank');
                  } else if (childItem.openDialog) {
                    globalModalContext?.setModalType(childItem.openDialog);
                  } else {
                    onNavigate(ev, childItem.href);
                  }

                  setMobileNavigationOpen(false);
                }}
              >
                {childItem.label}
              </MobileLinkStyled>
              {
                childrenRender(childItem.children)
              }
            </Fragment>
          ))}
        </MobileSubmenuStyled>
      );
    };
    return (
      <Fragment key={id}>
        <MobileLinkStyled
          key={id}
          onClick={(ev: any) => {
            if (external) {
              window.open(href, '_blank');
              setMobileNavigationOpen(false);
              return;
            }
            if (children) {
              return;
            }
            onNavigate(ev, href);
            setMobileNavigationOpen(false);
          }}
          fontWeight='600'
          fontSize={24}
        >
          {label || mobileLabel}
        </MobileLinkStyled>
        {childrenRender(children)}
      </Fragment>
    );
  });

  return (
    <MainHeaderStyled variant={variant}>
      <CustomLogoHolderStyled variant={variant} withCustomLogo={Boolean(customLogo)}>
        <Container maxWidth='lg'>
          {customLogo ? customLogo : (
            <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
              <Link to='/'>
                <img src={variantLogos[variant]} alt='Digital backpack logo' />
              </Link>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                startIcon={<ChevronLeft />}
                onClick={() => navigate('/')}
              >
                {t('login.goBackToMain')}
              </Button>
            </Box>
          )}
          {/* TODO: Change logo */}
        </Container>
      </CustomLogoHolderStyled>

      <NavigationStyled variant={variant}>
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <RCS visible='tablet'>
            {mobileNavigationOpen ? (
              <Close onClick={() => setMobileNavigationOpen(false)} />
            ) : (
              <MenuIcon onClick={() => setMobileNavigationOpen(true)} />
            )}
            <CollapseMenuStyled
              in={mobileNavigationOpen}
              orientation='vertical'
              unmountOnExit
            >
              {mobileNavigation}
            </CollapseMenuStyled>
          </RCS>
          <RCS hidden='tablet'>{desktopNavigation}</RCS>
          {topBarActions && (
            <TopBarActionsStyled>{topBarActions}</TopBarActionsStyled>
          )}
        </Container>
      </NavigationStyled>
    </MainHeaderStyled>
  );
}

MainHeader.defaultProps = {
  customLogo: null,
};

export default MainHeader;
