import * as yup from 'yup';
import { FILES_VALIDATION, REQUIRED_MESSAGE } from '../common/validations';

export const validationSchema = yup.object().shape({
  title: yup.string().required(REQUIRED_MESSAGE),
  deadline: yup.date().nullable().required(REQUIRED_MESSAGE),
  descriptionRawText: yup.string().required(REQUIRED_MESSAGE),
  subject: yup.string().required(REQUIRED_MESSAGE),
  classes: yup.array().min(1, 'Необходимо е да изберете поне един клас.'),
  files: FILES_VALIDATION
});

export const initialFormValues = {
  title: '',
  description: {},
  descriptionRawText: '',
  deadline: null,
  subject: undefined,
  classes: [],
  files: [],
  contents: []
};

export const notifications = {
  success: 'Домашното беше създадено успешно.',
  error: 'Възникна грешка при създаването на домашно. Моля, опитайте отново.'
};