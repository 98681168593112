import * as All from 'components/icons/icon-subjects';
import { palette } from 'theme/components';

export const data: any = {
  '1': {
    id: 1,
    name: 'Български език и литература',
    icon: (iconProps: any) => <All.IconSubject1 {...iconProps} />,
    color: '#E91F63',
  },
  '2': {
    id: 2,
    name: 'Математика',
    icon: (iconProps: any) => <All.IconSubject2 {...iconProps} />,
    color: '#5FB664',
  },
  '3': {
    id: 3,
    name: 'История',
    icon: (iconProps: any) => <All.IconSubject3 {...iconProps} />,
    color: '#E91F63',
  },
  '4': {
    id: 4,
    name: 'Родинознание',
    icon: (iconProps: any) => <All.IconSubject4 {...iconProps} />,
    color: '#6FBD74',
  },
  '5': {
    id: 5,
    name: 'Философия',
    icon: (iconProps: any) => <All.IconSubject5 {...iconProps} />,
    color: '#F6A14D',
  },
  '6': {
    id: 6,
    name: 'Психология',
    icon: (iconProps: any) => <All.IconSubject6 {...iconProps} />,
    color: '#F5CB00',
  },
  '7': {
    id: 7,
    name: 'Логика',
    icon: (iconProps: any) => <All.IconSubject7 {...iconProps} />,
    color: '#3181E9',
  },
  '8': {
    id: 8,
    name: 'Етика',
    icon: (iconProps: any) => <All.IconSubject8 {...iconProps} />,
    color: '#ED4C82',
  },
  '9': {
    id: 9,
    name: 'Естетика',
    icon: (iconProps: any) => <All.IconSubject9 {...iconProps} />,
    color: '#A731B7',
  },
  '10': {
    id: 10,
    name: 'Роден край',
    icon: (iconProps: any) => <All.IconSubject10 {...iconProps} />,
    color: '#6FBD74',
  },
  '11': {
    id: 11,
    name: 'Човекът и обществото',
    icon: (iconProps: any) => <All.IconSubject11 {...iconProps} />,
    color: '#FCA027',
  },
  '12': {
    id: 12,
    name: 'История и цивилизация',
    icon: (iconProps: any) => <All.IconSubject12 {...iconProps} />,
    color: '#E91F63',
  },
  '13': {
    id: 13,
    name: 'География и икономика',
    icon: (iconProps: any) => <All.IconSubject13 {...iconProps} />,
    color: '#5FB664',
  },
  '14': {
    id: 14,
    name: 'Психология и логика',
    icon: (iconProps: any) => <All.IconSubject14 {...iconProps} />,
    color: '#F5CB00',
  },
  '15': {
    id: 15,
    name: 'Етика и право',
    icon: (iconProps: any) => <All.IconSubject15 {...iconProps} />,
    color: '#F06292',
  },
  '16': {
    id: 16,
    name: 'Свят и личност',
    icon: (iconProps: any) => <All.IconSubject16 {...iconProps} />,
    color: '#3395C9',
  },
  '17': {
    id: 17,
    name: 'Гражданско образование',
    icon: (iconProps: any) => <All.IconSubject17 {...iconProps} />,
    color: '#5F9DEE',
  },
  '18': {
    id: 18,
    name: 'История и цивилизации',
    icon: (iconProps: any) => <All.IconSubject18 {...iconProps} />,
    color: '#E91F63',
  },
  '20': {
    id: 20,
    name: 'География',
    icon: (iconProps: any) => <All.IconSubject20 {...iconProps} />,
    color: '#5FB664',
  },
  '21': {
    id: 21,
    name: 'Биология',
    icon: (iconProps: any) => <All.IconSubject21 {...iconProps} />,
    color: '#F6A14D',
  },
  '22': {
    id: 22,
    name: 'Физика',
    icon: (iconProps: any) => <All.IconSubject22 {...iconProps} />,
    color: '#FC960F',
  },
  '23': {
    id: 23,
    name: 'Химия',
    icon: (iconProps: any) => <All.IconSubject23 {...iconProps} />,
    color: '#C16FCD',
  },
  '24': {
    id: 24,
    name: 'Природознание',
    icon: (iconProps: any) => <All.IconSubject24 {...iconProps} />,
    color: '#5FB664',
  },
  '25': {
    id: 25,
    name: 'Физика и астрономия',
    icon: (iconProps: any) => <All.IconSubject25 {...iconProps} />,
    color: '#FC960F',
  },
  '26': {
    id: 26,
    name: 'Околен свят',
    icon: (iconProps: any) => <All.IconSubject26 {...iconProps} />,
    color: '#488FEC',
  },
  '27': {
    id: 27,
    name: 'Човекът и природата',
    icon: (iconProps: any) => <All.IconSubject27 {...iconProps} />,
    color: '#5FB664',
  },
  '28': {
    id: 28,
    name: 'Биология и здравно образование',
    icon: (iconProps: any) => <All.IconSubject28 {...iconProps} />,
    color: '#F6A14D',
  },
  '29': {
    id: 29,
    name: 'Химия и опазване на околната среда',
    icon: (iconProps: any) => <All.IconSubject29 {...iconProps} />,
    color: '#C16FCD',
  },
  '30': {
    id: 30,
    name: 'Музика',
    icon: (iconProps: any) => <All.IconSubject30 {...iconProps} />,
    color: '#FF4D4D',
  },
  '31': {
    id: 31,
    name: 'Изобразително изкуство',
    icon: (iconProps: any) => <All.IconSubject31 {...iconProps} />,
    color: '#EB4F4A',
  },
  '32': {
    id: 32,
    name: 'Ръчен труд',
    icon: (iconProps: any) => <All.IconSubject32 {...iconProps} />,
    color: '#4DA2D0',
  },
  '33': {
    id: 33,
    name: 'Труд и техника',
    icon: (iconProps: any) => <All.IconSubject33 {...iconProps} />,
    color: '#F27800',
  },
  '34': {
    id: 34,
    name: 'Технологии',
    icon: (iconProps: any) => <All.IconSubject34 {...iconProps} />,
    color: '#488FEC',
  },
  '35': {
    id: 35,
    name: 'Информатика',
    icon: (iconProps: any) => <All.IconSubject35 {...iconProps} />,
    color: '#488FEC',
  },
  '36': {
    id: 36,
    name: 'Информационни технологии',
    icon: (iconProps: any) => <All.IconSubject36 {...iconProps} />,
    color: '#488FEC',
  },
  '37': {
    id: 37,
    name: 'Домашен бит и техника',
    icon: (iconProps: any) => <All.IconSubject37 {...iconProps} />,
    color: '#F27800',
  },
  '38': {
    id: 38,
    name: 'Домашна техника и икономика',
    icon: (iconProps: any) => <All.IconSubject38 {...iconProps} />,
    color: '#9FD3A2',
  },
  '39': {
    id: 39,
    name: 'Теория и методика на спортната тренировка',
    icon: (iconProps: any) => <All.IconSubject39 {...iconProps} />,
    color: '#4DA2D0',
  },
  '40': {
    id: 40,
    name: 'Физическа култура и спорт',
    icon: (iconProps: any) => <All.IconSubject40 {...iconProps} />,
    color: '#3181E9',
  },
  '41': {
    id: 41,
    name: 'Организиран отдих и спорт',
    icon: (iconProps: any) => <All.IconSubject41 {...iconProps} />,
    color: '#7FC583',
  },
  '42': {
    id: 42,
    name: 'Занимания по интереси',
    icon: (iconProps: any) => <All.IconSubject42 {...iconProps} />,
    color: '#CA83D4',
  },
  '43': {
    id: 43,
    name: 'Религия',
    icon: (iconProps: any) => <All.IconSubject43 {...iconProps} />,
    color: '#FF4D4D',
  },
  '44': {
    id: 44,
    name: 'Хореография',
    icon: (iconProps: any) => <All.IconSubject44 {...iconProps} />,
    color: '#FDB657',
  },
  '45': {
    id: 45,
    name: 'Физическо възпитание и спорт',
    icon: (iconProps: any) => <All.IconSubject45 {...iconProps} />,
    color: '#3181E9',
  },
  '46': {
    id: 46,
    name: 'Организиран отдих и физическа активност',
    icon: (iconProps: any) => <All.IconSubject46 {...iconProps} />,
    color: '#7FC583',
  },
  '49': {
    id: 49,
    name: 'Физкултура (спорт)',
    icon: (iconProps: any) => <All.IconSubject49 {...iconProps} />,
    color: '#3181E9',
  },
  '50': {
    id: 50,
    name: 'Първи чужд език',
    icon: (iconProps: any) => <All.IconSubject50 {...iconProps} />,
    color: '#FB8E03',
  },
  '51': {
    id: 51,
    name: 'Втори чужд език',
    icon: (iconProps: any) => <All.IconSubject51 {...iconProps} />,
    color: '#FB8E03',
  },
  '52': {
    id: 52,
    name: 'Трети чужд език',
    icon: (iconProps: any) => <All.IconSubject52 {...iconProps} />,
    color: '#FB8E03',
  },
  '53': {
    id: 53,
    name: 'Спортни дейности (бадминтон)',
    icon: (iconProps: any) => <All.IconSubject53 {...iconProps} />,
    color: '#8CB9F3',
  },
  '54': {
    id: 54,
    name: 'Спортни дейности (баскетбол )',
    icon: (iconProps: any) => <All.IconSubject54 {...iconProps} />,
    color: '#1A73E7',
  },
  '55': {
    id: 55,
    name: 'Спортни дейности (борба)',
    icon: (iconProps: any) => <All.IconSubject55 {...iconProps} />,
    color: '#1A73E7',
  },
  '56': {
    id: 56,
    name: 'Спортни дейности (волейбол)',
    icon: (iconProps: any) => <All.IconSubject56 {...iconProps} />,
    color: '#488FEC',
  },
  '57': {
    id: 57,
    name: 'Спортни дейности (лека атлетика)',
    icon: (iconProps: any) => <All.IconSubject57 {...iconProps} />,
    color: '#1A73E7',
  },
  '58': {
    id: 58,
    name: 'Спортни дейности (мини-баскетбол)',
    icon: (iconProps: any) => <All.IconSubject58 {...iconProps} />,
    color: '#3181E9',
  },
  '59': {
    id: 59,
    name: 'Спортни дейности (мини-волейбол)',
    icon: (iconProps: any) => <All.IconSubject59 {...iconProps} />,
    color: '#488FEC',
  },
  '60': {
    id: 60,
    name: 'Спортни дейности (мини-футбол)',
    icon: (iconProps: any) => <All.IconSubject60 {...iconProps} />,
    color: '#1A73E7',
  },
  '61': {
    id: 61,
    name: 'Български език',
    icon: (iconProps: any) => <All.IconSubject61 {...iconProps} />,
    color: '#E91F63',
  },
  '62': {
    id: 62,
    name: 'Социален свят',
    icon: (iconProps: any) => <All.IconSubject62 {...iconProps} />,
    color: '#FF6666',
  },
  '63': {
    id: 63,
    name: 'Природен свят',
    icon: (iconProps: any) => <All.IconSubject63 {...iconProps} />,
    color: '#5FB664',
  },
  '64': {
    id: 64,
    name: 'Художествена информация и литература за деца',
    icon: (iconProps: any) => <All.IconSubject64 {...iconProps} />,
    color: '#F279A1',
  },
  '65': {
    id: 65,
    name: 'Конструктивно-технически и битови дейности',
    icon: (iconProps: any) => <All.IconSubject65 {...iconProps} />,
    color: '#7FC583',
  },
  '66': {
    id: 66,
    name: 'Игрова култура и пресъздаване',
    icon: (iconProps: any) => <All.IconSubject66 {...iconProps} />,
    color: '#F7D533',
  },
  '67': {
    id: 67,
    name: 'Спортни дейности (мини-хандбал)',
    icon: (iconProps: any) => <All.IconSubject67 {...iconProps} />,
    color: '#3181E9',
  },
  '68': {
    id: 68,
    name: 'Спортни дейности (плуване)',
    icon: (iconProps: any) => <All.IconSubject68 {...iconProps} />,
    color: '#5F9DEE',
  },
  '69': {
    id: 69,
    name: 'Спортни дейности (ски - алпийски дисциплини)',
    icon: (iconProps: any) => <All.IconSubject69 {...iconProps} />,
    color: '#3181E9',
  },
  '70': {
    id: 70,
    name: 'Спортни дейности (спортна гимнастика )',
    icon: (iconProps: any) => <All.IconSubject70 {...iconProps} />,
    color: '#1A73E7',
  },
  '71': {
    id: 71,
    name: 'Компютърно моделиране',
    icon: (iconProps: any) => <All.IconSubject71 {...iconProps} />,
    color: '#1A73E7',
  },
  '72': {
    id: 72,
    name: 'Технологии и предприемачество',
    icon: (iconProps: any) => <All.IconSubject72 {...iconProps} />,
    color: '#7FC583',
  },
  '73': {
    id: 73,
    name: 'Предприемачество',
    icon: (iconProps: any) => <All.IconSubject73 {...iconProps} />,
    color: '#7FC583',
  },
  '74': {
    id: 74,
    name: 'Здравословни и безопасни условия на труд',
    icon: (iconProps: any) => <All.IconSubject74 {...iconProps} />,
    color: '#ED4C82',
  },
  '75': {
    id: 75,
    name: 'Икономика',
    icon: (iconProps: any) => <All.IconSubject75 {...iconProps} />,
    color: '#9FD3A2',
  },
  '76': {
    id: 76,
    name: 'Спортни дейности (таекуондо)',
    icon: (iconProps: any) => <All.IconSubject76 {...iconProps} />,
    color: '#1A73E7',
  },
  '77': {
    id: 77,
    name: 'Спортни дейности (футбол )',
    icon: (iconProps: any) => <All.IconSubject77 {...iconProps} />,
    color: '#1A73E7',
  },
  '78': {
    id: 78,
    name: 'Спортни дейности (хандбал)',
    icon: (iconProps: any) => <All.IconSubject78 {...iconProps} />,
    color: '#3181E9',
  },
  '79': {
    id: 79,
    name: 'Спортни дейности (художествена гимнастика)',
    icon: (iconProps: any) => <All.IconSubject79 {...iconProps} />,
    color: '#1A73E7',
  },
  '80': {
    id: 80,
    name: 'Образователно направление „Български език и литература“',
    icon: (iconProps: any) => <All.IconSubject80 {...iconProps} />,
    color: '#E91F63',
  },
  '81': {
    id: 81,
    name: 'Образователно направление „Математика“',
    icon: (iconProps: any) => <All.IconSubject81 {...iconProps} />,
    color: '#5FB664',
  },
  '82': {
    id: 82,
    name: 'Образователно направление „Околен свят“',
    icon: (iconProps: any) => <All.IconSubject82 {...iconProps} />,
    color: '#488FEC',
  },
  '83': {
    id: 83,
    name: 'Образователно направление „Изобразително изкуство“',
    icon: (iconProps: any) => <All.IconSubject83 {...iconProps} />,
    color: '#EB4F4A',
  },
  '84': {
    id: 84,
    name: 'Образователно направление „Музика“',
    icon: (iconProps: any) => <All.IconSubject84 {...iconProps} />,
    color: '#FF4D4D',
  },
  '85': {
    id: 85,
    name: 'Образователно направление „Конструиране и технологии“',
    icon: (iconProps: any) => <All.IconSubject85 {...iconProps} />,
    color: '#3395C9',
  },
  '86': {
    id: 86,
    name: 'Образователно направление „Физическа култура“',
    icon: (iconProps: any) => <All.IconSubject86 {...iconProps} />,
    color: '#3181E9',
  },
  '87': {
    id: 87,
    name: 'Други дейности по обучение, социализация, възпитание и отглеждане на децата',
    icon: (iconProps: any) => <All.IconSubject87 {...iconProps} />,
    color: '#FDC06F',
  },
  '88': {
    id: 88,
    name: 'Спортни дейности (шахмат)',
    icon: (iconProps: any) => <All.IconSubject88 {...iconProps} />,
    color: '#76ABF1',
  },
  '90': {
    id: 90,
    name: 'Чужд език по професията',
    icon: (iconProps: any) => <All.IconSubject90 {...iconProps} />,
    color: '#FB8E03',
  },
  '91': {
    id: 91,
    name: 'Компютърно моделиране и информационни технологии',
    icon: (iconProps: any) => <All.IconSubject91 {...iconProps} />,
    color: '#1A73E7',
  },
  '92': {
    id: 92,
    name: 'Проектни и творчески дейности',
    icon: (iconProps: any) => <All.IconSubject92 {...iconProps} />,
    color: '#FF3333',
  },
  '99': {
    id: 99,
    name: 'Български език като чужд за търсещи или получили закрила',
    icon: (iconProps: any) => <All.IconSubject99 {...iconProps} />,
    color: '#FC960F',
  },
  '100': {
    id: 100,
    name: 'Английски език',
    icon: (iconProps: any) => <All.IconSubject100 {...iconProps} />,
    color: '#FC960F',
  },
  '101': {
    id: 101,
    name: 'Немски език',
    icon: (iconProps: any) => <All.IconSubject101 {...iconProps} />,
    color: '#FC960F',
  },
  '102': {
    id: 102,
    name: 'Френски език',
    icon: (iconProps: any) => <All.IconSubject102 {...iconProps} />,
    color: '#FC960F',
  },
  '103': {
    id: 103,
    name: 'Испански език',
    icon: (iconProps: any) => <All.IconSubject103 {...iconProps} />,
    color: '#FC960F',
  },
  '104': {
    id: 104,
    name: 'Италиански език',
    icon: (iconProps: any) => <All.IconSubject104 {...iconProps} />,
    color: '#FC960F',
  },
  '105': {
    id: 105,
    name: 'Португалски език',
    icon: (iconProps: any) => <All.IconSubject105 {...iconProps} />,
    color: '#FC960F',
  },
  '106': {
    id: 106,
    name: 'Руски език',
    icon: (iconProps: any) => <All.IconSubject106 {...iconProps} />,
    color: '#FC960F',
  },
  '110': {
    id: 110,
    name: 'Иврит',
    icon: (iconProps: any) => <All.IconSubject110 {...iconProps} />,
    color: '#FC960F',
  },
  '111': {
    id: 111,
    name: 'Арменски език',
    icon: (iconProps: any) => <All.IconSubject111 {...iconProps} />,
    color: '#FC960F',
  },
  '112': {
    id: 112,
    name: 'Турски език',
    icon: (iconProps: any) => <All.IconSubject112 {...iconProps} />,
    color: '#FC960F',
  },
  '113': {
    id: 113,
    name: 'Ромски език',
    icon: (iconProps: any) => <All.IconSubject113 {...iconProps} />,
    color: '#FC960F',
  },
  '120': {
    id: 120,
    name: 'Японски език',
    icon: (iconProps: any) => <All.IconSubject120 {...iconProps} />,
    color: '#FC960F',
  },
  '121': {
    id: 121,
    name: 'Китайски език',
    icon: (iconProps: any) => <All.IconSubject121 {...iconProps} />,
    color: '#FC960F',
  },
  '122': {
    id: 122,
    name: 'Персийски език',
    icon: (iconProps: any) => <All.IconSubject122 {...iconProps} />,
    color: '#FC960F',
  },
  '123': {
    id: 123,
    name: 'Арабски език',
    icon: (iconProps: any) => <All.IconSubject123 {...iconProps} />,
    color: '#FC960F',
  },
  '124': {
    id: 124,
    name: 'Румънски език',
    icon: (iconProps: any) => <All.IconSubject124 {...iconProps} />,
    color: '#FC960F',
  },
  '125': {
    id: 125,
    name: 'Гръцки език',
    icon: (iconProps: any) => <All.IconSubject125 {...iconProps} />,
    color: '#FC960F',
  },
  '126': {
    id: 126,
    name: 'Албански език',
    icon: (iconProps: any) => <All.IconSubject126 {...iconProps} />,
    color: '#FC960F',
  },
  '127': {
    id: 127,
    name: 'Шведски език',
    icon: (iconProps: any) => <All.IconSubject127 {...iconProps} />,
    color: '#FC960F',
  },
  '128': {
    id: 128,
    name: 'Норвежки език',
    icon: (iconProps: any) => <All.IconSubject128 {...iconProps} />,
    color: '#FC960F',
  },
  '129': {
    id: 129,
    name: 'Финландски език',
    icon: (iconProps: any) => <All.IconSubject129 {...iconProps} />,
    color: '#FC960F',
  },
  '130': {
    id: 130,
    name: 'Датски език',
    icon: (iconProps: any) => <All.IconSubject130 {...iconProps} />,
    color: '#FC960F',
  },
  '131': {
    id: 131,
    name: 'Холандски език',
    icon: (iconProps: any) => <All.IconSubject131 {...iconProps} />,
    color: '#FC960F',
  },
  '132': {
    id: 132,
    name: 'Украински език',
    icon: (iconProps: any) => <All.IconSubject132 {...iconProps} />,
    color: '#FC960F',
  },
  '133': {
    id: 133,
    name: 'Полски език',
    icon: (iconProps: any) => <All.IconSubject133 {...iconProps} />,
    color: '#FC960F',
  },
  '134': {
    id: 134,
    name: 'Чешки език',
    icon: (iconProps: any) => <All.IconSubject134 {...iconProps} />,
    color: '#FC960F',
  },
  '135': {
    id: 135,
    name: 'Словашки език', //this language using icon from 'Словенски език', because for this language there is no icon
    icon: (iconProps: any) => <All.IconSubject143 {...iconProps} />,
    color: '#FC960F',
  },
  '136': {
    id: 136,
    name: 'Унгарски език', // There is not icon for this laguage a globe icon is placed.
    icon: (iconProps: any) => <All.IconSubject136 {...iconProps} />,
    color: '#FC960F',
  },
  '137': {
    id: 137,
    name: 'Сърбохърватски език',
    icon: (iconProps: any) => <All.IconSubject137 {...iconProps} />,
    color: '#FC960F',
  },
  '138': {
    id: 138,
    name: 'Хинди',
    icon: (iconProps: any) => <All.IconSubject138 {...iconProps} />,
    color: '#FC960F',
  },
  '139': {
    id: 139,
    name: 'Старогръцки език',
    icon: (iconProps: any) => <All.IconSubject139 {...iconProps} />,
    color: '#FC960F',
  },
  '140': {
    id: 140,
    name: 'Латински език',
    icon: (iconProps: any) => <All.IconSubject140 {...iconProps} />,
    color: '#FC960F',
  },
  '141': {
    id: 141,
    name: 'Старобългарски език',
    icon: (iconProps: any) => <All.IconSubject141 {...iconProps} />,
    color: '#FC960F',
  },
  '142': {
    id: 142,
    name: 'Корейски език',
    icon: (iconProps: any) => <All.IconSubject142 {...iconProps} />,
    color: '#FC960F',
  },
  '143': {
    id: 143,
    name: 'Словенски език',
    icon: (iconProps: any) => <All.IconSubject143 {...iconProps} />,
    color: '#FC960F',
  },
  '180': {
    id: 180,
    name: 'Възпитателни дейности',
    icon: (iconProps: any) => <All.IconSubject180 {...iconProps} />,
    color: '#7FC583',
  },
  '181': {
    id: 181,
    name: 'Образователни дейности в ДГ',
    icon: (iconProps: any) => <All.IconSubject181 {...iconProps} />,
    color: '#7FC583',
  },
  '196': {
    id: 196,
    name: 'Безопасност на движението',
    icon: (iconProps: any) => <All.IconSubject196 {...iconProps} />,
    color: '#3395C9',
  },
  '197': {
    id: 197,
    name: 'Работа с ученици и родители',
    icon: (iconProps: any) => <All.IconSubject197 {...iconProps} />,
    color: '#F6A14D',
  },
  '198': {
    id: 198,
    name: 'Самоподготовка',
    icon: (iconProps: any) => <All.IconSubject198 {...iconProps} />,
    color: '#CA83D4',
  },
  '199': {
    id: 199,
    name: 'Час на класа',
    icon: (iconProps: any) => <All.IconSubject199 {...iconProps} />,
    color: '#FC960F',
  },
  '-1': {
    id: -1,
    name: 'Чужд език',
    icon: (iconProps: any) => <All.IconSubject {...iconProps} />,
    color: '#FB8E03',
  },
};

export const getSubjectConfig = (subjectId: number) => {
  const subject = data[subjectId];
  return subject || {
    icon: (iconProps: any) => <All.IconSubjectDefault {...iconProps} />,
    color: palette.primary.dark
  };
};
