import styled from '@emotion/styled';
import {
  Box,
  Collapse,
  Typography,
  ButtonBase,
  Menu,
  MenuItem,
} from '@mui/material';
import { darken } from 'polished';
import backgrounds from './backgrounds/svg';
import svgToMiniDataURI from 'mini-svg-data-uri';
import { shadows } from 'theme/components';
import { Link } from 'react-router-dom';

export const MainHeaderStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ variant }: any) => {
  const { mainBackground, fallbackColor, textColor } = backgrounds[variant];

  return {
    ...(mainBackground
      ? {
        background: `url("${svgToMiniDataURI.toSrcset(
          mainBackground
        )}") 0 0 repeat-x ${fallbackColor}`,
      }
      : {}),
    boxShadow: shadows[4],
    ...(textColor
      ? {
        [`& .Mui-selected,
        & .MuiTab-root,
        & .MuiSvgIcon-root,
        & .MuiBadge-root svg,
        & .MuiTypography-root`]: {
          color: textColor,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: textColor,
        },
      }
      : {}),
  };
});

export const CustomLogoHolderStyled = styled('div', {
  shouldForwardProp: (prop) => (prop !== 'variant' && prop !== 'withCustomLogo'),
})(
  ({ variant, withCustomLogo }: any) => {
    const { containerBackground, fallbackColor } = backgrounds[variant];

    return {
      ...(!withCustomLogo ? { height: 80 } : {}),
      display: 'flex',
      alignItems: 'center',

      '& .MuiContainer-root': {
        ...(containerBackground
          ? {
            background: `url("${svgToMiniDataURI.toSrcset(
              containerBackground
            )}") 0 0 repeat-x ${fallbackColor}`,
          }
          : {}),
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    };
  }
);

export const NavigationStyled = styled(Box)(({ theme, variant }: any) => {
  const { palette } = theme;
  const { navigationBackground } = backgrounds[variant];

  return {
    background: navigationBackground || palette.common.white,
    position: 'relative',
  };
});

export const NavigationLinksStyled = styled(Box)(({ theme }: any) => {
  const { spacing } = theme;

  return {
    marginLeft: `-${spacing(2)}`,
    display: 'flex',
    alignItems: 'stretch',
  };
});

export const NavigationLinkStyled = styled(Link)(() => {
  return {
    display: 'inline-flex',
  };
});

export const NavigationLinkInnerStyled = styled(ButtonBase, {
  shouldForwardProp: (prop) => (prop !== 'variant' && prop !== 'active'),
})(
  ({ theme, variant, active }: any) => {
    const { breakpoints, spacing } = theme;
    const { textColor } = backgrounds[variant];
    const indicatorWidth = active ? '100%' : 0;

    return {
      color: textColor,
      padding: spacing(3),
      fontWeight: 500,
      alignItems: 'center',
      position: 'relative',

      [breakpoints.up('md')]: {
        fontSize: 14,
      },
      [breakpoints.up('lg')]: {
        fontSize: 18,
      },

      '&:after': {
        content: '""',
        background: textColor,
        position: 'absolute',
        width: indicatorWidth,
        height: '3px',
        bottom: 0,
        left: 0,
        transition: 'width 0.3s ease-in-out',
      },
    };
  }
);

export const TopBarActionsStyled = styled('div')(({ theme }: any) => {
  const { breakpoints, spacing } = theme;

  return {
    display: 'flex',
    alignItems: 'stretch',
    padding: spacing(1, 0),
    [breakpoints.up('md')]: {
      padding: 0,
    },
  };
});

export const CollapseMenuStyled = styled(Collapse)(({ theme }: any) => {
  const { palette, spacing } = theme;

  return {
    position: 'absolute',
    top: '100%',
    width: '100%',
    marginLeft: `-${spacing(3)}`,
    padding: spacing(2),
    background: palette.backdrop.mobile,
    zIndex: '100',
  };
});

export const MobileSubmenuStyled = styled('div')(({ theme }: any) => {
  const { palette, spacing } = theme;
  return {
    background: darken(0.1, palette.backdrop.mobile),
    paddingLeft: spacing(3),

    '& p': {
      borderColor: palette.common.white,
    },
  };
});

export const MobileLinkStyled = styled(Typography)(({ theme }: any) => {
  const { palette, spacing } = theme;

  return {
    cursor: 'pointer',
    color: palette.common.white,
    padding: spacing(2, 0),
    borderTop: `1px solid ${palette.backdrop.divider}`,
    '&:first-of-type': {
      border: 'none',
    },
  };
});

export const ImgStyled = styled('img')(({ theme }: any) => {
  const { breakpoints } = theme;
  return {
    width: 120,
    [breakpoints.up('md')]: {
      width: 60,
    },
    [breakpoints.up('lg')]: {
      width: 80,
    },
  };
});

export const MenuNotificationStyled = styled(Box)(({ }: any) => {
  return {
    display: 'flex',
    alignItems: 'center',
    width: '333px',
  };
});

export const MenuFixedNotificationStyled = styled(Box)(({ theme }: any) => {
  const { palette } = theme;
  return {
    position: 'fixed',
    zIndex: 1,
    backgroundColor: palette.common.white,
    width: '355px',
  };
});

export const MenuItemStyled = styled(MenuItem)(({ theme }: any) => {
  const { spacing } = theme;
  return {
    padding: `${spacing(2)}`,
    width: '355px',
  };
});
