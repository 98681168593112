import { Grid, Skeleton } from '@mui/material';

type Props = {
  itemsCount: number,
  item?: boolean | undefined,
  height?: number,
  gridXs?: number,
  gridSm?: number,
  gridMd?: number,
  gridLg?: number,
  margin?: number
};

// TODO: replace loading skeleton everywhere with this component
function LoadingSkeleton({ itemsCount, item, height, gridXs, gridSm, gridMd, gridLg, margin }: Props) {
  const items = Array.from(Array(itemsCount).keys());

  return (
    <Grid item={item} container spacing={3} mb={margin}>
      {items.map((skeletonItem) => (
        <Grid item key={skeletonItem} xs={gridXs} sm={gridSm} md={gridMd} lg={gridLg}>
          <Skeleton variant="rectangular" height={height} />
        </Grid>
      ))}
    </Grid>
  );
}

LoadingSkeleton.defaultProps = {
  gridXs: 12,
  gridMd: 12,
  gridSm: 12,
  gridLg: 12,
  margin: 0
};

export default LoadingSkeleton;
