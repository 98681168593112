import { useEffect, useState } from 'react';
import { Badge, Button, Checkbox, List, ListItem, ListItemButton, Typography } from '@mui/material';
import { Tune } from '@mui/icons-material';
import BlockingMobileDialog from 'components/blocking-mobile-dialog';
import { SelectDataType } from 'components/select/types';
import { IconButtonStyled } from 'components/search/styles';

type Props = {
  data: SelectDataType[];
  onSelect: (items: SelectDataType[]) => void;
  title: string;
  value: SelectDataType[];
};

const MobileMultipleSelect = ({ onSelect, data, title, value }: Props) => {
  const [anchorElement, setAnchorElement] = useState<any>(null);
  const [selected, setSelected] = useState<SelectDataType[]>([]);

  useEffect(() => {
    const newSelected = selected.filter((s) => data.find((o) => o.id === s.id));
    setSelected(newSelected);
  }, [data]);

  const onItemClick = (item: SelectDataType) => {
    const itemAlreadySelected = selected?.find((s) => s.id === item.id);
    if (itemAlreadySelected) {
      setSelected(selected.filter((s) => s.id !== item.id));
    } else {
      setSelected([...selected, item]);
    }
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <>
      <IconButtonStyled
        color='primary'
        onClick={(event: any) => {
          if (event && event.currentTarget) {
            setAnchorElement(event.currentTarget);
          }
        }}
        isActive={Boolean(anchorElement)}
      >
        <Badge
          badgeContent={value.length}
          color='info'
          overlap='circular'
        >
          <Tune color='secondary' />
        </Badge>
      </IconButtonStyled>
      <BlockingMobileDialog
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(false)}
        title={title}
        footer={
          <>
            <Button
              variant='contained'
              onClick={() => { onSelect(selected); setAnchorElement(false); }}
            >
              Потвърди
            </Button>
            <Button
              variant='outlined'
              onClick={() => setSelected([])}
            >
              Изтрий всичко
            </Button>
          </>
        }
      >
        <List>
          {data.map((item: any) => {
            const { id, label } = item;
            const isSelected = Boolean(selected?.find((s) => s.id === id));
            return (
              <ListItem
                key={id}
                onClick={() => onItemClick(item)}
                sx={{ padding: 0 }}
              >
                <ListItemButton sx={{ py: 0 }}>
                  <Checkbox checked={isSelected} />
                  <Typography variant='subtitle2' noWrap color={isSelected ? 'primary' : ''}>
                    {label}
                  </Typography>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </BlockingMobileDialog>
    </>
  );
};

export default MobileMultipleSelect;