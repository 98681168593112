import styled from "@emotion/styled";
import { List, Container, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';

export const RootStyled = styled('div')(({ theme }) => {
  const { breakpoints }: any = theme;

  return ({
    background: '#d6cdd0',
    height: '430px',
    '& > div': {
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/m-600.jpg)`,
      backgroundPosition: '100% 0',
      [breakpoints.up('sm')]: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/m-960.jpg)`,
      },
      [breakpoints.up('md')]: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/m-1280.jpg)`,
      },
      [breakpoints.up('lg')]: {
        backgroundPosition: '100% 0'
      },
    }
  })
});

export const RootInnerStyled = styled('div')(({ theme }) => {
  const { breakpoints, palette }: any = theme;

  return ({
    height: '300px',
    background: '#0f0909',

    '& > div': {
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/inner-m-600.jpg)`,
      backgroundPosition: '100% 0',

      [breakpoints.up('sm')]: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/inner-m-960.jpg)`
      },

      [breakpoints.up('md')]: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/inner-m-1280.jpg)`
      },

      [breakpoints.up('lg')]: {
        backgroundPosition: '100% 0'
      },
    },

    '& $mainText': {
      color: palette.common.white
    }
  })
});

export const MarqueeContainerStyled = styled(Container)(() => {
  return ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  })
});

export const TextContainerStyled = styled('div')(({ theme }) => {
  const { breakpoints, spacing, palette }: any = theme;

  return ({
    textShadow: `0 0 10px ${palette.common.white}`,
    [breakpoints.up('sm')]: {
      maxWidth: '70%',
      marginLeft: spacing(3),
    },
    [breakpoints.up('md')]: {
      maxWidth: '72%'
    },
    [breakpoints.up('lg')]: {
      maxWidth: '60%'
    },
  })
});

export const MainTextStyled = styled(Typography)(({ theme }) => {
  const { breakpoints }: any = theme;

  return ({
    fontSize: '25px',
    lineHeight: '1.5',
    fontWeight: '700',
    letterSpacing: 0,

    [breakpoints.up('sm')]: {
      fontSize: '32px',
    },

    [breakpoints.up('md')]: {
      fontSize: '45px',
    }
  })
});

export const SubTextStyled = styled(Typography)(({ theme }) => {
  const { breakpoints, spacing }: any = theme;

  return ({
    fontSize: '15px',
    marginTop: spacing(3),
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      fontSize: '16px',
    },
    [breakpoints.up('md')]: {
      fontSize: '18px',
      maxWidth: '620px'
    }
  })
});

export const ListStyled = styled(List)(({ theme }) => {
  const { breakpoints, palette, spacing }: any = theme;

  return ({
    margin: spacing(0, 0, 3, 0),
    padding: 0,
    fontSize: '14px',
    color: palette.text.secondary,

    [breakpoints.up('md')]: {
      fontSize: '15px',
    },

    '& li': {
      display: 'flex',
      alignItems: 'center',
      padding: spacing(0.5, 0)
    }
  })
});

export const ListBulletStyled = styled(Check)(({ theme }) => {
  const { breakpoints, palette, spacing }: any = theme;

  return ({
    color: palette.success.main,
    fontSize: '14px',
    marginRight: spacing(2),
    [breakpoints.up('sm')]: {
      fontSize: '20px',
    },
  })
});
