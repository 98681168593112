import axios, { AxiosRequestConfig, Method } from 'axios';
import { AuthManager } from 'authentication/auth-manager';
import {
  Base_Auth_API_URL, Public_Communications_API_URL, BASE_API_URL,
  Base_Content_API_URL, Base_Notifications_API_URL, Base_Assignment_URL,
  Base_Meeting_API_URL,
} from 'services/api';
import { PaginatedResult } from 'types/global';
import * as Types from './types';
import { DEFAULT_NOTIFICATIONS_PAGINATION } from 'components/header/components/constants';
import { DEFAULT_PAGINATION } from 'views/library/config';
import { DEFAULT_SEARCH_PAGINATION } from 'views/search/config';
import { HomeworkStatuses } from 'enums/homeworkStatuses.enum';
import { buildFormBody } from 'utils/helpers';
import { AssignmentPeriodType } from 'enums/assignments/assignmentPeriodType.enum';
import { TypeCrud } from 'routes/administration/crud/config';

export interface CustomAxiosRequestConfig<T> extends AxiosRequestConfig<T> {
  errorHandling?: {
    message?: string;
    disabled?: boolean;
  };
}

const MIME_TYPES: { [key: string]: string; } = {
  'doc': 'application/msword',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'xls': 'application/vnd.ms-excel',
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'ppt': 'application/vnd.ms-powerpoint',
  'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'pdf': 'application/pdf'
};

const getContentTypeHeader = (body: any) => {
  if (body instanceof File) {
    const fileType = body.name.split('.')[1];
    if (MIME_TYPES[fileType]) {
      return { 'Content-Type': MIME_TYPES[fileType] };
    }
  } else if (!(body instanceof FormData)) {
    return { 'Content-Type': 'application/json' };
  }

  return {};
};

export const request = async (
  endpoint: string,
  method: Method,
  body: any = undefined,
  authorisation = true,
  requestConfig: CustomAxiosRequestConfig<any> | undefined = undefined,
) => {
  const isJsonBody = !(body instanceof FormData || body instanceof File);
  const headers = {
    ...(getContentTypeHeader(body)),
    ...(authorisation ? {
      Authorization: `Bearer ${AuthManager.getBearerToken()}`,
    } : {}),
  };

  const mergedRequestConfig = {
    errorHandling: {
      disabled: false,
    },
    ...(requestConfig || {}),
  };

  const { data } = await axios.request({
    ...mergedRequestConfig,
    method,
    url: endpoint,
    // @ts-ignore
    headers,
    data: isJsonBody ? JSON.stringify(body) : body,
  });

  return data;
};

export default request;

const generateListParams = (collectionName: any, items: any) => (items?.length > 0 ?
  items
    .map((field: any, index: any) => `${collectionName}[${index}]=${field}`)
    .join('&') :
  '');

// Public
export const requestPublicNews = async ({ itemsPerPage, page, tags, title, enabled }: any) => {
  const filter = `${tags ? `${generateListParams('Tags', tags)}&` : ''}${title ? `Title=${title}&` : ''}${enabled ? `Enabled=${enabled}` : ''}`;

  return request(`${Public_Communications_API_URL}/public/news?${filter}&Pagination.ItemsPerPage=${itemsPerPage}&Pagination.Page=${page}`, 'GET', undefined, false, {
    errorHandling: { disabled: true },
  });
};

export const requestPublicNotifications = async () => request(`${Public_Communications_API_URL}/public/notifications?Active=true`, 'GET', undefined, false, {
  errorHandling: { disabled: true },
});

//crud
export const getRoles = async () => request(`${Public_Communications_API_URL}/roles`, 'GET');

export const getItemCrud = async (type: TypeCrud, id: string | undefined): Promise<Types.News | Types.BlockingNotification> => request(`${Public_Communications_API_URL}/${type}/${id}`, 'GET');

export const createCrud = async (type: TypeCrud, data: Types.News | Types.BlockingNotification): Promise<PaginatedResult<Types.News | Types.BlockingNotification>> => request(`${Public_Communications_API_URL}/${type}`,
  'POST',
  type === TypeCrud.news ? buildFormBody(data) : data,
  true,
  {
    errorHandling: { message: "Възникна грешка. Моля, опитайте отново." },
  },
);

export const editCrud = async (type: TypeCrud, data: Types.News | Types.BlockingNotification, id: string | undefined): Promise<Types.News | Types.BlockingNotification> =>
  request(`${Public_Communications_API_URL}/${type}/${id}`, 'PUT', data, true, {
    errorHandling: { message: "Възникна грешка. Моля, опитайте отново." },
  });

export const deleteCrud = async (type: TypeCrud, id: string) => request(`${Public_Communications_API_URL}/${type}/${id}`, 'DELETE');

//Blocking notification
export const getBlockingNotifications = async (filter: { enabled: boolean, searchTitle?: string, disabledErrorHandling?: boolean, showAll?: boolean }, pagination: { itemsPerPage: number; page: number }) => {
  return request(
    `${Public_Communications_API_URL}/notifications?ShowAll=${filter.showAll || false}&Active=${filter.enabled}&${filter.searchTitle ? `Message=${filter.searchTitle}&` : ``}Pagination.ItemsPerPage=${pagination?.itemsPerPage}&Pagination.Page=${pagination?.page}`, 'GET', undefined, true,
    {
      errorHandling: { disabled: filter?.disabledErrorHandling },
    });
};

//news
export const getNews = async (filter: { showAll?: boolean, enabled: boolean, searchTitle?: string | undefined }, pagination: { itemsPerPage: number; page: number },): Promise<PaginatedResult<Types.News>> => {
  return request(`${Public_Communications_API_URL}/news?ShowAll=${filter.showAll || false}&${filter.searchTitle ? `Title=${filter.searchTitle}&` : ''}Enabled=${filter.enabled}&Pagination.ItemsPerPage=${pagination?.itemsPerPage}&Pagination.Page=${pagination?.page}`, 'GET');
};

export const requestApplications = async () => request(`${Public_Communications_API_URL}/applications`, 'GET', undefined, true);

export const getToken = async () => {
  const { data } = await axios.get(`${Base_Auth_API_URL}/public/token`, {
    headers: {
      Authorization: `Bearer ${AuthManager.getAzureAccessToken()}`,
    },
  });

  return data;
};

export const getEnrichedInstitutionToken = async (institutionid: string) => {
  const { data } = await axios.get(`${Base_Auth_API_URL}/public/token/enrich?institutionId=${institutionid}`, {
    headers: {
      Authorization: `Bearer ${AuthManager.getTempBearerToken()}`,
    },
  });

  return data;
};

export const getParentsToken = async (studentId: string, institutionId: number) => {
  const { data } = await axios.get(`${Base_Auth_API_URL}/public/token/enrichParent?studentId=${studentId}&institutionId=${institutionId}`, {
    headers: {
      Authorization: `Bearer ${AuthManager.getTempBearerToken()}`,
    },
  });

  return data;
};

export const getMozaikToken = async () => request(
  `${Base_Auth_API_URL}/public/MozaikToken`, 'GET', undefined, true,
);

export const getIzziToken = async () => request(
  `${Base_Auth_API_URL}/public/IzziToken`, 'GET', undefined, true,
);

// Noms
export const getBasicClassNom = async () => request(
  `${BASE_API_URL}/Nomenclature/basic-class?filterByClass=true`, 'GET',
);

export const getSimpleNomById = async (nomId: any) => request(
  `${BASE_API_URL}/Nomenclature/simple/${nomId}`, 'GET',
);

export const getTopic = async (id: any) => request(
  `${BASE_API_URL}/Nomenclature/topics/${id}`, 'GET',
);

export const getProfAreas = async () => request(
  `${BASE_API_URL}/Nomenclature/profArea/all`, 'GET',
);

export const getProfAreaByCode = async (areaCode: string) => request(
  `${BASE_API_URL}/Nomenclature/profArea?areaCode=${areaCode}`, 'GET',
);

export const getProfStudyAidsByCode = async (areaCode: string) => request(
  `${BASE_API_URL}/Nomenclature/profArea/study-aids?areaCode=${areaCode}`, 'GET',
);

export const getTopicsBySubjectAndBasicClasses = async (
  subjectId: number, basicClassesIds: number[],
): Promise<Types.Topic[]> => {
  const topics: Types.Topic[] = await request(
    `${BASE_API_URL}/Nomenclature/topics?subjectId=${subjectId}&${generateListParams('basicClassIds', basicClassesIds)}`,
    'GET',
  );
  return topics;
};

export const getTopicsBySubjectAndBasicClass = async (
  subjectId: number, basicClassId: number,
): Promise<Types.Topic[]> => getTopicsBySubjectAndBasicClasses(subjectId, [basicClassId]);

export const getTopicsBySubjectAndBasicClassPaginated = async (
  subjectId: number, basicClassId: number, offset: number, page: number,
): Promise<PaginatedResult<Types.Topic>> => await request(
  `${BASE_API_URL}/Nomenclature/subjects/${subjectId}/topics/paginated?basicClassIds[0]=${basicClassId}&filter.Offset=${offset}&filter.Page=${page}`,
  'GET',
);

export const getSubjectsByBasicClass = async (basicClass: number) => request(
  `${BASE_API_URL}/Nomenclature/subject-class/classes?basicclassid=${basicClass}`, 'GET',
);

export const getEnums = async () => request(
  `${BASE_API_URL}/enums`, 'GET',
);

export const getSchoolbooksBySubjectAndBasicClass = async (
  subjectId: number, basicClassId: number,
): Promise<Types.SchoolbookType[]> => request(
  `${BASE_API_URL}/Nomenclature/schoolbooks?subjectId=${subjectId}&basicClassId=${basicClassId}`, 'GET',
);

// Curriculum
export const getStudentCurriculum = async (): Promise<Types.StudentCurriculum[]> => request(`${BASE_API_URL}/Student/curriculum`, 'GET');

export const getTeacherCurriculum = async (subjectId?: number) => request(
  `${BASE_API_URL}/Teacher/curriculum${subjectId ? `?subjectId=${subjectId}` : ''}`, 'GET',
);

export const getSchedule = async (
  fromDate: string,
  toDate: string,
  type?: Types.ScheduleType,
  subjectId?: number,
  subjectTypeId?: number,
  classId?: number,
): Promise<Types.Shift[]> => request(
  `${BASE_API_URL}/${type}/schedule?fromdate=${fromDate}&todate=${toDate}${subjectId ? `&subjectId=${subjectId || ''}` : ''}&subjectTypeId=${subjectTypeId || ''}${classId ? `&classId=${classId}` : ''}`, 'GET',
);

export const getStudentCurriculumByCurriculumId = async (id: string): Promise<Types.StudentCurriculum> => request(
  `${BASE_API_URL}/Student/curriculum/${id}`,
  'GET',
);

export const getTeacherCurriculumByCurriculumId = async (id: string, classId?: number): Promise<Types.TeacherCurriculum[]> => request(
  `${BASE_API_URL}/Teacher/curriculum/${id}${classId ? `?classId=${classId}` : ''}`,
  'GET',
);

// Contents
export const getContent = async (id: any) => request(
  `${Base_Content_API_URL}/public/Contents/${id}`, 'GET', undefined, true,
);

export const getContentsByFilter = async (
  filter: { topicIds?: number[], subjectId?: number, isProfileSubject?: boolean, classIds?: number[], name?: string, offset?: number, page?: number },
): Promise<PaginatedResult<Types.Content>> => {
  const paginationParams = `Page=${filter.page || DEFAULT_PAGINATION.Page}&Offset=${filter.offset || DEFAULT_PAGINATION.Offset}`;
  const topicsFilter = filter.topicIds ? generateListParams('TopicIds', filter.topicIds) : '';
  const subjectFilter = `SubjectId=${filter.subjectId || ''}&IsProfileSubject=${filter.isProfileSubject || ''}`;
  const classIdsFilter = filter.classIds ? generateListParams('ClassIds', filter.classIds) : '';
  const filterParams = `${topicsFilter}&${subjectFilter}&${classIdsFilter}&Name=${filter.name || ''}&State=Published`;

  const response = await request(
    `${Base_Content_API_URL}/public/Contents?${paginationParams}&${filterParams}`, 'GET',
  );

  return response;
};

export const postContentRating = async (id: any, rating: number) => request(
  `${Base_Content_API_URL}/public/contents/${id}/ratings`, 'POST', { rating },
);

export const getFavoriteContentsByFilter = async (
  filter: { topicId?: number, name?: string, offset?: number, page?: number, subjectId?: number, basicClassId?: number, isProfileSubject?: boolean },
): Promise<PaginatedResult<Types.Content>> => {
  const pageParam = `Page=${filter.page || DEFAULT_PAGINATION.Page}`;
  const offsetParam = `Offset=${filter.offset || DEFAULT_PAGINATION.Offset}`;
  const filterParams = (
    `Name=${filter.name || ''}&TopicId=${filter.topicId || ''}&SubjectId=${filter.subjectId || ''}${filter.basicClassId ? `&BasicClassId=${filter.basicClassId}` : ''}${filter.isProfileSubject ? `&IsProfileSubject=${filter.isProfileSubject}` : ''}`
  );

  return request(`${Base_Content_API_URL}/public/contents/favourites?${pageParam}&${offsetParam}&${filterParams}`, 'GET');
};

export const addFavoriteContent = async (contentId: string) => request(
  `${Base_Content_API_URL}/public/contents/${contentId}/favourites`,
  'POST',
);

export const removeFavoriteContent = async (contentId: string) => request(
  `${Base_Content_API_URL}/public/contents/${contentId}/favourites`,
  'DELETE',
);

export const getStudentAssignedContentsByFilter = async (
  filter: { subjectId: number, topicId?: number, scheduleId?: number, name?: string, offset?: number, page?: number },
): Promise<PaginatedResult<Types.Content>> => {
  const pageParam = `Page=${filter.page || DEFAULT_PAGINATION.Page}`;
  const offsetParam = `Offset=${filter.offset || DEFAULT_PAGINATION.Offset}`;
  const filterParams = `Name=${filter.name || ''}&TopicId=${filter.topicId || ''}&ScheduleId=${filter.scheduleId || ''}`;

  return request(
    `${Base_Content_API_URL}/public/contents/assignments/subjects/${filter.subjectId}?${pageParam}&${offsetParam}&${filterParams}`,
    'GET',
  );
};

export const assignContentToClasses = async (
  contentId: string,
  assignment: Types.ClassesContentAssignmentRequest,
) => request(
  `${Base_Content_API_URL}/public/contents/${contentId}/assignments/classes`, 'POST', assignment,
);

export const getRelatedContents = async (
  filter: { topicId?: number, count?: number, contentId?: string },
): Promise<Types.Content[]> => {
  const filterParams = `TopicId=${filter.topicId || ''}&Count=${filter.count || ''}&ContentId=${filter.contentId || ''}&State=Published`;

  return request(
    `${Base_Content_API_URL}/public/contents/related?${filterParams}`,
    'GET',
  );
};

export const getAssignedContentBySubjectId = async (subjectId: number, scheduleId?: number, subjectTypeId?: number): Promise<Types.AssignedContentType[]> => request(
  `${Base_Content_API_URL}/public/contents/assignments/subjects/${subjectId}?${scheduleId ? `scheduleId=${scheduleId}` : ''}${subjectTypeId ? `&subjectTypeId=${subjectTypeId}` : ''}`,
  'GET',
);

export const getAssignedContentByClassId = async (classId: number, subjectId: number, scheduleId?: number, subjectTypeId?: number): Promise<Types.AssignedContentType[]> => request(
  `${Base_Content_API_URL}/public/contents/assignments/classes/${classId}/subjects/${subjectId}?${scheduleId ? `scheduleId=${scheduleId}` : ''}${subjectTypeId ? `&subjectTypeId=${subjectTypeId}` : ''}`,
  'GET',
);

export const deleteAssignedContentByClassId = async (id: string, classId: number, subjectId: string, assignmentPeriod: AssignmentPeriodType, scheduleId: number) => request(
  `${Base_Content_API_URL}/public/contents/${id}/assignments/classes/${classId}`,
  'DELETE',
  {
    subjectId,
    assignmentPeriod,
    scheduleId
  },
);

export const getSearchContentsBySubjectClassId = async (filter: Types.SearchContentsBySubjectClassIdFilterType):
  Promise<PaginatedResult<Types.SearchContentsBySubjectClassIdResultType>> => request(
    `${Base_Content_API_URL}/public/Contents/name/search?basicClassId=${filter.basicClassId}&subjectId=${filter.subjectId}&Type=${filter.type}&pattern=${filter.pattern}&offset=${filter.offset}&page=${filter.page}`,
    'GET',
  );

export const getAssignedContents = async (filter: { page: number, offset?: number, name?: string }): Promise<PaginatedResult<Types.AssignedContentItem>> => {
  const paginationParams = `filter.Page=${filter.page || DEFAULT_PAGINATION.Page}&filter.Offset=${filter.offset || DEFAULT_PAGINATION.Offset}`;
  const filterParams = `filter.Name=${filter.name || ''}`;

  return request(
    `${Base_Content_API_URL}/public/contents/assignments?${filterParams}&${paginationParams}`, 'GET',
  );
};

export const deleteAssignedContent = async (assignmentInfo?: Types.AssignmentInfoBase): Promise<void> => {
  const url = `${Base_Content_API_URL}/public/contents/${assignmentInfo?.contentId}/assignments/classes/${assignmentInfo?.classId || 0}`;
  return request(url,
    'DELETE',
    assignmentInfo,
    true,
    {
      errorHandling: {
        message: 'Насоченото съдържание не беше изтрито.',
      },
    },
  );
};

export const createFileContent = async (data: Types.FileContentRequest): Promise<Types.FileContentResponse> =>
  request(`${Base_Content_API_URL}/public/contents/file`,
    'POST',
    data,
    true,
    {
      errorHandling: {
        message: 'Възникна грешка при създаването на съдържанието.',
      },
    },
  );

export const uploadFileContent = async (url: string, data: File) =>
  request(url,
    'PUT',
    data,
    false,
    {
      errorHandling: {
        message: 'Възникна грешка при качването на съдържанието.',
      },
    },
  );

export const createLinkContent = async (data: Types.LinkContentRequest): Promise<Types.UploadedContentResponse> =>
  request(`${Base_Content_API_URL}/public/contents/link`,
    'POST',
    data,
    true,
    {
      errorHandling: {
        message: 'Възникна грешка при създаването на съдържанието.',
      },
    },
  );

export const deleteFileContent = async (contentId: any, attachmentId: any) => request(
  `${Base_Content_API_URL}/public/Contents/${contentId}?attachmentId=${attachmentId}`, 'DELETE', undefined, true,
);

// Notifications
export const getNotifications = async (pagination: { page: number, itemsPerPage: number }, isSeen?: boolean): Promise<PaginatedResult<Types.NotificationItem>> => {
  const paginationParams = `paging.page=${pagination.page || DEFAULT_NOTIFICATIONS_PAGINATION.page}&paging.itemsPerPage=${pagination.itemsPerPage || DEFAULT_NOTIFICATIONS_PAGINATION.itemsPerPage}`;
  const isSeenParam = isSeen !== undefined ? `filter.isSeen=${isSeen}&` : ``;
  return request(
    `${Base_Notifications_API_URL}/notifications?filter.type=100&${isSeenParam}${paginationParams}`, 'GET',
  );
};

export const getNotificationCounts = async (): Promise<Types.NotificationsCounts> => request(
  `${Base_Notifications_API_URL}/notifications/counts`, 'GET',
);

export const postSeenNotifications = async (notifications: any) => request(
  `${Base_Notifications_API_URL}/notifications/seen`, 'POST', notifications,
);

// Homeworks
export const getStudentAssignments = async (status?: Types.StudentHomeworksType): Promise<Types.StudentAssignment[]> =>
  request(`${Base_Assignment_URL}/public/studentassignments?status=${status}`, 'GET');

export const getStudentHomework = async (assignmentId: string): Promise<Types.StudentHomework> =>
  request(`${Base_Assignment_URL}/public/studentassignments/${assignmentId}`, 'GET');

export const getTeacherAssignments = async (): Promise<Types.TeacherAssignment[]> =>
  request(`${Base_Assignment_URL}/public/teacherassignments`, 'GET');

export const getTeacherAssignment = async (id: string): Promise<Types.DetailedTeacherAssignment> =>
  request(`${Base_Assignment_URL}/public/teacherassignments/${id}`, 'GET');

export const getHomeworkSolution = async (solutionId: string): Promise<Types.DetailedStudentSolution> =>
  request(`${Base_Assignment_URL}/public/solutions/${solutionId}`, 'GET');

export const getAllAssignmentSolutions = async (assignmentId: string, {
  studentName,
  statuses,
  page,
  offset,
}: Types.SolutionsFilter): Promise<PaginatedResult<Types.StudentSolution>> => {
  const query = `studentName=${studentName || ''}&${generateListParams('statuses', statuses)}&page=${page || DEFAULT_PAGINATION.Page}&offset=${offset || DEFAULT_PAGINATION.Offset}`;
  return request(`${Base_Assignment_URL}/public/solutions/${assignmentId}/all?${query}`, 'GET');
};

export const updateSolutionStatus = async (solutionId: string, status: HomeworkStatuses, disableDefaultErrorHanding = false) =>
  request(`${Base_Assignment_URL}/public/solutions/${solutionId}/status`,
    'PATCH',
    { status },
    true,
    {
      errorHandling: {
        disabled: disableDefaultErrorHanding,
        message: 'Възникна грешка при смяната на статуса на домашното.',
      },
    },
  );

export const saveDraftSolution = async (solution: Types.SolutionRequest, disableDefaultErrorHanding = false) =>
  request(`${Base_Assignment_URL}/public/solutions`,
    'POST',
    buildFormBody(solution),
    true,
    {
      errorHandling: {
        disabled: disableDefaultErrorHanding,
        message: 'Възникна грешка при записа на черновата.',
      },
    },
  );

export const assessSolution = async (solutionId: string, body: Types.SolutionAssessmentRequest): Promise<void> =>
  request(`${Base_Assignment_URL}/public/solutions/${solutionId}/assessment`,
    'POST',
    body,
    true,
    {
      errorHandling: {
        message: 'Възникна грешка при оценяването на домашното.',
      },
    },
  );

export const createAssignment = async (data: Types.AssignmentRequest): Promise<Types.CreateAssignmentResult> =>
  request(`${Base_Assignment_URL}/public/teacherassignments`,
    'POST',
    buildFormBody(data),
    true,
    {
      errorHandling: {
        message: 'Възникна грешка при създаването на домашното.',
      },
    },
  );

export const apiGetWaitingStudentAssignmentsBySubject = async (
  subjectId: number,
  isProfileSubject: boolean,
  offset?: number,
  page?: number,
): Promise<Types.BasicStudentAssignment[]> => {
  // returns only filtered results with status 100 & 200
  return request(
    `${Base_Assignment_URL}/public/StudentAssignments/subject?SubjectId=${subjectId}&IsProfileSubject=${isProfileSubject}${offset ? `&offset=${offset}` : ''}${page ? `&page=${page}` : ''}`,
    'GET',
  );
};

export const apiGetWaitingTeacherHomeworksBySubject = async (
  subjectId: number,
  classId: number,
  isProfileSubject: boolean,
  offset?: number,
  page?: number,
): Promise<Types.TeacherHomeworksBySubjectType> => request(
  `${Base_Assignment_URL}/public/TeacherAssignments/subject?SubjectId=${subjectId}&ClassId=${classId}&IsProfileSubject=${isProfileSubject}${offset ? `&offset=${offset}` : ''}${page ? `&page=${page}` : ''}`,
  'GET',
);

export const deleteAssignment = async (id: string): Promise<void> =>
  request(`${Base_Assignment_URL}/public/teacherassignments/${id}`,
    'DELETE',
    undefined,
    true,
    {
      errorHandling: {
        message: 'Домашното не беше изтрито.',
      },
    },
  );

// Attachments
export const getAttachments = async (referenceId: string, type: Types.AttachmentsType): Promise<Types.FileAttachment[]> => {
  let url = '';
  switch (type) {
    case Types.AttachmentsType.Assignment:
      url = `${Base_Assignment_URL}/public/assignments/${referenceId}/attachments`;
      break;
    case Types.AttachmentsType.Solution:
      url = `${Base_Assignment_URL}/public/solutions/${referenceId}/attachments`;
      break;
    default:
      console.error('Incorrect attachment type.');
      return Promise.reject();
  }

  return request(url, 'GET');
};

export const downloadAttachment = async (referenceId: string, attachmentId: string, type: Types.AttachmentsType, link?: string): Promise<Blob | string> => {
  let url = '';
  switch (type) {
    case Types.AttachmentsType.Assignment:
      url = `${Base_Assignment_URL}/public/assignments/${referenceId}/attachments/${attachmentId}/download`;
      break;
    case Types.AttachmentsType.Solution:
      url = `${Base_Assignment_URL}/public/solutions/${referenceId}/attachments/${attachmentId}/download`;
      break;
    case Types.AttachmentsType.Crud:
      return Promise.resolve(link || '');
    default:
      console.error('Incorrect attachment type.');
      return Promise.reject();
  }

  return request(url, 'GET', undefined, true, { responseType: 'blob' });
};

// Neispuo
export const getScheduleByStartEndDate = async (role: string, fromDate: string, toDate: string, subjectId: number | null = null) => request(
  `${BASE_API_URL}/${role}/schedule?fromdate=${fromDate}&todate=${toDate}${subjectId ? `&subjectId=${subjectId}` : ''}`, 'GET',
);

export const getGrades = async (role = 'Student', curriculumId?: number) => request(
  `${BASE_API_URL}/${role}/grades?${curriculumId ? `&curriculumId=${curriculumId}` : ''}`, 'GET',
);

export const getRemarks = async (role = 'Student', curriculumId?: number, offset?: number, page?: number): Promise<PaginatedResult<Types.Remark>> => request(
  `${BASE_API_URL}/${role}/remarks?${curriculumId ? `&curriculumId=${curriculumId}` : ''}&offset=${offset}&page=${page}`, 'GET',
);

export const getAbsences = async (role = 'Student', curriculumId?: number) => request(
  `${BASE_API_URL}/${role}/absences?${curriculumId ? `&curriculumId=${curriculumId}` : ''}`, 'GET',
);

// Meetings
export const getMeetings = async (fromDate: any, toDate: any) => request(
  `${Base_Meeting_API_URL}/public/meetings?from=${fromDate}&to=${toDate}`, 'GET',
);

export const createMeeting = async (meeting: Types.MeetingRequest) => request(
  `${Base_Meeting_API_URL}/public/meetings`, 'POST', meeting,
);

export const deleteMeeting = async (id: string) => request(
  `${Base_Meeting_API_URL}/public/meetings/${id}`, 'DELETE'
);

export const getMeetingsToolConfiguration = async () => request(
  `${Base_Meeting_API_URL}/public/institution/externalSystem`, 'GET'
);

export const setMeetingsToolConfiguration = async (externalSystem: Types.MeetingExternalSystem) => request(
  `${Base_Meeting_API_URL}/public/institution/externalSystem`, 'POST', { externalSystem }
);

// Global Search
export const getGlobalSearchResults = async (pagination: { itemsPerPage: number; page: number }, input?: string): Promise<PaginatedResult<Types.GlobalSearchItem>> => {
  const paginationParams = `filter.Page=${pagination.page || DEFAULT_SEARCH_PAGINATION.page}&filter.Offset=${pagination.itemsPerPage || DEFAULT_SEARCH_PAGINATION.itemsPerPage}`;
  const filterParams = Boolean(input) ? `filter.Pattern=${input}&` : '';

  return request(
    `${Base_Content_API_URL}/public/contents/search?${filterParams}${paginationParams}`, 'GET'
  )
};
