import { Grid, Typography } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  label?: {
    required?: boolean;
    text: string;
  };
  touched?: boolean;
  error?: string;
  height?: number;
  inputInfo?: string | ReactNode;
};

const FormInputRow = ({ label, children, error, touched, height, inputInfo }: Props) => (
  <Grid container alignItems="center" py={1} height={height}>
    {label && (
      <Grid item xs={12} md={2.5} py={1}>
        <Typography variant="h6" component="span">
          {label.text}
          {label.required && (
            <Typography variant="h6" component="span" color="error">
              *
            </Typography>
          )}
        </Typography>
      </Grid>
    )}
    <Grid item xs={12} md={label ? 9.5 : 12} display="flex" flexDirection="column" sx={{ height: '100%' }}>
      {children}
      {touched && error && (
        typeof error === 'string' ? (
          <Typography variant="caption" color="error" className="validation-error">{error}</Typography>
        ) : (error as string[]).map((e) => (
          <Typography variant="caption" color="error" className="validation-error">{e}</Typography>
        ))
      )}
    </Grid>
    {inputInfo && (
      <>
        {label && <Grid item xs={12} md={2.5} />}
        <Grid item xs={12} md={label ? 9.5 : 12} display="flex" flexDirection="column" sx={{ height: '100%' }}>
          {typeof inputInfo === 'string' ? (
            <Typography variant='subtitle2'>
              {inputInfo}
            </Typography>
          ) : inputInfo}

        </Grid>
      </>
    )}
  </Grid>
);

export default FormInputRow;