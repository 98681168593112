export enum Roles {
  Director = 0,
  MOE = 1,
  RME = 2,
  Teacher = 5,
  Student = 6,
  Parent = 7,
  ClassMaster = 8,
  RMEExpert = 9,
  MOEExpert = 12,
  AcademicTeacher = 22,
  Publisher = 23,
  SundaySchools = 24
}

// https://telelink.atlassian.net/wiki/spaces/MoELMS/pages/2845802497/Access+matrix
export const UserGroups = {
  Teacher: [Roles.Teacher, Roles.ClassMaster],
  RUO: [Roles.RME, Roles.RMEExpert],
  Admin: [Roles.MOE, Roles.MOEExpert],
};

export const AllUserRoles: any = Object.keys(Roles).reduce((a: any, c: any) => {
  const numericCurrent = Number(c);
  if (!isNaN(numericCurrent)) {
    return [...a, numericCurrent];
  }
  return a;
}, []);

export const SchoolbooksUserRoles: any = [
  ...UserGroups.Teacher,
  Roles.Student,
  Roles.Parent,
  Roles.Director,
];
