import styled from '@emotion/styled';
import { Check } from '@mui/icons-material';
import { CheckboxIcon } from 'components/icons/svg';
import { palette } from 'theme/components';

export const CheckboxIconStyled = styled(CheckboxIcon)({
  color: palette.common.white,
  border: `1px solid ${palette.secondary.dark}`,
});

export const CheckIconStyled = styled(Check)({
  border: `1px solid ${palette.secondary.dark}`
});