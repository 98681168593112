import React, { ReactNode } from 'react';
import {
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { palette } from 'theme/components';
import { DialogStyled } from './styles';

type Props = {
  open: boolean;
  onClose: React.MouseEventHandler;
  children: ReactNode;
  title: string;
  footer?: ReactNode;
};

const BlockingMobileDialog = ({
  open,
  onClose,
  title,
  children,
  footer,
}: Props) => {
  return (
    <DialogStyled open={open} fullScreen onClose={onClose}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        bgcolor={palette.common.white}
        p={1}
        pl={1}
      >
        <Grid item xs={11} alignItems="center">
          <Typography
            variant="h6"
            color={palette.primary[600]}
            align="center"
            fontWeight={700}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent sx={{ borderBlockColor: palette.grey[800] }}>
        {children}
      </DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </DialogStyled>
  );
};

BlockingMobileDialog.defaultProps = {
  footer: null,
};

export default BlockingMobileDialog;
