export const trimText = (text: string, maxLength: number = 20): string => {
  if (text && text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }

  return text;
};

export const displayTextInTwoLines = (
  text: string,
  showSymbol: number,
  maxSymbol: number
) => {
  const splittingText = text?.split(' ');
  let isSplitted = false;
  const result = splittingText?.reduce((acc: string, current: any) => {
    let returnText = `${acc}${current}`;
    if (!isSplitted) {
      if (acc.length <= showSymbol) {
        return returnText + ' ';
      } else {
        isSplitted = true;
        return returnText + '</br>';
      }
    }
    return returnText + ' ';
  }, '');
  return trimText(result, maxSymbol);
};

export const underlineTextByAnArrayOfSearchWords = (
  searchWordsArray: string[],
  text: string,
) => {
  let mutatedText = text;
  searchWordsArray.forEach((searchWord) => {
    const regex = new RegExp(searchWord.toLowerCase(), 'i');
    const originalFoundWord = mutatedText.match(regex)?.[0];
    mutatedText = mutatedText.replace(regex, `<u>${originalFoundWord}</u>`);
  });
  return mutatedText;
}
