import { PublicClientApplication } from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';
import { Roles } from 'enums/userRoles';
import { Roles as RolesObject } from 'enums/roles';
import { getEnrichedInstitutionToken, getParentsToken, getToken } from 'api/api';
import { authconfig } from './config';
import { BASE_APP_URL, REFRESH_TOKEN_MINUTES_BEFORE_EXPIRATION, USER_SESSION_LIFETIME_MINUTES } from 'windows';
import { B2CInstitutionsType, B2CStudentsType, Institution, IUserModel, LoginTypeEnum } from './types';

export const LOGOUT_EVENT_TYPE = 'logout';

const publicClientApp = (loginRoleType = LoginTypeEnum.aad) => new PublicClientApplication({
  auth: {
    ...authconfig[loginRoleType]
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
});

export class AuthManager {
  static userCacheKey = 'user';
  static institutionIdCacheKey = 'instId';
  static bearerCacheKey = 'bearer';
  static tempBearerCacheKey = 'tempBearer';
  static azureAccessToken = 'aTknid';
  static userSessionExpiration = 'sessionExpiration';
  static b2cData = [];

  static institutions: Institution[] = [];

  static azureID: string;

  static setInstitutionIds(institutionId: number): void {
    if (!institutionId || institutionId <= 0) {
      return;
    }

    let user = this.getUser();
    user.institutionId = institutionId.toString();
    this.setInstitutionId(institutionId.toString());

    this.setUser(user);
  }

  static AddUser(
    id: string,
    job: string,
    roles: Roles[],
    userName: string,
    name: string,
    azureId: string,
    subjects: string[],
    schoolName: string,
    schoolId: number,
    classes: number[],
    selectedInstitutionId: string,
    accessToken: string,
    loginType: LoginTypeEnum,
    hasSpecialities: string,
    students?: B2CStudentsType[],
    classId?: number,
  ): void {
    let user = {
      username: userName,
      name: name,
      id: id,
      job: job,
      roles: roles,
      azureId: azureId,
      subjects: subjects,
      school: schoolName,
      schoolId: schoolId,
      class: classes,
      schoolYear: '2021',
      institutionId: selectedInstitutionId,
      accessToken:accessToken,
      loginType,
      hasSpecialities,
      ...(students ? { students } : {}),
      ...(classId ? { classId: +classId } : {})
    };

    localStorage.setItem(this.userCacheKey, JSON.stringify(user));
  }

  static getLoggedAccount = () => {
    const { loginType }: IUserModel = this.getUser();
    const accounts = publicClientApp(loginType).getAllAccounts();
    const userId = this.getUser()?.azureId;
    const account = accounts.find(a => a.localAccountId === userId);
    return account;
  }

  static refreshToken = async () => {
    const account = this.getLoggedAccount();
    if (!account) {
      return this.logout();
    }

    const { loginType, schoolId, students }: IUserModel = this.getUser();
    const result = await publicClientApp(loginType).acquireTokenSilent({
      scopes: authconfig[loginType].scopes || [],
      account
    });

    this.azureID = result.uniqueId;
    this.setAzureAccessToken(result.accessToken);
    let { access_token } = await getToken();
    this.setTempBearerToken(access_token);

    if (loginType === LoginTypeEnum.aad) {
      const institutionId = this.getInstitutionId();
      if (institutionId) {
        const enrichedTokenResponse = await getEnrichedInstitutionToken(
          institutionId
        );
        access_token = enrichedTokenResponse.access_token;
      }

      this.setBearerToken(access_token);
      const { exp } = jwtDecode<any>(access_token);
      this.setTimerForRefreshingToken(exp);
      return access_token;
    }

    if (loginType === LoginTypeEnum.b2c) {
      const studentId = students.find(({ Selected }) => Selected)?.Id;

      if (studentId) {
        const { access_token } = await finalParentsTokenEnrichment(studentId, schoolId);
        return access_token;
      } else {
        await this.logout();
      }
    }

  }

  static removeStorageData = () => {
    this.removeUser();
    this.removeBearerToken();
    this.removeAzureAccessToken();
    this.removeInstitutionId();
    this.removeTempBearerToken();
    this.removeUserSessionExpiraton();
  };

  static logout = (): Promise<void> => {
    const account = this.getLoggedAccount();
    if (account) {
      const { loginType }: IUserModel = this.getUser();
      return publicClientApp(loginType)
        .logoutPopup({ account, mainWindowRedirectUri: BASE_APP_URL })
        .then(() => this.removeStorageData())
        .catch(() => this.logoutFromApp());
    }

    this.logoutFromApp();
    return Promise.resolve();
  };

  static logoutFromApp = () => {
    this.removeStorageData();
    window.dispatchEvent(new Event(LOGOUT_EVENT_TYPE));
  };

  static getUser = (): IUserModel => {
    const user = JSON.parse(localStorage.getItem(this.userCacheKey) || '{}');

    let instId = this.getInstitutionId();
    if (user.institutionId == '' && instId != '') {
      user.institutionId = instId;
      this.setUser(user);
    }

    return user;
  };

  // enriched token from identity
  static getBearerToken(): string {
    let user = this.getUser();
    let bearerToken = localStorage.getItem(this.bearerCacheKey) || '';

    if (bearerToken == '' && user.accessToken != '') {
      localStorage.setItem(this.bearerCacheKey, user.accessToken);
      bearerToken = user.accessToken;
    }

    return bearerToken;
  }

  static setBearerToken(bearerToken: string): void {
    localStorage.setItem(this.bearerCacheKey, bearerToken);
  }

  static removeBearerToken(): void {
    localStorage.removeItem(this.bearerCacheKey);
  }

  // not enriched token from identity
  static setTempBearerToken(token: string): void {
    localStorage.setItem(this.tempBearerCacheKey, token);
  }

  static removeTempBearerToken(): void {
    localStorage.removeItem(this.tempBearerCacheKey);
  }

  static getTempBearerToken(): string {
    const token = localStorage.getItem(this.tempBearerCacheKey) || '';
    return token;
  }

  static removeUser(): void {
    localStorage.removeItem(this.userCacheKey);
  }

  static setUser(user: IUserModel): void {
    let instId = this.getInstitutionId();

    if (user.institutionId == '' && instId != '') {
      user.institutionId = instId;
    }

    localStorage.setItem(this.userCacheKey, JSON.stringify(user));
  }

  static setInstitutionId(institutionId: string): void {
    localStorage.setItem(this.institutionIdCacheKey, institutionId);
  }

  static getInstitutionId(): string {
    const institutionId = localStorage.getItem(this.institutionIdCacheKey) || '';
    return institutionId;
  }

  static removeInstitutionId(): void {
    localStorage.removeItem(this.institutionIdCacheKey);
  }

  static getAzureAccessToken(): string {
    const azureAccessToken = localStorage.getItem(this.azureAccessToken) || '';
    return azureAccessToken;
  }

  static setAzureAccessToken(azureAccessToken: string): void {
    localStorage.setItem(this.azureAccessToken, azureAccessToken);
  }

  static removeAzureAccessToken(): void {
    localStorage.removeItem(this.azureAccessToken);
  }

  static getUserSessionExpiration(): number {
    const expiration = localStorage.getItem(this.userSessionExpiration) || '0';
    return parseInt(expiration);
  }

  static setUserSessionExpiraton(expiration?: number): number {
    if (!expiration) {
      expiration = Date.now() + USER_SESSION_LIFETIME_MINUTES * 60 * 1000;
    }

    localStorage.setItem(this.userSessionExpiration, expiration.toString());
    return expiration;
  }

  static removeUserSessionExpiraton(): void {
    localStorage.removeItem(this.userSessionExpiration);
  }

  static isAuthenticated = () => {
    const token = this.getBearerToken();
    if (token) {
      try {
        const { exp } = jwtDecode<any>(token);
        return Number(exp) * 1000 - Date.now() > 0;
      } catch {
        return false;
      }
    }

    return false;
  };

  static getUserRoles = () => {
    let usr = this.getUser();

    const isAdmin =
      Object.entries(usr).length > 0 && usr?.roles?.indexOf(Roles.Director) >= 0;
    const isTeacher =
      Object.entries(usr).length > 0 && usr?.roles?.indexOf(Roles.Teacher) >= 0;
    const isStudent =
      Object.entries(usr).length > 0 && usr?.roles?.indexOf(Roles.Student) >= 0;

    return { isAdmin, isTeacher, isStudent };
  };

  static setTimerForRefreshingToken = (tokenExpiration?: string | null) => {
    if (!tokenExpiration) {
      const token = this.getBearerToken();
      const { exp }: any = jwtDecode(token);
      tokenExpiration = exp;
    }

    const msUntilRefresh = this.getMsUntilTokenExpiration(tokenExpiration) - REFRESH_TOKEN_MINUTES_BEFORE_EXPIRATION * 60 * 1000;
    if (msUntilRefresh > 0) {
      setTimeout(this.onTokenRefresh, msUntilRefresh);
    }
  }

  static onTokenRefresh = () => {
    const token = this.getBearerToken();
    const { exp }: any = jwtDecode(token);
    if (this.getMsUntilTokenExpiration(exp) <= REFRESH_TOKEN_MINUTES_BEFORE_EXPIRATION * 60 * 1000) {
      this.refreshToken();
    } else {
      this.setTimerForRefreshingToken(exp);
    }
  };

  static getMsUntilTokenExpiration = (expiration?: string | null): number => {
    if (!expiration) return 0;

    return Number(expiration) * 1000 - Date.now();
  };
}

export const createAuthManagerInstitution = (institutionsArray: string[]): any[] => {
  return institutionsArray.map((institutionItem) => {
    if (!institutionItem) {
      return {
        id: '0',
        name: 'no institution'
      };
    }
    const institution: any = { id: 0, name: '' };
    const keyVal = institutionItem.split(':');
    const nameSplit = keyVal[1].split('-');

    institution.id = Number(keyVal[0]);
    institution.name = nameSplit.length > 1 ?  `${nameSplit[0].trim()} (${nameSplit[1].trim()})` : `${nameSplit[0].trim()}`;
    return institution;
  });
}

export const populateUserDataByAccessToken = (access_token: string, institutionsArray: string[]) => {
  const { basicClassId, firstName, id, lastName, mail, role, classId, hasSpecialities } = jwtDecode<any>(access_token);

  // user data:
  const userId: string = id;
  const job: string = RolesObject[role].name;
  const roles: number[] = [Number(role)];
  const userName: string = mail;
  const name: string = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
  const azureId: string = AuthManager.azureID;
  const subjects: string[] = [];
  const classes: number[] = basicClassId ? [Number(basicClassId)] : [];
  const hasSpecialty: string = hasSpecialities ? hasSpecialities : 'false'

  let schoolName: string = '';
  let schoolId: number = 0;
  let selectedInstitutionId: string = '';

  if (institutionsArray.length === 1) {
    schoolId = AuthManager.institutions[0].id;
    schoolName = AuthManager.institutions[0].name;
    // we need to switch the types here, because AddUser needs different types
    selectedInstitutionId = `${AuthManager.institutions[0].id}`;
    // add the id in AuthManager
    AuthManager.setInstitutionIds(schoolId);
  }

  // TODO: TRASH!
  AuthManager.AddUser(
    userId, job, roles, userName, name, azureId, subjects, schoolName, schoolId,
    classes, selectedInstitutionId, access_token, LoginTypeEnum.aad, hasSpecialty, undefined, classId
  );
}

// TODO: ALL THIS SHOULD BE REFACTORED!! IN Backend AS WELL!
export const populateB2CDataByAccessToken = (access_token: string) => {
  const { students, parentId, role, mail, firstName, lastName, hasSpecialities } = jwtDecode<any>(access_token);
  const parsedStudents = JSON.parse(students);
  const selectedStudent = parsedStudents.find(({ Selected }: B2CStudentsType) => Selected);
  const selectedInstitution = selectedStudent.Institutions.find(({ Selected }: B2CInstitutionsType) => Selected);

  // user data:
  const userId: string = parentId;
  const job: string = RolesObject[role].name;
  const roles: number[] = [Number(role)];
  const userName: string = mail;
  const name: string = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
  const azureId: string = AuthManager.azureID;
  const subjects: string[] = [];
  const classes: number[] = [];
  const schoolId: number = selectedInstitution.InstitutionId;
  const schoolName: string = selectedInstitution.InstitutionName;
  const hasSpecialty: string = hasSpecialities ? hasSpecialities : 'false'
  // TODO: this should go in the trash, it brings me stress --->
  AuthManager.AddUser(
    userId, job, roles, userName, name, azureId, subjects, schoolName, schoolId,
    classes, `${schoolId}`, access_token, LoginTypeEnum.b2c, hasSpecialty, parsedStudents,
  );
}

export const accessTokenPropertyName = (loginRoleType: LoginTypeEnum) =>  loginRoleType === LoginTypeEnum.aad ? 'accessToken' : 'idToken';

const getAccessToken = async (loginRoleType: LoginTypeEnum) => {
  // we begin with Azure Identity Popup. We want to get the Azure token first.
  const response = await publicClientApp(loginRoleType).loginPopup({
    scopes: authconfig[loginRoleType].scopes,
    prompt: 'select_account'
  });

  // we populate the data in the AuthManager object
  AuthManager.azureID = response.uniqueId;
  AuthManager.setAzureAccessToken(response[accessTokenPropertyName(loginRoleType)]);
  // we get a token with the user's data that comes from НЕИСПУО
  const { access_token } = await getToken();
  AuthManager.setTempBearerToken(access_token);

  return access_token;
}

export const beginAADLoginProcess = async (promptEnrichTokenProcess: Function, loginProcessFail: Function) => {
  const loginRoleType = LoginTypeEnum.aad;
  try {
    localStorage.removeItem('hasScheduleRedirected');
    const access_token = await getAccessToken(loginRoleType);
    // let's decode the token and populate some user's data we can use later:
    const { institutions, exp } = jwtDecode<any>(access_token);
    // in case we have only one institution we will just continue with it.
    // In other case, we will prompt the user to choose institution
    const institutionsArray = [].concat(institutions);
    // we populate all institution the user has in the AuthManager
    AuthManager.institutions = createAuthManagerInstitution(institutionsArray);

    if (institutionsArray.length === 1) {
      // we just populate our data and go to the digital backpack website
      AuthManager.setBearerToken(access_token);
      populateUserDataByAccessToken(access_token, institutionsArray);
      AuthManager.setTimerForRefreshingToken(exp);
      return true;
    }

    if (institutionsArray.length > 1) {
      // we prompt the user to choose their institution
      promptEnrichTokenProcess();
    }

    if (institutionsArray.length === 0) {
      // this is a faulty data, we have to log out the user and
      // display an error message
      await AuthManager.logout();
    }
  } catch(e) {
    // if login fails:
    loginProcessFail(e);
  }
}

export const beginB2CLoginProcess = async (promptEnrichTokenProcess: Function, loginProcessFail: Function) => {
  const loginRoleType = LoginTypeEnum.b2c;
  try {
    const access_token = await getAccessToken(loginRoleType);
    const { students: stringifiedStudents } = jwtDecode<any>(access_token);
    // get all students that the parent have and all their institutions.
    const students = JSON.parse(stringifiedStudents);
    AuthManager.b2cData = students;

    if (students.length >= 1) {
      // If we have only one student and only one institution, we just continue...
      if (students.length === 1 && students[0].Institutions.length === 1) {
        await finalParentsTokenEnrichment(students[0].Id, students[0].Institutions[0].InstitutionId);
        return true;
      }

      promptEnrichTokenProcess();
    }

    if (students.length <= 0) {
      // if the students are less than 1, we have faulty data:
      await AuthManager.logout();
    }
    return false;
  } catch(e) {
    // if login fails:
    loginProcessFail(e);
    return false;
  }
}

export const finalParentsTokenEnrichment = async (studentId: string, institutionId: number) => {
  // enrich parent's token for this student and institution
  const { access_token } = await getParentsToken(studentId, institutionId);
  AuthManager.setBearerToken(access_token);
  const { exp } = jwtDecode<any>(access_token);
  populateB2CDataByAccessToken(access_token);
  AuthManager.setTimerForRefreshingToken(exp);
  return access_token;
}
