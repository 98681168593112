import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from 'utils/dateFormat';
import { trimText } from 'utils/text';
import { TypeCrud } from './config';
import Crud from './crud';

function NewsCrud() {
  const { t } = useTranslation();
  return (
    <Crud
      type={TypeCrud.news}
      headers={[
        {
          field: 'dateCreated',
          label: t('news.table.dateCreated'),
          renderer: (value: any) => dateFormat(value),
        },
        {
          field: `titleBg`,
          label: t('news.table.title'),
        },
        {
          field: `contentBg`,
          label: t('news.table.content'),
          renderer: (value: any) => trimText(value, 100),
        },
      ]}
    />
  );
}

NewsCrud.defaultProps = {};

export default NewsCrud;
