import React from 'react';
import { RootStyled } from './styles';

type Props = {
  children: React.ReactElement,
  variant: string | number
};

function Background({ children, variant }: Props) {
  return (
    <RootStyled variant={variant}>
      <div>
        {children}
      </div>
    </RootStyled>
  );
}

Background.defaultProps = {
};

export default Background;
