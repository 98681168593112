import { getHomePageUrl } from 'utils/navigation';

export const studyAidBreadCrumbs = (): any => [
  {
    href: getHomePageUrl(),
    label: 'Начало'
  },
  {
    href: '/study-aids',
    label: 'Учебни помагала'
  }
];

export const getStudyAidBreadCrumbs = (): any => [
  ...studyAidBreadCrumbs(),
];