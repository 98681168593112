import React, { useEffect, useState } from 'react';
import { Box, IconButton, Input, InputAdornment, Typography } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { IconButtonStyled, DrawerStyled, ButtonStyled } from './styles';
import { palette } from 'theme/components';

type Props = {
  initialValue?: string;
  onSearch: Function;
  controlledSearch?: {
    onInputChange: Function;
    searchValue: string;
  }
};

  function MobileSearch({ initialValue, onSearch, controlledSearch }: Props) {
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false);
  const [searchValue, setSearchValue]: any = useState(initialValue || '');

  useEffect(() => {
    setSearchValue(initialValue);
  }, [initialValue])

  const onSearchClose = () => {
    setOpenSearchDrawer(false);
    setSearchValue(initialValue || '');
  }

  return (
    <>
      <IconButtonStyled
        color='primary'
        onClick={() => setOpenSearchDrawer(!openSearchDrawer)}
        isActive={openSearchDrawer}
      >
        <Search />
      </IconButtonStyled>
      <DrawerStyled
        anchor='top'
        open={openSearchDrawer}
        onClose={onSearchClose}
      >
        <Box px={2} pt={2} pb={4} display='flex' flexDirection='column' alignItems='center'>
          <Box display='flex' width='100%' justifyContent='flex-end'>
            <IconButton onClick={onSearchClose}>
              <Close sx={{ color: palette.common.white }} />
            </IconButton>
          </Box>
          <Typography
            fontWeight='300'
            variant='h3'
            sx={{ color: palette.common.white }}
            fontSize={30}
            mb={4}
          >
            Търсене
          </Typography>
          <Input
            fullWidth
            placeholder='Търси'
            sx={{ boxShadow: 4, mb: 3 }}
            endAdornment={
              <InputAdornment position='end'>
                <Search sx={{ color: palette.primary.light }} />
              </InputAdornment>
            }
            onChange={(e) => {
              if (controlledSearch) {
                controlledSearch.onInputChange(e.target.value);
              } else {
                setSearchValue(e.target.value);
              }
            }}
            value={controlledSearch ? controlledSearch.searchValue : searchValue}
          />
          <ButtonStyled
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              onSearchClose();
              onSearch(searchValue);
            }}
          >
            Потвърди
          </ButtonStyled>
        </Box>
      </DrawerStyled>
    </>
  );
}

MobileSearch.defaultProps = {};

export default MobileSearch;
