import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowForward, Phone, Email, AccessTime } from '@mui/icons-material';
import HelpDialog from './help-dialog';

import Login from 'components/login';
import { getHomePageUrl } from 'utils/navigation';

import { AuthManager } from 'authentication/auth-manager';
import { LoginTypeEnum } from 'authentication/types';

import { helpContactData } from './data';

import { QuickLinkContainerStyled } from './styles';
import AccountDialog from './account-dialog';

const { REACT_APP_PARENT_AUTH_ENABLED } = window._env_ || process.env;

const Meta = ({ tel, email, workTime }: any) => {
  return (
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      mt={-2}
      mb={2}
    >
      <Grid item display="flex" alignItems="center" mb={1} gap={1}>
        <Phone color="primary" fontSize="large" />
        <a href={`tel:${tel}`}>{tel}</a>
      </Grid>
      <Grid item display="flex" alignItems="center" mb={1} gap={1}>
        <Email color="primary" fontSize="large" />
        <a href={`mailto:${email}`}>{email}</a>
      </Grid>
      <Grid
        item
        display="flex"
        alignItems="center"
        mb={1}
        gap={1}
        component="span"
      >
        <AccessTime color="primary" fontSize="large" />
          {workTime}
      </Grid>
    </Grid>
  );
};

const containers = (setDialogOpen: any, setParentInfoDialogOpen: any) => {
  const { t } = useTranslation();
  const isLoggedIn = AuthManager.isAuthenticated();
  const helpData = helpContactData();
  const navigate = useNavigate();

  const renderLoggedInButton = (
    <Button
      variant='contained'
      color='info'
      endIcon={<ArrowForward />}
      onClick={() => navigate(getHomePageUrl())}
    >
      {t('common.proceedToDashboard')}
    </Button>
  );

  return [
    {
      id: 1,
      image: 'images/login-teacher-student.png',
      title: 'landingPage.section.quickLinks.1.title',
      description: 'landingPage.section.quickLinks.1.description',
      action: (
        <>
          {isLoggedIn && renderLoggedInButton}
          {!isLoggedIn && <Login />}
        </>
      ),
    },
    ...(REACT_APP_PARENT_AUTH_ENABLED ? [{
      id: 2,
      image: 'images/login-parent.jpg',
      title: 'landingPage.section.quickLinks.2.title',
      description: 'landingPage.section.quickLinks.2.description',
      action: (
        <>
          {isLoggedIn && renderLoggedInButton}
          {!isLoggedIn && <Login loginRoleType={LoginTypeEnum.b2c} />}
          <Button
            sx={{ ml: 1 }}
            variant='contained'
            color='info'
            endIcon={<ArrowForward />}
            onClick={() => setParentInfoDialogOpen(true)}
          >
            {t('landingPage.section.quickLinks.2.actionButtons.noAccount')}
          </Button>
        </>
      )
    }] : []),
    {
      id: 3,
      image: 'images/oer.png',
      title: 'landingPage.section.quickLinks.3.title',
      description: 'landingPage.section.quickLinks.3.description',
      action: (
        <Button
          variant='contained'
          color='info'
          endIcon={<ArrowForward />}
          onClick={() => window.open('https://oer.mon.bg', '_blank')}
        >
          {t('landingPage.navigation.resources')}
        </Button>
      ),
    },
    {
      id: 4,
      image: 'images/contact.jpg',
      title: 'landingPage.section.quickLinks.4.title',
      subTitle: (
        <Meta
          tel={helpData.meta.tel}
          email={helpData.meta.email}
          workTime={helpData.meta.workTime}
        />
      ),
      description: 'landingPage.section.quickLinks.4.description',
      action: (
        <Grid item>
          <Button
            variant="contained"
            color="info"
            endIcon={<ArrowForward />}
            onClick={() => setDialogOpen(true)}
          >
            {t('common.seeMore')}
          </Button>
        </Grid>
      ),
    },
  ];
};

function QuickLinksSection() {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [parentDialogOpen, setParentDialogOpen] = useState(false);

  const { t } = useTranslation();
  const renderContainers = containers(setHelpDialogOpen, setParentDialogOpen);

  return (
    <>
      <Container maxWidth='lg'>
        {
          renderContainers.map(({ id, image, title, description, action, subTitle = null }) => (
            <QuickLinkContainerStyled
              container
              key={id}
              spacing={{
                xs: 3,
                sm: 6,
                md: 10
              }}
            >
              <Grid xs={12} sm={6} item>
                <img alt={t(title)} src={image} />
              </Grid>
              <Grid xs={12} sm={6} item>
                <Typography variant='h3'>{t(title)}</Typography>
                {subTitle}
                <Typography>{t(description)}</Typography>
                <Grid
                  container
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                  gap={1}
                  mb={2}
                  mt={3}
                >
                  {action}
                </Grid>
              </Grid>
            </QuickLinkContainerStyled>
          )
        )}
      </Container>
      <HelpDialog open={helpDialogOpen} onClose={() => setHelpDialogOpen(false)} />
      <AccountDialog open={parentDialogOpen} onClose={() => setParentDialogOpen(false)} />
    </>
  );
}

QuickLinksSection.defaultProps = {};

export default QuickLinksSection;
