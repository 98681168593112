import styled from '@emotion/styled';
import { Button, ButtonProps } from '@mui/material';

export const AttachButton = styled((props: ButtonProps) => <Button {...props} />)
(({ theme: { palette } }: any) => ({
  width: '100%',
  borderRadius: 0,
  boxShadow: 'none',
  color: palette.text.primary,
  background: palette.primary[50],
  fontWeight: 500,
  fontSize: '16px',
  textTransform: 'none',
  '&:hover': {
    background: palette.primary[100]
  }
}));
