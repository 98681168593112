import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import { CardIconAvatar } from 'components/card';
import Breadcrumbs from 'components/breadcrumbs';
import LoadingSkeleton from 'components/loading-skeleton';
import { MultipleSelectLibrary } from 'components/select';
import { ContextSearch } from 'components/search';
import { FallbackSection } from 'components/fallback';
import GradesPage from './grades-page';
import { HIGHEST_GRADE, LOWEST_GRADE } from './constants';

import { useGetSubjectsByBasicClass } from 'services/queryHooks/nomenclatures-hooks';

import useTextFieldSearch from 'hooks/useTextFieldSearch';
import { useNomenclaturesContext } from 'hooks/useNomenclaturesContext';

import { getSubjectConfig } from 'config/subject-config-data';
import { libraryBreadcrumbs } from 'views/library/breadcrumbs';
import { palette } from 'theme/components';


const LibraryClass = () => {
  /* Hooks */
  const navigate = useNavigate();

  const { classId }: any = useParams();
  const { list, setList, handleSearch, inputValue } = useTextFieldSearch('name');

  const { basicClasses, classSubjects: { setClassSubjects } } = useNomenclaturesContext();

  const selectData = basicClasses.items.reduce((data: any, item: any) => {
    if (item.id != classId)
      data.push({ id: item.id, label: `${item.name} клас` })

    return data;
  }, []);

  const { data: subjects, isFetching: areSubjectsFetching } = useGetSubjectsByBasicClass(classId);

  /* Effects */
  useEffect(() => {
    setList(subjects);
    setClassSubjects({ classId, items: subjects });
  }, [subjects]);

  {/* This is because of the few seconds time delay
  between subjects being loaded and being set into list */}
  const listLoading = !areSubjectsFetching && Boolean(subjects?.length) && !Boolean(list?.length) && !inputValue;

  {/* If student is in institution satisfied by csop specification
  with basic class different than 1 to 12, navigate to GradesPage */}
  if (classId < LOWEST_GRADE || classId > HIGHEST_GRADE) {
    return (
      <GradesPage />
    )
  }

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={libraryBreadcrumbs()} />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={7} md={9} display="flex" alignItems="center" mb={3}>
          <Link to={'/library'}>
            <ArrowBackIos fontSize="large" color="secondary" />
          </Link>
          <Typography variant="h1">{classId} Клас</Typography>
          <Box ml={3}>
            <MultipleSelectLibrary
              data={selectData}
              onChange={(c: any) => {
                navigate(`/library/${c.id}`);
                handleSearch('');
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5} md={3} display="flex" alignItems="flex-start" justifyContent="flex-end">
          <ContextSearch value={inputValue} onChange={(value: string) => handleSearch(value)} />
        </Grid>
      </Grid>
      {(areSubjectsFetching || listLoading) && (
        <LoadingSkeleton itemsCount={6} height={110} gridMd={6} gridLg={6} />
      )}
      {areSubjectsFetching || listLoading || (
        <>
          {Boolean(list?.length) && (
            <Grid container spacing={3}>
              {list.map((subject: any) => {
                const { id, name } = subject;
                const subjectConfig = getSubjectConfig(id);
                return (
                  <Grid key={`subject${id}`} item xs={12} md={6}>
                    <Link to={`/library/${classId}/${id}`}>
                      <Card sx={{ cursor: 'pointer' }}>
                        <CardContent>
                          <Box display="flex" alignItems="center" gap={1}>
                            <CardIconAvatar
                              iconBackdrop={subjectConfig.color}
                              icon={subjectConfig.icon({
                                fontSize: 'large',
                              })}
                              withShadow
                            />
                            <Typography variant="h4">{name}</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          )}
          {!Boolean(list?.length) && (
            <>
              {!inputValue && (
                <FallbackSection message="Няма налични предмети за този клас." />
              )}
              {inputValue && (
                <FallbackSection
                  message={(
                    <>
                      Няма открити резултати за
                      <Typography
                        component='span'
                        sx={{ color: palette.primary[700] }}
                      >
                        "{inputValue}"
                      </Typography>
                    </>
                  )}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LibraryClass;
