import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const SelectLabelStyled = styled(Typography)(({ theme }: any) => {
  const { palette, spacing } = theme;

  return {
    color: palette.text.primary,
    fontWeight: '600',
    paddingBottom: spacing(1)
  }
});