import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { objectToSearchString } from 'serialize-query-params';
import { useDebounce } from 'use-debounce';

import { Box, Button, ButtonGroup, Card, CardContent, Grid, Stack, Tooltip, Typography, useMediaQuery, } from '@mui/material';
import { ArrowBackIos, Error as ErrorIcon } from '@mui/icons-material';

import Breadcrumbs from 'components/breadcrumbs';
import { MultipleSelectLibrary } from 'components/select';
import SelectButton from 'components/select-button';
import LoadingSkeleton from 'components/loading-skeleton';
import { ContextSearch } from 'components/search';
import { FallbackSection } from 'components/fallback';
import RCS from 'components/responsive-control-system';
import { ContentsList } from './components';
import { palette } from 'theme/components';

import { getSubjectsByBasicClass } from 'api/api';
import { useGetSchoolbooks } from 'services/queryHooks/schoolbooks-hook';
import { useGetTopicsBySubjectAndBasicClass } from 'services/queryHooks/topics-hooks';
import { useGetContentsByFilter } from 'services/queryHooks/contents-hooks';

import useTextFieldSearch from 'hooks/useTextFieldSearch';
import { useNomenclaturesContext } from 'hooks/useNomenclaturesContext';
import { useUserContext } from 'hooks/useUserContext';

import { librarySubjectBreadcrumb } from 'views/library/breadcrumbs';
import { getSubjectConfig } from 'config/subject-config-data';
import { ClassesType, SubjectTabs, SubjectTabsType, getConfig } from './config';
import { hasRequiredRoles } from 'authentication/utils';
import { SchoolbooksUserRoles } from 'enums/userRoles';


const LibrarySubject = () => {
  const navigate = useNavigate();
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const userContext = useUserContext();
  const userRole = userContext?.userRole;

  const { classId, subjectId }: any = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabType = searchParams?.get('type');
  const isClassWithProfiles = classId === ClassesType.Eleventh || classId === ClassesType.Twelfth;
  const defaultTab = isClassWithProfiles ? SubjectTabsType.Contents : SubjectTabsType.Topics;
  const [activeTab, setActiveTab] = useState(activeTabType || defaultTab);

  const [contentsSearchValue, setContentsSearchValue] = useState('');
  const [debouncedInput] = useDebounce(contentsSearchValue, 500);
  const { inputValue, list, setList, handleSearch } = useTextFieldSearch('name');

  const {
    subjects,
    topics: { setTopics: setTopicsNom },
    classSubjects
  } = useNomenclaturesContext();

  const subjectName = subjects.items.find(
    (x: any) => x.id === +subjectId
  )?.name;
  const subjectConfig = getSubjectConfig(subjectId);
  const subjectCustomIcon = subjectConfig.icon({
    fontSize: 'large',
    sx: { color: subjectConfig.color },
  });

  const config = getConfig(userRole)
  const selectData = classSubjects?.items.filter(x => x.id != subjectId)
    .map((x: any) => ({ id: x.id, label: x.name }));

  const schoolbookAccess = hasRequiredRoles(SchoolbooksUserRoles);
  const { data: schoolbooks, isLoading: isSchoolbookLoading } = useGetSchoolbooks(subjectId, classId, {
    enabled: schoolbookAccess
  });

  const { data: topics, isFetching: areTopicsFetching } = useGetTopicsBySubjectAndBasicClass(subjectId, classId);
  const profileContents = useGetContentsByFilter({ subjectId, isProfileSubject: true, classIds: [classId], name: contentsSearchValue });

  useEffect(() => {
    if (classSubjects?.items.length === 0) {
      getSubjectsByBasicClass(classId).then((data: any[]) => {
        classSubjects.setClassSubjects({ classId, items: data });
      });
    }
  }, [classId]);

  useEffect(() => {
    if (!areTopicsFetching && topics) {
      setList(topics || []);
      setTopicsNom(topics || []);
    }
  }, [topics, areTopicsFetching]);

  useEffect(() => {
    setContentsSearchValue(debouncedInput);
  }, [debouncedInput]);

  useEffect(() => {
    handleSearch('');
    setContentsSearchValue('');
  }, [activeTab]);

  const renderTabsButtons = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={mobile ? "space-around" : "flex-start"}
      spacing={1}
    >
      <ButtonGroup disableElevation>
        <Button
          onClick={() => {
            setActiveTab(SubjectTabsType.Topics);
            setSearchParams(
              objectToSearchString({
                type: SubjectTabsType.Topics,
              }),
            );
          }}
          variant={
            activeTab === SubjectTabsType.Topics
              ? 'contained'
              : 'outlined'
          }
        >
          {SubjectTabs.topics.label}
        </Button>
        <Button
          onClick={() => {
            setActiveTab(SubjectTabsType.Contents);
            setSearchParams(
              objectToSearchString({
                type: SubjectTabsType.Contents,
              }),
            );
          }}
          variant={
            activeTab === SubjectTabsType.Contents
              ? 'contained'
              : 'outlined'
          }
        >
          {SubjectTabs[SubjectTabsType.Contents].label}
        </Button>
      </ButtonGroup>
      {Boolean(list?.length) && (
        <Tooltip
          placement={"right"}
          title={<Typography>Ресурсите по учебната програма са в "Теми".</Typography>}
          enterTouchDelay={0}
        >
          <ErrorIcon fontSize="large" color="warning" />
        </Tooltip>
      )}
    </Stack>
  );

  const renderSchoolbooksButton = schoolbookAccess && (
    <SelectButton
      title="ПРЕГЛЕДАЙ УЧЕБНИК"
      data={schoolbooks || []}
      tooltipText={config.textBookTooltip}
      isLoading={isSchoolbookLoading}
    />
  );

  {/* This is because of the few seconds time delay
  between topics being loaded and being set into list */}
  const listLoading = !areTopicsFetching && Boolean(topics?.length)
    && !Boolean(list?.length) && !inputValue;

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={librarySubjectBreadcrumb(classId)} />
      </Box>

      {(areTopicsFetching || listLoading || subjects.isLoading) || (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} display="flex" alignItems="center" mb={3}>
              <Link to={`/library/${classId}`}>
                <ArrowBackIos fontSize="large" color="secondary" />
              </Link>
              <Typography
                variant="h1"
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                {subjectCustomIcon}
                {subjectName} ({classId} Клас)
              </Typography>
              <Box ml={3}>
                <MultipleSelectLibrary
                  data={selectData}
                  onChange={(subject: any) =>
                    navigate(`/library/${classId}/${subject.id}`)}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
              {isClassWithProfiles && (
                <RCS hidden='tablet'>
                  <Grid item md={6}>
                    {renderTabsButtons}
                  </Grid>
                </RCS>
              )}
              <Grid item xs={9} md={isClassWithProfiles ? 3 : 9}>
                {renderSchoolbooksButton}
              </Grid>
              <Grid item xs={3} display='flex' alignItems='flex-start' justifyContent='flex-end'>
                {activeTab === SubjectTabsType.Topics && (
                  <ContextSearch value={inputValue} onChange={(value: string) => handleSearch(value)} />
                )}
                {activeTab === SubjectTabsType.Contents && (
                  <ContextSearch value={contentsSearchValue} onChange={(value: string) => setContentsSearchValue(value)} />
                )}
              </Grid>
            </Grid>
            {isClassWithProfiles && (
              <RCS visible='tablet'>
                <Grid item xs={12}>
                  {renderTabsButtons}
                </Grid>
              </RCS>
            )}
          </Grid>
        </>
      )}
      {activeTab === SubjectTabsType.Topics && (
        <>
          {(areTopicsFetching || listLoading) && <LoadingSkeleton itemsCount={6} height={83} />}
          {areTopicsFetching || listLoading || (
            <Grid container rowSpacing={3} my={2}>
              {list?.map((chapter: any) => (
                <Grid item xs={12} key={chapter.id}>
                  <Link to={`/library/${classId}/${subjectId}/${chapter.id}`}>
                    <Card
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1,
                      }}
                    >
                      <CardContent>
                        <Box display="flex" alignItems="center" gap={1.5}>
                          {subjectCustomIcon}
                          <Box display="flex" flexDirection="column">
                            <Typography variant="h4">{chapter.name}</Typography>
                            {chapter.unit && (
                              <Typography
                                variant='body2'
                                mt={0.5}
                              >
                                <Typography
                                  component='span'
                                  variant='subtitle2'
                                >
                                  Раздел:&nbsp;
                                </Typography>
                                {chapter.unit}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
              <Grid item xs={12}>
                {!Boolean(list?.length) && (
                  <>
                    {!inputValue && (
                      <FallbackSection message="Няма налични теми за този предмет.." />
                    )}
                    {inputValue && (
                      <FallbackSection
                        message={(
                          <>
                            Няма открити резултати за
                            <Typography
                              component='span'
                              sx={{ color: palette.primary[700] }}
                            >
                              "{inputValue}"
                            </Typography>
                          </>
                        )}
                      />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}

      {activeTab === SubjectTabsType.Contents && (
        <Grid item my={4}>
          <ContentsList
            list={profileContents}
            fallbackText='Няма налични профилиращи съдържания за този предмет.'
            searchInputValue={inputValue}
            linkState={{
              type: activeTab,
              basicClassId: classId,
              subjectId
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default LibrarySubject;
