const teamsQuestions = [
  {
    title: 'landingPage.section.faq.teams.section1.title',
    list: [
      {
        question: 'landingPage.section.faq.teams.section1.q1.question',
        answer: {
          key: 'landingPage.section.faq.teams.section1.q1.answer',
          links: [
            'https://dechica.com/teams/activate-accounts.html'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.teams.section1.q2.question',
        answer: {
          key: 'landingPage.section.faq.teams.section1.q2.answer',
          links: [
            'https://portal.office.com/',
            'https://dechica.com/teams/video.html'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.teams.section1.q3.question',
        answer: {
          key: 'landingPage.section.faq.teams.section1.q3.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section1.q4.question',
        answer: {
          key: 'landingPage.section.faq.teams.section1.q4.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section1.q5.question',
        answer: {
          key: 'landingPage.section.faq.teams.section1.q5.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section1.q6.question',
        answer: {
          key: 'landingPage.section.faq.teams.section1.q6.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section1.q7.question',
        answer: {
          key: 'landingPage.section.faq.teams.section1.q7.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section1.q8.question',
        answer: {
          key: 'landingPage.section.faq.teams.section1.q8.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section1.q9.question',
        answer: {
          key: 'landingPage.section.faq.teams.section1.q9.answer'
        }
      }
    ]
  },
  {
    title: 'landingPage.section.faq.teams.section2.title',
    list: [
      {
        question: 'landingPage.section.faq.teams.section2.q1.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q1.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q2.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q2.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q3.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q3.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q4.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q4.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q5.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q5.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q6.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q6.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q7.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q7.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q8.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q8.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q9.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q9.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q10.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q10.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section2.q11.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q11.answer'
        },
        links: [
          'https://portal.office.com/'
        ]
      },
      {
        question: 'landingPage.section.faq.teams.section2.q12.question',
        answer: {
          key: 'landingPage.section.faq.teams.section2.q12.answer'
        }
      }
    ]
  },
  {
    title: 'landingPage.section.faq.teams.section3.title',
    list: [
      {
        question: 'landingPage.section.faq.teams.section3.q1.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q1.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q2.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q2.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q3.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q3.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q4.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q4.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q5.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q5.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q6.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q6.answer',
          links: [
            'https://portal.office.com/',
            'https://web.microsoftstream.com/browse'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q7.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q7.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q8.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q8.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q9.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q9.answer',
          links: [
            'https://www.microsoft.com/en-GB/p/microsoft-whiteboard/9mspc6mp8fm4?activetab=pivot:overviewtab'
          ],
          images: [
            'https://dechica.com/teams/img/microsoft-whiteboard.jpg'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q10.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q10.answer'
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q11.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q11.answer',
          images: [
            'https://dechica.com/teams/img/end-meeting.jpg'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.teams.section3.q12.question',
        answer: {
          key: 'landingPage.section.faq.teams.section3.q12.answer'
        }
      }
    ]
  }
];

const classroomQuestions = [
  {
    title: 'landingPage.section.faq.google.section1.title',
    list: [
      {
        question: 'landingPage.section.faq.google.section1.q1.question',
        answer: {
          key: 'landingPage.section.faq.google.section1.q1.answer',
          images: [
            'images/faq/google-1.png',
          ],
          links: [
            'https://edu.mon.bg',
          ]
        }
      },
      {
        question: 'landingPage.section.faq.google.section1.q2.question',
        answer: {
          links: [
            'https://edu.mon.bg'
          ],
          key: 'landingPage.section.faq.google.section1.q2.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section1.q3.question',
        answer: {
          key: 'landingPage.section.faq.google.section1.q3.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section1.q4.question',
        answer: {
          key: 'landingPage.section.faq.google.section1.q4.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section1.q5.question',
        answer: {
          key: 'landingPage.section.faq.google.section1.q5.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section1.q6.question',
        answer: {
          key: 'landingPage.section.faq.google.section1.q6.answer',
          links: [
            'https://myaccount.google.com/language',
            'https://support.google.com/accounts/answer/32047?hl=bg'
          ],
          images: [
            'images/faq/google-5.png',
            'images/faq/google-6.png'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.google.section1.q7.question',
        answer: {
          key: 'landingPage.section.faq.google.section1.q7.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section1.q8.question',
        answer: {
          key: 'landingPage.section.faq.google.section1.q8.answer'
        }
      },
    ]
  },
  {
    title: 'landingPage.section.faq.google.section2.title',
    list: [
      {
        question: 'landingPage.section.faq.google.section2.q9.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q9.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q10.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q10.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q11.question',
        answer: {
          links: [
            'https://classroom.google.com'
          ],
          key: 'landingPage.section.faq.google.section2.q11.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q12.question',
        answer: {
          links: [
            'https://classroom.google.com'
          ],
          images: [
            'images/faq/question11-list-item-2.png',
            'images/faq/question11-list-item-3.png'
          ],
          key: 'landingPage.section.faq.google.section2.q12.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q13.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q13.answer',
          links: [
            'https://classroom.google.com'
          ],
          images: [
            'images/faq/question17-list-item-3.png',
            'images/faq/question17-list-item-2-part-3.png'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q14.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q14.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q15.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q15.answer',
          links: [
            'https://classroom.google.com'
          ],
          images: [
            'images/faq/question13-list-item-3.png'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q16.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q16.answer'
        }
      },
    ]
  },
  {
    title: 'landingPage.section.faq.google.section2.title',
    list: [
      {
        question: 'landingPage.section.faq.google.section2.q17.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q17.answer',
          images: [
            'images/faq/google-9.png'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q18.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q18.answer',
          links: [
            'https://classroom.google.com'
          ],
          images: [
            'images/faq/question16-list-item-3.png',
            'images/faq/question16-list-item-2-part-2.png'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q19.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q19.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q20.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q20.answer'
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q21.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q21.answer',
          links: [
            'https://classroom.google.com'
          ],
          images: [
            'images/faq/question20-list-item-3.png'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q22.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q22.answer',
          images: [
            'images/faq/google-15.png',
            'images/faq/google-16.png',
            'images/faq/google-17.png'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.google.section2.q23.question',
        answer: {
          key: 'landingPage.section.faq.google.section2.q23.answer',
          images: [
            'images/faq/google-19.png',
            'images/faq/google-20.png',
            'images/faq/google-21.png',
            'images/faq/google-23.png',
            'images/faq/google-24.png',
            'images/faq/google-25.png',
            'images/faq/google-26.png'
          ]
        }
      },
    ]
  }
];

const usersQuestions = [
  {
    title: 'landingPage.section.faq.users.section1.title',
    list: [
      {
        question: 'landingPage.section.faq.users.section1.q1.question',
        answer: {
          key: 'landingPage.section.faq.users.section1.q1.answer',
          images: [
            'images/faq/users-1.png',
          ]
        }
      },
      {
        question: 'landingPage.section.faq.users.section1.q2.question',
        answer: {
          key: 'landingPage.section.faq.users.section1.q2.answer',
          images: [
            'images/faq/users-2.png',
            'images/faq/users-3.png',
          ],
          links: [
            'https://www.office.com/'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.users.section1.q3.question',
        answer: {
          key: 'landingPage.section.faq.users.section1.q3.answer',
          images: [
            'images/faq/users-4.png',
            'images/faq/users-5.png',
            'images/faq/users-6.png',
          ],
          links: [
            'https://neispuo.mon.bg/'
          ]
        }
      },
      {
        question: 'landingPage.section.faq.users.section1.q4.question',
        answer: {
          key: 'landingPage.section.faq.users.section1.q4.answer',
          images: [
            'images/faq/users-7.png',
            'images/faq/users-8.png',
          ]
        }
      },
      {
        question: 'landingPage.section.faq.users.section1.q5.question',
        answer: {
          key: 'landingPage.section.faq.users.section1.q5.answer',
        }
      },
    ]
  }
];

const backpackQuestions = [
  {
    title: '',
    list: [
      {
        question: 'landingPage.section.faq.backpack.section1.q1.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q1.answer',
        }
      },
      {
        question: 'landingPage.section.faq.backpack.section1.q2.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q2.answer',
          images: [
            'images/faq/backpack-1.png',
            'images/faq/backpack-2.png',
          ]
        }
      },
      {
        question: 'landingPage.section.faq.backpack.section1.q3.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q3.answer',
        }
      },
      {
        question: 'landingPage.section.faq.backpack.section1.q4.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q4.answer',
          images: [
            'images/faq/backpack-3.png',
          ]
        }
      },
      {
        question: 'landingPage.section.faq.backpack.section1.q5.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q5.answer',
          images: [
            'images/faq/backpack-4.png',
          ]
        }
      },
      {
        question: 'landingPage.section.faq.backpack.section1.q6.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q6.answer',
          images: [
            'images/faq/backpack-5.png',
            'images/faq/backpack-6.png',
          ]
        }
      },
      {
        question: 'landingPage.section.faq.backpack.section1.q7.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q7.answer'
        }
      },
      {
        question: 'landingPage.section.faq.backpack.section1.q8.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q8.answer',
          images: [
            'images/faq/backpack-7.png',
          ]
        }
      },
      {
        question: 'landingPage.section.faq.backpack.section1.q9.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q9.answer'
        }
      },
      {
        question: 'landingPage.section.faq.backpack.section1.q10.question',
        answer: {
          key: 'landingPage.section.faq.backpack.section1.q10.answer',
          images: [
            'images/faq/backpack-8.png',
            'images/faq/backpack-9.png',
          ]
        }
      }
    ]
  }
];

export default { teamsQuestions, classroomQuestions, usersQuestions, backpackQuestions };
