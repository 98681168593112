import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ExpandMoreRounded, ExpandLessRounded } from '@mui/icons-material';
import { LabelStyled, RootStyled, ListItemStyled, CollapseStyled } from './styles';
import type { SelectDataType } from './types';

type Props = {
  data: SelectDataType[];
  value?: SelectDataType;
  onChange: Function;
  open?: boolean;
};

function NumericValueSelect({ data, value, onChange, open }: Props) {
  const [selectOpen, setSelectOpen] = useState(open);
  return (
    <RootStyled>
      <LabelStyled open={selectOpen} onClick={() => setSelectOpen(!selectOpen)}>
        {!value && !selectOpen && (<ExpandMoreRounded />)}
        {!value && selectOpen && (<ExpandLessRounded />)}
        {value && <Box>{value.label}</Box>}
      </LabelStyled>
      <CollapseStyled in={selectOpen} timeout="auto" unmountOnExit>
        {data.map((selectItem) => {
          const { id, label } = selectItem;
          return (
            <ListItemStyled
              key={id}
              onClick={() => {
                onChange(selectItem);
              }}
            >
              {label}
            </ListItemStyled>
          );
        })}
      </CollapseStyled>
    </RootStyled>
  );
}

NumericValueSelect.defaultProps = {
  open: false
};

export default NumericValueSelect;
