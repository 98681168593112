import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { PaperStyled } from './styles';
import { FallbackVariantsType, VariantsEnum } from './types';
import {
  FallbackRocketAndBoyImage,
  FallbackSearchImage,
  FallbackRelaxedImage,
  FallbackQuestionImage
} from 'components/icons/fallback';

type Props = {
  message: string | ReactNode;
  variant?: FallbackVariantsType;
  [key: string]: any;
};

function FallbackSection({ message, variant, ...propsToPropagate }: Props) {
  return (
    <PaperStyled {...propsToPropagate}>
      {variant === VariantsEnum.SEARCH && <FallbackSearchImage width={460} />}
      {variant === VariantsEnum.NOITEMS && (
        <FallbackRocketAndBoyImage width={460} />
      )}
      {
        variant === VariantsEnum.RELAXED_ROBOT && (
          <FallbackRelaxedImage width={460} />
        )
      }
      {variant === VariantsEnum.WONDERING && <FallbackQuestionImage width={460} />}
      {typeof message === 'string' ? (
        <Typography mt={3} fontWeight={600}>{message}</Typography>
      ) : (
        <Box mt={3}>
          {message}
        </Box>
      )}
    </PaperStyled>
  );
}

FallbackSection.defaultProps = {
  variant: VariantsEnum.SEARCH
};

export default FallbackSection;
