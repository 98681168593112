import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng'
    },
    fallbackLng: 'bg',
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

export const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

export const getStringLocalization = () => {
  const lng = getCurrentLng();
  return lng.charAt(0).toUpperCase() + lng.slice(1);
};

export const onLanguageChange = () => {
  const lang = localStorage.getItem('i18nextLng');
  const newLang = lang === 'bg' ? 'en' : 'bg';
  localStorage.setItem('i18nextLng', newLang);
  i18n.changeLanguage(newLang);
};
