import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMediaQuery,
  Fab,
  Grid,
  IconButton,
  Alert,
  Box,
} from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { getMozaikToken } from 'api/api';
import { useUserContext } from 'hooks/useUserContext';
import LoadingSkeleton from 'components/loading-skeleton';
import { palette } from 'theme/components';
import { Roles } from 'enums/userRoles';
import {
  iframeConfig,
  MOZAIK_TOKEN_URL,
  MOZAIK_TOKEN_DIGITAL_LESSONS,
  MOZAIK_DIGITAL_LESSONS,
} from './config';

export default function Mozaik() {
  const { t } = useTranslation();
  const user = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullContentLink, setFullContentLink] = useState<string>();
  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const desktopUp = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (
      user?.user.roles[0] === Roles.Parent ||
      user?.user.roles[0] === Roles.Director
    ) {
      setFullContentLink(MOZAIK_DIGITAL_LESSONS);
      setIsLoading(false);
    } else {
      getMozaikToken().then((a) => {
        const link = `${MOZAIK_TOKEN_URL}?token=${a.access_token}&target=${MOZAIK_TOKEN_DIGITAL_LESSONS}`;
        setFullContentLink(link);
        setIsLoading(false);
      });
    }
  }, []);

  const renderButtonClose = (
    <Fab
      sx={{
        position: 'fixed',
        top: 10,
        left: 10,
        zIndex: '2500',
      }}
      onClick={() => {
        document.body.style.overflowY = 'visible';
        setIsFullScreen(false);
      }}
    >
      <FullscreenExit
        fontSize={tabletAndUp ? 'large' : 'medium'}
        sx={{ color: palette.grey.main }}
      />
    </Fab>
  );

  let styles = { ...iframeConfig.default };
  if (isFullScreen) {
    styles = { ...styles, ...iframeConfig.fullscreen };
  }

  return (
    <Box mt={1}>
      <Grid container alignItems="center">
        <Grid item xs={11} pb={2}>
          <Alert severity="warning">
            {user?.userRole?.isTeacher
              ? t('mozaikPage.warningTeacher')
              : t('mozaikPage.warningAll')}
          </Alert>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <IconButton
            onClick={() => {
              document.body.style.overflowY = 'hidden';
              setIsFullScreen(true);
            }}
          >
            <Fullscreen
              fontSize={tabletAndUp ? 'large' : 'medium'}
              sx={{ color: palette.grey.main }}
            />
          </IconButton>
        </Grid>
      </Grid>

      {isLoading && !fullContentLink && (
        <LoadingSkeleton itemsCount={1} height={1188} />
      )}
      {!isLoading && fullContentLink && (
        <Box height="100vh" width={desktopUp ? undefined : '1200px'}>
          {isFullScreen && renderButtonClose}
          <iframe src={fullContentLink} style={styles} />
        </Box>
      )}
    </Box>
  );
}
