import styled from '@emotion/styled';
import { Menu } from '@mui/icons-material';
import svgToMiniDataURI from 'mini-svg-data-uri';
import backgrounds from 'components/header/backgrounds/svg';

export const NavigationStyled = styled('div')(({ theme }: any) => {
  const { spacing, breakpoints } = theme;

  return ({
    '& > div': {
      flexDirection: 'column',
      [breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
      '& > a': {
        margin: spacing(3, 0.5),
        padding: spacing(0, 2),
        fontSize: '18px',
        textDecoration: 'none',
      },
    },
  });
});

export const LogoStyled = styled('img')(({ theme }: any) => {
  const { breakpoints } = theme;

  return {
    cursor: 'pointer',
    width: '40px',
    [breakpoints.up('md')]: {
      width: '92px',
    },
    [breakpoints.up('sm')]: {
      width: '60px',
    },
  };
});

export const PartnersLogoStyled = styled('img')(({ theme, sx }: any) => {
  const { breakpoints } = theme;

  return {
    cursor: 'pointer',
    width: '90px',
    ...sx,
    [breakpoints.up('sm')]: {
      width: '100px',
    },
    [breakpoints.up('md')]: {
      width: '135px',
    },
  };
});

export const LogoContainerStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const LogoTitleStyled = styled('div')(({ theme }: any) => {
  const { breakpoints, spacing } = theme;

  return {
    margin: `${spacing(0.5)} ${spacing(2)}`,
    textAlign: 'center',
    cursor: 'pointer',
    color: '#333333',

    [breakpoints.up('sm')]: {
      textAlign: 'left',
      marginLeft: spacing(2),
      marginRight: 0,
    },

    '& h1': {
      fontWeight: '700',
      letterSpacing: '-0.5px',
      fontSize: '16px',
      textTransform: 'uppercase',
      margin: 0,
      lineHeight: '1.2',

      [breakpoints.up('sm')]: {
        fontSize: '20px',
      },

      [breakpoints.up('md')]: {
        fontSize: '24px',
      },
    },

    '& h2': {
      letterSpacing: '-0.3px',
      fontSize: '14px',
      margin: 0,
      lineHeight: '1.2',
      fontWeight: '400',

      [breakpoints.up('sm')]: {
        fontSize: '16px',
      },

      [breakpoints.up('md')]: {
        fontSize: '19px',
      },
    },
  };
});

export const HeaderStyled = styled('header')(({ theme, sticky }: any) => {
  const { breakpoints, spacing, palette } = theme;
  let stickyStyles = {};

  if (sticky) {
    stickyStyles = {
      position: 'sticky',
      top: 0,
      zIndex: 3,
      [breakpoints.up('sm')]: {
        position: 'sticky',
      },
    };
  }

  return {
    backgroundColor: palette.common.white,
    padding: spacing(0.5, 0),
    maxHeight: '120px',
    boxShadow: '0px 6px 15px 0px rgb(0 0 0 / 4%)',
    ...stickyStyles,
  };
});

export const PartnersHeaderStyled = styled('header')(({ theme }: any) => {
  const { palette } = theme;

  return {
    background: palette.common.white,
    maxHeight: 'none',
    alignSelf: 'flex-end',
    width: '100%',

    '& h1': {
      lineHeight: 1.2,
    },

    '& .MuiContainer-root': {
      background: '#fff'
    }
  };
});

export const MenuIconStyled = styled(Menu)(({ theme }) => ({
  width: '50px',
}));
