import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Divider,
  ListItemText,
  Typography,
  MenuItem,
  MenuList,
  Button,
  IconButton,
  Menu,
  Box,
} from '@mui/material';
import { Brightness1, NotificationsNone } from '@mui/icons-material';
import { bg } from 'date-fns/locale';
import { formatDistance } from 'date-fns';

import LoadingSkeleton from 'components/loading-skeleton';
import { FallbackSection } from 'components/fallback';
import BlockingMobileDialog from 'components/blocking-mobile-dialog';
import RCS from 'components/responsive-control-system';
import { VariantsEnum } from 'components/fallback/types';
import { palette } from 'theme/components';

import { NotificationItem } from 'api/types';

import { MenuStickyHeaderStyled, MenuStickyFooterStyled } from './styles';

import { NavigationLinks } from 'enums/routes';
import { trimText, displayTextInTwoLines } from 'utils/text';
import {
  useUpdateNotificationSeenStatus,
  useUserNotificationsCounts,
  useUserNotificationsList
} from 'services/queryHooks/notifications-hooks';

const ContextNotificationsMenu = () => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = useState<any>(null);
  const openMenu = Boolean(anchorElement);
  const { data: notificationsList,
    isLoading: isLoading,
    refetch: refetchOnClick }: any = useUserNotificationsList();
  const { data: notificationsCounts }: any = useUserNotificationsCounts();
  const { mutate: mutateUpdateSeenStatus } = useUpdateNotificationSeenStatus(openMenu);

  const renderNotification = ({
    isSeen,
    title,
    id,
    content,
    createdOn,
  }: NotificationItem) => (
    <MenuItem
      key={id}
      onClick={() => !isSeen && mutateUpdateSeenStatus({ notificationsIds: [id] })}
      sx={{
        cursor: !isSeen ? 'pointer' : 'default',
        opacity: !isSeen ? 1 : 0.5,
        py: 2,
      }}
    >
      <ListItemText>
        <Typography variant="subtitle2">
          <b>{trimText(title, 70)}</b> ·{' '}
          {formatDistance(new Date(createdOn), new Date(), {
            addSuffix: true,
            locale: bg,
          })}
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          dangerouslySetInnerHTML={{
            __html: `${displayTextInTwoLines(content, 25, 70)} <br />`,
          }}
        ></Typography>
      </ListItemText>
      {!isSeen && (
        <Typography pl={4}>
          <Brightness1
            sx={{
              color: palette.primary.main,
              fontSize: 8,
            }}
          />
        </Typography>
      )}
    </MenuItem>
  );

  return (
    <>
      <IconButton
        onClick={(event) => {
          if (event && event.currentTarget) {
            setAnchorElement(event.currentTarget);
            refetchOnClick();
          }
        }}
      >
        <Badge
          badgeContent={notificationsCounts?.unseenNotificationsCount}
          color="info"
          overlap="circular"
        >
          <NotificationsNone color="secondary" />
        </Badge>
      </IconButton>
      <RCS hidden="mobile">
        <Menu
          open={openMenu}
          anchorEl={anchorElement}
          onClose={() => setAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            sx: { p: 0, backgroundColor: palette.common.white },
          }}
        >
          <MenuStickyHeaderStyled p={2}>
            <Typography variant="h5">
              {t('pages.notifications.title')}
            </Typography>
            {Boolean(notificationsCounts?.unseenNotificationsCount) && (
              <Button
                variant="text"
                onClick={() => mutateUpdateSeenStatus({ notificationsIds: [], openMenu: openMenu })}
                sx={{ py: 0, pr: 1, pl: 0, textTransform: 'capitalize' }}
              >
                <Typography variant="caption">
                  {t('pages.notifications.button.markAll')}
                </Typography>
              </Button>
            )}
          </MenuStickyHeaderStyled>
          {!isLoading && !notificationsList?.pages[0]?.items?.length && (
            <Box px={2} maxWidth={360}>
              <FallbackSection
                sx={{ boxShadow: 0 }}
                variant={VariantsEnum.NOITEMS}
                message={t('pages.notifications.emptyMessage')}
              />
            </Box>
          )}
          {isLoading && (
            <MenuItem sx={{ minWidth: 300 }}>
              <LoadingSkeleton itemsCount={5} height={30} />
            </MenuItem>
          )}
          {Boolean(notificationsList?.pages[0]?.items?.length) && (
            <>
              <MenuList
                sx={{
                  pt: notificationsList?.pages[0]?.items?.length ? 1 : 0,
                }}
              >
                {!isLoading &&
                  notificationsList?.pages[0]?.items?.map(
                    (notification: NotificationItem, index: number) => {
                      const isLastItem =
                        index < notificationsList?.pages[0]?.items?.length - 1;
                      return (
                        <Box key={notification.id}>
                          {renderNotification(notification)}
                          {isLastItem && (
                            <Divider sx={{ margin: '0px !important' }} />
                          )}
                        </Box>
                      );
                    }
                  )}
              </MenuList>
              <MenuStickyFooterStyled p={2}>
                <NavLink
                  to={NavigationLinks.notifications}
                  onClick={() => setAnchorElement(false)}
                >
                  <Button variant="contained">
                    <Typography
                      textTransform="uppercase"
                      variant="caption"
                      color={palette.common.white}
                      px={5}
                    >
                      {t('pages.notifications.button.seeAll')}
                    </Typography>
                  </Button>
                </NavLink>
              </MenuStickyFooterStyled>
            </>
          )}
        </Menu>
      </RCS>
      <RCS visible="mobile">
        <BlockingMobileDialog
          open={openMenu}
          onClose={() => setAnchorElement(false)}
          title="Известия"
          footer={
            !isLoading &&
            notificationsList?.pages[0]?.items?.length && (
              <NavLink
                to={NavigationLinks.notifications}
                onClick={() => setAnchorElement(false)}
              >
                <Button
                  sx={{ color: palette.primary[600] }}
                  variant="contained"
                >
                  <Typography
                    textTransform="uppercase"
                    variant="caption"
                    color={palette.common.white}
                    component="span"
                    px={4}
                  >
                    {t('pages.notifications.button.seeAll')}
                  </Typography>
                </Button>
              </NavLink>
            )
          }
        >
          {!isLoading && !notificationsList?.pages[0]?.items?.length && (
            <MenuItem>
              <FallbackSection
                sx={{ boxShadow: 0 }}
                variant={VariantsEnum.NOITEMS}
                message={t('pages.notifications.emptyMessage')}
              />
            </MenuItem>
          )}
          {isLoading && (
            <MenuItem>
              <LoadingSkeleton itemsCount={6} height={40} />
            </MenuItem>
          )}
          {!isLoading &&
            notificationsList?.pages[0]?.items?.map(
              (notification: NotificationItem, index: number) => {
                const isLastItem = index < notificationsList?.pages[0]?.items?.length - 1;
                return (
                  <React.Fragment key={notification.id}>
                    {renderNotification(notification)}
                    {isLastItem && (
                      <Divider sx={{ margin: '0px !important' }} />
                    )}
                  </React.Fragment>
                );
              }
            )}
        </BlockingMobileDialog>
      </RCS>
    </>
  );
};

export default ContextNotificationsMenu;
