import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const NaviContainerStyled = styled(Grid)(({ theme, onClick, active }: any) => {
  const { breakpoints, palette } = theme;

  return ({
    textAlign: 'center',
    cursor: onClick ? 'pointer' : 'default',

    '& svg': {
      fontSize: '30px',

      [breakpoints.up('sm')]: {
        fontSize: '40px'
      },

      [breakpoints.up('md')]: {
        fontSize: '50px'
      }
    },

    '& h3': {
      fontSize: '16px',
      padding: '8px 0 12px',
      margin: '0 !important',
      fontWeight: '600',
      borderBottom: active ? `4px solid ${palette.text.primary}` : '',

      [breakpoints.up('md')]: {
        fontSize: '20px',
        margin: '10px 0 20px',
      }
    },

    '& p': {
      color: '#58566b',
      fontSize: '14px',
      fontWeight: '200',
      lineHeight: '2rem',

      [breakpoints.up('md')]: {
        fontSize: '18px',
      }
    }
  })
});
