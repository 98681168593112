import { getEndOfDayFormattedDate } from 'utils/dateFormat';

export const DEFAULT_CRUD_PAGINATION = {
  itemsPerPage: 10,
  page: 1,
};

export const INITIAL_FORM_VALUES = () => ({
  news: {
    contentBg: '',
    contentEn: '',
    dateCreated: '',
    descriptionBg: '',
    descriptionEn: '',
    enabled: true,
    hyperlinks: [
      {
        link: '',
        titleBg: '',
        titleEn: '',
      },
    ],
    files: [],
    id: '',
    roles: [],
    tag: '',
    titleBg: '',
    titleEn: '',
  },
  notifications: {
    dateCreated: '',
    enabled: true,
    endDate: getEndOfDayFormattedDate(new Date()),
    id: '',
    messageBg: '',
    messageEn: '',
    roles: [],
  }
})


export const notification = (type: any) => {

  return ({
    success: {
      create: `${type}.success.create`,
      edit: `${type}.success.edit`,
      delete: `${type}.success.delete`,
    },
    error: {
      create: `${type}.error.create`,
      edit: `${type}.error.edit`,
    }
  })
}


export const tags = [
  {
    id: 0,
    title: 'news.form.tag.teams',
  },
  {
    id: 1,
    title: 'news.form.tag.googleClassroom',
  },
  {
    id: 2,
    title: 'news.form.tag.others',
  },
];
