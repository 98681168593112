import i18next from 'i18next';

export const classroomData = () => [
  {
    id: 'teams',
    image: {
      src: 'images/teams-classroom.jpg',
      alt: i18next.t('landingPage.section.classrooms.teams.imageAlt')
    },
    imageIcon: {
      src: 'images/microsoft-teams-logo.png',
      alt: i18next.t('landingPage.section.classrooms.teams.imageIconAlt')
    },
    meta: {
      tel: '0700 10188',
      email: 'help@edu.mon.bg'
    },
    title: i18next.t('landingPage.section.classrooms.teams.title'),
    subtitle: i18next.t('landingPage.section.classrooms.teams.subTitle'),
    footer: {
      href: 'https://login.microsoftonline.com/?whr=edu.mon.bg'
    }
  },
  {
    id: 'google',
    image: {
      src: 'images/google-classroom.jpg',
      alt: i18next.t('landingPage.section.classrooms.google.imageAlt')
    },
    imageIcon: {
      src: 'images/google-classroom-logo.png',
      alt: i18next.t('landingPage.section.classrooms.google.imageIconAlt')
    },
    meta: {
      tel: '02 4007360',
      email: 'google.support@edu.mon.bg'
    },
    title: i18next.t('landingPage.section.classrooms.google.title'),
    subtitle: i18next.t('landingPage.section.classrooms.google.subTitle'),
    footer: {
      href: 'https://accounts.google.com/'
    }
  }
];

export const helpContactData = () => ({
  image: {
    src: 'images/contact.jpg',
    alt: i18next.t('landingPage.section.contact.imageAlt')
  },
  meta: {
    tel: '0700 10 241',
    email: 'help@edu.mon.bg',
    workTime: '08:00 - 17:00',
  },
  title: i18next.t('landingPage.section.contact.cardContent')
});
