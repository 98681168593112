export class LocalCacheService {
  private static redirectKey = 'rPath';

  static removeRedirectPath(): void {
    localStorage.removeItem(this.redirectKey);
  }

  static getRedirectPath(): string {
    const redirect = localStorage.getItem(this.redirectKey);
    return redirect ? redirect : '';
  }
}
