// TODO: move in api.ts
import { postAnalytics } from 'services/api';

export const apiPostAnalytics = async (item: ContentView): Promise<ContentView> => {
  return await postAnalytics(`ContentViews`, item);
};

export interface ContentView {
  contentId: string;
  institutionId: number;
  authorId?: number;
  userId: string;
  dateTime: string;
  linkSourceCode?: string;
  rating?: number;
  name?: string;
  userType?: string;
  basicClass?: string;
  subjectName?: string;
  subjectId: number;
  topicName?: string;
  topicId: number;
  contentLink?: string;
  createdBy?: string;
  isProfileSubject: boolean;
}

export default { apiPostAnalytics };
