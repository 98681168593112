import { ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { objectToSearchString } from 'serialize-query-params';
import { useDebounce } from 'use-debounce';

import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import Breadcrumbs from 'components/breadcrumbs';
import { MultipleSelectLibrary } from 'components/select';
import RCS from 'components/responsive-control-system';
import SelectButton from 'components/select-button';
import { ContextSearch } from 'components/search';
import { ContentsList } from './components';

import { getTopicsBySubjectAndBasicClass } from 'api/api';
import {
  useGetContentsByFilter,
  useGetFavoriteContents,
  useGetAssignedContents,
} from 'services/queryHooks/contents-hooks';
import { useGetSchoolbooks } from 'services/queryHooks/schoolbooks-hook';

import { useNomenclaturesContext } from 'hooks/useNomenclaturesContext';
import { useUserContext } from 'hooks/useUserContext';

import { libraryTopicBreadcrumb } from 'views/library/breadcrumbs';
import { getSubjectConfig } from 'config/subject-config-data';
import { ContentsButtons, getConfig, DEFAULT_SEARCH_QUERY_FILTER, ContentsButtonType, SubjectTabsType } from './config';
import { hasRequiredRoles } from 'authentication/utils';
import { SchoolbooksUserRoles } from 'enums/userRoles';

const LibraryTopic = () => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const userRole = userContext?.userRole;

  const { id: classId, subjectId, topicId }: any = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const valueSearchType = searchParams?.get('type');
  const [activeButton, setActiveButton] = useState(
    valueSearchType || ContentsButtons.public.searchParam,
  );
  const [topic, setTopic] = useState<any>();
  const [inputValue, setInputValue] = useState<string>('');
  const [searchQueryFilter, setSearchQueryFilter] = useState(DEFAULT_SEARCH_QUERY_FILTER);

  const { subjects, topics } = useNomenclaturesContext();
  const [debouncedInput] = useDebounce(inputValue, 500);

  const subjectName = subjects.items.find(
    (x: any) => x.id === +subjectId,
  )?.name;
  const subjectConfig = getSubjectConfig(subjectId);
  const subjectCustomIcon = subjectConfig.icon({
    fontSize: 'large',
    sx: { color: subjectConfig.color },
  });

  const config = getConfig(userRole);
  const selectData = topics.items.filter(x => x.id != topicId)
    .map((x: any) => ({ id: x.id, label: x.name }));

  const schoolbookAccess = hasRequiredRoles(SchoolbooksUserRoles);
  const { data: schoolbooks, isLoading: isSchoolbookLoading } = useGetSchoolbooks(subjectId, classId, {
    enabled: schoolbookAccess
  });

  const allContents = useGetContentsByFilter({ topicIds: [topicId], name: searchQueryFilter.public });

  const favContents = useGetFavoriteContents({ topicId, isProfileSubject: false, name: searchQueryFilter.favorite });

  const assignedContents = useGetAssignedContents({ subjectId, topicId, name: searchQueryFilter.assigned }, {
    enabled: userRole?.isStudent || userRole?.isParent
  });

  useEffect(() => {
    loadContents();
  }, [activeButton, allContents.data, favContents.data, assignedContents.data]);

  useEffect(() => {
    setSearchQueryFilter({
      ...DEFAULT_SEARCH_QUERY_FILTER,
      [activeButton]: debouncedInput
    });
  }, [debouncedInput]);

  useEffect(() => {
    if (topics?.items.length === 0) {
      getTopicsBySubjectAndBasicClass(subjectId, classId).then(
        (data: any[]) => {
          topics.setTopics(data);
        }
      );
    }

    const buttonType = Boolean(valueSearchType) && ContentsButtons[valueSearchType as keyof typeof ContentsButtons] ?
      ContentsButtons[valueSearchType as keyof typeof ContentsButtons].searchParam :
      ContentsButtons.public.searchParam;
    onActiveButtonClick(buttonType);
  }, [topicId]);

  useEffect(() => {
    if (topics.items.length > 0) {
      const selectedTopic = topics.items.find((x: any) => x.id === +topicId);
      setTopic(selectedTopic);
    }
  }, [topics.items, topicId]);

  const setActiveButtonContents = (
    contents: any,
    shouldFallback: boolean = true,
  ) => {
    if (!Boolean(contents?.pages?.[0]?.items.length) && !Boolean(debouncedInput) && shouldFallback) {
      // Fallback to all contents if current active btn has no contents
      setActiveButton(ContentsButtons.public.searchParam);
      setSearchParams(
        objectToSearchString({
          type: ContentsButtons.public.searchParam,
        }),
      );
    }
  };

  const loadContents = () => {
    if (activeButton === ContentsButtons.public.searchParam) {
      setActiveButtonContents(allContents.data, false);
    }

    if (activeButton === ContentsButtons.favorite.searchParam) {
      setActiveButtonContents(favContents.data);
    }

    if (
      (userRole?.isStudent || userRole?.isParent) &&
      activeButton === ContentsButtons.assigned.searchParam
    ) {
      setActiveButtonContents(assignedContents.data);
    }
  };

  const onActiveButtonClick = (buttonType: ContentsButtonType) => {
    setActiveButton(buttonType);
    setSearchParams(
      objectToSearchString({
        type: buttonType,
      })
    );
    setInputValue('');
  };

  const renderTabsContent = (
    button: ContentsButtonType,
    contents: any,
    fallbackText: string | ReactNode = '',
  ) => {
    if (activeButton === button) {
      return (
        <ContentsList
          list={contents}
          fallbackText={fallbackText}
          searchInputValue={inputValue}
          linkState={{
            type: activeButton,
            topicId
          }}
        />
      );
    }
  };

  const shouldDisplayButton = (button: ContentsButtonType, contents: any) =>
    Boolean(contents?.pages?.[0]?.totalCount) || (Boolean(debouncedInput) && activeButton === button);

  const renderTabsButtons = (
    <ButtonGroup disableElevation>
      <Button
        onClick={() => onActiveButtonClick(ContentsButtons.public.searchParam)}
        variant={
          activeButton === ContentsButtons.public.searchParam
            ? 'contained'
            : 'outlined'
        }
      >
        {ContentsButtons.public.label}
        {!allContents.isLoading && !allContents.isFetching && `(${allContents?.data?.pages?.[0]?.totalCount || 0})`}
      </Button>
      {shouldDisplayButton(ContentsButtons.favorite.searchParam, favContents?.data) && (
        <Button
          onClick={() => onActiveButtonClick(ContentsButtons.favorite.searchParam)}
          variant={
            activeButton === ContentsButtons.favorite.searchParam
              ? 'contained'
              : 'outlined'
          }
        >
          {ContentsButtons.favorite.label}{' '}
          {!favContents.isLoading && !favContents.isFetching && `(${favContents?.data?.pages?.[0]?.totalCount || 0})`}
        </Button>
      )}
      {(userRole?.isStudent || userRole?.isParent) && shouldDisplayButton(ContentsButtons.assigned.searchParam, assignedContents?.data) && (
        <Button
          onClick={() => onActiveButtonClick(ContentsButtons.assigned.searchParam)}
          variant={
            activeButton === ContentsButtons.assigned.searchParam
              ? 'contained'
              : 'outlined'
          }
        >
          {ContentsButtons.assigned.label}
          {!assignedContents.isLoading && !assignedContents.isFetching && `(${assignedContents?.data?.pages?.[0]?.totalCount || 0})`}
        </Button>
      )}
    </ButtonGroup>
  );

  const renderSchoolbooksButton = schoolbookAccess && (
    <SelectButton
      title="ПРЕГЛЕДАЙ УЧЕБНИК"
      data={schoolbooks || []}
      tooltipText={config.textBookTooltip}
      isLoading={isSchoolbookLoading}
    />
  );

  const renderContextSearch = (
    <ContextSearch value={inputValue} onChange={(value: string) => setInputValue(value)} />
  );

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs
          items={libraryTopicBreadcrumb(classId, subjectId, subjectName)}
        />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} display='flex' alignItems='center' mb={3}>
          <Link to={`/library/${classId}/${subjectId}?type=${SubjectTabsType.Topics}`}>
            <ArrowBackIos fontSize='large' color='secondary' />
          </Link>
          <Typography
            variant='h1'
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            {subjectCustomIcon}
            {topic?.name || ''}
          </Typography>
          <Box ml={3}>
            <MultipleSelectLibrary
              data={selectData}
              onChange={(topic: any) =>
                navigate(`/library/${classId}/${subjectId}/${topic.id}?type=${ContentsButtonType.Public}`)
              }
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <RCS hidden='tablet'>
            <Grid item md={6}>
              {renderTabsButtons}
            </Grid>
          </RCS>
          <Grid item xs={9} md={3}>
            {renderSchoolbooksButton}
          </Grid>
          <Grid item xs={3} display='flex' alignItems='flex-start' justifyContent='flex-end'>
            {renderContextSearch}
          </Grid>
        </Grid>
        <RCS visible='tablet'>
          <Grid item xs={12}>
            {renderTabsButtons}
          </Grid>
        </RCS>
      </Grid>

      <Box mt={3}>
        {renderTabsContent(
          ContentsButtons.public.searchParam,
          allContents,
          config.fallbackText.All,
        )}
        {renderTabsContent(
          ContentsButtons.favorite.searchParam,
          favContents,
          config.fallbackText.Favorite,
        )}
        {(userRole?.isStudent || userRole?.isParent) &&
          renderTabsContent(
            ContentsButtons.assigned.searchParam,
            assignedContents,
            config.fallbackText.Assigned,
          )}
      </Box>
    </>
  );
};

export default LibraryTopic;
