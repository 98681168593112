import { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import {
  Alert, Box, ButtonBase, Card, Grid,
  IconButton, Fab, Rating as MuiRating, Typography, useMediaQuery,
} from '@mui/material';
import {
  ArrowBackIos, FileDownload, Favorite, FavoriteBorder, Fullscreen, FullscreenExit, PlayCircle, StarRounded,
} from '@mui/icons-material';

import Breadcrumbs from 'components/breadcrumbs';
import ContentTile from 'components/content-tile';
import Rating from 'components/rating'
import { Loader } from 'components/loader/loader';
import LoadingSkeleton from 'components/loading-skeleton';
import AssignedContentsManagementButton from 'components/assigned-contents-management-button';
import { InstitutionIcon } from 'components/icons/svg';
import { FallbackSection } from 'components/fallback';
import { AssignContent } from 'containers';

import { AuthManager } from 'authentication/auth-manager';
import {
  useGetRelatedContents,
  useGetContentById,
  useAddFavoriteContent,
  useRemoveFavoriteContent,
  useAddContentRating,
} from 'services/queryHooks/contents-hooks';
import { useGetTopicById } from 'services/queryHooks/topics-hooks';
import { getIzziToken, getMozaikToken } from 'api/api';
import { Content } from 'api/types';
import { useUserContext } from 'hooks/useUserContext';
import { useNomenclaturesContext } from 'hooks/useNomenclaturesContext';

import { libraryDigitalContentBreadcrumb } from 'views/library/breadcrumbs';
import { getSubjectConfig } from 'config/subject-config-data';
import { theme } from 'theme/theme';
import { iframeConfig, MOZAIK_TOKEN_URL, ContentLinkTypes } from './config';
import { apiPostAnalytics, ContentView } from 'services/apiFunctions/apiAnalytics';
import { Roles } from 'enums/userRoles';
import { ContentLinkSources, ContentVisibilityLevelEnum } from 'enums/contents-type.enum';
import { CONTENTS_VIEWER_SRC } from 'windows';

import { PrivateDecNotificationStyled } from './styles';

const LibraryDigitalContent = () => {
  const { contentId }: any = useParams();
  const { state: routerState }: any = useLocation();
  const userContext = useUserContext();
  const user = userContext?.user;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [contentLink, setContentLink] = useState('');
  const [fullContentLink, setFullContentLink] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [topicId, setTopicId] = useState(routerState?.topicId || 0);
  const [subjectName, setSubjectName] = useState('');

  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const isMozaik = contentLink?.includes(ContentLinkTypes.Mozaik);
  const isKhan = contentLink?.includes(ContentLinkTypes.Khan);
  const isIzzi = contentLink?.includes(ContentLinkTypes.Izzi);
  const isProsveta = contentLink?.includes(ContentLinkTypes.Prosveta);

  const {
    data: digitalContent,
    isLoading: isDigitalContentLoading,
  } = useGetContentById(contentId, {
    onSuccess: (content: any) => {
      const link = content.links[0]?.url || '';
      setContentLink(link);

      if (!Boolean(routerState?.topicId)) {
        const topicId = content.topics.filter((t: any) => t.id > 0 && t.type === "Primary")[0]?.id;
        setTopicId(topicId);
      }
    },
  });

  const isUploadedContent = digitalContent?.links?.[0].sourceCode === ContentLinkSources.DigitalBackpack;
  const isPdf = contentLink?.endsWith('.pdf');

  const { data: topic, isLoading: isTopicLoading } = useGetTopicById(topicId, {
    enabled: Boolean(topicId),
  });

  const { data: relatedContents, isLoading: areRelatedContentsLoading } = useGetRelatedContents(
    { topicId, count: 5, contentId },
    { enabled: Boolean(topicId) }
  );

  const { subjects } = useNomenclaturesContext();

  const {
    mutate: mutateAddContentRating,
    isLoading: isLoadingAddContentRating,
  } = useAddContentRating(contentId);

  const {
    mutate: mutateAddFavoriteContent,
    isLoading: isLoadingAddFavoriteContent,
  } = useAddFavoriteContent(topicId, {
    enabled: Boolean(topicId),
  });

  const {
    mutate: mutateRemoveFavoriteContent,
    isLoading: isLoadingRemoveFavoriteContent,
  } = useRemoveFavoriteContent(topicId, {
    enabled: Boolean(topicId),
  });

  const subjectId = topic?.subjectId || digitalContent?.primarySubjectId;
  const subjectConfig = getSubjectConfig(subjectId);
  const subjectCustomIcon = subjectConfig?.icon({
    fontSize: 'large',
    sx: { color: subjectConfig?.color },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [contentId]);

  useEffect(() => {
    const contentLinkVariant = isMozaik && ContentLinkTypes.Mozaik || isKhan && ContentLinkTypes.Khan ||
      isProsveta && ContentLinkTypes.Prosveta || isIzzi && ContentLinkTypes.Izzi || 
      isPdf && ContentLinkTypes.Pdf || isUploadedContent && ContentLinkTypes.DigitalBackpack;
    switch (contentLinkVariant) {
      case ContentLinkTypes.Mozaik: {
        if (user?.roles[0] === Roles.Parent) {
          setFullContentLink(contentLink);
          setIsLoading(false);
        } else {
          getMozaikToken().then((a) => {
            const lnk = `${MOZAIK_TOKEN_URL}?target=${contentLink}&token=${a.access_token}`;
            setFullContentLink(lnk);
            setIsLoading(false);
          });
        }
        break;
      }
      case ContentLinkTypes.Khan:
      case ContentLinkTypes.Pdf: {
        setFullContentLink(contentLink);
        setIsLoading(false);
        break;
      }
      case ContentLinkTypes.Prosveta: {
        const accessToken = AuthManager.getAzureAccessToken();
        setFullContentLink(`${contentLink}&accessToken=${accessToken}`);
        setIsLoading(false);
        break;
      }
      case ContentLinkTypes.Izzi: {
        getIzziToken().then((a) => {
          setFullContentLink(`${contentLink}?jwt=${a.access_token}`);
          setIsLoading(false);
        });
        break;
      }
      case ContentLinkTypes.DigitalBackpack: {
        if (digitalContent?.links?.[0].isExternal) {
          setFullContentLink(contentLink);
        } else {
          setFullContentLink(`${CONTENTS_VIEWER_SRC}${encodeURIComponent(contentLink)}`);
        }
        setIsLoading(false);
        break;
      }
      default: {
        setIsLoading(false);
        break;
      }
    }
  }, [contentLink, isUploadedContent]);

  useEffect(() => {
    if (Boolean(digitalContent) && !isTopicLoading && Boolean(subjects?.items?.length)) {
      const subjectName = subjects?.items.find(s => s.id === digitalContent?.primarySubjectId)?.name;
      setSubjectName(subjectName || '');

      const analyticRequest: ContentView = {
        contentId: digitalContent?.id || '',
        institutionId: parseInt(user?.institutionId!),
        authorId: digitalContent?.authorId,
        userId: user?.azureId || '',
        linkSourceCode: digitalContent?.links?.[0].sourceCode,
        rating: digitalContent?.ratingInfo.averageRating,
        name: digitalContent?.name,
        userType: Roles[user?.roles[0]!].toString(),
        basicClass: topic?.basicClassName || digitalContent?.classId?.toString(),
        subjectName: topic?.subjectName || subjectName,
        subjectId: topic?.subjectId || digitalContent?.primarySubjectId,
        isProfileSubject: Boolean(digitalContent?.isProfileSubject),
        topicName: topic?.name,
        topicId: topic?.id,
        contentLink: digitalContent?.links?.[0].url,
        dateTime: new Date().toISOString(),
        createdBy: digitalContent?.createdBy,
      };
      apiPostAnalytics(analyticRequest);
    }
  }, [digitalContent, isTopicLoading, subjects]);

  const renderExternalContent = () => (
    <Box mt={5} mb={5}>
      <Alert
        severity='warning'
        onClick={() =>
          window.open(fullContentLink, '_blank', 'noopener,noreferrer')
        }
        action={
          <ButtonBase
            onClick={() =>
              window.open(fullContentLink, '_blank', 'noopener,noreferrer')
            }
          >
            <PlayCircle />
          </ButtonBase>
        }
      >
        <Typography component='div' sx={{ cursor: 'pointer' }}>
          За да се прегледа този ресурс (
          <Typography component='span' fontWeight='bold'>
            {digitalContent?.name || ''}
          </Typography>
          ), e необходимо той да бъде отворен в нов прозорец.{' '}
          <Typography component='span' fontWeight='bold'>
            Mоля кликнете тук.
          </Typography>
        </Typography>
      </Alert>
    </Box>
  );

  const renderIFrameContent = () => {
    let styles = { ...iframeConfig.default };
    if (isFullscreen) {
      styles = { ...styles, ...iframeConfig.fullscreen };
    }

    return (
      <Box sx={{ height: '100vh' }}>
        {isFullscreen && (
          <Fab
            sx={{
              position: 'fixed',
              top: 10,
              left: 10,
              zIndex: '2500',
            }}
            onClick={() => {
              document.body.style.overflowY = 'visible';
              setIsFullscreen(false);
            }}
          >
            <FullscreenExit
              fontSize={tabletAndUp ? 'large' : 'medium'}
              sx={{ color: theme.palette.grey.main }}
            />
          </Fab>
        )}
        <iframe src={fullContentLink} style={styles} />
      </Box>
    );
  };

  const onFavorite = (isFavourite: boolean, id: string) => {
    const isAddFavorite = !isFavourite;
    if (isAddFavorite) {
      mutateAddFavoriteContent(id);
    } else {
      mutateRemoveFavoriteContent(id);
    }
  };

  const relatedContentActionButtons = (content: Content) => (
    <Box display='flex' alignContent='center' pr={[0, 3]} gap={0.5}>
      {content.hasAssignments && <AssignedContentsManagementButton />}
      <IconButton
        onClick={() => onFavorite(content.isFavourite, content.id)}
        disabled={isFavoriteProcessing}
      >
        {content.isFavourite ? (
          <Favorite
            fontSize={tabletAndUp ? 'large' : 'medium'}
            sx={{ color: theme.palette.info.main }}
          />
        ) : (
          <FavoriteBorder
            fontSize={tabletAndUp ? 'large' : 'medium'}
            sx={{ color: theme.palette.grey.main }}
          />
        )}
      </IconButton>
    </Box>
  );

  const actionButton = (isFavorite: boolean, id: string) => (
    <IconButton
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onFavorite(isFavorite, id);
      }}
      disabled={isFavoriteProcessing}
    >
      {Boolean(isFavorite) ?
        <Favorite sx={{ fontSize: '30px', color: theme.palette.info.main }} /> :
        <FavoriteBorder sx={{ fontSize: '30px', color: theme.palette.grey[900] }} />
      }
    </IconButton>
  );

  const renderRelatedContent = (content: Content) => (
    <Card key={content.id} sx={{ mb: 2 }}>
      <Link to={`/library/content/${content.id}`} state={routerState}>
        <Box p={2}>
          <ContentTile
            renderActionButtons={({ id, isFavorite }: any) => actionButton(isFavorite, id)}
            variant="large"
            {...content}
          />
        </Box>
      </Link>
    </Card>
  );

  const isFavoriteProcessing = isLoadingAddFavoriteContent || isLoadingRemoveFavoriteContent;
  const isContentPrivate = digitalContent?.visibilityLevel === ContentVisibilityLevelEnum.Organization;
  const routerStateUrl = routerState?.type ? `?type=${routerState?.type}` : '';
  const backButtonUrl = topicId ?
    `/library/${topic?.basicClassId}/${topic?.subjectId}/${topicId}${routerStateUrl}` :
    `/library/${digitalContent?.classId}/${digitalContent?.primarySubjectId}${routerStateUrl}`;

  return (
    <>
      <Box pb={[2, 4]}>
        {!isTopicLoading && topic && (
          <Breadcrumbs
            items={libraryDigitalContentBreadcrumb(
              topic.basicClassId,
              topic.subjectId,
              topic.subjectName,
              topicId,
              topic.name
            )}
          />
        )}
        {!topic && digitalContent && (
          <Breadcrumbs
            items={libraryDigitalContentBreadcrumb(
              digitalContent?.classId,
              digitalContent?.primarySubjectId,
              subjectName
            )}
          />
        )}
      </Box>

      {!isDigitalContentLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} display='flex' alignItems='center' mb={1}>
            <Link
              to={backButtonUrl}
            >
              <ArrowBackIos fontSize='large' color='secondary' />
            </Link>
            <Typography
              variant='h1'
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              {((Boolean(topicId) && Boolean(topic)) || Boolean(digitalContent?.primarySubjectId)) && subjectCustomIcon}
              {digitalContent?.name || ''}
            </Typography>
          </Grid>
        </Grid>
      )}

      {isContentPrivate && (
        <PrivateDecNotificationStyled>
          <InstitutionIcon />
          <Typography variant='caption'>
            Авторът на това съдържание е избрал да е достъпно само за твоето училище.
          </Typography>
        </PrivateDecNotificationStyled>
      )}

      <Grid container spacing={2} display='flex' alignItems='center'>
        <Grid item xs={8}>
          <Box display='flex' sx={mobile ? { flexDirection: 'column' } : {}}>
            <Typography color={theme.palette.grey.main} component='legend'>
              Оцени съдържание:
            </Typography>
            <MuiRating
              name='content-rating'
              emptyIcon={
                <StarRounded
                  fontSize='inherit'
                  sx={{ color: theme.palette.grey.main }}
                />
              }
              icon={
                <StarRounded
                  fontSize='inherit'
                  sx={{ color: theme.palette.warning.main }}
                />
              }
              onChange={(event, value) => {
                if (value) {
                  mutateAddContentRating(value);
                }
              }}
              value={digitalContent?.ratingInfo?.currentRating || 0}
              disabled={isLoadingAddContentRating}
            />
          </Box>
          <Box display='flex' sx={mobile ? { flexDirection: 'column' } : {}}>
            <Rating
              of={digitalContent?.ratingInfo?.maxValue || 0}
              value={digitalContent?.ratingInfo?.averageRating || 0}
              usersCount={digitalContent?.ratingInfo?.usersCount || 0}
            />
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            {userContext?.userRole.isTeacher && (
              <AssignContent
                contentId={contentId}
                subject={{
                  subjectId: topic?.subjectId || digitalContent?.primarySubjectId,
                  isProfileSubject: digitalContent?.isProfileSubject
                }}
              />
            )}
            <IconButton
              onClick={() => digitalContent && onFavorite(digitalContent.isFavourite, digitalContent.id)}
              disabled={isFavoriteProcessing}
            >
              {digitalContent?.isFavourite ? (
                <Favorite
                  fontSize={tabletAndUp ? 'large' : 'medium'}
                  sx={{ color: theme.palette.info.main }}
                />
              ) : (
                <FavoriteBorder
                  fontSize={tabletAndUp ? 'large' : 'medium'}
                  sx={{ color: theme.palette.grey.main }}
                />
              )}
            </IconButton>
            {isUploadedContent && !digitalContent?.links?.[0].isExternal && (
              <IconButton href={contentLink}>
                <FileDownload
                  fontSize={tabletAndUp ? 'large' : 'medium'}
                  sx={{ color: theme.palette.grey.main }}
                />
              </IconButton>
            )}
            <IconButton onClick={() => {
              document.body.style.overflowY = 'hidden';
              setIsFullscreen(true);
            }}>
              <Fullscreen
                fontSize={tabletAndUp ? 'large' : 'medium'}
                sx={{ color: theme.palette.grey.main }}
              />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Box mt={1}>
        {isDigitalContentLoading || isLoading &&  <Loader />}

        {(isKhan || isUploadedContent) && digitalContent?.links?.[0].isExternal && digitalContent?.name && renderExternalContent()}
        {!isKhan && !digitalContent?.links?.[0].isExternal && !isLoading && renderIFrameContent()}
      </Box>
      {topic && (
        <Box my={2}>
          <Typography variant='h5' sx={{ marginBottom: '20px' }}>
            Свързани уроци с тази тема:
          </Typography>
          {areRelatedContentsLoading && (
            <LoadingSkeleton itemsCount={6} height={83} />
          )}
          {areRelatedContentsLoading || relatedContents?.map((x: any) => renderRelatedContent(x))}
          {!areRelatedContentsLoading && !Boolean(relatedContents?.length) && (
            <FallbackSection message='Все още няма свързани уроци с тази тема' />
          )}
        </Box>
      )}
    </>
  );
};

export default LibraryDigitalContent;
