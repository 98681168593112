import React from 'react';
import { useTranslation } from 'react-i18next';

import { MainTextStyled, MarqueeContainerStyled, RootInnerStyled, TextContainerStyled } from './styles';
import { palette } from 'theme/components';

type Props = {
  user?: string | undefined | null,
  headerText: string
}

function MarqueeInner({ user, headerText }: Props) {
  const { t } = useTranslation();
  return (
    <RootInnerStyled>
      <MarqueeContainerStyled maxWidth="lg">
        <TextContainerStyled>
          <MainTextStyled variant="h2" sx={{ color: palette.common.white, textShadow: 'none' }}>
            {user && t('innerPage.header.title', { user })}
            {headerText}
          </MainTextStyled>
        </TextContainerStyled>
      </MarqueeContainerStyled>
    </RootInnerStyled>
  );
}

MarqueeInner.defaultProps = {
  user: null
};

export default MarqueeInner;
