import React, { useState } from 'react';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import type { SelectDataType } from './types';
import { LabelStyled, MenuItemStyled, MenuStyled, RootStyled } from './styles';
import { ListItemText, Typography } from '@mui/material';
import { Container } from '@mui/system';

type Props = {
  data: SelectDataType[];
  onChange: Function;
};

function QuickLinkSelect({ data, onChange }: Props) {
  const [anchorEl, setSelectOpen] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setSelectOpen(event.currentTarget);
  };

  const handleClose = () => {
    setSelectOpen(null);
  };

  return (
    <RootStyled>
      <LabelStyled open={anchorEl} onClick={handleClick} id="select-item">
        {!anchorEl && <ExpandMoreRounded />}
        {anchorEl && <ExpandLessRounded />}
      </LabelStyled>
      <MenuStyled
        anchorEl={anchorEl}
        id="select-item"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
      >
        {data.map((selectItem: any) => {
          const { id, label } = selectItem;
          return (
            <MenuItemStyled
              key={`${id} + ${label}`}
              onClick={() => {
                handleClose();
                onChange(selectItem);
              }}
            >
              {isNaN(label) ? (
                <Typography variant="subtitle2">{label}</Typography>
              ) : (
                <ListItemText>
                  <Typography variant="subtitle2">{label}</Typography>
                </ListItemText>
              )}
            </MenuItemStyled>
          );
        })}
      </MenuStyled>
    </RootStyled>
  );
}

QuickLinkSelect.defaultProps = {};

export default QuickLinkSelect;
