import * as Yup from 'yup';
import { AssignmentType } from './constants';

export const validationSchema = Yup.object({
  scheduled: Yup.object().when('type', {
    is: (type: any) => type == AssignmentType.Scheduled,
    then: Yup.object({
      classId: Yup.string().required('Моля, изберете паралелка.'),
      scheduleId: Yup.string().required('Моля, изберете конкретен час.')
    })
  }),
  allTime: Yup.object().when('type', {
    is: (type: any) => type == AssignmentType.AllTime,
    then: Yup.object({
      classes: Yup.array().min(1, "Моля, изберете поне една паралелка.")
    })
  }),
});