import { useState, createContext, useMemo } from 'react';
import UploadContent from 'views/upload-content';

export enum ModalTypes {
  UploadContent = 100
};

interface GlobalModal {
  setModal: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setModalType: React.Dispatch<React.SetStateAction<ModalTypes | null>>;
}

export const GlobalModalContext = createContext<GlobalModal | null>(null);

export const GlobalModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [modal, setModal] = useState<React.ReactNode>(null);
  const [modalType, setModalType] = useState<ModalTypes | null>(null);

  const value = useMemo(() => ({ setModal, setModalType }), [setModal, setModalType]);

  return (
    <GlobalModalContext.Provider value={value}>
      {modal}
      {modalType === ModalTypes.UploadContent && <UploadContent />}
      {children}
    </GlobalModalContext.Provider>
  );
};
