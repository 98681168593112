import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Card,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ContainerStyled, ContainerInnerStyled } from 'views/public/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { palette } from 'theme/components';

import questions from './data';
import InnerNavi from '../../../components/inner-navigation/inner-navigation';
import { Person3 } from '@mui/icons-material';
import { GoogleIcon, TeamsIcon, TrimmedLogo } from 'components/icons/svg';
import { AnswerContainerStyled } from './styles';

const items = [
  {
    id: 1,
    icon: <Person3 sx={{ color: palette.text.primary }} />,
    title: 'landingPage.section.faq.1.title',
  },
  {
    id: 2,
    icon: <TrimmedLogo />,
    title: 'landingPage.section.faq.2.title',
  },
  {
    id: 3,
    icon: <TeamsIcon />,
    title: 'landingPage.section.faq.3.title',
  },
  {
    id: 4,
    icon: <GoogleIcon />,
    title: 'landingPage.section.faq.4.title',
  }
];

function FAQSection() {
  const { t } = useTranslation();
  const [faqDialog, setFaqDialogOpen]: any = useState(false);
  const [value, setValue]: any = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: any) => {
    setValue(index);
  };

  const renderFAQList = ({ title, list }: any) => (
    <Box key={title} pb={4}>
      <Typography variant='h6' color={palette.common.white} textAlign='left'>
        {t(title)}
      </Typography>
      <Box>
        {list.map(({ question, answer }: any) => (
          <Card key={question} sx={{ marginY: 2, padding: 2, cursor: 'pointer' }}>
            <Typography
              variant='h5'
              textAlign='left'
              onClick={() => setFaqDialogOpen({
                title: t(question),
                content: answer,
              })}
            >
              {t(question)}
            </Typography>
          </Card>
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Container maxWidth='lg'>
        <Typography variant='h2'>{t('landingPage.section.faq.title')}</Typography>
      </Container>

      <Container maxWidth='lg'>
        <InnerNavi items={items} onClick={handleChange} activeIndex={value} />
      </Container>

      <Grid container>
        <SwipeableViews
          axis={'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          animateHeight
        >
          <ContainerStyled side='users'>
            <ContainerInnerStyled>
              <Container maxWidth='lg'>
                <Typography variant='h3' align='center'>{t('landingPage.section.faq.users.title')}</Typography>
                {questions.usersQuestions.map(renderFAQList)}
              </Container>
            </ContainerInnerStyled>
          </ContainerStyled>

          <ContainerStyled side='backpack'>
            <ContainerInnerStyled>
              <Container maxWidth='lg'>
                <Typography variant='h3' align='center'>{t('landingPage.section.faq.backpack.title')}</Typography>
                {questions.backpackQuestions.map(renderFAQList)}
              </Container>
            </ContainerInnerStyled>
          </ContainerStyled>

          <ContainerStyled side='teams' xs={12} item>
            <ContainerInnerStyled>
              <Container maxWidth='lg'>
                <Typography variant='h3' align='center'>{t('landingPage.section.faq.teams.title')}</Typography>
                {questions.teamsQuestions.map(renderFAQList)}
              </Container>
            </ContainerInnerStyled>
          </ContainerStyled>

          <ContainerStyled side='google' xs={12} item>
            <ContainerInnerStyled>
              <Container maxWidth='lg'>
                <Typography variant='h3' align='center'>{t('landingPage.section.faq.google.title')}</Typography>
                {questions.classroomQuestions.map(renderFAQList)}
              </Container>
            </ContainerInnerStyled>
          </ContainerStyled>
        </SwipeableViews>
      </Grid>

      <Dialog open={faqDialog} onClose={() => setFaqDialogOpen(false)} maxWidth="lg">
        {
          faqDialog && (
            <>
              <DialogTitle display='flex' alignItems='center' justifyContent='space-between' gap={2}>
                <Typography variant='h6'>
                  {faqDialog.title}
                </Typography>
                <IconButton onClick={() => setFaqDialogOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <AnswerContainerStyled
                  dangerouslySetInnerHTML={{
                    __html: t(
                      faqDialog.content.key, { links: faqDialog.content.links, images: faqDialog.content.images },
                    ),
                  }}
                />
              </DialogContent>
            </>
          )
        }
      </Dialog>
    </>
  );
}

FAQSection.defaultProps = {};

export default FAQSection;
