import { Grid, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import Link from '@mui/material/Link';
import { BoxStyled, ImgLogoMonStyed, ImgStyled, BoxLinkStyled } from './styles';
import { useUserRoleContext } from 'hooks/useUserRoleContext';
import { navLinks } from 'system-settings';
import { INavLink } from 'system-settings-types';
import { palette } from 'theme/components';
import { getLinkObjectKeysByRole } from 'utils/helpers';
import { useGlobalModalContext } from 'hooks/useGlobalModalContext';

type Props = {};

function Footer({}: Props) {
  const navigate = useNavigate();
  const role = useUserRoleContext();
  const globalModalContext = useGlobalModalContext();
  const linkKeysList = getLinkObjectKeysByRole(navLinks(), role);
  const renderRecursiveLinks: any = (data: INavLink[]) => {
    return data?.map(({ id, label, href, children, external, openDialog }: INavLink) => {
      if (!children) {
        return (
          <BoxLinkStyled
            key={id}
            onClick={() => {
              if (external) {
                window.open(href, '_blank');
              } else if (openDialog) {
                globalModalContext?.setModalType(openDialog);
              } else {
                navigate(`${href}`);
              }
            }}
          >
            <Typography variant="body1" color={palette.primary.main}>
              {label}
            </Typography>
          </BoxLinkStyled>
        );
      }
      return renderRecursiveLinks(children);
    });
  };
  const moveText = (
    <Grid item>
      <Box display="flex" flexDirection="column" pt={3}>
        <Typography variant="h6" pb={1}>
          ДИГИТАЛНА РАНИЦА
        </Typography>
        <Typography variant="body2" color="text.secondary">
          © Министерство на образованието и науката / Всички права запазени
        </Typography>
      </Box>
    </Grid>
  );
  return (
    <BoxStyled>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <Link href="https://mon.bg/" target="_blank" underline="none">
                <ImgLogoMonStyed
                  src={`${process.env.PUBLIC_URL}/images/logo.png`}
                  alt="logo Mon"
                />
              </Link>
              <Box pr={3}>
                <Typography variant="h4">РЕПУБЛИКА БЪЛГАРИЯ</Typography>
                <Typography>Министерство на образованието и науката</Typography>
              </Box>
            </Box>
            <Box>{moveText}</Box>

            <Grid container item gap={1} pt={1}>
              {renderRecursiveLinks(linkKeysList).map((links: any) => {
                return links;
              })}
            </Grid>
          </Grid>
          <Grid item sm={6} display="flex" flexDirection="column" gap={5}>
            <Box display="flex" alignItems="center" gap={1}>
              <Link href="#" target="_blank" underline="none" pr={4}>
                <ImgStyled
                  src={`${process.env.PUBLIC_URL}/images/eusef-logo-en.png`}
                  alt="logo EU"
                />
              </Link>
              <Link href="#" target="_blank" underline="none">
                <ImgStyled
                  src={`${process.env.PUBLIC_URL}/images/opnoir-logo.png`}
                  alt="logo OP"
                />
              </Link>
            </Box>
            <Box>
              <Typography variant="body1" color={palette.text}>
                Проект BG05M2ОP001-2.012-0001 „Образование за утрешния ден“,
                финансиран от Оперативна програма „Наука и образование за
                интелигентен растеж“, съфинансирана от Европейския съюз чрез
                Европейските структурни и инвестиционни фондове.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </BoxStyled>
  );
}

Footer.defaultProps = {};

export default Footer;
