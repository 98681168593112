import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { UseInfiniteQueryResult } from "react-query";

import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Box, Button, Card, CardContent, IconButton, Typography, useMediaQuery } from "@mui/material";

import AssignedContentsManagementButton from "components/assigned-contents-management-button";
import { FallbackSection } from "components/fallback";
import { PrivateContentIcon, PublicContentIcon } from "components/icons/svg";
import Rating from "components/rating";
import LoadingSkeleton from "components/loading-skeleton";

import { useAddFavoriteContent, useRemoveFavoriteContent } from "services/queryHooks/contents-hooks";
import { Content } from "api/types";
import { ContentActivity, ContentLinkSources, ContentVisibilityLevelEnum } from "enums/contents-type.enum";
import { PaginatedResult } from "types/global";
import { theme } from 'theme/theme';

type Props = {
  list: UseInfiniteQueryResult<PaginatedResult<Content>, Error>;
  fallbackText: string | ReactNode;
  searchInputValue: string;
  linkState: object;
  topicId?: number;
};

const ContentsList = ({ 
  list: { isLoading, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage, data },
  searchInputValue,
  fallbackText,
  linkState,
  topicId 
}: Props) => {
  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const {
    mutate: mutateAddFavoriteContent,
    isLoading: isLoadingAddFavoriteContent,
  } = useAddFavoriteContent(topicId);
  const {
    mutate: mutateRemoveFavoriteContent,
    isLoading: isLoadingRemoveFavoriteContent,
  } = useRemoveFavoriteContent(topicId);
  const isProcessing = isLoadingAddFavoriteContent || isLoadingRemoveFavoriteContent;

  const onFavoriteClick = (content: Content) => {
    const isAddFavorite = !content.isFavourite;
    if (isAddFavorite) {
      mutateAddFavoriteContent(content.id);
    } else {
      mutateRemoveFavoriteContent(content.id);
    }
  };

  const renderContent = (content: Content) => {
    const isContentPrivate = content.visibilityLevel === ContentVisibilityLevelEnum.Organization;
    const sourceCode = content.links?.[0]?.sourceCode;
    return (
      <Link
        key={content.id}
        to={`/library/content/${content.id}`}
        state={linkState}
      >
        <Card
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
            justifyContent: 'space-between',
            marginBottom: 2,
          }}
        >
          <CardContent>
            <Rating
              of={content.ratingInfo.maxValue}
              value={+content.ratingInfo.averageRating}
              usersCount={content.ratingInfo.usersCount}
            />
            <Box alignItems='center' display='flex' gap={1.5}>
              {isContentPrivate && (
                <PrivateContentIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />
              )}
              {isContentPrivate || (
                <PublicContentIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />
              )}
              <Box>
                <Typography variant='h4'>{content.name}</Typography>
                {sourceCode === ContentLinkSources.Mozaik && !isContentPrivate && content.activityId === ContentActivity.WithPayment && (
                  <Typography variant='caption'>
                    Изготвено по проект {tabletAndUp ? '"Образование за утрешния ден"' : 'ОУД'}
                  </Typography>
                )}
              </Box>
            </Box>
          </CardContent>
          <Box display='flex' alignContent='center' pr={[0, 3]} gap={0.5}>
            {content.hasAssignments && <AssignedContentsManagementButton />}
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onFavoriteClick(content);
              }}
              disabled={isProcessing}
            >
              {content.isFavourite ? (
                <Favorite
                  fontSize={tabletAndUp ? 'large' : 'medium'}
                  sx={{ color: theme.palette.info.main }}
                />
              ) : (
                <FavoriteBorder
                  fontSize={tabletAndUp ? 'large' : 'medium'}
                  sx={{ color: theme.palette.grey.main }}
                />
              )}
            </IconButton>
          </Box>
        </Card>
      </Link>
    );
  };
  
  return (
    <>
      {data?.pages && (
        <>
          {Boolean(data?.pages?.length) && (
            <>
              {data?.pages?.map((page: any) => (page.items.map((content: any) => renderContent(content))))}
              {hasNextPage && !isLoading && !isFetching && (
                <Box display="flex" justifyContent="center">
                  <Button
                    disableElevation
                    color="primary"
                    variant="outlined"
                    onClick={() => fetchNextPage()}
                    disabled={isFetchingNextPage}
                  >
                    Покажи още
                  </Button>
                </Box>
              )}
            </>
          )}
          {!Boolean(data?.pages?.[0]?.items.length) && (
            <>
              {!searchInputValue && (
                <FallbackSection message={fallbackText} />
              )}
              {searchInputValue && (
                <FallbackSection
                  message={(
                    <>
                      Няма открити резултати за
                      <Typography
                        component='span'
                        sx={{ color: theme.palette.primary[700] }}
                      >
                        "{searchInputValue}"
                      </Typography>
                    </>
                  )}
                />
              )}
            </>
          )}
        </>
      )}
      {(isLoading || isFetching) && <LoadingSkeleton itemsCount={6} height={83} />}
    </>
  );
};

export default ContentsList;