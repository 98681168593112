import styled from "@emotion/styled";

export const LanguageLinkStyled = styled('button')(({ theme }) => {
  const { spacing }: any = theme;

  return ({
    padding: spacing(0, 2),
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    borderRadius: '100%',
    marginRight: `${spacing(2)} !important`,
    textAlign: 'center'
  })
});
