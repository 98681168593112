import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import { transparentize } from 'polished';
import { Close } from '@mui/icons-material';
import { shadows } from 'theme/components';
import { BORDER_RADIUS } from 'theme/constants';

export const GridStyled = styled(Grid)(({ theme }: any) => {
  const { spacing, breakpoints } = theme;
  return {
    padding: spacing(3),
    backgroundColor: transparentize(0.1, '#468EEB'),
    boxShadow: shadows[4],
    [breakpoints.down('sm')]: {
      backgroundColor: '#32609C',
    },
  };
});

export const CloseIconStyled = styled(Close)(({ theme }: any) => {
  const { palette, breakpoints } = theme;
  return {
    fontSize: 50,
    color: palette.common.white,
    [breakpoints.down('md')]: {
      fontSize: 30,
    },
  };
});

export const MenuStickyHeaderStyled = styled(Box)(
  ({ theme: { palette, spacing } }: any) => {
    return {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: spacing(8),
      backgroundColor: palette.background.paper,
      borderRadius: `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0px 0px`,
    };
  }
);

export const MenuStickyFooterStyled = styled(Box)(
  ({ theme: { palette } }: any) => {
    return {
      position: 'sticky',
      bottom: 0,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: palette.common.white,
    };
  }
);
