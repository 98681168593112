import { Route, Routes } from 'react-router-dom';

import ProfArea from 'views/study-aids/prof-area';
import StudyAids from 'views/study-aids/study-aids';

type Props = {
};

function ProfStudyAids({}: Props) {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<ProfArea />} />
        <Route path="/:areaCode" element={<StudyAids />} />
      </Route>
    </Routes>
  );
}

ProfStudyAids.defaultProps = {
};

export default ProfStudyAids;