import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import MainHeader from 'components/header/MainHeader';
import Language from 'components/language-switch/language';
import Login from 'components/login';
import { LogosHeader } from 'components/header/logos';
import BlockingNotifications from 'components/blocking-notifications';

import { LoginTypeEnum } from 'authentication/types';
import { AuthManager } from 'authentication/auth-manager';
import { useGetAllCrudPublicItems } from 'services/queryHooks/crud-hooks';

import { getHomePageUrl } from 'utils/navigation';

function PublicLayout({ children }: any) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const pathnameStripped = pathname.substring(1).split('/')[0];
  const active = `/${pathnameStripped}` || '/';
  const navigate = useNavigate();
  const isLoggedIn = AuthManager.isAuthenticated();
  const { data: crudNotifications } = useGetAllCrudPublicItems();

  return (
    <>
      {Boolean(crudNotifications?.length) && (
        <BlockingNotifications notifications={crudNotifications} />
      )}
      <MainHeader
        activeNavigation={active}
        onNavigate={(ev, href) => navigate(href)}
        topBarActions={
          <Box display="flex">
            <Language />
            {
              isLoggedIn && (
                <Button
                  variant='contained'
                  color='info'
                  endIcon={<ArrowForward />}
                  onClick={() => navigate(getHomePageUrl())}
                >
                  {t('common.proceedToDashboard')}
                </Button>
              )
            }
            {
              !isLoggedIn && (
                <>
                  <Login navi />
                  <Login loginRoleType={LoginTypeEnum.b2c} navi />
                </>
              )
            }
          </Box>
        }
        variant={6}
        customLogo={<LogosHeader />}
        navigationItems={[
          { label: t('landingPage.navigation.home'), id: '0', href: '/' },
        ]}
      />
      {children}
    </>
  );
}

export default PublicLayout;
