/* eslint-disable */
// @ts-nocheck
import { Roles } from 'enums/userRoles';
import { AuthManager } from 'authentication/auth-manager';

export const useUserRoleContext = (): Roles  => {
  let user = AuthManager.getUser();

  // TODO: Petar Minev, please fix!!!
  if (!user || !user.roles || typeof (user.roles[0]) !== 'number') {
    return null;
  }

  let role = +user.roles[0];
  return role;
};
