import { Navigate } from 'react-router';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';
import { Roles } from 'enums/userRoles';
import { AuthManager } from './auth-manager';
import { hasRequiredRoles } from 'authentication/utils';
import { NavigationLinks } from 'enums/routes';

export const RequireAuth: React.FC<{ children: JSX.Element, requiredRoles?: Roles[] }> = ({ children, requiredRoles }) => {
  const isAuthenticated = AuthManager.isAuthenticated();
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (requiredRoles) {
    let hasRoles = hasRequiredRoles(requiredRoles);
    if (!hasRoles) {
      return (
        <Paper>
          <Box p={15} display="flex" flexDirection="column" alignItems="center">
            <Typography>Нямате необходимите права, за да достъпите страницата.</Typography>
            <Button startIcon={<Home />} href={NavigationLinks.weeklySchedule}>Начало</Button>
          </Box>
        </Paper>
      );
    }
  }

  return children;
};
