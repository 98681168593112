import styled from '@emotion/styled';
import {
  Box,
  Collapse,
  ListItem,
  Menu,
  MenuItem,
  MenuProps,
} from '@mui/material';
import { BORDER_RADIUS } from 'theme/constants';

const WIDTH = 40;
const HEIGHT = 40;
const MAXHEIGHT = 360;

export const RootStyled = styled(Box)(({ theme }: any) => {
  const { palette, typography } = theme;
  return {
    color: palette.common.white,
    fontFamily: typography.fontFamily,
    fontWeight: 400,
    borderRadius: BORDER_RADIUS,
    position: 'relative',
  };
});

export const LabelStyled = styled(Box)(({ theme, open }: any) => {
  const { palette, spacing, shadows } = theme;

  return {
    cursor: 'pointer',
    background: palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: HEIGHT,
    maxWidth: WIDTH,
    padding: spacing(1),
    borderRadius: BORDER_RADIUS,
    transitionDuration: '0.2s',
    transitionProperty: 'border-radius',
    boxShadow: shadows[2],
  };
});

export const CollapseStyled = styled(Collapse)(({ theme }: any) => {
  const { shadows } = theme;
  return {
    position: 'absolute',
    zIndex: 1,
    maxWidth: WIDTH,
    borderRadius: BORDER_RADIUS,
    boxShadow: shadows[2],
  };
});

export const ListItemStyled = styled(ListItem)(({ theme }: any) => {
  const { palette } = theme;
  return {
    cursor: 'pointer',
    background: palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:last-of-type': {
      borderRadius: `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`,
    },
  };
});

export const MultipleLabelStyled = styled(Box)(({ theme }: any) => {
  const { palette, spacing, shadows } = theme;
  return {
    position: 'relative',
    zIndex: 2,
    cursor: 'pointer',
    background: palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: HEIGHT,
    padding: spacing(1),
    borderRadius: BORDER_RADIUS,
    boxShadow: shadows[4],
    width: '100%',
    color: palette.text.primary,
  };
});

export const MultipleMenuStyled = styled(Collapse)(({ theme }: any) => {
  const { shadows, palette } = theme;
  return {
    width: '100%',
    background: palette.background.paper,
    position: 'absolute',
    zIndex: 1,
    // we try to offset the border radius, so that the collapsible looks like a part of the label
    top: `calc(100% - ${BORDER_RADIUS / 2})px`,
    borderRadius: `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`,
    boxShadow: shadows[4],
  };
});

export const MultipleSelectValuesHolderStyled = styled(Box)(
  ({ theme }: any) => {
    const { palette, spacing } = theme;
    return {
      display: 'flex',
      alignItems: 'center',
      padding: spacing(2),
      gap: 1.5,
      background: palette.grey[200],
      borderRadius: BORDER_RADIUS,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      },
    };
  }
);

export const MenuItemStyled = styled(MenuItem)(({ theme }: any) => {
  const { palette } = theme;
  return {
    color: palette.text.primary,
    fontWeight: 500,
  };
});

export const MenuStyled = styled((props: MenuProps) => <Menu {...props} />)(
  ({ theme }: any) => {
    const { palette, shadows, spacing } = theme;
    return {
      '& .MuiPaper-root': {
        borderRadius: BORDER_RADIUS,
        color: palette.secondary.main,
        boxShadow: shadows[4],
        maxHeight: MAXHEIGHT,
        '& .MuiMenu-list': {
          paddingTop: spacing(-1),
          boxShadow: shadows[4],
          backgroundColor: palette.background.paper,
        },
        '& .MuiMenuItem-root': {
          textAlign: 'center',
          padding: `${spacing(1)} ${spacing(1.5)} ${spacing(1)} ${spacing(1)}`,
          '& .MuiTypography-root': {
            '&:hover': { color: palette.primary.main },
          },
        },
        '&::-webkit-scrollbar': {
          width: '4px',
          borderRadius: BORDER_RADIUS,
        },
        '&::-webkit-scrollbar-track': {
          background: '#EBEBEC',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#7B809A',
          borderRadius: BORDER_RADIUS,
        },
      },
    };
  }
);
