/* eslint-disable */

declare global {
  interface Window {
    _env_: any;
  }
}

export const BASE_APP_URL = window._env_
  ? window._env_.REACT_APP_URL
  : process.env.REACT_APP_URL;

export const AAD_CLIENT_ID = window._env_
  ? window._env_.REACT_APP_AAD_CLIENT_ID
  : process.env.REACT_APP_AAD_CLIENT_ID;

export const AAD_AUTHORITY = window._env_
  ? window._env_.REACT_APP_AAD_AUTHORITY
  : process.env.REACT_APP_AAD_AUTHORITY;

export const B2C_CLIENT_ID = window._env_
  ? window._env_.REACT_APP_B2C_CLIENT_ID
  : process.env.REACT_APP_B2C_CLIENT_ID;

export const B2C_AUTHORITY = window._env_
  ? window._env_.REACT_APP_B2C_AUTHORITY
  : process.env.REACT_APP_B2C_AUTHORITY;

export const meetingAPI = window._env_
  ? window._env_.REACT_APP_MEETING_API
  : process.env.REACT_APP_MEETING_API;

export const authAPI = window._env_
  ? window._env_.REACT_APP_AUTH_API
  : process.env.REACT_APP_AUTH_API;

export const basAPI = window._env_
  ? window._env_.REACT_APP_API
  : process.env.REACT_APP_API;

export const assignmentAPI = window._env_
  ? window._env_.REACT_APP_ASSIGNMENT_API
  : process.env.REACT_APP_ASSIGNMENT_API;

export const contentAPI = window._env_
  ? window._env_.REACT_APP_CONTENT_API
  : process.env.REACT_APP_CONTENT_API;

export const notificationAPI = window._env_
  ? window._env_.REACT_APP_NOTIFICATIONS_API
  : process.env.REACT_APP_NOTIFICATIONS_API;

export const analythicsAPI = window._env_
  ? window._env_.REACT_APP_ANALYTHICS_API
  : process.env.REACT_APP_ANALYTHICS_API;

export const publicCommunicationsAPI = window._env_
  ? window._env_.REACT_APP_PUBLIC_COMMUNICATIONS_API
  : process.env.REACT_APP_PUBLIC_COMMUNICATIONS_API;

const REACT_APP_USER_SESSION_LIFETIME_MINUTES = window._env_
  ? window._env_.REACT_APP_USER_SESSION_LIFETIME_MINUTES
  : process.env.REACT_APP_USER_SESSION_LIFETIME_MINUTES;
export const USER_SESSION_LIFETIME_MINUTES = REACT_APP_USER_SESSION_LIFETIME_MINUTES || 60;

const REACT_APP_REFRESH_TOKEN_MINUTES = window._env_
  ? window._env_.REACT_APP_REFRESH_TOKEN_MINUTES
  : process.env.REACT_APP_REFRESH_TOKEN_MINUTES;
export const REFRESH_TOKEN_MINUTES_BEFORE_EXPIRATION = REACT_APP_REFRESH_TOKEN_MINUTES || 5;

export const BACKPACK_SHOULD_END_SESSION_KEY = window._env_
  ? window._env_.REACT_APP_BACKPACK_SHOULD_END_SESSION_KEY
  : process.env.REACT_APP_BACKPACK_SHOULD_END_SESSION_KEY;

export const ASSESSMENT_SHOULD_END_SESSION_KEY = window._env_
  ? window._env_.REACT_APP_ASSESSMENT_SHOULD_END_SESSION_KEY
  : process.env.REACT_APP_ASSESSMENT_SHOULD_END_SESSION_KEY;

export const CONTENTS_VIEWER_SRC = window._env_
  ? window._env_.REACT_APP_CONTENTS_VIEWER_SRC
  : process.env.REACT_APP_CONTENTS_VIEWER_SRC;

export const MOZABOOK_DOWNLOAD_LINK = window._env_
  ? window._env_.REACT_APP_MOZABOOK_DOWNLOAD_LINK
  : process.env.REACT_APP_MOZABOOK_DOWNLOAD_LINK