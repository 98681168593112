import { Filter } from './components/solutions-table';

export const STATUSES_QUERY_PARAM = 'statuses';
export const PAGE_QUERY_PARAM = 'page';
export const STUDENT_NAME_QUERY_PARAM = 'studentName';

export const buildQueryParams = (filter: Filter): URLSearchParams => {
  let params = new URLSearchParams();
  if (filter.statuses) {
    params.set(STATUSES_QUERY_PARAM, filter.statuses.join(','));
  }

  if (filter.page) {
    params.set(PAGE_QUERY_PARAM, `${filter.page}`);
  }

  if (filter.studentName) {
    params.set(STUDENT_NAME_QUERY_PARAM, filter.studentName || '');
  }

  return params;
};