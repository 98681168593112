export const TABLE_HEADERS = [
  {},
  {
    field: 'name',
    label: 'licenses.table.headers.name',
  },
  {
    field: 'key',
    label: 'licenses.table.headers.key',
  },
  {
    field: 'link',
    label: 'licenses.table.headers.link.label',
  },
];

export const MS_OFFICE_LICENSES = [
  {
    id: 1,
    name: 'Office LTSC Professional Plus 2021',
    key: 'MVVNF-Y63C9-3KVC9-PG77H-7Q2CX',
    link: 'https://moedistro.blob.core.windows.net/isos/en_office_professional_plus_2021_x86_x64_dvd_c6dd6dc6.iso',
  },
  {
    id: 2,
    name: 'Office Professional Plus 2019',
    key: 'FNXQB-8F6WG-DC3YT-YVPH7-KHMDD',
    link: 'https://moedistro.blob.core.windows.net/isos/Office_Deployment_Tools_2019_-3_MLF_X22-09817.EXE',
  },
  {
    id: 3,
    name: 'Office Professional Plus 2016 64bit English',
    key: 'MPN9K-DCYDY-62M2K-88PW7-JQKYB',
    link: 'https://moedistro.blob.core.windows.net/isos/SW_DVD5_Office_Professional_Plus_2016_64Bit_English_MLF_X20-42432.ISO',
  },
  {
    id: 4,
    name: 'Office Professional Plus 2016 32bit English',
    key: 'MPN9K-DCYDY-62M2K-88PW7-JQKYB',
    link: 'https://moedistro.blob.core.windows.net/isos/SW_DVD5_Office_Professional_Plus_2016_W32_English_MLF_X20-41353.ISO',
  },
  {
    id: 5,
    name: 'Office Professional Plus 2016 32bit Bulgarian',
    key: 'MPN9K-DCYDY-62M2K-88PW7-JQKYB',
    link: 'https://moedistro.blob.core.windows.net/isos/SW_DVD5_Office_Professional_Plus_2016_W32_Bulgarian_MLF_X20-41322.ISO',
  },
  {
    id: 6,
    name: 'Office Professional Plus 2013 32/64 Bit Bulgarian',
    key: '46JXD-9ND9J-9WK84-6P69P-3YF3H',
    link: 'https://moedistro.blob.core.windows.net/isos/SW_DVD5_Office_Professional_Plus_2013w_SP1_32-BIT_X64_Bulgarian_MLF_X19-35886.ISO',
  },
];

export const WINDOWS_LICENSES = [
  {
    id: 1,
    name: 'Windows 11 Education 64bit Bulgarian/English',
    key: '9N2B7-P7CXY-27TT8-GYBKG-3V64Q',
    link: 'https://moedistro.blob.core.windows.net/isos/en-us_windows_11_business_editions_version_22h2_updated_oct_2022_x64_dvd_080047de.iso',
  },
  {
    id: 2,
    name: 'Windows 10 LTSC',
    key: 'NDQGB-MXXTB-FY98F-J26QK-6CT2P',
    link: 'https://moedistro.blob.core.windows.net/isos/SW_DVD5_WIN_ENT_LTSC_2019_32BIT_English_-2_MLF_X22-05018%20(1).ISO',
  },
  {
    id: 3,
    name: 'Windows 10 Education 64 Bit Bulgarian / English',
    key: 'Bulgarian / English	9N2B7-P7CXY-27TT8-GYBKG-3V64Q',
    link: 'https://moedistro.blob.core.windows.net/isos/SW_DVD9_Win_Pro_10_1903_64BIT_English_Pro_Ent_EDU_N_MLF_X22-02890.ISO',
  },
  {
    id: 4,
    name: 'Windows 10 Education 32 Bit Bulgarian / English',
    key: '9N2B7-P7CXY-27TT8-GYBKG-3V64Q',
    link: 'https://moedistro.blob.core.windows.net/isos/SW_DVD9_Win_Pro_10_1903_32BIT_English_Pro_Ent_EDU_N_MLF_X22-02889.ISO',
  },
  {
    id: 5,
    name: 'Windows 7 Pro 32bit',
    key: 'VKX94-76GT8-3PTWK-7VMC7-FMM6J',
    link: 'https://moedistro.blob.core.windows.net/isos/SW_DVD5_Win_Pro_7_32BIT_English_Full_MLF_X15-71033.ISO',
  },
  {
    id: 6,
    name: 'Windows 10 Pro 64bit',
    key: '96PN8-FQ6DY-P92RM-4JR8W-VH66Y',
    link: 'https://moedistro.blob.core.windows.net/isos/SW_DVD9_Win_Pro_10_20H2.1_64BIT_English_Pro_Ent_EDU_N_MLF_-2_X22-43918.ISO',
  },
];

export const OFFICE_APPLICATIONS_PACKAGE = [
  'Microsoft Word',
  'Microsoft Excel',
  'Microsoft Outlook',
  'Microsoft Power Point',
  'Microsoft One Note',
];
