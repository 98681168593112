import styled from '@emotion/styled';
import { Grid, IconButton } from '@mui/material';
import { Box } from '@mui/system';

export const QuickLinkContainerStyled = styled(Grid)(({ theme }: any) => {
  const { breakpoints } = theme;

  return ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '0 !important',

    '&:nth-of-type(even)': {
      flexDirection: 'row-reverse'
    },

    '& img': {
      display: 'block',
      width: '100%'
    },

    '& h3': {
      fontSize: '20px',
      lineHeight: '1.2',
      fontWeight: 600,
      marginBottom: '30px',

      [breakpoints.up('md')]: {
        fontSize: '40px'
      }
    },

    '& p': {
      color: '#58566b',
      fontSize: '18px',
      fontWeight: '200',
      lineHeight: '1.8'
    }
  })
});

export const AccountDialogContentStyled = styled(Box)(({ theme }: any) => {
  const { palette } = theme;

  return ({
    '& a': {
      color: palette.primary.main,
    },
    '& img': {
      display: 'block',
      maxWidth: '100%'
    }
  })
});

export const IconButtonStyled = styled(IconButton)(({ theme: { palette, spacing } }: any) => ({
  position: 'absolute',
  right: spacing(1),
  top: spacing(1),
  color: palette.grey.main,
}));
