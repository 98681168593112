import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import {
  assignContentToClasses, deleteAssignedContent, getAssignedContentBySubjectId, getAssignedContents, getContent, getContentsByFilter, getFavoriteContentsByFilter,
  getRelatedContents, getStudentAssignedContentsByFilter, postContentRating, getAssignedContentByClassId,
  deleteAssignedContentByClassId, getSearchContentsBySubjectClassId, addFavoriteContent, removeFavoriteContent,
} from 'api/api';
import { DEFAULT_PAGINATION } from 'views/library/config';
import { PaginatedResult } from 'types/global';
import {
  AssignedContentType, AssignedContentItem, AssignmentInfoBase, ClassesContentAssignmentRequest, Content, SearchContentsBySubjectClassIdFilterType,
} from 'api/types';

import { useSnackbarContext } from 'hooks/useSnackbarContext';

/* Helpers: */
const invalidateQueriesOnFavorites = (queryClient: any, topicId: number | undefined) => {
  queryClient.invalidateQueries(['contentsByFilter']);
  queryClient.invalidateQueries(['favoriteContents']);
  queryClient.invalidateQueries(['teacherPinnedContent']);
  queryClient.invalidateQueries(['assignedContents']);
  queryClient.invalidateQueries(['relatedContents']);
  // Teacher:
  queryClient.invalidateQueries(['assignmentContentForClassByScheduleId']);
  queryClient.invalidateQueries(['assignmentContentForClass']);
  // Student:
  queryClient.invalidateQueries(['assignmentContentForSubjectByScheduleId']);
  queryClient.invalidateQueries(['assignmentContentForSubject']);
}

export const getNextPage = (response: any) => response?.pagination?.hasMore ? response?.pagination?.page + 1 : undefined;

/* Hooks: */

export const useGetContentById = (contentId: string, options = {}) =>
  useQuery<Content, Error>(
    ['contentById', contentId],
    () => getContent(contentId),
    {
      ...options,
    }
  );

export const useGetContentsByFilter = (
  filter: {
    topicIds?: number[], subjectId?: number, isProfileSubject?: boolean, classIds?: number[], name?: string, offset?: number
  },
  options = {}
) =>
  useInfiniteQuery<PaginatedResult<Content>, Error>(
    ['contentsByFilter', filter],
    ({ pageParam = DEFAULT_PAGINATION.Page }) => getContentsByFilter({ ...filter, page: pageParam }),
    {
      ...options,
      getNextPageParam: getNextPage,
    },
  );

export const useGetFavoriteContents = (
  filter: { topicId?: number, name?: string, offset?: number, subjectId?: number, basicClassId?: number, isProfileSubject: boolean },
  options = {}
) =>
  useInfiniteQuery<PaginatedResult<Content>, Error>(
    ['favoriteContents', { ...filter }],
    ({ pageParam = DEFAULT_PAGINATION.Page }) => getFavoriteContentsByFilter({ ...filter, page: pageParam }),
    {
      ...options,
      getNextPageParam: getNextPage,
    },
  );

export const useAddFavoriteContent = (topicId?: number, options = {} as any) => {
  const queryClient = useQueryClient();

  return useMutation((contentId: string) => addFavoriteContent(contentId), {
    ...options,
    onSuccess: (data, contentId) => {
      invalidateQueriesOnFavorites(queryClient, topicId);

      const content = queryClient.getQueryData<Content>(['contentById', contentId]);
      const updatedContent = { ...content, isFavourite: true };

      queryClient.setQueryData(['contentById', contentId], updatedContent);

      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  });
};

export const useRemoveFavoriteContent = (topicId?: number, options = {} as any) => {
  const queryClient = useQueryClient();

  return useMutation((contentId: string) => removeFavoriteContent(contentId), {
      ...options,
      onSuccess: (data, contentId) => {
        invalidateQueriesOnFavorites(queryClient, topicId);

      const content = queryClient.getQueryData<Content>(['contentById', contentId]);
      const updatedContent = { ...content, isFavourite: false };
      queryClient.setQueryData(['contentById', contentId], updatedContent);

      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  }
  );
};

export const useAddContentRating = (contentId: string, options = {} as any) => {
  const queryClient = useQueryClient();

  return useMutation((rating: number) => postContentRating(contentId, rating), {
    ...options,
    onSuccess: (currentRating) => {
      if (options.onSuccess) {
        options.onSuccess(currentRating);
      }

      const content = queryClient.getQueryData<Content>(['contentById', contentId]);
      const updatedContent = { ...content, ratingInfo: currentRating };
      queryClient.setQueryData(['contentById', contentId], updatedContent);

      queryClient.invalidateQueries(['contentsByFilter']);
    },
  });
};

export const useGetAssignmentsForSubject = (
  subjectId: number,
  subjectTypeId: number,
  options = {}
) =>
  useQuery<AssignedContentType[], Error>(
    'assignmentContentForSubject',
    () => getAssignedContentBySubjectId(subjectId, undefined, subjectTypeId),
    {
      ...options,
    }
  );

export const useGetAssignmentsForSubjectByScheduleId = (
  subjectId: number,
  scheduleId: number,
  options = {}
) =>
  useQuery<AssignedContentType[], Error>(
    ['assignmentContentForSubjectByScheduleId', scheduleId],
    () => getAssignedContentBySubjectId(subjectId, scheduleId),
    {
      ...options,
    }
  );

export const useGetAssignmentsForClass = (
  classId: number,
  subjectId: number,
  subjectTypeId: number,
  options = {}
) => useQuery<AssignedContentType[], Error>(
  ['assignmentContentForClass', classId, subjectId, subjectTypeId],
  () => getAssignedContentByClassId(classId, subjectId, undefined, subjectTypeId),
  {
    ...options,
  }
)

export const useGetAssignmentsForClassByScheduleId = (
  classId: number,
  subjectId: number,
  scheduleId: number,
  options = {}
) =>
  useQuery<AssignedContentType[], Error>(
    ['assignmentContentForClassByScheduleId', scheduleId],
    () => getAssignedContentByClassId(classId, subjectId, scheduleId),
    {
      ...options,
    }
  );

export const useRemoveAssignmentsForClass = (classId: number, subjectId: string, scheduleId: number, options = {} as any) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, assignmentPeriod }: any) => deleteAssignedContentByClassId(id, classId, subjectId, assignmentPeriod, scheduleId),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(['teacher-homeworks']);
        if (options.onSuccess) {
          options.onSuccess();
        }
      }
    }
  );
};

export const useGetAssignedContents = (
  filter: { subjectId: number, topicId?: number, scheduleId?: number, name?: string, offset?: number },
  options = {}
) =>
  useInfiniteQuery<PaginatedResult<Content>, Error>(
    ['assignedContents', { ...filter }],
    ({ pageParam = DEFAULT_PAGINATION.Page }) => getStudentAssignedContentsByFilter({ ...filter, page: pageParam, }),
    {
      ...options,
      getNextPageParam: getNextPage,
    }
  );

export const useAssignContent = (contentId: string, options = {} as any) => {
  const queryClient = useQueryClient();

  return useMutation((assignment: ClassesContentAssignmentRequest) =>
    assignContentToClasses(contentId, assignment), {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess(data);
      }

        queryClient.invalidateQueries(['assignmentContentForClass']);
        queryClient.invalidateQueries(['assignmentContentForClassByScheduleId']);
      queryClient.invalidateQueries(['managingAssignedContents']);
      },
    });
  };

export const useGetRelatedContents = (
  filter: { topicId?: number, count?: number, contentId?: string },
  options = {}
) =>
  useQuery<Content[], Error>(
    ['relatedContents', { topicId: filter?.topicId, contentId: filter.contentId }],
    () => getRelatedContents(filter),
    {
      ...options,
    }
  );

export const useManagingAssignedContentList = (filter: { offset?: number, name?: string }, options: Object = {}) =>
  useInfiniteQuery<PaginatedResult<AssignedContentItem>, Error>(
    ['managingAssignedContents', { ...filter }],
    ({ pageParam = DEFAULT_PAGINATION.Page }) => getAssignedContents({ ...filter, page: pageParam }),
    {
      ...options,
      getNextPageParam: getNextPage,
    }
  );

export const useAddFavoriteAssignedContent = (options = {} as any) => {
  const queryClient = useQueryClient();

  return useMutation((contentId: string) => addFavoriteContent(contentId), {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['managingAssignedContents']);

      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  });
};

export const useRemoveFavoriteAssignedContent = (options = {} as any) => {
  const queryClient = useQueryClient();

  return useMutation((contentId: string) => removeFavoriteContent(contentId), {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['managingAssignedContents']);

      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  });
};

export const useDeleteAssignment = (options = {} as any) => {
  const queryClient = useQueryClient();
  const snackbarContext = useSnackbarContext();
  return useMutation((assignmentInfo: AssignmentInfoBase) => deleteAssignedContent(assignmentInfo), {
    ...options,
    onSuccess: (response, request: any) => {
      if (Boolean(request?.contentId.length)) {
        snackbarContext?.addSnackbar({
          key: crypto.randomUUID(),
          title: 'Съдържанието е изтрито от списъка с насочени съдържания',
          severity: 'success'
        });
      }
      queryClient.invalidateQueries(['managingAssignedContents']);

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  });
};

export const useGetSearchContentsBySubjectClassId = (
  filter: SearchContentsBySubjectClassIdFilterType,
  options = {}
) => {
  return useQuery(
    ['getSearchContentsBySubjectClassId', filter],
    () => getSearchContentsBySubjectClassId(filter),
    {
      ...options,
      retry: false
    }
  );
};