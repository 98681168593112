export const linksContentPage = [
  {
    id: 1,
    title: 'contentPage.section.about.links.link1',
    href: 'https://www.youtube.com/watch?v=zEphcbQ7IIA&list=PL9SWXVqniPoIeRAVfPXJ28hXQ6xq3D_OH&index=9',
  },
  {
    id: 2,
    title: 'contentPage.section.about.links.link2',
    href: 'https://www.youtube.com/watch?v=6LgdkOszLU0&list=PL9SWXVqniPoIeRAVfPXJ28hXQ6xq3D_OH&index=7',
  },
  {
    id: 3,
    title: 'contentPage.section.about.links.link3',
    href: 'https://www.youtube.com/watch?v=hs_1z2TsEks&list=PL9SWXVqniPoIeRAVfPXJ28hXQ6xq3D_OH&index=6',
  },
  {
    id: 4,
    title: 'contentPage.section.about.links.link4',
    href: 'https://www.youtube.com/watch?v=dL6Q0PzP11E&list=PL9SWXVqniPoIeRAVfPXJ28hXQ6xq3D_OH&index=5',
  },
  {
    id: 5,
    title: 'contentPage.section.about.links.link5',
    href: 'https://www.youtube.com/watch?v=Y8jWOhK91RM&list=PL9SWXVqniPoIeRAVfPXJ28hXQ6xq3D_OH&index=4',
  },
  {
    id: 6,
    title: 'contentPage.section.about.links.link6',
    href: 'https://www.youtube.com/watch?v=MjMcmuaidi0&list=PL9SWXVqniPoIeRAVfPXJ28hXQ6xq3D_OH&index=3',
  },
];
