import { GlobalSearchItem, TopicInfo } from 'api/types';
import { getSubjectConfig } from 'config/subject-config-data';
import { PaginatedResult } from 'types/global';
import { underlineTextByAnArrayOfSearchWords } from 'utils/text';

export const normalizeSearchData = (
  data: PaginatedResult<GlobalSearchItem>,
  value: string
) => {
  const searchArray = value?.split(' ');

  const normalize = data?.items?.reduce((acc: any, current: any) => {
    const { name, topics, keywords } = current;
    const allBasicClasses: number[] = [];
    const allSubjectIds: number[] = [];
    const allMutatedSubjects: any[] = [];
    const mutatedSearchName = underlineTextByAnArrayOfSearchWords(
      searchArray,
      name,
    );
    const mutatedSearchKeywords: string[] = [];

    keywords && keywords.forEach((keyword: any) => {
      mutatedSearchKeywords.push(underlineTextByAnArrayOfSearchWords(
        searchArray,
        keyword
      ));
    });

    const mutateTopics = topics?.map((topic: TopicInfo) => {
      const { basicClassId, subjectId, name, keywords } = topic;
      const mutatedTopicsName = underlineTextByAnArrayOfSearchWords(
        searchArray,
        name
      );

      if (!allBasicClasses.find((x) => x === basicClassId)) {
        allBasicClasses.push(basicClassId);
      }
      if (!allSubjectIds.find((x) => x === subjectId)) {
        const subjectName = getSubjectConfig(subjectId)?.name;
        allSubjectIds.push(subjectId);
        allMutatedSubjects.push({
          subjectName: underlineTextByAnArrayOfSearchWords(
            searchArray,
            subjectName
          ),
          subjectId: subjectId,
        });
      }

      keywords && keywords.forEach((keyword: any) => {
        mutatedSearchKeywords.push(underlineTextByAnArrayOfSearchWords(
          searchArray,
          keyword
        ));
      });
      
      return {
        ...topic,
        name: mutatedTopicsName,
      };
    });

    return acc.concat({
      ...current,
      topics: mutateTopics,
      name: mutatedSearchName,
      allBasicClasses,
      subjects: allMutatedSubjects,
      keywords: mutatedSearchKeywords,
    });
  }, []);
  
  return { items: normalize, pagination: data?.pagination };
};
