import { ContentVisibilityLevel } from 'enums/contents-type.enum';
import { NotificationTypes } from 'enums/notificationTypes.enum';
import { HomeworkStatuses } from 'enums/homeworkStatuses.enum';
import { PaginationType } from '../types/global';
import { AssignmentBEType } from '../containers/assign-content/constants';

/*
  LEGEND
  1. Nomenclatures
  2. Contents
  3. Schedule
  4. Notifications
  5. Homeworks
  6. Attachments
  7. News
*/

// Nomenclatures
export interface Topic {
  id: number;
  name: string;
  isFavourite: boolean;
  subjectId: number;
  subjectName: string;
  basicClassId: number;
  basicClassName: string;
  unit: string;
  code: number;
  typeOfLesson: string[];
  methodics: string[];
  keywords: string[];
  additionalMaterials: boolean;
}

export interface SelectButtonData {
  id: any;
  name: string;
  link?: string;
  publisherId?: number;
  description?: string;
}

export interface SchoolbookType extends SelectButtonData {
  publisherId?: number;
  description?: string;
  subjectId?: number;
  basicClassId?: number;
  type?: number;
}

// Contents
export interface RatingInfo {
  minValue: number;
  maxValue: number;
  averageRating: number;
  usersCount: number;
  currentRating: number;
}

export interface ContentTopic {
  id: number;
  type: number;
}

export interface ContentLink {
  contentId: string;
  sourceCode: string;
  sourceName: string;
  url: string;
  isExternal: boolean;
}

export interface Content {
  id: string;
  name: string;
  activityId: number;
  ratingInfo: RatingInfo;
  status: string;
  isForSOPKids: boolean;
  authorId: number;
  state: number;
  externalId: string;
  isProfileSubject: boolean;
  topics: ContentTopic[];
  links: ContentLink[];
  keywords: string[];
  isFavourite: boolean;
  createdBy: string;
  visibilityLevel: ContentVisibilityLevel;
  hasAssignments?: boolean;
  primarySubjectId?: number;
  classId?: number;
}

export interface ClassesContentAssignmentRequest {
  subjectId: number;
  subjectName: string;
  subjectTypeId: number;
  subjectTypeName: string;
  assignmentPeriod: 1 | 2;
  scheduleId?: number;
  scheduleDate?: string;
  scheduleHour?: number;
  classes: Partial<TeacherCurriculum>[];
}

export interface SearchContentsBySubjectClassIdFilterType {
  basicClassId: number;
  subjectId: number;
  pattern: string;
  offset: number;
  page: number;
  type?: number;
  classId?: number;
}

export interface SearchContentsBySubjectClassIdResultType {
  contentId: string;
  contentName: string;
  topics: {
    id: string;
    name: string;
  }[];
  visibilityLevel: ContentVisibilityLevel;
  activityId: number;
  links: ContentLink[];
}

export interface ClassAssignmentInfo {
  assignmentPeriod: AssignmentBEType;
  classId: number;
  className: string;
  subjectId: number;
}

export interface AssignedContentType {
  id: string;
  name: string;
  links: ContentLink[];
  classAssignments: ClassAssignmentInfo[];
  studentAssignments: any[];
  topicId: number;
  ratingInfo: RatingInfo;
}

export interface ContentByTopicType {
  id: string;
  name: string;
  activityId: number;
  ratingInfo: RatingInfo;
  status: string;
  isForSOPKids: boolean;
  authorId: number;
  state: number;
  externalId: string;
  topics: {
    id: number;
    type: number;
  }[];
  links: ContentLink[];
  keywords: string[];
  isFavourite: boolean;
  createdBy: string;
  visibilityLevel: ContentVisibilityLevel;
  isProfileSubject: boolean;
}

export interface FileContentRequest {
  title: string;
  fileName: string;
  fileSize: number;
}

export interface LinkContentRequest {
  title: string;
  url: string;
}

export interface UploadedContentResponse {
  id: string;
  title: string;
}

export interface FileContentResponse extends UploadedContentResponse {
  attachmentId: string;
  uploadFileUrl: string;
}

// Schedule
export interface ClassTeacher {
  azureId: string;
  name: string;
}

export interface ShiftClass {
  basicClassId: number;
  curriculumId: number;
  profCurriculumId?: number;
  curriculumGroup?: number;
  id: number;
  name: string;
  neispuoScheduleId: number;
  subjectTypeId: number;
  subjectTypeName: string;
  isProfileSubject: boolean;
  hasSubjectCurriculum: boolean;
  teachers: ClassTeacher[];
}

export interface ShiftHour {
  number: number;
  startTime?: string;
  endTime?: string;
  subjectId?: number;
  subjectName?: string;
  subjectNameShort?: string;
  isEmptyHour?: boolean;
  classes: ShiftClass[];
}

export interface ShiftDay {
  number: number;
  date: string;
  hours: ShiftHour[];
}

export interface Shift {
  id: number;
  externalId: number;
  name: string;
  minHour: number;
  maxHour: number;
  current: boolean;
  days: ShiftDay[];
  // For mapping purposes
  hours?: {
    hourNumber: number,
    schedule: Partial<ShiftHour>[]
  }[],
  totalHoursCount: number;
};

export enum ScheduleType {
  Teacher = 'Teacher',
  Student = 'Student',
}

export interface TeacherCurriculum {
  curriculumId: number;
  subjectId: number;
  subjectName: string;
  subjectTypeId: number;
  subjectTypeName: string;
  isProfileSubject: boolean;
  classId: number;
  className: string;
  basicClassId: number;
}

export interface StudentCurriculum {
  basicClassId: number;
  curriculumId: number;
  curriculumPartId: number;
  flSubjectId: number;
  id: number;
  institutionId: number;
  isFL: boolean;
  personId: number;
  studentAzureId: string;
  studentId: number;
  subjectId: number;
  subjectTypeId: number;
  subjectName: string;
}

// Notifications
export interface NotificationTemplate {
  id: string;
  deleted: boolean;
  createdBy: string;
  createdOn: Date;
  action: string;
  type: string;
  notificationType: NotificationTypes;
  title: string;
  content: string;
  description: string;
}

export interface NotificationItem {
  id: string;
  deleted: boolean;
  createdBy: string;
  createdOn: Date;
  title: string;
  content: string;
  isSeen: boolean;
  recipientId: string;
  type: NotificationTypes;
  error: string;
  isSent: boolean;
  templateId: string;
  template: NotificationTemplate;
}

export interface NotificationsCounts {
  unseenNotificationsCount: number;
  allNotificationsCount: number;
}

// Homeworks
export interface AssignmentRequest {
  title: string;
  description: string;
  descriptionRawText: string;
  deadline: string;
  subjectId: number;
  subjectName: string;
  isProfileSubject: boolean;
  contents: string[];
  classes: number[];
  students: number[];
  files: any[];
}

export interface CreateAssignmentResult {
  id: string;
}

export interface BasicStudentAssignment {
  assignmentId: string;
  title: string;
  deadline: string;
  status: number;
  isOverdue: boolean;
}

export interface StudentAssignment extends BasicStudentAssignment {
  teacherId: string;
  teacherName: string;
  subjectId: number;
  subjectName: string;
  mark: number;
  comment: string;
  topicId: number;
  topicName: string;
  description: string;
  descriptionRawText: string;
}

export interface BasicTeacherAssignment {
  id: string;
  title: string;
  deadline: string;
  totalAssignees: number;
  totalSubmitted: number;
  totalInProgress: number;
}

export enum StudentHomeworksType {
  Waiting = 100,
  Done = 200,
}

export interface ProfArea {
  id: string;
  name: string;
  code: string;
};

export interface ProfStudyAid{
  id: number;
  name: string;
  code: string;
  link: string;
}

export interface TeacherAssignment extends BasicTeacherAssignment {
  createdOn: Date;
  subjectId: number;
  subjectName: string;
  classes: HomeworkClassAssignee[];
  students: HomeworkStudentAssignee[];
}

export interface DetailedTeacherAssignment extends TeacherAssignment {
  description: string;
  contents: HomeworkContent[];
}

export interface HomeworkStudentAssignee {
  id: string;
  name: string;
  classId: number;
  className: string;
}

export interface HomeworkClassAssignee {
  id: number;
  name: string;
}

export interface HomeworkContent {
  id: string;
  name: string;
  visibilityLevel: ContentVisibilityLevel;
  links: string[];
}

export interface BasicStudentSolution {
  id: string;
  studentId: string;
  studentName: string;
  classId: number;
  className: string;
  status: HomeworkStatuses;
  submittedOn: string;
  isOverdue: boolean;
}

export interface StudentSolution extends BasicStudentSolution {
  content: string;
  contentRawText: string;
  mark?: number;
  comment?: string;
  contents: HomeworkContent[];
}

export interface DetailedStudentSolution extends StudentSolution {
  assignmentTitle: string;
  assignmentDeadline: string;
  assignmentSubjectId: number;
  assignmentSubjectName: string;
}

export interface StudentHomework {
  assignmentId: string;
  title: string;
  deadline: string;
  teacherId: string;
  teacherName: string;
  subjectId: number;
  subjectName: string;
  isProfileSubject: boolean;
  description: string;
  descriptionRawText: string;
  contents: HomeworkContent[];
  solution: StudentSolution;
}

export interface SolutionRequest {
  assignmentId: string;
  content: string;
  contentRawText: string;
  contents: string[];
  attachments: string[];
  files: File[];
}

export interface SolutionAssessmentRequest {
  mark?: number;
  comment: string;
}

export type SubmitSolutionRequest = {
  solution?: SolutionRequest;
  solutionId: string;
};

export interface SolutionsFilter {
  studentName?: string | null;
  statuses?: number[];
  page?: number;
  offset?: number;
}
export type TeacherHomeworkBySubjectType = {
  deadline: string;
  descriptionRawText: string;
  id: string;
  title: string;
  totalAssignees: number;
  totalInProgress: number;
  totalSubmitted: number;
};

export interface TeacherHomeworksBySubjectType {
  items: TeacherHomeworkBySubjectType[];
  pagination: PaginationType;
  totalCount: any;
}

// Attachments
export enum AttachmentsType {
  Assignment = 100,
  Solution = 200,
  Crud = 300
}

export interface FileAttachment {
  id: string;
  name: string;
  sizeBytes: number;
  mimeType: string;
  contentType: number;
  blobStorageReference: string;
  blobStorageFileName: string;
  referenceId: string;
  createdAt: string;
  createdBy: string;
  downloadPath: string;
}

// News
export interface Crud {
  enabled?: boolean;
  id?: string;
  roles?: RolesCrud[];
  dateCreated: string;
}

export interface News extends Crud {
  contentBg: string;
  contentEn: string;
  descriptionBg: string;
  descriptionEn: string;
  hyperlinks: HyperlinksType[];
  tag: number | string;
  titleBg: string;
  titleEn: string;
  files?: File[];
}

export interface BlockingNotification extends Crud {
  endDate: string | null;
  messageBg: string;
  messageEn: string;
}

export interface HyperlinksType {
  titleBg: string;
  titleEn: string;
  link: string;
  isFile?: boolean;
}

export interface RolesCrud {
  id: string;
  nameBg: string;
  nameEn: string;
}

// Meetings
export enum MeetingExternalSystem {
  MicrosoftTeams = 1,
  GoogleHangout = 2,
}

export interface Meeting {
  id: string;
  createdBy: string;
  createdOn: Date;
  classIds: number[];
  subjectId: number;
  subjectName: string;
  meetingLink: string;
  institutionId: number;
  date: string;
  creatorName: string;
  externalSystem: MeetingExternalSystem;
}

export interface MeetingClass {
  classId: number;
  basicClassId: number;
  name: string;
}

export interface MeetingRequest {
  classes: MeetingClass[];
  subjectId: number;
  subjectName: string;
  date: Date;
}

//Managing assigned contents
export interface AssignedContentItem {
  id: string;
  name: string;
  isFavourite: boolean;
  visibilityLevel: ContentVisibilityLevel;
  ratingInfo: any;
  subjectId: number;
  subjectName: string;
  subjectTypeId: number;
  subjectTypeName: string;
  isProfileSubject: boolean;
  activityId: number;
  links: ContentLink[];
  alltimeAssignments: AssignmentInfo[];
  scheduleAssignments: AssignmentInfo[];
}

export interface AssignmentInfo {
  assigneeId: string;
  assigneeName: string;
  classId?: number;
  className: string;
  subjectId: number;
  subjectName: string;
  subjectTypeId: number;
  subjectTypeName: string;
  scheduleId?: number;
  scheduleHour?: number;
  scheduleDate: string;
  assignmentPeriod: any;
}

export interface AssignmentInfoBase {
  contentId?: string;
  classId?: number;
  subjectId?: number;
  subjectTypeId: number;
  subjectTypeName: string;
  scheduleId?: number;
  assignmentPeriod: any;
}

//NEISPUO
export interface Remark {
  RemarkId: number;
  classId: number;
  className: string;
  curriculumId: number;
  date: string;
  description: string;
  institutionId: number;
  schoolYear: number;
  subjectId: number;
  subjectName: string;
  subjectNameShort: string;
  subjectTypeId: number;
  subjectTypeName: string;
  teacherName: string;
  type: number;
}

//Global Search
export interface TopicInfo {
  id: string;
  name: string;
  keywords: string[];
  subject: string;
  subjectId: number;
  subjectName: string;
  basicClassId: number;
}

export interface GlobalSearchItem {
  id: string;
  name: string;
  keywords: string[];
  topics: TopicInfo[];
}
