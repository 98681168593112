import * as yup from 'yup';

export const REQUIRED_MESSAGE = 'Полето е задължително.';
const MAX_FILES_SIZE_MB = 100;

export const FILES_VALIDATION = yup.array().test(
  'totalFilesSize',
  `Общият размер на прикачените файлове не трябва да надвишава ${MAX_FILES_SIZE_MB}MB.`,
  (files) => {
    const totalSize = files?.reduce((acc, file) => acc + file.size, 0);
    return totalSize <= MAX_FILES_SIZE_MB * 1000 * 1000;
  }
);