import React from 'react';
import {
  SpecialGradeBad,
  SpecialGradeGood,
  SpecialGradeNeutral,
  SpecialGradeVeryBad,
  SpecialGradeVeryGood,
} from 'components/icons/svg';

export enum GradeEnum {
  CurrentMark = 1,
  TestMark = 2,
  MidtermExam = 3,
  QuizMark = 4,
  Homework = 5,
  EntryExam = 11,
  FinalExam = 12,
  TermMark = 21,
  AnnualMark = 22,
  ImportMark = 99,
}

export const specialGradeToIcon: any = {
  2: <SpecialGradeVeryBad />,
  3: <SpecialGradeBad />,
  4: <SpecialGradeNeutral />,
  5: <SpecialGradeGood />,
  6: <SpecialGradeVeryGood />,
}
