import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AttachFiles } from 'containers';
import { Formik, FormikProps } from 'formik';
import { bg } from 'date-fns/locale';
import SimpleMDEReact from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

import { useGetRoles } from 'services/queryHooks/crud-hooks';
import { AttachmentsType, BlockingNotification, News, RolesCrud } from 'api/types';

import Hyperlinks from './hyperlinks';
import { validationSchemaCrud } from '../common/validations';
import { tags } from '../constants';
import { formStructure, TypeCrud } from '../config';

import { TextareaStyled } from './styles';

type Props = {
  onSubmit: (event: any) => void;
  submitting: boolean;
  crudType: TypeCrud;
  initialValues: News | BlockingNotification;
  isEdit?: boolean;
};

type LabelFiled = {
  label?: string;
  required?: boolean;
};

interface FormStructureType extends LabelFiled {
  id: string;
  type: string;
  xs?: number;
  sm?: number;
  md?: number;
  children?: any;
}

interface NormalizeRoles {
  key: RolesCrud;
}

function CreateCrudForms({
  onSubmit,
  submitting,
  crudType,
  initialValues,
  isEdit = false
}: Props) {
  const { t } = useTranslation();
  const { data: roles } = useGetRoles();
  const itemFormStructure = formStructure()[crudType];
  const validationForm = validationSchemaCrud()[crudType];

  const normalizeRoles: NormalizeRoles[] = roles?.reduce(
    (acc: any[], c: any) => {
      return acc.concat({
        [c.id]: {
          ...c,
        },
      });
    },
    []
  );

  const renderLabel = ({ label, required }: LabelFiled) => (
    <>
      {label && t(label)}
      {required && (
        <Typography variant="h6" component="span" color="error">
          *
        </Typography>
      )}
    </>
  );

  const renderRecursiveFields = (
    formStructure: FormStructureType[],
    props: FormikProps<any>
  ) => {
    const { handleChange, setFieldValue, handleBlur, errors, values, touched } =
      props;
    return (
      <>
        {formStructure.map(
          ({
            id,
            type,
            children,
            label,
            required,
            ...rest
          }: FormStructureType) => {
            const renderError = (
              <>
                {touched[id] && errors[id] && (
                  <Typography key={id} variant="caption" color="error">
                    {`${errors[id]}`}
                  </Typography>
                )}
              </>
            );

            if (type === 'grid-row') {
              return (
                <Grid key={id} container item {...rest}>
                  {renderRecursiveFields(children, props)}
                </Grid>
              );
            }

            if (type === 'grid-col') {
              return (
                <Grid key={id} item {...rest}>
                  {renderRecursiveFields(children, props)}
                </Grid>
              );
            }

            return (
              <>
                {type === 'textarea' && (
                  <InputLabel key={id + label} id={id}>
                    {renderLabel({ label, required })}
                  </InputLabel>
                )}

                <FormControl fullWidth key={id}>
                  {type === 'textarea' && (
                    <TextareaStyled
                      key={id}
                      id={id}
                      minRows={5}
                      value={values && values[id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}

                  {type === 'text' && (
                    <TextField
                      key={id}
                      id={id}
                      label={renderLabel({ label, required })}
                      fullWidth
                      name={id}
                      value={values && values[id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}

                  {type === 'richtext' && (
                    <React.Fragment key={id}>
                      <InputLabel id={id}>
                        {renderLabel({ label, required })}
                      </InputLabel>
                      <Box pt={5}>
                        <SimpleMDEReact
                          value={values && values[id]}
                          onChange={(value: any) => {
                            setFieldValue(id, value);
                          }}
                          options={editorOptions}
                        />
                      </Box>
                    </React.Fragment>
                  )}

                  {type === 'select' && (
                    <React.Fragment key={type}>
                      <InputLabel id={id}>
                        {renderLabel({ label, required })}
                      </InputLabel>
                      <Select
                        name={id}
                        multiple={values && id === 'roles'}
                        value={values && values[id]}
                        renderValue={(selected) => {
                          if (id === "roles") {
                            const selectedRoles: string[] = [];
                            normalizeRoles.map((objRoles: any) => {
                              selected?.forEach((selectRoles: string) => {
                                if (selectRoles === objRoles[selectRoles]?.id) {
                                  selectedRoles.push(
                                    objRoles[selectRoles]?.nameBg
                                  );
                                }
                              });
                            });
                            return selectedRoles.join(', ')
                          }
                          if (id === "tag") {
                            const nameTag: string | undefined = tags?.find(x => x.id === selected)?.title;
                            return nameTag && t(nameTag)
                          }

                        }}
                        label={renderLabel({ label, required })}
                        labelId={id}
                        onChange={(e) => setFieldValue(id, e.target.value)}
                        onBlur={handleBlur}
                      >
                        {id === 'tag' &&
                          tags?.map(({ id, title }: any) => (
                            <MenuItem key={id} value={id}>
                              {t(title)}
                            </MenuItem>
                          ))}
                        {id === 'roles' && roles?.map(({ id, nameBg }: any) => (
                          <MenuItem key={id} value={id}>
                            <ListItemText
                              primary={nameBg}
                              primaryTypographyProps={{
                                style: { whiteSpace: 'normal' },
                              }}
                            />
                            <Checkbox
                              checked={values && values?.roles?.indexOf(id) > -1}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </React.Fragment>
                  )}

                  {type === 'datePicker' && (
                    <>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={bg}
                      >
                        <DateTimePicker
                          label={label ? renderLabel({ label, required }) : ''}
                          reduceAnimations
                          minDate={new Date()}
                          value={values && values[id]}
                          onChange={(nextDate: any) =>
                            setFieldValue(id, nextDate)
                          }
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              error={touched[id] && errors[id]}
                              inputProps={{
                                ...params.inputProps,
                                pt: 0,
                              }}
                              sx={{
                                width: '100%',
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  )}

                  {type === 'switch' && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values && values[id]}
                          onChange={(e) => setFieldValue(id, e.target.checked)}
                        />
                      }
                      label={renderLabel({ label, required })}
                    />
                  )}

                  {type === 'attach' && (
                    <Grid item xs={12} md={12}>
                      <AttachFiles
                        files={!isEdit && values ? values[id] : []}
                        attachments={isEdit && values ? values[id] : []}
                        referenceId={values && values.id}
                        type={AttachmentsType.Crud}
                        onUpload={(files: any) => setFieldValue(id, files)}
                        error={
                          touched.files ? (errors.files as string) : undefined
                        }
                        readonly={isEdit}
                      />
                    </Grid>
                  )}

                  {type === 'hyperlinks' && (
                    <Hyperlinks
                      key={id}
                      value={values && values[id]}
                      label={renderLabel({ label, required })}
                      onChange={(value: any) => {
                        setFieldValue(id, value);
                      }}
                    />
                  )}
                </FormControl>

                {renderError}
              </>
            );
          }
        )}
      </>
    );
  };

  const editorOptions = useMemo(() => ({ maxHeight: '250px' }), []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationForm}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <form onSubmit={props.handleSubmit}>
            <Grid
              container
              xs={12}
              display="flex"
              flexDirection="column"
              gap={3}
            >
              {renderRecursiveFields(itemFormStructure, props)}
            </Grid>

            <Grid
              item
              xs={12}
              py={1}
              display="flex"
              justifyContent="center"
              gap={2}
              pt={2}
            >
              <NavLink to={`/administration/crud/${crudType}`}>
                <Button
                  disableElevation
                  variant="outlined"
                  disabled={submitting}
                >
                  {t('common.cancel')}
                </Button>
              </NavLink>

              <LoadingButton
                disableElevation
                variant="contained"
                type="submit"
                loading={submitting}
                sx={{ marginRight: 2 }}
              >
                {t('common.agree')}
              </LoadingButton>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}

CreateCrudForms.defaultProps = {};

export default CreateCrudForms;
