import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { FallbackSection } from 'components/fallback';
import LoadingSkeleton from 'components/loading-skeleton';
import ContentTile from 'components/content-tile';

import { useGlobalSearchList } from 'services/queryHooks/use-global-search';
import { ContentLink, GlobalSearchItem, TopicInfo } from 'api/types';
import { PaginatedResult } from 'types/global';

import { DEFAULT_SEARCH_PAGINATION } from './config';
import { normalizeSearchData } from './utils';

import { ContentVisibilityLevel } from 'enums/contents-type.enum';

export type ModifySubject = {
  subjectName: string;
  subjectId: number;
};

interface ModifyData {
  id: string;
  name: string;
  keywords: string[];
  allBasicClasses: number[];
  subjects: ModifySubject[];
  topics: TopicInfo[];
  visibilityLevel: ContentVisibilityLevel;
  activityId: number;
  links: ContentLink[];
  subjectId?: number;
  classId?: number;
  isProfileSubject: boolean;
}

export default function GlobalSearch() {
  const { t } = useTranslation();
  const [searchParams]: any = useSearchParams();
  const search: string = searchParams.get('search');

  const [currentPage, setCurrentPage] = useState<number>(
    DEFAULT_SEARCH_PAGINATION.page
  );

  const { data: searchItems, isLoading: isLoading } = useGlobalSearchList(
    { itemsPerPage: DEFAULT_SEARCH_PAGINATION.itemsPerPage, page: currentPage },
    search,
    {
      select: (data: PaginatedResult<GlobalSearchItem>) =>
        normalizeSearchData(data, search),
    }
  );

  const renderFallbackImage = isLoading && (
    <LoadingSkeleton itemsCount={4} height={83} />
  );

  useEffect(() => {
    setCurrentPage(DEFAULT_SEARCH_PAGINATION.page);
  }, [search]);

  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  if (!searchItems) {
    return <>{renderFallbackImage}</>;
  }

  return (
    <>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={6} display="flex" alignItems="center" gap={1}>
          <Typography variant="h1">
            {t('pages.globalSearch.labelPage')}
          </Typography>
          <Typography variant="h2">
            "<strong>{search}</strong>"
          </Typography>
        </Grid>
      </Grid>
      {!isLoading && !Boolean(searchItems?.items?.length) && (
        <FallbackSection message={t('pages.globalSearch.emptyMessage')} />
      )}
      <Grid item xs={12} display="flex" flexDirection="column" gap={2} py={2}>
        {renderFallbackImage}
        {searchItems &&
          searchItems?.items?.map(
            ({
              id,
              name,
              topics,
              allBasicClasses,
              visibilityLevel,
              subjects,
              keywords,
              activityId,
              links,
              subjectId,
              classId,
              isProfileSubject
            }: ModifyData) => {
              return (
                <Card
                  onClick={() => {
                    window.open(`/library/content/${id}`, '_blank');
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <CardContent>
                    <ContentTile
                      id={id}
                      name={name}
                      topics={topics}
                      subjects={subjectId ? [{ subjectId, isProfileSubject }] : subjects}
                      classes={classId ? [classId] : allBasicClasses}
                      visibilityLevel={visibilityLevel}
                      activityId={activityId}
                      sourceCode={links?.[0]?.sourceCode}
                      keywords={keywords}
                      variant={tabletAndUp ? 'large' : 'medium'}
                    />
                  </CardContent>
                </Card>
              );
            }
          )}
      </Grid>
      {!isLoading && Boolean(searchItems?.items?.length) && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="outlined">
            <Button
              disabled={searchItems?.pagination?.page <= 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <ChevronLeft />
            </Button>
            <Button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={!searchItems?.pagination?.hasMore}
            >
              <ChevronRight />
            </Button>
          </ButtonGroup>
        </Grid>
      )}
    </>
  );
}
