import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { transparentize } from 'polished';
import { BORDER_RADIUS_LARGE } from 'theme/constants';

export const PaperStyled = styled(Paper)(({ theme }: any) => {
  return {
    // This is done, to ensure negative margins are applied. It disables the margin collapse default browser property
    paddingTop: '1px',
    position: 'relative',
    minHeight: '225px',
  };
});

export const AvatarContainerStyled = styled('div')(({ theme }: any) => {
  const { spacing } = theme;

  return {
    margin: spacing(-2, 0, 0, 2),
  };
});

export const AvatarStyled = styled('div')(
  ({ theme, backdrop, withShadow }: any) => {
    const { palette } = theme;

    return {
      background: backdrop,
      borderRadius: BORDER_RADIUS_LARGE,
      width: '64px',
      height: '64px',
      color: palette.common.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...(withShadow
        ? {
            boxShadow: `0px 2px 6px ${transparentize(0.64, backdrop)}`,
          }
        : {}),
    };
  }
);
