import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type Props = {
  text?: string | ReactNode;
  sx?: Object;
};

// TODO: remove
const FallbackText = (props: Props) => {
  const { text, sx } = props;
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Typography>{text}</Typography>
    </div>
  );
};

FallbackText.defaultProps = {
  text: 'Няма намерени резултати.',
};

export default FallbackText;
