import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Box, Button, Grid, Paper, SelectChangeEvent, TextField, Typography
} from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { bg } from 'date-fns/locale';

import { Editor } from 'components/rich-text-editor';
import Breadcrumbs from 'components/breadcrumbs';
import { HomeworkContents } from 'views/homeworks/components/homework-attachments';
import LoadingSkeleton from 'components/loading-skeleton';
import { AttachFiles } from 'containers';

import { AssignmentRequest, Content, TeacherCurriculum } from 'api/types';
import { getTeacherCurriculum, createAssignment } from 'api/api';

import { FormInputRow } from 'components/form-input-row';
import {
  ClassesSelect, SubjectSelect, ClassOption, SubjectOption
} from './components/select';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { initialFormValues, notifications, validationSchema } from './constants';
import { breadcrumbs } from '../common/breadcrumbs';
import { getEndOfDayFormattedDate } from 'utils/dateFormat';
import { NavigationLinks } from 'enums/routes';

type FormProps = {
  title: string;
  description: object;
  descriptionRawText: string;
  deadline: Date | null;
  subject?: string;
  classes: number[];
  files: File[];
  contents: Content[];
};

const CreateHomework = () => {
  const navigate = useNavigate();
  const snackbarContext = useSnackbarContext();

  const addNotification = (type: 'success' | 'error') => {
    snackbarContext?.addSnackbar({
      key: crypto.randomUUID(),
      title: notifications[type],
      severity: type
    });
  };

  const {
    handleChange, handleSubmit, setFieldValue, errors, values, touched, isSubmitting
  } = useFormik<FormProps>({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: (assignmentValues) => {
      setSubmitting(true);
      const homework: AssignmentRequest = {
        title: assignmentValues.title,
        description: JSON.stringify(assignmentValues.description),
        descriptionRawText: assignmentValues.descriptionRawText,
        deadline: getEndOfDayFormattedDate(assignmentValues.deadline),
        subjectId: selectedSubject?.subjectId!,
        subjectName: selectedSubject?.label!,
        isProfileSubject: Boolean(selectedSubject?.isProfileSubject),
        classes: assignmentValues.classes,
        // TODO: implement select for students
        students: [],
        files: assignmentValues.files,
        contents: assignmentValues.contents.map(c => c.id),
      };
      createAssignment(homework)
        .then((res) => {
          if (res.id) {
            addNotification('success');
            navigate(NavigationLinks.homeworks);
          } else {
            addNotification('error');
          }
        })
        .catch(() => {
          addNotification('error');
        })
        .finally(() => setSubmitting(false));
    }
  });

  // subjects
  const [allCuriculums, setAllCuriculums] = useState<TeacherCurriculum[]>([]);
  const [subjectOptions, setSubjectOptions] = useState<SubjectOption[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<SubjectOption | undefined>(undefined);

  // classes
  const [classOptions, setClassOptions] = useState<ClassOption[]>([]);
  const [selectedBasicClasses, setSelectedBasicClasses] = useState<number[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getTeacherCurriculum().then((res) => {
      setAllCuriculums(res);
      const uniqueSubjectOptions = res.reduce((acc: SubjectOption[], curriculum: TeacherCurriculum) => {
        if (!acc.find(x => x.subjectId === curriculum.subjectId && x.subjectTypeId === curriculum.subjectTypeId)) {
          acc.push({
            label: `${curriculum.subjectName}${curriculum.isProfileSubject ? ` ${curriculum.subjectTypeName}` : ''}`,
            id: `${curriculum.subjectId}-${curriculum.subjectTypeId}`,
            subjectTypeId: curriculum.subjectTypeId,
            subjectId: curriculum.subjectId,
            isProfileSubject: curriculum.isProfileSubject
          });
        }

        return acc;
      }, []);
      setSubjectOptions(uniqueSubjectOptions);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      const selectedCuriculums = allCuriculums.filter((c) =>
        c.subjectId === selectedSubject.subjectId &&
        c.subjectTypeId === selectedSubject.subjectTypeId &&
        values.classes.some(classId => classId === c.classId)
      );

      const basicClasses = selectedCuriculums.reduce((acc: number[], c) => {
        if (!acc.includes(c.basicClassId))
          return [...acc, c.basicClassId];

        return acc;
      }, []);
      setSelectedBasicClasses(basicClasses);
    }
  }, [values.classes, selectedSubject]);

  const onSubjectSelect = (event: SelectChangeEvent<number>, selected: SubjectOption) => {
    handleChange(event);
    setSelectedSubject(selected);

    const relevantClasses = allCuriculums.reduce((acc: ClassOption[], c) => {
      const isUnique = !acc.some(o => o.id === c.classId);
      if (c.subjectId === selected.subjectId &&
        c.subjectTypeId === selected.subjectTypeId &&
        isUnique) {
        acc = [...acc, { label: c.className, id: c.classId }];
      }
      return acc;
    }, []);
    setClassOptions(relevantClasses);
    setFieldValue("classes", []);
    setSelectedBasicClasses([]);
  };

  useEffect(() => {
    // scroll to the first error when submit the form
    const el = document.querySelector('.validation-error');
    (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [isSubmitting]);

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={breadcrumbs()} />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} display="flex" alignItems="center" mb={3}>
          <Link to="/homeworks">
            <ArrowBackIos fontSize="large" color="secondary" />
          </Link>
          <Typography
            variant="h1"
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            Ново домашно
          </Typography>
        </Grid>
      </Grid>
      <Paper sx={{ padding: 5 }}>
        {isLoading && (<LoadingSkeleton itemsCount={6} height={80} />)}
        {isLoading || (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <FormInputRow
                label={{ text: "Избор на крайна дата", required: true }}
                error={errors.deadline}
                touched={touched.deadline}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={bg}>
                  <DatePicker
                    reduceAnimations
                    minDate={new Date()}
                    value={values.deadline}
                    onChange={(nextDate: any) => setFieldValue('deadline', nextDate)}
                    renderInput={(params: any) =>
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Дата"
                        }}
                        variant="outlined"
                        sx={{ width: 240 }}
                      />
                    }
                  />
                </LocalizationProvider>
              </FormInputRow>
              <FormInputRow
                label={{ text: "Наименование", required: true }}
                error={errors.title}
                touched={touched.title}
              >
                <TextField
                  fullWidth
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
              </FormInputRow>
              <FormInputRow
                label={{ text: "Предмет", required: true }}
                error={errors.subject}
                touched={touched.subject}
              >
                <SubjectSelect
                  name="subject"
                  value={values.subject}
                  options={subjectOptions}
                  onSelect={onSubjectSelect}
                />
              </FormInputRow>
              <FormInputRow
                label={{ text: "Класове", required: true }}
                error={errors.classes as string}
                touched={touched.classes}
              >
                <ClassesSelect
                  name="classes"
                  value={values.classes}
                  options={classOptions}
                  onSelect={handleChange}
                  onRemove={(id) =>
                    setFieldValue("classes", values.classes.filter(x => x !== id))
                  }
                />
              </FormInputRow>
              <FormInputRow
                height={454}
                error={errors.descriptionRawText}
                touched={touched.descriptionRawText}
              >
                <Editor
                  onChange={(desc: object, descRaw: string) => {
                    setFieldValue('description', desc);
                    setFieldValue('descriptionRawText', descRaw);
                  }}
                />
              </FormInputRow>
              <HomeworkContents
                contents={values.contents}
                onAttach={(contents) => setFieldValue("contents", contents)}
                error={touched.contents && errors.contents as string}
                basicClassesIds={selectedBasicClasses}
                subjectId={selectedSubject?.subjectId || 0}
                isProfileSubject={selectedSubject?.isProfileSubject}
                disabled={!(Boolean(selectedBasicClasses.length) && selectedSubject?.id)}
              />
              <AttachFiles
                files={values.files}
                onUpload={(files) => setFieldValue("files", files)}
                error={touched.files && errors.files as string}
              />
              <Grid item xs={12} py={1} display="flex" justifyContent="center">
                <LoadingButton
                  disableElevation
                  variant="contained"
                  type="submit"
                  loading={submitting}
                  sx={{ marginRight: 2 }}
                >
                  Приключи
                </LoadingButton>
                <Button
                  disableElevation
                  variant="outlined"
                  href={NavigationLinks.homeworks}
                  disabled={submitting}
                >
                  Откажи
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </>
  );
};

export default CreateHomework;
