import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { AttachFile as AttachmentIcon, Clear, Description } from '@mui/icons-material';
import { ItemsList } from 'components/items-list';
import { AttachmentsType, FileAttachment } from 'api/types';
import { useDownloadAttachment } from 'services/queryHooks/attachments-hooks';
import { downloadFile } from 'utils/files';
import { palette } from 'theme/components';

import { AttachButton } from './styles';

type Props = {
  attachments?: FileAttachment[];
  files?: File[];
  onUpload?: (files: File[]) => void;
  onAttachmentsChange?: (newAttachments: FileAttachment[]) => void;
  error?: string;
  readonly?: boolean;
  titleVariant?: Variant;
  referenceId?: string,
  type?: AttachmentsType,
};

const AttachFiles = ({
  files = [], onUpload, error, readonly = false, titleVariant = 'h4', attachments = [], onAttachmentsChange, referenceId, type
}: Props) => {
  const { mutate: downloadAttachment } = useDownloadAttachment();

  const onFileRemove = (idToRemove: string | number) => {
    if (onUpload) {
      const newFiles = files.filter((f) => f.name !== idToRemove);
      onUpload(newFiles);
    }

    if (onAttachmentsChange) {
      const newAttachedFiles = attachments.filter((f) => f.id !== idToRemove);
      onAttachmentsChange(newAttachedFiles);
    }
  };

  const onFileClick = (fileId: string | number) => {
    const file = files.find((f) => f.name === fileId);
    if (file) {
      downloadFile(file, file.name);
    } else {
      const attachedFile = attachments.find((f) => f.id === fileId);
      if (attachedFile && referenceId && type) {
        downloadAttachment({ id: attachedFile.id, referenceId, type, fileName: attachedFile.name, link: attachedFile.blobStorageReference });
      }
    }
  };

  const onFilesSelect = (newFiles: File[]) => {
    if (onUpload) {
      const uniqueFiles = [...files, ...newFiles].reduce((acc: File[], file: File) => {
        if (!acc.find(x => x.name === file.name)) {
          acc.push(file);
        }

        return acc;
      }, []);

      onUpload(uniqueFiles);
    }
  };

  const renderDeleteItemIcon = (itemToRemove: any) => {
    if (!readonly) {
      return (
        <IconButton edge='end' onClick={() => onFileRemove(itemToRemove.id)}>
          <Clear color='error' />
        </IconButton>
      );
    }
  };

  const noItemsLabel = Boolean(readonly) ?
    'Няма прикачено съдържание.' :
    'Няма прикачено съдържание. За да добавите, изберете бутона \"Прикачи файл\".';

  const items = files.map((f) => ({ id: f.name, name: f.name, isNew: true })).concat(
    attachments.map((f) => ({ id: f.id, name: f.name, isNew: false }))
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={titleVariant} pb={2}>Прикачени файлове</Typography>
        <Divider sx={{ borderColor: palette.grey[300] }} />
      </Grid>
      <Grid container py={2}>
        <ItemsList
          name='files'
          items={items}
          startIcon={() => <Description sx={{ color: palette.primary[700] }} />}
          renderEndIcon={renderDeleteItemIcon}
          noItemsLabel={noItemsLabel}
          onItemClick={(item) => onFileClick(item.id)}
        />
        {!readonly && (
          <>
            <Grid item xs={12} pt={4}>
              <AttachButton
                variant='contained'
                component='label'
                startIcon={<AttachmentIcon />}
              >
                Прикачи файл
                <input
                  multiple
                  type='file'
                  hidden
                  onChange={(event) => {
                    if (event.target.files) {
                      onFilesSelect(Array.from(event.target.files));
                    }

                    // we have to reset the value because if we import file and then delete it from the list,
                    // we cannot import it again because change event is not triggered
                    event.target.value = '';
                  }}
                />
              </AttachButton>
            </Grid>
            {error && (
              <Grid item xs={12} pt={4}>
                <Typography variant='caption' color='error'>{error}</Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AttachFiles;
