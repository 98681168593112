import { Checkbox, CheckboxProps } from '@mui/material';
import { CheckboxIconStyled, CheckIconStyled } from './styles';

const CheckboxStyled = ({ size = 'medium', ...props }: CheckboxProps) => (
  <Checkbox
    icon={<CheckboxIconStyled />}
    checkedIcon={<CheckIconStyled fontSize={size} />}
    {...props}
  />
);

export default CheckboxStyled;