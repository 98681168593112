import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import {
  Button,
  FormControlLabel,
  Grid,
  Typography,
  DialogContent,
  Dialog,
} from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import Card from './card';
import { formatNewsDate } from 'utils/dateFormat';
import { getStringLocalization } from 'i18n';

import {
  FilterStyled,
  NewsMetaStyled,
  NewsMetaChipsStyled,
  TagStyled,
  LoadMoreContainerStyled,
  BigButtonStyled,
  LoaderStyled,
  DialogLinksStyled,
  DialogTitleStyled,
} from './styles';
import { Checkbox } from 'components/checkbox';

const TAG: any = {
  TEAMS: 0,
  GOOGLE: 1,
  OTHERS: 2,
};

function NewsList({ news, onLoadMore }: any) {
  const { t } = useTranslation();
  const [selectedTag, selectTag]: any = useState([
    TAG.TEAMS,
    TAG.GOOGLE,
    TAG.OTHERS,
  ]);
  const [selectedNewsItem, selectNewsItem]: any = useState(null);
  const Lng = getStringLocalization();

  const { items = [], pagination, loader } = news;

  const onCheckboxChange = (e: any, tagType: any) => {
    let selected = [];
    if (e.target.checked) {
      selected = selectedTag.concat(tagType);
    } else {
      selected = selectedTag.filter((i: any) => i !== tagType);
    }

    selectTag(selected);
    onLoadMore(selected, 1);
  };

  const propsToPropagate: any = {
    size: 'small'
  };

  return (
    <>
      <FilterStyled>
        <Typography>{`${t('common.filter')}:`}</Typography>

        <FormControlLabel
          control={
            <Checkbox
              {...propsToPropagate}
              checked={selectedTag.includes(TAG.TEAMS)}
              onChange={(e) => onCheckboxChange(e, TAG.TEAMS)}
              sx={{ boxShadow: 'none' }}
            />
          }
          label={t('landingPage.section.news.tag.teams')}
        />
        <FormControlLabel
          control={
            <Checkbox
              {...propsToPropagate}
              checked={selectedTag.includes(TAG.GOOGLE)}
              onChange={(e) => onCheckboxChange(e, TAG.GOOGLE)}
            />
          }
          label={t('landingPage.section.news.tag.google')}
        />
        <FormControlLabel
          control={
            <Checkbox
              {...propsToPropagate}
              checked={selectedTag.includes(TAG.OTHERS)}
              onChange={(e) => onCheckboxChange(e, TAG.OTHERS)}
            />
          }
          label={t('landingPage.section.news.tag.others')}
        />
      </FilterStyled>

      <Grid container spacing={5}>
        {items.map((item: any) => {
          const { enabled, hyperlinks, id, tag, dateCreated, ...newsItem } =
            item;
          const tagTypeKey = Object.keys(TAG).find(
            (key: any) => TAG[key] === tag
          );
          const tagType = tagTypeKey?.toLowerCase() ?? 'others';

          return (
            <Grid key={id} item xs={12} sm={6} md={4}>
              <Card
                size="medium"
                meta={
                  <NewsMetaStyled>
                    {tag !== null && (
                      <NewsMetaChipsStyled>
                        <TagStyled
                          tagType={`${tagType}Tag`}
                          label={t(`landingPage.section.news.tag.${tagType}`)}
                        />
                      </NewsMetaChipsStyled>
                    )}
                    <Typography>{formatNewsDate(dateCreated)}</Typography>
                  </NewsMetaStyled>
                }
                title={newsItem[`title${Lng}`]}
                subtitle={newsItem[`description${Lng}`]}
                footer={
                  <Button
                    color="secondary"
                    endIcon={<ArrowForward />}
                    onClick={() => selectNewsItem(item)}
                  >
                    {t('common.seeMore')}
                  </Button>
                }
                onTitleClick={() => selectNewsItem(item)}
              />
            </Grid>
          );
        })}
      </Grid>

      {pagination?.hasMore && (
        <LoadMoreContainerStyled
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BigButtonStyled
            color="primary"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={!loader ? () => onLoadMore(selectedTag) : null}
          >
            {loader && <LoaderStyled size={16} color="inherit" />}
            {t('common.loadMore')}
          </BigButtonStyled>
        </LoadMoreContainerStyled>
      )}

      <Dialog
        open={Boolean(selectedNewsItem)}
        onClose={() => selectNewsItem(null)}
      >
        {selectedNewsItem && (
          <>
            <DialogTitleStyled disableTypography>
              <Typography variant="h6">
                {selectedNewsItem[`title${Lng}`]}
              </Typography>
            </DialogTitleStyled>
            <DialogContent>
              {/* @ts-ignore */}
              <ReactMarkdown plugins={[gfm]}>
                {selectedNewsItem[`content${Lng}`]}
              </ReactMarkdown>
            </DialogContent>
            <DialogLinksStyled disableSpacing>
              {selectedNewsItem.hyperlinks.map((link: any) => Boolean(link.link) ? (
                <Button
                  key={link.link}
                  color="secondary"
                  endIcon={<ArrowForward />}
                  href={link.link}
                  target="_blank"
                >
                  {link[`title${Lng}`]}
                </Button>
              ) : null)}
            </DialogLinksStyled>
          </>
        )}
      </Dialog>
    </>
  );
}

NewsList.defaultProps = {};

export default NewsList;
