import styled from "@emotion/styled";
import { Star } from '@mui/icons-material';
import { Paper } from '@mui/material';

export const RootStyled = styled(Paper)(() => {
  return ({
    height: '100%',
    position: 'relative',
    display: 'grid'
  })
});

export const ImageContainerStyled = styled('div')(() => {
  return ({
    position: 'relative',
    '& img': {
      width: '100%',
      borderRadius: '5px 5px 0 0',
      display: 'block'
    }
  })
});

export const IconStyled = styled('div')(() => {
  return ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      display: 'block',
      maxWidth: '70px',
    }
  })
});

export const MetaStyled = styled('div')(({ theme, size }: any) => {
  const { breakpoints, spacing } = theme;

  return ({
    margin: (() => {
      if (size === 'small') return spacing(3, 3, 1, 3);
      if (size === 'large') return spacing(2);
      return spacing(2);
    })(),
    [breakpoints.up('md')]: {
      margin: (() => {
        if (size === 'small') return spacing(3, 3, 1, 3);
        if (size === 'large') return spacing(3);
        return spacing(3, 4);
      })(),
    },
  })
});

export const TitleContainerStyled = styled('div')(({ theme, size, hasMeta }: any) => {
  const { breakpoints, spacing } = theme;

  const stylesWithoutMeta = {
    marginTop: (() => {
      if (size === 'small') return spacing(0.5);
      if (size === 'large') return spacing(3);
      return spacing(1);
    })()
  }

  return ({
    margin: (() => {
      if (size === 'small') return spacing(0, 3, 3, 3);
      if (size === 'large') return spacing(3);
      return spacing(2);
    })(),
    textAlign: 'center',
    [breakpoints.up('md')]: {
      margin: (() => {
        if (size === 'small') return spacing(0, 3, 3, 3);
        if (size === 'large') return spacing(0, 3, 4, 3);
        return spacing(0, 4, 3, 4)
      })(),
    },
    ...(hasMeta ? {} : stylesWithoutMeta)
  })
});

export const InteractiveTitleStyled = styled('button')(({ theme }: any) => {
  return ({
    display: 'block',
    textAlign: 'left',
    background: 'none',
    font: 'inherit',
    padding: '0',
    border: '0',
    outline: '0',
    cursor: 'pointer'
  })
});

export const TitleStyled = styled('h3')(({ theme, size }: any) => {
  const { breakpoints, spacing } = theme;

  return ({
    margin: (() => {
      if (size === 'small') return spacing(1, 0);
      if (size === 'large') return 0;
      return 0;
    })(),
    textAlign: (() => {
      if (size === 'small') return 'left';
      if (size === 'large') return 'center';
      return 'left';
    })(),
    fontSize: (() => {
      if (size === 'small') return '16px';
      if (size === 'large') return '19px';
      return '16px';
    })(),
    fontWeight: '600',
    [breakpoints.up('md')]: {
      fontSize: (() => {
        if (size === 'small') return '18px';
        if (size === 'large') return '24px';
        return '20px';
      })(),
    },
  })
});

export const SeparatorStyled = styled(Star)(({ theme }: any) => {
  const { palette, spacing } = theme;
  return {
    color: palette.success.main,
    margin: spacing(2)
  }
})

export const SubTitleStyled = styled('p')(({ theme, size }: any) => {
  const { palette, spacing, breakpoints } = theme;

  return {
    margin: (() => {
      if (size === 'small') return `${spacing(2)}px 0`;
      if (size === 'large') return 0;
      return spacing(1, 0, 0, 0);
    })(),
    textAlign: (() => {
      if (size === 'small') return 'left';
      if (size === 'large') return 'center';
      return 'left';
    })(),
    color: palette.text.secondaryText,
    fontSize: (() => {
      if (size === 'small') return '12px';
      if (size === 'large') return '14px';
      return '14px';
    })(),
    [breakpoints.up('md')]: {
      fontSize: (() => {
        if (size === 'small') return '14px';
        if (size === 'large') return '16px';
        return '14px';
      })(),
      margin: (() => {
        if (size === 'small') return 0;
        if (size === 'large') return 0;
        return spacing(3, 0, 0, 0)
      })()
    },
  }
})

export const FooterStyled = styled('div')(({ theme, size }: any) => {
  const { palette, spacing, breakpoints } = theme;

  return {
    padding: spacing(1, 3),
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px dashed ${palette.divider}`,
    alignSelf: 'flex-end',
    [breakpoints.up('md')]: {
      padding: (() => {
        if (size === 'small') return spacing(1, 3);
        if (size === 'large') return spacing(3);
        return spacing(2, 3);
      })()
    },
  }
});
