export enum AssignmentType {
  AllTime = 1,
  Scheduled = 2
}

export enum AssignmentBEType {
  AllTime = 'AllTime',
  Scheduled = 'Scheduled'
}

export const NO_CLASS_SCHEDULE_MESSAGE = "Според седмичното Ви разписание нямате часове за избраната дата с избраната паралелка.";

export const TooltipMessages = {
  AllTime: "С тази опция можете да насочите съдържание, отнесено към съответния предмет, като то ще бъде видимо за всеки учебен час, както и винаги за този предмет, докато не бъде променено от Вас.",
  Scheduled: "С тази опция можете да насочите съдържание към конкретен учебен час, като то ще е видимо за Вас и Вашите ученици само за този час. Трябва да изберете паралелка и дата и системата ще Ви предложи часове, между които да изберете."
};

export const Notifications = {
  success: 'Съдържанието беше насочено успешно.'
};
