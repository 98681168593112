import { transparentize } from 'polished';
import palette from './palette';

export default {
  styleOverrides: {
    root: {
      backgroundColor: transparentize(0.55, palette.grey.main)
    },
    invisible: {
      backgroundColor: 'transparent'
    }
  },
};
