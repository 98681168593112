import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { Dialog, DialogContent, DialogTitle, Divider, MenuItem, Select, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ArrowForward } from '@mui/icons-material';
import B2CForm from './components/b2c-form';

import {
  AuthManager,
  beginAADLoginProcess,
  beginB2CLoginProcess,
  createAuthManagerInstitution, finalParentsTokenEnrichment,
  populateUserDataByAccessToken,
} from 'authentication/auth-manager';
import { LoginTypeEnum } from 'authentication/types';
import { getEnrichedInstitutionToken } from 'api/api';
import { LocalCacheService } from 'services/localCache.service';
import { getHomePageUrl } from 'utils/navigation';

import { BoxStyled } from './styles';
import { FormDialog } from '../form-dialog';

const containerProps = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const buttons: any = (loginInProgress: boolean) => ({
  variant: 'contained',
  color: 'info',
  endIcon: <ArrowForward />,
  loading: loginInProgress,
  loadingPosition: 'end'
});

const beginInstitutionEnrichTokenProcess = async (
  chosenInstitutionId: string,
  loginProcessComplete: Function,
  loginProcessFail: Function
) => {
  // first we set AuthManager's institution id:
  AuthManager.setInstitutionIds(Number(chosenInstitutionId));
  try {
    // let's get the enriched token:
    const { access_token } = await getEnrichedInstitutionToken(chosenInstitutionId);
    AuthManager.setBearerToken(access_token);
    const { institutions, exp } = jwtDecode<any>(access_token);
    AuthManager.setTimerForRefreshingToken(exp);
    // TODO: refactor:
    const institutionsArray = [].concat(institutions);
    // we populate all institution the user has in the AuthManager
    AuthManager.institutions = createAuthManagerInstitution(institutionsArray);
    populateUserDataByAccessToken(access_token, institutionsArray);
    loginProcessComplete();
  } catch (e) {
    loginProcessFail(e);
  }
};

const beginB2CInstitutionEnrichTokenProcess = async (
  chosenStudentId: string,
  chosenInstitutionId: number,
  loginProcessComplete: Function,
  loginProcessFail: Function,
  setFormikSubmitting: Function,
) => {
  try {
    await finalParentsTokenEnrichment(chosenStudentId, chosenInstitutionId);
    loginProcessComplete();
  } catch (e) {
    loginProcessFail(e, setFormikSubmitting);
  }
}

// type = 'aad' | 'b2c'(parent)
function Login({ navi = false, loginRoleType = LoginTypeEnum.aad }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const redirectPath = LocalCacheService.getRedirectPath();
  const [selectInstitutionDialog, setSetSelectInstitutionDialog] = useState(false);
  const [selectB2CDataDialog, setSelectB2CDataDialog] = useState(false);
  const [loginInProgress, setLoginInProgress] = useState(false);

  const onLogin = (loginType: LoginTypeEnum) => {
    setLoginInProgress(true);
    if (loginType === LoginTypeEnum.aad) {
      beginAADLoginProcess(() => setSetSelectInstitutionDialog(true), loginProcessFailCallback).then(finishLoginProcess);
    }

    if (loginType === LoginTypeEnum.b2c) {
      beginB2CLoginProcess(() => setSelectB2CDataDialog(true), loginProcessFailCallback).then(finishLoginProcess);
    }
  };

  const finishLoginProcess = (processFinished: boolean | undefined) => {
    if (processFinished) {
      setLoginInProgress(false);
      navigate(getHomePageUrl());
    }
  }

  const loginProcessFailCallback = (error: any, setFormikSubmitting: Function) => {
    setLoginInProgress(false);
    if (setFormikSubmitting) {
      setFormikSubmitting(false);
    }
    console.warn(error);
  };

  const loginProcessComplete = () => {
    setSetSelectInstitutionDialog(false);
    setLoginInProgress(false);
    if (redirectPath) {
      LocalCacheService.removeRedirectPath();
      navigate(redirectPath);
    } else {
      navigate(getHomePageUrl());
    }
  };

  return (
    <>
      <BoxStyled {...containerProps} navi={navi}>
        {loginRoleType === LoginTypeEnum.aad && (
          <LoadingButton {...buttons(loginInProgress)} onClick={() => onLogin(LoginTypeEnum.aad)}>
            {t('common.login')}
          </LoadingButton>
        )}
        {loginRoleType === LoginTypeEnum.b2c && (
          <LoadingButton {...buttons(loginInProgress)} onClick={() => onLogin(LoginTypeEnum.b2c)} sx={{ ml: navi ? 1 : 0 }}>
            {t('common.parentLogin')}
          </LoadingButton>
        )}
      </BoxStyled>
      {selectInstitutionDialog && (
        <FormDialog
          onClose={() => {
            setLoginInProgress(false);
            setSetSelectInstitutionDialog(false);
          }}
          open
          maxWidth="sm"
          fullWidth
        >
          <>
            <DialogTitle>
              <Typography variant="subtitle1" component="div">
                {t('login.selectInstitutionDialog.title')}
              </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Select
                fullWidth
                onChange={({ target }: any) =>
                  beginInstitutionEnrichTokenProcess(
                    target.value,
                    loginProcessComplete,
                    loginProcessFailCallback
                  )
                }
              >
                {AuthManager.institutions.map((institution) => (
                  <MenuItem key={institution.id} value={institution.id}>
                    {institution.name}
                  </MenuItem>
                ))}
              </Select>
            </DialogContent>
          </>
        </FormDialog>
      )}
      {
        selectB2CDataDialog && (
          <FormDialog
            onClose={() => {
              setLoginInProgress(false);
              setSelectB2CDataDialog(false);
            }}
            open
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              <Typography variant="subtitle1" component="div">
                {t('login.selectStudentDialog.title')}
              </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ pb: 1 }}>
              <B2CForm
                onFormSubmit={(studentId: string, institutionId: number, setFormikSubmitting: Function) => {
                  beginB2CInstitutionEnrichTokenProcess(
                    studentId,
                    institutionId,
                    loginProcessComplete,
                    loginProcessFailCallback,
                    setFormikSubmitting
                  )
                }}
              />
            </DialogContent>
          </FormDialog>
        )
      }
    </>
  );
}

Login.defaultProps = {
  loading: false,
};

export default Login;
