import { Link } from "react-router-dom";

import { Edit } from "@mui/icons-material";
import { IconButton, useMediaQuery } from "@mui/material";

import { theme } from 'theme/theme';

import { NavigationLinks } from "enums/routes";

function AssignedContentsManagementButton() {
  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  return (
    <Link to={NavigationLinks.managingAssignedContent}>
      <IconButton>
        <Edit
          fontSize={tabletAndUp ? 'large' : 'medium'}
          sx={{ color: theme.palette.grey.main }}
        />
      </IconButton>
    </Link>
  );
}

export default AssignedContentsManagementButton;
