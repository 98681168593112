import { Box, Button, Chip, DialogTitle, DialogActions } from '@mui/material';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';

export const NewsMetaStyled = styled('div')(({ theme }: any) => {
  const { palette } = theme;

  return {
    '& p': {
      fontSize: '14px',
      color: palette.text.secondaryText,
    },
  };
});

export const NewsMetaChipsStyled = styled('div')(({ theme }: any) => {
  const { breakpoints, spacing } = theme;

  return {
    marginBottom: spacing(1),
    [breakpoints.up('md')]: {
      marginBottom: spacing(3),
    },
  };
});

export const TagStyled = styled(Chip)(({ theme, tagtype }: any) => {
  const { palette } = theme;
  let background = '';

  switch (tagtype) {
    case 'teamsTag':
      background = '#ffa100 !important';
      break;
    case 'googleTag':
      background = '#1dc295 !important';
      break;
    case 'othersTag':
      background = '#14c6eb !important';
      break;
  }

  return {
    color: `${palette.common.white} !important`,
    background,
  };
});

export const LoadMoreContainerStyled = styled(Box)(({ theme }: any) => {
  const { breakpoints, spacing } = theme;

  return {
    padding: spacing(3),
    [breakpoints.up('md')]: {
      padding: spacing(5),
    },
  };
});

export const BigButtonStyled = styled(Button)(({ theme }: any) => {
  const { breakpoints, spacing } = theme;

  return {
    [breakpoints.up('md')]: {
      padding: spacing(1.5, 5),
    },
  };
});

export const DialogTitleStyled = styled(DialogTitle)(({ theme }: any) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& h6': {
      fontWeight: '600',
    },
  };
});

export const DialogLinksStyled = styled(DialogActions)(({ theme }: any) => {
  const { spacing } = theme;

  return {
    flexDirection: 'column',
    alignItems: 'start',
    padding: spacing(2),
  };
});

export const RootStyled = styled(Paper)(() => {
  return {
    height: '100%',
    position: 'relative',
    display: 'grid',
  };
});

export const MetaStyled = styled('div')(({ theme, size }: any) => {
  const { breakpoints, spacing } = theme;

  return {
    margin: (() => {
      if (size === 'small') return spacing(3, 3, 1, 3);
      if (size === 'large') return spacing(2);
      return spacing(2);
    })(),
    [breakpoints.up('md')]: {
      margin: (() => {
        if (size === 'small') return spacing(3, 3, 1, 3);
        if (size === 'large') return spacing(3);
        return spacing(3, 4);
      })(),
    },
  };
});

export const TitleContainerStyled = styled('div')(
  ({ theme, size, hasMeta }: any) => {
    const { breakpoints, spacing } = theme;

    const stylesWithoutMeta = {
      marginTop: (() => {
        if (size === 'small') return spacing(0.5);
        if (size === 'large') return spacing(3);
        return spacing(1);
      })(),
    };

    return {
      margin: (() => {
        if (size === 'small') return spacing(0, 3, 3, 3);
        if (size === 'large') return spacing(3);
        return spacing(2);
      })(),
      textAlign: 'center',
      [breakpoints.up('md')]: {
        margin: (() => {
          if (size === 'small') return spacing(0, 3, 3, 3);
          if (size === 'large') return spacing(0, 3, 4, 3);
          return spacing(0, 4, 3, 4);
        })(),
      },
      ...(hasMeta ? {} : stylesWithoutMeta),
    };
  }
);

export const InteractiveTitleStyled = styled('button')(({ theme }: any) => {
  return {
    display: 'block',
    textAlign: 'left',
    background: 'none',
    font: 'inherit',
    padding: '0',
    border: '0',
    outline: '0',
    cursor: 'pointer',
  };
});

export const TitleStyled = styled('h3')(({ theme, size }: any) => {
  const { breakpoints, spacing } = theme;

  return {
    margin: (() => {
      if (size === 'small') return spacing(1, 0);
      if (size === 'large') return 0;
      return 0;
    })(),
    textAlign: (() => {
      if (size === 'small') return 'left';
      if (size === 'large') return 'center';
      return 'left';
    })(),
    fontSize: (() => {
      if (size === 'small') return '16px';
      if (size === 'large') return '19px';
      return '16px';
    })(),
    fontWeight: '600',
    [breakpoints.up('md')]: {
      fontSize: (() => {
        if (size === 'small') return '18px';
        if (size === 'large') return '24px';
        return '20px';
      })(),
    },
  };
});

export const SubTitleStyled = styled('p')(({ theme, size }: any) => {
  const { palette, spacing, breakpoints } = theme;

  return {
    margin: (() => {
      if (size === 'small') return `${spacing(2)}px 0`;
      if (size === 'large') return 0;
      return spacing(1, 0, 0, 0);
    })(),
    textAlign: (() => {
      if (size === 'small') return 'left';
      if (size === 'large') return 'center';
      return 'left';
    })(),
    color: palette.text.secondaryText,
    fontSize: (() => {
      if (size === 'small') return '12px';
      if (size === 'large') return '14px';
      return '14px';
    })(),
    [breakpoints.up('md')]: {
      fontSize: (() => {
        if (size === 'small') return '14px';
        if (size === 'large') return '16px';
        return '14px';
      })(),
      margin: (() => {
        if (size === 'small') return 0;
        if (size === 'large') return 0;
        return spacing(3, 0, 0, 0);
      })(),
    },
  };
});

export const FooterStyled = styled('div')(({ theme, size }: any) => {
  const { palette, spacing, breakpoints } = theme;

  return {
    padding: spacing(1, 3),
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px dashed ${palette.divider}`,
    alignSelf: 'flex-end',
    [breakpoints.up('md')]: {
      padding: (() => {
        if (size === 'small') return spacing(1, 3);
        if (size === 'large') return spacing(3);
        return spacing(2, 3);
      })(),
    },
  };
});
