import { useQuery } from 'react-query';
import { StudentCurriculum } from '../Types';
import { getStudentCurriculum, getStudentCurriculumByCurriculumId, getTeacherCurriculum, getTeacherCurriculumByCurriculumId } from 'api/api';
import { TeacherCurriculum } from '../../api/types';

export const useStudentCurriculum = (options: Object = {}) =>
  useQuery<StudentCurriculum[], Error>(
    ['curriculum'],
    () => getStudentCurriculum(),
    options
  );

export const useStudentCurriculumByCurriculumId = (curriculumId: string, options: Object = {}) =>
  useQuery<StudentCurriculum, Error>(
    ['studentCurriculumById', curriculumId],
    () => getStudentCurriculumByCurriculumId(curriculumId),
    options
  );

export const useTeacherCurriculumByCurriculumId = (curriculumId: string, classId?: number, options: Object = {}) =>
  useQuery<TeacherCurriculum[], Error>(
    ['teacherCurriculumById', curriculumId, classId],
    () => getTeacherCurriculumByCurriculumId(curriculumId, classId),
    options
  );
  

export const useGetTeacherCurriculum = (
  subjectId?: number,
  options = {}
) =>
  useQuery<TeacherCurriculum[], Error>(
    ['teacherCurriculum'],
    () => getTeacherCurriculum(subjectId),
    { ...options }
  );

export const useGetTeacherClassesBySubject = (
  subjectId: number,
  options = {}
): any => useGetTeacherCurriculum(subjectId, { ...options });
