import { Link } from 'react-router-dom';
import { Link as MuiLink, Typography } from "@mui/material";
import { palette } from 'theme/components';
import { BORDER_RADIUS_LARGE } from 'theme/constants';

const config = {
  Student: {
    textBookTooltip: (
      <>
        За да се визуализира тук избраният от Вашия учител учебник, следва
        директорът на Вашето училище да е попълнил електронна заявка за учебници
        и издателят да поддържа негов вариант в електронен формат.
      </>
    ),
    fallbackText: {
      All: "Няма налично дигитално образователно съдържание към избраната тема.",
      Favorite: "Все още не сте добавили любимо дигитално образователно съдържание към избраната тема.",
      Assigned: "Към избраната тема няма дигитално образователно съдържание, насочено от Вашите учители."
    }
  },
  Teacher: {
    textBookTooltip: (
      <Typography component="span">
        За да се визуализира тук избраният от Вас учебник, следва директорът да е
        попълнил електронна заявка за учебници на
        <Typography component="span" sx={{ fontWeight: 'bold' }}>
          <MuiLink
            target="_blank"
            href="https://schoolbooks.mon.bg/login"
            paddingLeft={1}
            paddingRight={1}
          >
            https://schoolbooks.mon.bg
          </MuiLink>
        </Typography>
        и издателят да поддържа негов вариант в електронен формат.
      </Typography>
    ),
    fallbackText: {
      All: (
        <>
          Няма налично дигитално образователно съдържание към избраната тема.
          (Тук ще се визуализират и създадените от Вас и Вашите колеги дигитални
          уроци и съдържание, отнесени към избраните клас, предмет и тема.
          За да създадете съдържание, използвайте
          <Link to="/content">
            <Typography component="span" sx={{ color: palette.primary[700], fontWeight: 'bold' }}> "Създай съдържаниe"</Typography>
          </Link>
          .)
        </>
      ),
      Favorite: "Все още не сте добавили любимо дигитално образователно съдържание към избраната тема.",
      Assigned: ""
    }
  },
  Other: {
    textBookTooltip: (
      <Typography component="span">
        За да се визуализира тук избраният от институцията учебник, следва директорът да е
        попълнил електронна заявка за учебници на
        <Typography component="span" sx={{ fontWeight: 'bold' }}>
          <MuiLink
            target="_blank"
            href="https://schoolbooks.mon.bg/login"
            paddingLeft={1}
            paddingRight={1}
          >
            https://schoolbooks.mon.bg/login
          </MuiLink>
        </Typography>
        и издателят да поддържа негов вариант в електронен формат.
      </Typography>
    ),
    fallbackText: {
      All: "Няма налично дигитално образователно съдържание към избраната тема. (Тук ще се визуализират и създадените от учителите дигитални уроци и съдържание, отнесени към избраните клас, предмет и тема).",
      Favorite: "Все още не сте добавили любимо дигитално образователно съдържание към избраната тема.",
      Assigned: ""
    }
  }
}

export const getConfig = (userRole: any) => {
  if (userRole.isStudent || userRole.isParent)
    return config.Student;

  if (userRole.isTeacher || userRole.isDirector)
    return config.Teacher;

  return config.Other;
};

export const iframeConfig = {
  default: {
    width: '100%',
    height: '100%',
    border: '0',
    borderRadius: BORDER_RADIUS_LARGE
  },
  fullscreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: '2000',
  }
};

export const MOZAIK_TOKEN_URL = 'https://mozaweb.bg/api/v1/jwt/auth';

export const DEFAULT_PAGINATION = {
  Page: 1,
  Offset: 20
};

export const DEFAULT_SEARCH_QUERY_FILTER = {
  public: '',
  favorite: '',
  assigned: ''
};

export enum ContentsButtonType {
  Public = 'public',
  Favorite = 'favorite',
  Assigned = 'assigned'
};

export const ContentsButtons = {
  public: {
    searchParam: ContentsButtonType.Public,
    label: 'Публично съдържание',
  },
  favorite: {
    searchParam: ContentsButtonType.Favorite,
    label: 'Любими',
  },
  assigned: {
    searchParam: ContentsButtonType.Assigned,
    label: 'От моите учители',
  },
};

export enum SubjectTabsType {
  Topics = 'topics',
  Contents = 'contents'
};

export const SubjectTabs = {
  [SubjectTabsType.Topics]: {
    label: 'Теми',
  },
  [SubjectTabsType.Contents]: {
    label: 'Профилиращи съдържания',
  },
};

export enum ClassesType {
  Eleventh = '11',
  Twelfth = '12'
};

export enum ContentLinkTypes {
  Mozaik = 'mozaweb',
  Khan = 'khanacademy',
  Prosveta = 'prosveta',
  Izzi = 'izzi',
  Pdf = 'pdf',
  DigitalBackpack = 'dbp',
};