import styled from '@emotion/styled';
import { transparentize } from 'polished';
import { Box, Tooltip } from '@mui/material';
import { BORDER_RADIUS } from 'theme/constants';

export const TooltipStyled = styled(Tooltip)(({ theme }: any) => {
  return ({
    padding: 0
  });
});

export const TitleStyled = styled(Box)(({ theme }: any) => {
  const { palette } = theme;
  return ({
    background: transparentize(0.5, palette.grey[100]),
    borderRadius: `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0`
  });
});

export const ContentStyled = styled(Box)(({ theme }: any) => {
  const { palette } = theme;
  return ({
    background: palette.common.white,
    borderRadius: `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`
  });
});
