import styled from '@emotion/styled';
import { Box, Chip, Menu, Popper, PopperProps } from '@mui/material';

export const CellStyled = styled(Box)(
  ({ theme, currentday, disabled }: any) => {
    const { spacing } = theme;

    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      width: '100%',
      cursor: disabled ? 'not-allowed' : 'pointer',
      backgroundColor: currentday === 'true' ? '#ffde8733' : 'transparent',
      '& svg': {
        paddingRight: spacing(0.5),
      },
    };
  }
);

export const ChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }: any) => {
  const { spacing, palette } = theme;

  return {
    marginRight: spacing(0.5),
    backgroundColor: palette.common.white,
    boxShadow: isActive ? `0px 0px 0px 2px ${palette.warning[400]}` : '',
    height: 'auto',

    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },

    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
  };
});

export const MenuStyled = styled(Menu)(({ theme, color }: any) => {
  const { shadows, palette } = theme;
  return {
    '& .MuiPaper-root': {
      border: `1px solid ${palette?.[color]?.main}`,
      background: palette.common.white,
      boxShadow: shadows[4],
      boxSizing: 'border-box',
    },
  };
});

export const PopperStyled = styled(Popper)({
  zIndex: 1,
  '&::after': {
    content: '" "',
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    marginLeft: '-8px',
    borderWidth: '8px',
    borderStyle: 'solid',
    borderColor: 'transparent transparent white transparent',
  },
});
