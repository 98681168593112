import styled from '@emotion/styled';
import { TextField, TextFieldProps } from '@mui/material';

export const LinkInput = styled((props: TextFieldProps) => <TextField {...props} />)
(({ theme }: any) => {
  return ({
    input: {
      '&::placeholder': { 
        color: 'grey', 
        opacity: 1 
      }
    }
  });
});
