import { useState } from 'react';
import { Clear } from '@mui/icons-material';
import { Divider, IconButton, Grid, Typography, useMediaQuery } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ContentIcon, PrivateContentIcon, PublicContentIcon } from 'components/icons/svg';
import { ItemsList } from 'components/items-list';
import AttachContentsDialog from './attach-contents-dialog';
import { AttachButton } from './styles';
import { ContentVisibilityLevelEnum } from 'enums/contents-type.enum';
import { Content } from 'api/types';
import { BASE_APP_URL } from 'windows';
import { theme } from 'theme/theme';
import { palette } from 'theme/components';

type Props = {
  contents: Content[];
  onAttach?: (items: Content[]) => void;
  basicClassesIds?: number[];
  subjectId?: number;
  isProfileSubject?: boolean;
  error?: string;
  disabled?: boolean;
  readonly?: boolean;
  titleVariant?: Variant;
};

const HomeworkContents = ({
  contents, onAttach, error, disabled, basicClassesIds, subjectId, isProfileSubject, readonly = false, titleVariant = 'h4'
}: Props) => {
  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const [open, setDialogOpen] = useState(false);

  const onItemRemove = (idToRemove: string | number) => {
    if (onAttach) {
      const newItems = contents.filter((c) => c.id !== idToRemove);
      onAttach(newItems);
    }
  };

  const renderContentIcon = ({ visibilityLevel }: Content) => {
    const isContentPrivate = visibilityLevel === ContentVisibilityLevelEnum.Organization;

    if (isContentPrivate) {
      return <PrivateContentIcon sx={{ color: theme.palette.primary.main }} />
    }

    return <PublicContentIcon sx={{ color: theme.palette.primary.main }} />	
  };

  const renderDeleteItemIcon = (itemToRemove: any) => {
    if (!readonly) {
      return (
        <IconButton edge="end" onClick={() => onItemRemove(itemToRemove.id)}>
          <Clear color="error" />
        </IconButton>
      );
    }
  };

  const noItemsLabel = Boolean(readonly) ?
    'Няма споделено съдържание.' :
    'Няма споделено съдържание. За да добавите, изберете бутона \"Сподели съдържание\".';

  return (
    <Grid container py={tabletAndUp ? 4 : 2}>
      <Grid item xs={12}>
        <Typography variant={titleVariant} pb={2}>Споделено съдържание</Typography>
        <Divider sx={{ borderColor: palette.grey[300] }} />
      </Grid>
      <Grid container py={2}>
        <ItemsList
          name="contents"
          items={contents.map(x => ({ id: x.id, name: x.name, visibilityLevel: x.visibilityLevel }))}
          startIcon={renderContentIcon}
          onItemClick={(i) => window.open(`${BASE_APP_URL}library/content/${i.id}`)}
          renderEndIcon={renderDeleteItemIcon}
          noItemsLabel={noItemsLabel}
        />
        {!Boolean(readonly) && (
          <>
            {disabled && (
              <Grid item xs={12} pt={2}>
                <Typography variant="body1" textAlign="center">
                  За да споделите съдържание, първо изберете "Предмет" и "Kласове".
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} pt={4}>
              <AttachButton
                variant="contained"
                component="label"
                startIcon={<ContentIcon />}
                onClick={() => setDialogOpen(true)}
                disabled={disabled}
              >
                Сподели съдържание
              </AttachButton>
              <AttachContentsDialog
                open={open}
                onClose={() => setDialogOpen(false)}
                basicClassesIds={basicClassesIds || []}
                subjectId={subjectId || 0}
                isProfileSubject={Boolean(isProfileSubject)}
                attachedContents={contents}
                onAttach={(contents) => {
                  if (onAttach) {
                    onAttach(contents)
                  }
                }}
              />
            </Grid>
            {error && (
              <Grid item xs={12} pt={4}>
                <Typography variant="caption" color="error">{error}</Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default HomeworkContents;
