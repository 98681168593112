import React, { createContext, useMemo, useEffect, useState } from 'react';
import { Nomenclature, Nomenclatures } from 'services/Types';
import { getBasicClassNom, getEnums, getSimpleNomById } from 'api/api';

export interface NomenclaturesContextProps {
  basicClasses: {
    items: Nomenclature[],
    isLoading: boolean
  },
  subjects: {
    items: Nomenclature[],
    isLoading: boolean
  },
  enums: {
    items: Nomenclatures,
    isLoading: boolean
  },
  topics: {
    items: any[],
    setTopics: Function
  },
  classSubjects: {
    classId: number | undefined,
    items: any[],
    setClassSubjects: Function
  }
}

const NomenclatureDefault = {
  items: [],
  isLoading: false
}

const NomenclaturesDefault = {
  items: {
    simpleNomenclature: [],
    absenceType: [],
    excuseReason: [],
    gradeCategory: [],
    gradeType: [],
    qualitativeGrade: [],
    remarkType: [],
    specialGrade: [],
    term: []
  },
  isLoading: false
}

export const NomenclaturesContext = createContext<NomenclaturesContextProps>({
  basicClasses: NomenclatureDefault,
  subjects: NomenclatureDefault,
  enums: NomenclaturesDefault,
  topics: {
    items: [],
    setTopics: Function
  },
  classSubjects: {
    classId: undefined,
    items: [],
    setClassSubjects: Function
  }
});

export const NomenclaturesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {

  const [basicClasses, setBasicClasses] = useState(NomenclatureDefault);
  const [subjects, setSubjects] = useState(NomenclatureDefault);
  const [enums, setEnums] = useState(NomenclaturesDefault);
  const [topics, setTopics] = useState([]);
  const [classSubjects, setClassSubjects] = useState({ classId: undefined, items: [] });

  const loadBasicClasses = () => {
    setBasicClasses({ items: [], isLoading: true});

    getBasicClassNom()
      .then((payload) => {
        setBasicClasses({ items: payload, isLoading: false});
      });
  }

  const loadEnums = () => {
    setEnums({ items: NomenclaturesDefault.items, isLoading: true });

    getEnums()
      .then((payload) => {
        setEnums({ items: payload, isLoading: false });

        const subjectNomId = payload?.simpleNomenclature?.find((x: any) => x.name === 'Subject')?.id;
        if (subjectNomId) loadSubjects(subjectNomId);
      });
  }

  const loadSubjects = (subjectNomId: number) => {
    setSubjects({ items: [], isLoading: true });

    getSimpleNomById(subjectNomId)
      .then((payload) => {
        setSubjects({ items: payload, isLoading: false });
      });
  }

  useEffect(() => {
    loadBasicClasses();
    loadEnums();
  }, []);

  const contextValue = useMemo(() => ({
    basicClasses,
    subjects,
    enums,
    topics: {
      items: topics,
      setTopics
    },
    classSubjects: {
      classId: classSubjects.classId,
      items: classSubjects.items,
      setClassSubjects
    }
  }), [basicClasses, subjects, enums, topics, classSubjects]);

  return (
    <NomenclaturesContext.Provider value={contextValue}>
      {children}
    </NomenclaturesContext.Provider>
  )
};
