import { lazy, Suspense } from 'react';
import { useSharedViewContext } from 'hooks/useSharedViewContext';

const Subject = () => {
  const viewName = useSharedViewContext();
  const Component = lazy(() => import(`../../views/subject/${viewName}`));

  return (
    <Suspense fallback={<></>}>
      <Component />
    </Suspense>
  );
};

export default Subject;
