import { useMutation, useQuery } from 'react-query';
import { downloadAttachment, getAttachments } from 'api/api';
import { AttachmentsType, FileAttachment } from 'api/types';
import { downloadFile } from 'utils/files';

export const useAttachments = (referenceId: string, type: AttachmentsType, options = {}) =>
  useQuery<FileAttachment[], Error>(
    ['attachments', referenceId],
    () => getAttachments(referenceId, type),
    options
  );

type DownloadAttachmentRequest = {
  id: string;
  fileName: string;
  referenceId: string;
  type: AttachmentsType;
  link?: string;
};

export const useDownloadAttachment = () =>
  useMutation(
    ({ id, referenceId, type, link }: DownloadAttachmentRequest) => downloadAttachment(referenceId, id, type, link),
    {
      onSuccess: (blob, { fileName }) => {
        downloadFile(blob, fileName);
      }
    }
  );
