import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { AttachFile as AttachFileIcon, UploadFile as FileIcon, Link as LinkIcon } from '@mui/icons-material';
import { Alert, Button, ButtonGroup, Grid, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material';
import { MuiFileInput } from 'mui-file-input';

import { FormDialog } from 'components/form-dialog';
import { FormInputRow } from 'components/form-input-row';
import { ConfirmationDialog } from 'components/confirmation-dialog';
import { LinkInput } from './styles';

import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useGlobalModalContext } from 'hooks/useGlobalModalContext';

import { createFileContent, createLinkContent, deleteFileContent, uploadFileContent } from 'api/api';
import {
  FILE_SIZE_MESSAGE, FILE_TYPE_MESSAGE, initialFormValues, notifications, validationSchema
} from './constants';
import { UploadedContentResponse } from 'api/types';

type FormProps = {
  title: string;
  file?: File | null;
  link?: string | null;
};

enum Tabs {
  File = 0,
  Link = 1
}

const UploadContent = () => {
  const [submitting, setSubmitting] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(Tabs.File);
  const [createdContentId, setCreatedContentId] = useState<string>();

  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const fileInputRef = useRef<HTMLInputElement>(null);
  const snackbarContext = useSnackbarContext();
  const globalModalContext = useGlobalModalContext();

  const onClose = () => {
    globalModalContext?.setModalType(null);
  };

  const addNotification = (type: 'success' | 'error') => {
    snackbarContext?.addSnackbar({
      key: crypto.randomUUID(),
      title: notifications[type],
      severity: type
    });
  };

  const createContent = ({ title, file, link }: FormProps): Promise<UploadedContentResponse> => {
    if (file) {
      const content = {
        title: title.trim(),
        fileName: file.name,
        fileSize: file.size
      };
      return createFileContent(content)
        .then((createdContent) => uploadFileContent(createdContent.uploadFileUrl, file!)
          .then(() => createdContent)
          .catch(async (err) => {
            await deleteFileContent(createdContent.id, createdContent.attachmentId);
            throw err;
          }));
    }

    if (link) {
      const content = {
        title: title.trim(),
        url: link.trim()
      };
      return createLinkContent(content);
    }

    return Promise.reject();
  }

  const {
    handleChange, handleSubmit, setFieldValue, errors, values, touched
  } = useFormik<FormProps>({
    initialValues: initialFormValues,
    validate: (values) => validationSchema.validate(values, { abortEarly: false })
      .then(() => {})
      .catch((err) => {
        const errorsByField = err.inner.reduce((acc: any, element: any) => {
          // we would like to display all validation errors
          if (!(element.path in acc)) {
            acc[element.path] = [];
          } else if (acc[element.path].find((e: any) => e.type === 'required')) {
            // when "required" rule fails for a field then we do not care about other rules
            return acc;
          }
  
          acc[element.path] = acc[element.path].concat([element]);
          return acc;
        }, {});
        return Object.keys(errorsByField).reduce((acc: any, key: string) => {
            acc[key] = errorsByField[key].map((e: any) => e.errors);
            return acc;
        }, {});
      }),
    onSubmit: (values) => {
      setSubmitting(true);
      createContent(values)
        .then((createdContent) => {
          setCreatedContentId(createdContent.id);
          addNotification('success');
          setConfirmationOpen(true);
        })
        .catch(() => addNotification('error'))
        .finally(() => setSubmitting(false));
    }
  });

  return (
    <>
      <FormDialog
        key='upload-dialog'
        open
        onClose={onClose}
        maxWidth='md'
        disableCloseOnBackdropClick
        title='Качване на съдържание'
        handleSubmit={handleSubmit}
        dialogContentSx={{ paddingTop: 0, paddingBottom: 0 }}
        actions={(
          <>
            <LoadingButton
              disableElevation
              autoFocus
              variant='contained'
              type='submit'
              loading={submitting}
              sx={{ width: 112 }}
            >
              Изпрати
            </LoadingButton>
            <Button
              variant='outlined'
              onClick={onClose}
              sx={{ width: 112 }}
              disabled={submitting}
            >
              Откажи
            </Button>
          </>
        )}
      >
        <Grid container px={0}>
          <Grid item xs={12} pr={1}>
            <FormInputRow
              label={{ text: 'Заглавие', required: true }}
              error={errors.title}
              touched={touched.title}
              inputInfo='Заглавието не може да бъде променяно на по-късен етап.'
            >
              <TextField
                fullWidth
                name='title'
                value={values.title}
                onChange={handleChange}
              />
            </FormInputRow>
            <Grid container alignItems="center">
              {tabletAndUp && <Grid item xs={12} md={2.5} />}
              <Grid item xs={12} md={9.5}>
                <ButtonGroup disableElevation>
                  <Button
                    startIcon={<FileIcon />}
                    variant={selectedTab === Tabs.File ? 'contained' : 'outlined'}
                    onClick={() => setSelectedTab(Tabs.File)}
                  >
                    Файл
                  </Button>
                  <Button
                    startIcon={<LinkIcon />}
                    variant={selectedTab === Tabs.Link ? 'contained' : 'outlined'}
                    onClick={() => setSelectedTab(Tabs.Link)}
                  >
                    Линк
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            {selectedTab === Tabs.File && (
              <FormInputRow
                label={{ text: 'Файл', required: true }}
                error={errors.file}
                touched={touched.file}
                inputInfo={Boolean(values.link)
                  ? 'Не е възможно прикачването на файл, когато е въведен линк.'
                  : (
                    <>
                      <Typography variant='subtitle2'>
                        {FILE_TYPE_MESSAGE}
                      </Typography>
                      <Typography variant='subtitle2'>
                        {FILE_SIZE_MESSAGE}
                      </Typography>
                    </>
                  )
                }
              >
                <MuiFileInput
                  placeholder='Изберете файл за качване.'
                  value={values.file}
                  onChange={(newValue) => setFieldValue('file', newValue, true)}
                  disabled={Boolean(values.link)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment onClick={() => fileInputRef.current?.click()} position="start">
                        <AttachFileIcon />
                      </InputAdornment>
                    )
                  }}
                  inputRef={fileInputRef}
                />
              </FormInputRow>
            )}
            {selectedTab === Tabs.Link && (
              <FormInputRow
                label={{ text: 'Линк към ресурс', required: true }}
                error={errors.link}
                touched={touched.link}
                inputInfo={Boolean(values.file)
                  ? 'Не е възможно въвеждането на линк, когато е избран файл.'
                  : undefined
                }
              >
                <LinkInput
                  fullWidth
                  name='link'
                  value={values.link}
                  onChange={handleChange}
                  placeholder='Въведете линк към външен ресурс.'
                  disabled={Boolean(values.file)}
                />
              </FormInputRow>
            )}
          </Grid>
          <Grid item xs={12} pr={1} pt={3}>
            <Alert severity='warning'>
              <Typography variant='h6'>
                Качването на съдържание изисква въвеждане на таксономия в Модула за оценка.
              </Typography>
            </Alert>
          </Grid>
        </Grid>
      </FormDialog>
      <ConfirmationDialog
        open={confirmationOpen}
        onAccept={() => { 
          setConfirmationOpen(false); 
          // Navigate to assessment module so that the user could edit the taxonomy
          window.open(`/assessment/content/new/${createdContentId}?tab=1`); 
          setCreatedContentId(undefined); 
          onClose(); 
        }}
        onDecline={() => {
          setConfirmationOpen(false); 
          setCreatedContentId(undefined); 
          onClose();
        }}
        message='Ще бъдете пренасочени към Модула за оценка за въвеждане на таксономия на каченото съдържание.'
      />
    </>
  );
};

export default UploadContent;
