import styled from '@emotion/styled';
import { darken, lighten } from 'polished';
import { Button, Drawer, IconButton, InputAdornment } from '@mui/material';
import { BORDER_RADIUS, BORDER_RADIUS_SMALL } from 'theme/constants';

export const InputAdornmentStyled = styled(InputAdornment, {
  shouldForwardProp: (prop) => prop !== 'withButton',
})(({ theme, withButton }: any) => {
  const { shadows, spacing, palette, transitions } = theme;

  if (withButton) {
    return {
      boxShadow: shadows[3],
      display: 'inline-flex',
      height: spacing(4),
      padding: `0 ${spacing(0.5)}`,
      cursor: 'pointer',
      borderRadius: BORDER_RADIUS_SMALL,
      position: 'relative',
      left: '6px',
      background: palette.primary.main,
      transition: `${transitions.create(['background'], {
        duration: transitions.duration.standard,
      })}`,
      '&:hover, &:active': {
        background: palette.primary.dark,
      },
      '& svg': {
        color: palette.common.white
      }
    };
  }

  return {};
});

export const IconButtonStyled = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }: any) => {
  const { palette, shadows } = theme;

  return {
    boxShadow: shadows[5],
    borderRadius: BORDER_RADIUS,
    background: isActive ? palette.primary.main : palette.common.white,
    transition: 'background 0.3s ease',

    '&:hover': {
      background: isActive ? darken(0.1, palette.primary.main) : lighten(0.47, palette.primary.main),
    },

    '& svg': {
      color: isActive ? palette.common.white : palette.primary.main,
    },
  };
});

export const DrawerStyled = styled(Drawer)(({ theme }: any) => {
  const { palette } = theme;

  return {
    '& .MuiPaper-root': {
      background: palette.backdrop.mobile,
      borderRadius: `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`,
    },
  };
});

export const ButtonStyled = styled(Button)(({ theme }: any) => {
  const { shadows } = theme;

  return {
    textTransform: 'uppercase',
    height: 40,
    boxShadow: shadows[5],
  };
});
