import styled from '@emotion/styled';
import { Grid, Box } from '@mui/material';

export const SectionStyled = styled(Box)(({ theme, type }: any) => {
  const { breakpoints, spacing, palette } = theme;

  return ({
    display: 'flex',
    flexDirection: 'column',

    '& h2': {
      fontWeight: '600',
      textAlign: 'center',
      fontSize: '30px',
      marginBottom: spacing(4),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(8),
        fontSize: '40px',
      },
    }
  })
});

export const ContainerStyled = styled(Grid)(({ theme, side }: any) => {
  const { breakpoints, spacing, palette } = theme;

  const teamsContainer = {
    background: 'linear-gradient(135deg, #7351ff 0%, #60bbff 100%)',
    alignItems: 'flex-end'
  };

  const googleContainer = {
    background: '#152236'
  }

  const usersContainer = {
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary[500]} 100%)`,
    alignItems: 'flex-end'
  }

  const backpackContainer = {
    background: theme.palette.text.primary
  }

  return ({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3, 1),
    [breakpoints.up('md')]: {
      padding: spacing(5, 3),
    },
    ...(side === 'teams' ? teamsContainer : {}),
    ...(side === 'google' ? googleContainer : {}),
    ...(side === 'users' ? usersContainer : {}),
    ...(side === 'backpack' ? backpackContainer : {}),
  })
});

export const ContainerInnerStyled = styled('div')(({ theme }: any) => {
  const { breakpoints, spacing, palette } = theme;

  return ({
    width: '100%',

    '& h3': {
      color: palette.common.white,
      fontWeight: '600',
      fontSize: '22px',
      paddingBottom: spacing(3),
      textAlign: 'center',

      [breakpoints.up('sm')]: {
        textAlign: 'left'
      },

      [breakpoints.up('md')]: {
        fontSize: '30px',
        paddingBottom: spacing(5),
      }
    },

    '& p': {
      color: palette.common.white,
      lineHeight: '2rem'
    }
  })
});
