import styled from '@emotion/styled';

export const AnswerContainerStyled = styled('div')(({ theme: { palette, spacing } }: any) => ({
  '& a' : {
    color: palette.primary.main
  },
  '& img': {
    maxWidth: '100%'
  }
}));
