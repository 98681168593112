import React from 'react';
import { PaperStyled, AvatarContainerStyled } from './styles';
import CardIconAvatar from './card-icon-avatar';

type Props = {
  avatar?: {
    icon: any,
    iconBackdrop: string
  },
  children: React.ReactNode | string | null | any,
  withAvatarShadow: boolean
};

function Card({ avatar, children, withAvatarShadow = false }: Props) {
  return (
    <PaperStyled sx={{ position: 'relative', padding: '1px' }}>
      { avatar && (
        <AvatarContainerStyled>
          <CardIconAvatar icon={avatar.icon} iconBackdrop={avatar.iconBackdrop} withShadow={withAvatarShadow} />
        </AvatarContainerStyled>
      )}
      { children }
    </PaperStyled>
  );
}

Card.defaultProps = {
  withAvatarShadow: false
};

export default Card;
