import { spacing } from '@mui/system';
import { responsiveProperty } from '@mui/material/styles/cssUtils';
import { transparentize } from 'polished';
import palette from './palette';
import BASE_SPACING from './spacing';
import shadows from './shadows';
import { BORDER_RADIUS } from 'theme/constants';
import deepmerge from 'utils/deepmerge';

export const MuiDialog = {
  styleOverrides: {
    root: {
      '& .MuiBackdrop-root': {
        background: transparentize(0.45, palette.grey.main),
      },
    },
    paper: {
      ...spacing({ p: 4 }),
      borderRadius: 12,
      boxShadow: shadows[2],
    },
  },
};

export const MuiDialogTitle = {
  styleOverrides: {
    root: {
      ...spacing({ pt: 0, pb: 3, pl: 0, pr: 0 }),
    },
  },
};

export const MuiDialogContent = {
  styleOverrides: {
    root: {
      ...spacing({ pt: 4, pb: 4, pl: 0, pr: 0 }),
      '&::-webkit-scrollbar': {
        width: '4px',
        borderRadius: BORDER_RADIUS,
      },
      '&::-webkit-scrollbar-track': {
        background: palette.grey[100],
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: palette.grey.main,
        borderRadius: BORDER_RADIUS,
      },
    },
    dividers: {
      borderLeft: 0,
      borderRight: 0,
      borderStyle: 'dashed',
    },
  },
};

export const MuiDialogActions = {
  styleOverrides: {
    root: {
      ...spacing({ pl: 0, pr: 0 }),
      ...deepmerge(
        responsiveProperty({
          cssProperty: 'paddingTop',
          min: BASE_SPACING * 2,
          max: BASE_SPACING * 4,
          unit: 'px',
        }),
        responsiveProperty({
          cssProperty: 'paddingBottom',
          min: BASE_SPACING * 2,
          max: BASE_SPACING * 4,
          unit: 'px',
        })
      ),
      justifyContent: 'center',
    },
  },
};
