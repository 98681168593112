import { ReactNode } from 'react';
import { Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { palette } from 'theme/components';

import { IconButtonStyled, DialogContentStyled } from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
  actions?: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg';
  backgroundColor?: string,
  disableCloseOnBackdropClick?: boolean;
  handleSubmit?: () => void;
  dialogContentSx?: object;
  [key: string]: any;
};

const FormDialog = ({
                      open,
                      onClose,
                      title,
                      children,
                      actions,
                      maxWidth,
                      disableCloseOnBackdropClick,
                      handleSubmit,
                      backgroundColor,
                      dialogContentSx,
                      ...rest
                    }: Props) => {
  const onDialogClose = (event: any, reason: string) => {
    if (disableCloseOnBackdropClick && reason === 'backdropClick')
      return;

    onClose();
  };

  const renderBody = () => {
    const body = (
      <>
        <DialogContentStyled sx={dialogContentSx || {}}>
          {children}
        </DialogContentStyled>
        {actions && (
          <DialogActions>
            {actions}
          </DialogActions>
        )}
      </>
    );

    if (handleSubmit) {
      return (
        <form onSubmit={handleSubmit}>
          {body}
        </form>
      );
    }

    return body;
  };

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      fullWidth
      maxWidth={maxWidth}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': { backgroundColor: backgroundColor || palette.background.default },
      }}
      {...rest}
    >
      <DialogTitle>
        {title && (
          <Typography textAlign='center' variant='h4' p={1} component='div'>
            {title}
          </Typography>
        )}
        <IconButtonStyled onClick={onClose}>
          <Close />
        </IconButtonStyled>
      </DialogTitle>
      {renderBody()}
    </Dialog>
  );
};

export default FormDialog;
