import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table as TableMUI,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Typography,
} from '@mui/material';
import palette from 'theme/components/palette';
import { TABLE_HEADERS } from '../config';

export default function Table({ data }: any) {
  const { t } = useTranslation();

  return (
    <TableMUI sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          {TABLE_HEADERS.map(({ label }: any) => (
            <TableCell>
              <Typography variant="h6" color={palette.common.white} p={1}>
                {t(label)}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ id, name, key, link }: any) => {
          return (
            <TableRow hover key={id}>
              <TableCell align="center">{id}</TableCell>
              <TableCell>
                <Typography variant="subtitle2">{name} </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">{key}</Typography>
              </TableCell>
              <TableCell align="center">
                <Link href={link} target="_blank">
                  <Typography variant="subtitle2" color={palette.primary.main}>
                    {t('licenses.table.headers.link.column')}
                  </Typography>
                </Link>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </TableMUI>
  );
}
