import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Typography, Link } from '@mui/material';
import { MarqueeInner } from 'components/marquee';
import GuideSection, { installationGuideContainers, userGuideContainers, } from './components';
import { linksContentPage } from './config';
import { palette } from 'theme/components';
import { MOZABOOK_DOWNLOAD_LINK } from 'windows';

type Props = {};

function GuidePage({}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <MarqueeInner user={null} headerText={t('contentPage.marquee.title')} />
      <Box py={[2, 2]}>
        <Container maxWidth="lg">
          <Typography variant="h2">
            {t('contentPage.section.about.title')}
          </Typography>
          <Box my={[2, 4]}>
            <Typography variant="body1">
              {t('contentPage.section.about.content.paragraph1')}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={[2, 4]}
          >
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                window.open(
                  'https://moedistro.blob.core.windows.net/resources/2.1.%20%D0%94%D0%97%D0%97%D0%94%20%D0%94%D0%B8%D0%B3%D0%B8%D1%82%D0%B0%D0%BB%D0%BD%D0%B0%20%D0%A0%D0%B0%D0%BD%D0%B8%D1%86%D0%B0%20-%20%D0%A3%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B7%D0%B0%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0%20%D1%81%20%D0%BC%D0%BE%D0%B4%D1%83%D0%BB%20%D0%B7%D0%B0%20%D1%81%D1%8A%D0%B7%D0%B4%D0%B0%D0%B2%D0%B0%D0%BD%D0%B5%20%D0%BD%D0%B0%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%B2%D0%BD%D0%BE%20%D0%B4%D0%B8%D0%B3%D0%B8%D1%82%D0%B0%D0%BB%D0%BD%D0%BE%20%D1%83%D1%87%D0%B5%D0%B1%D0%BD%D0%BE%20%D1%81%D1%8A%D0%B4%D1%8A%D1%80%D0%B6%D0%B0%D0%BD%D0%B8%D0%B5%20(mozaBook).pdf'
                );
              }}
            >
              {t('contentPage.section.about.guide.download')}
            </Button>
          </Box>
          <Box my={[2, 4]}>
            <Typography variant="body1">
              {t('contentPage.section.about.content.paragraph2')}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box py={2} pt={0}>
        <Container maxWidth="lg">
          <Box display="flex" flexDirection="column" justifyContent="centetr">
            <Typography variant="body1" pb={2}>
              {t('contentPage.section.about.links.paragraph1')}
            </Typography>
            {linksContentPage.map(({ id, href, title }: any) => (
              <Link
                key={id}
                sx={{ p: 1, color: palette.primary.main }}
                variant="body1"
                underline="always"
                href={href}
                target="_blank"
              >
                {t(title)}
              </Link>
            ))}
          </Box>
        </Container>
      </Box>
      <Box py={2}>
        <Container>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            pb={3}
          >
            <Typography variant="body1" pb={2}>
              {t('contentPage.section.about.links.paragraph2')}
            </Typography>
            <Link
              sx={{ p: 1, color: palette.primary.main }}
              variant="body1"
              href="https://www.facebook.com/groups/2982412361902246/"
              target="_blank"
            >
              {t('contentPage.section.about.links.link7')}
            </Link>
          </Box>
        </Container>
      </Box>

      <Box pt={0} pb={[2, 8]}>
        <Container maxWidth="lg">
          <Typography variant="h2">
            {t('contentPage.section.download.title')}
          </Typography>
          <Box my={[2, 4]}>
            <Typography align="center" variant="body1">
              {t('contentPage.section.download.content.paragraph')}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={[2, 4]}
          >
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                window.open(
                  MOZABOOK_DOWNLOAD_LINK
                );
              }}
            >
              {t('contentPage.section.download.content.download')}
            </Button>
          </Box>
        </Container>
      </Box>

      <Box pt={4} pb={[2, 8]}>
        <GuideSection containers={installationGuideContainers} />
      </Box>

      <Box pt={0} pb={[2, 8]}>
        <GuideSection containers={userGuideContainers} />
      </Box>
    </>
  );
}

GuidePage.defaultProps = {};

export default GuidePage;
