import { isSameDay } from 'date-fns';
import { useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import FallbackSection from "components/fallback/fallback-section";
import { VariantsEnum } from "components/fallback/types";
import { TableContextProvider } from "./table-context-provider";
import { Meeting, Shift, ShiftClass } from "api/types";
import { useUserContext } from 'hooks/useUserContext';
import { CELL_TEXT_LENGTH_THRESHOLD, gridColumns } from "../../constants";

type Props = {
  data: Shift,
  meetings: Meeting[],
  isLoading: boolean,
  hasMultipleShifts: boolean,
  handleNextShift: () => void,
  activeDay: number | undefined
};

const ScheduleTable = ({ data: { days, hours }, meetings, isLoading, hasMultipleShifts, handleNextShift, activeDay }: Props) => {
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const user = useUserContext();

  const gridRows = () => {
    if (!hours) return [];

    let mappedRows: any[] = hours.map((h) => {
      let mappedSchedule = h.schedule.map((s: any) => {
        let userMeetings: any[] = [];
        if (user?.userRole.isTeacher) {
          userMeetings = meetings
            ?.filter((x: Meeting) => x.subjectId === s?.subjectId
              && isSameDay(new Date(x.date), new Date(s?.date))
              && s?.classes?.some((c: ShiftClass) => x.classIds.includes(c.id)));
        } else if (user?.userRole.isStudent) {
          userMeetings = meetings
            ?.filter((x: Meeting) => x.subjectId === s?.subjectId
              && isSameDay(new Date(x.date), new Date(s?.date)));
        }

        return { ...s, meetings: userMeetings };
      });

      return { ...h, schedule: mappedSchedule };
    });

    return [
      ...mappedRows,
      ...(hasMultipleShifts ? [{ hourNumber: -1, isLastRow: true }] : [])
    ]
  };

  const columns = gridColumns(days || [], mobile, handleNextShift);
  const rows = gridRows();

  const columnsVisibility = columns.reduce((visibilityModel: any, current) => {
    const currentFieldInt = parseInt(current.field);
    if (!currentFieldInt) {
      visibilityModel[current.field as typeof visibilityModel] = true;
    } else {
      visibilityModel[current.field as typeof visibilityModel] =
        mobile ? activeDay == +current.field : true;
    }

    return visibilityModel;
  }, {});

  const isLargerCell = (titleLength = 0, chipsLength = 0, hasMeetings = false) => {
    if (user?.userRole.isStudent) {
      if ((hasMeetings && titleLength >= CELL_TEXT_LENGTH_THRESHOLD.Mid)
        || (!hasMeetings && titleLength >= CELL_TEXT_LENGTH_THRESHOLD.Long)) {
        return true;
      }
    } else if (user?.userRole.isTeacher) {
      // has meetings
      if (hasMeetings
        && (titleLength >= CELL_TEXT_LENGTH_THRESHOLD.Short || chipsLength  > 4)) {
          return true;
      }

      // no meetings
      if (!hasMeetings && titleLength >= CELL_TEXT_LENGTH_THRESHOLD.Mid) {
        return true;
      }
    }

    return false;
  };

  const getRowHeight = (params: any) => {
    const { schedule } = params.model;

    if (mobile) {
      const isEmptyCell = !Boolean(schedule?.find((s: any) => s.dayNumber === activeDay)?.subjectId);
      if (isEmptyCell) {
        return null;
      }
    }

    const largeCell = schedule?.find((s: any) =>
        isLargerCell(s.subjectName?.length, s?.classes?.length, Boolean(s?.meetings?.length)));

    if (Boolean(largeCell))
      return 'auto';

    return null;
  };

  return (
    <TableContextProvider>
      {(isLoading || Boolean(hours?.length)) && (
        <DataGrid
          hideFooter
          autoHeight
          disableSelectionOnClick
          rowHeight={140}
          getRowHeight={getRowHeight}
          loading={isLoading}
          rows={rows}
          columns={columns}
          getRowId={(row) => row.hourNumber}
          columnVisibilityModel={columnsVisibility}
        />
      )}
      {!isLoading && (!hours || hours.length === 0) && (
        <FallbackSection
          variant={VariantsEnum.WONDERING}
          message="Няма данни за седмично разписание за избраната от Вас седмица"
        />
      )}
    </TableContextProvider>
  );
};

export default ScheduleTable;
