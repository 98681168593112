import { BORDER_RADIUS } from '../constants';
import palette from './palette';

export default {
  styleOverrides: {
    root: {
      background: palette.background.paper,
      borderRadius: BORDER_RADIUS
    },
    deleteIcon: {
      color: palette.text.primary,
      fontSize: '11px'
    }
  }
}
