import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { BORDER_RADIUS } from 'theme/constants';

export const PrivateDecNotificationStyled = styled(Box)(({ theme }: any) => {
  const { spacing, palette } = theme;

  return ({
    width: '100%',
    borderRadius: BORDER_RADIUS,
    background: palette.warning[50],
    display: 'flex',
    alignItems: 'baseline',
    gap: spacing(1),
    padding: `0 ${spacing(1)}`,

    '& > svg': {
      fontSize: '10px'
    }
  })
});
