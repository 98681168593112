import { format, isToday } from "date-fns";

import { Box, Button, Typography } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { ScheduleCell } from "./components/table";

import { ShiftDay } from "api/types";

import { getHomePageUrl } from "utils/navigation";

export const breadcrumbs: any = () => [
  {
    href: getHomePageUrl(),
    label: 'Начало'
  }
];

export const DATE_STRING_FORMAT = 'yyyy-MM-dd';

export const CELL_TEXT_LENGTH_THRESHOLD = {
  Short: 16,
  Mid: 35,
  Long: 70
};

export const Weekdays = {
  Monday: {
    id: 1,
    label: 'Понеделник',
    labelShort: 'Пн.'
  },
  Tuesday: {
    id: 2,
    label: 'Вторник',
    labelShort: 'Вт.'
  },
  Wednesday: {
    id: 3,
    label: 'Сряда',
    labelShort: 'Ср.'
  },
  Thursday: {
    id: 4,
    label: 'Четвъртък',
    labelShort: 'Чт.'
  },
  Friday: {
    id: 5,
    label: 'Петък',
    labelShort: 'Пт.'
  }
};

const renderCell = (params: any) => {
  const { field, row } = params;
  const schedule = row.schedule?.find((x: any) => x.dayNumber == field);

  const active = field == new Date().getDay() && isToday(new Date(schedule?.date));
  const date = schedule?.date ? format(new Date(schedule.date), 'yyyy-MM-dd') : undefined;

  return (
    <ScheduleCell
      subjectId={schedule?.subjectId}
      subjectName={schedule?.subjectName}
      isCurrentDay={active}
      classes={schedule?.classes || []}
      date={date}
      meetings={schedule?.meetings || []}
    />
  );
};

const renderHeader = (field: string, days: ShiftDay[] = [], mobile?: boolean) => {
  const day = days.find(x => x.number === +field);
  const weekday = Object.values(Weekdays).find((d) => d.id === +field);
  const weekdayLabel = mobile ? weekday?.labelShort : weekday?.label;
  const date = day?.date ? format(new Date(day?.date), 'dd.MM') : '';

  return `${weekdayLabel || ''} ${date}`;
};

export const gridColumns = (
  days: ShiftDay[], mobile?: boolean, handleNextShift?: () => void
): GridColumns<any> => [
  {
    field: 'hourNumber',
    headerName: 'Час',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    colSpan: ({ row }) => {
      if (row.isLastRow) {
        return 6;
      }
    },
    renderCell: ({ row }) => {
      if (row.isLastRow) {
        return (
          <Box p={2} display="flex" flexDirection={mobile ? "column" : "row"} alignItems="center">
            <Typography mr={1}>Имате часове в друга смяна.</Typography>
            <Button onClick={handleNextShift} variant="contained">Следваща смяна</Button>
          </Box>
        )
      }
    }
  },
  {
    field: '1',
    renderHeader: ({ field }) => renderHeader(field, days, mobile),
    headerAlign: 'center',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell,
  },
  {
    field: '2',
    renderHeader: ({ field }) => renderHeader(field, days, mobile),
    headerAlign: 'center',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell,
  },
  {
    field: '3',
    renderHeader: ({ field }) => renderHeader(field, days, mobile),
    headerAlign: 'center',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell,
  },
  {
    field: '4',
    renderHeader: ({ field }) => renderHeader(field, days, mobile),
    headerAlign: 'center',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell,
  },
  {
    field: '5',
    renderHeader: ({ field }) => renderHeader(field, days, mobile),
    headerAlign: 'center',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell,
  }
];


export const Notifications = {
  delete: {
    success: 'Срещата беше изтрита успешно.',
    error: 'Възникна грешка при изтриването на срещата. Моля, опитайте отново.'
  }
};
