import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ImgLogoMonStyed = styled('img')(({ theme }: any) => {
  const { breakpoints } = theme;
  return {
    width: '120px',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      width: '100px',
    },
    [breakpoints.down('sm')]: {
      width: '72px',
    },
  };
});

export const ImgStyled = styled('img')(({ theme }: any) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'block',
    maxWidth: '250px',
    minWidth: '100px',
    [breakpoints.down('md')]: {
      width: '70%',
    },
    [breakpoints.down('md')]: {
      width: '90%',
    },
  };
});

export const BoxStyled = styled(Box)(({ theme }: any) => {
  const { palette, spacing } = theme;
  return {
    padding: spacing(4, 0),
    borderTop: `5px solid ${palette.primary.main}`,
  };
});

export const BoxLinkStyled = styled(Box)(({ theme }: any) => {
  const { palette } = theme;
  return {
    margin: 0,
    color: palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: "pointer"
    },
  };
});
