import { getSchedule } from "api/api";
import { ScheduleType, Shift } from "api/types";
import { useQuery } from "react-query";

export const useGetSchedule = (
  filter: {
    fromDate: string,
    toDate: string,
    type?: ScheduleType | undefined,
    subjectId?: number,
    subjectTypeId?: number,
    classId?: number,
  },
  options = {}
) =>
  useQuery<Shift[], Error>(
    [`${filter.type}Schedule`, filter],
    () =>  getSchedule(filter.fromDate, filter.toDate, filter.type, filter.subjectId, filter.subjectTypeId, filter.classId),
    { ...options }
  );
