export const FallbackSearchImage: any = (props: any) => (
  <svg viewBox='0 0 178.75 90.13' {...props}>
    <defs>
      <linearGradient id='_Áåçûìÿííûé_ãðàäèåíò_87' data-name='Áåçûìÿííûé ãðàäèåíò 87' x1='5.31' y1='83.62' x2='165.37'
                      y2='83.62' gradientUnits='userSpaceOnUse'>
        <stop offset='0' stopColor='#d9d6d7' />
        <stop offset='.53' stopColor='#aca5a7' />
        <stop offset='1' stopColor='#d9d6d7' />
      </linearGradient>
    </defs>
    <g id='_Ñëîé_1' data-name='Ñëîé 1'>
      <g>
        <g>
          <path className='cls-4' style={{ fill: '#00ccd3' }}
                d='M169.21,69.68c.05-1.93-.06-8.32-6.07-14.55-5.61-5.81-14.31-6.57-17.57-4.86,2.48-3.48,1.63-11.97-2.82-17.72-7.17-9.26-17.02-7.69-19.5-7.37-.1-1.02-.53-3.68-2.38-6.62-2.11-3.37-6.57-7.73-15.78-9.34-10.28-1.8-17.04,.85-20.9,3.39-3.41,2.24-5.19,4.74-5.78,5.68-1.94-1.37-11.66-7.48-22.26-1.63-4.77,2.64-7.38,6.21-8.8,9.54-3.89-1.12-6.93,.18-8.19,.89,0,0-10.03-3.94-15.97,4.51-5.94,8.44-1.08,15.43-1.08,15.43,0,0-8.26,.22-15.31,8.42C-3.52,67.43,.98,79.63,.98,79.63H178.37c.21,0,.38-.17,.38-.38,0-7.36-7.78-9.24-9.54-9.57Z' />
          <path className='cls-3' style={{ fill: '#00f3e8' }}
                d='M23.11,79.41h122.46s.39-5.16-4.28-7.26c-4.67-2.11-8.82,1.19-8.82,1.19,0,0-.25-3.01-3.7-3.84-3.45-.83-5.11,1.72-5.11,1.72,0,0,1.83-8.24-5.81-12.62-8.25-4.73-16.14,2.05-16.14,2.05,0,0-8.25-7.27-19.78-3.84-10.45,3.11-12.27,12.28-12.27,12.28,0,0-.89-3.54-4.88-4.64-4.62-1.28-7.66,1.04-7.66,1.04,0,0-2.47-9.07-13.8-7.22-12.35,2.02-10.93,11.3-10.93,11.3,0,0-4.41-1.84-7.87,1.34-3.46,3.19-1.4,8.49-1.4,8.49Z' />
          <g>
            <path className='cls-6' style={{ fill: '#6bdbc8' }}
                  d='M128.01,22.05l-.72-.43,.43-.72c.11-.19,.05-.44-.14-.56-.19-.11-.44-.05-.56,.14l-.43,.72-.72-.43c-.19-.11-.44-.05-.56,.14-.11,.19-.05,.44,.14,.56l.72,.43-.43,.72c-.11,.19-.05,.44,.14,.56,.19,.11,.44,.05,.56-.14l.43-.72,.72,.43c.19,.11,.44,.05,.56-.14,.11-.19,.05-.44-.14-.56Z' />
            <path className='cls-6' style={{ fill: '#6bdbc8' }}
                  d='M17.26,38.18l-.72-.43,.43-.72c.11-.19,.05-.44-.14-.56-.19-.11-.44-.05-.56,.14l-.43,.72-.72-.43c-.19-.11-.44-.05-.56,.14s-.05,.44,.14,.56l.72,.43-.43,.72c-.11,.19-.05,.44,.14,.56,.19,.11,.44,.05,.56-.14l.43-.72,.72,.43c.19,.11,.44,.05,.56-.14,.11-.19,.05-.44-.14-.56Z' />
            <path className='cls-6' style={{ fill: '#6bdbc8' }}
                  d='M151.97,46.33l-.46-.27,.27-.46c.07-.12,.03-.28-.09-.36s-.28-.03-.36,.09l-.27,.46-.46-.27c-.12-.07-.28-.03-.36,.09s-.03,.28,.09,.36l.46,.27-.27,.46c-.07,.12-.03,.28,.09,.36s.28,.03,.36-.09l.27-.46,.46,.27c.12,.07,.28,.03,.36-.09,.07-.12,.03-.28-.09-.36Z' />
            <path className='cls-6' style={{ fill: '#6bdbc8' }}
                  d='M38.54,23.92l-.6-.35,.35-.6c.09-.16,.04-.37-.12-.46-.16-.09-.37-.04-.46,.12l-.35,.6-.6-.35c-.16-.09-.37-.04-.46,.12-.09,.16-.04,.37,.12,.46l.6,.35-.35,.6c-.09,.16-.04,.37,.12,.46s.37,.04,.46-.12l.35-.6,.6,.35c.16,.09,.37,.04,.46-.12,.09-.16,.04-.37-.12-.46Z' />
          </g>
          <path className='cls-12' style={{ fill: 'url(#_Áåçûìÿííûé_ãðàäèåíò_87)' }}
                d='M165.33,79.78s.64,.85-3.87,1.78c-5.3,1.09-18.18,.27-19.44,2.29-1.38,2.21,8.78,1.58,8.66,4.16-.1,2.19-7,2.11-14.79,2.11H48.64c-6.3,0-5.16-1.91-4.27-2.78,1.3-1.26,.73-2.43-4.95-2.59-5.51-.16-9.54-.29-12.54-.29-8.01,0-6.44-2.64-12.68-2.64-4.04,0-8.89-1.02-8.89-2.19,25.53-1.63,52.7-2.58,81.35-2.52,27.67,.06,53.94,1.04,78.68,2.67Z' />
        </g>
        <g>
          <path className='cls-7' style={{ fill: '#5d7e93' }}
                d='M50.94,64.06s.36,10,2.68,18.43l2.23-.77s-.91-6.06,.64-15.59l-5.54-2.07Z' />
          <path className='cls-7' style={{ fill: '#5d7e93' }}
                d='M55.4,81.11s2.5-.09,3.86,.82c1.36,.91,1.06,2.79,1.06,2.79h-6.52s-.81-1.33-.47-2.98l2.06-.64Z' />
          <path className='cls-8' style={{ fill: '#4c6672' }}
                d='M57.75,83.8s.03,0,.05,0c.18-.03,.3-.19,.27-.37-.13-.89-.02-1.63,.1-2.1-.22-.05-.43-.09-.64-.12-.13,.53-.24,1.34-.1,2.31,.02,.16,.16,.28,.32,.28Z' />
          <path className='cls-8' style={{ fill: '#4c6672' }}
                d='M56.17,83.8s.03,0,.05,0c.18-.03,.3-.19,.27-.37-.15-1.01,.01-1.83,.14-2.27-.25-.02-.48-.04-.66-.04-.14,.53-.27,1.37-.12,2.41,.02,.16,.16,.28,.32,.28Z' />
          <path className='cls-7' style={{ fill: '#5d7e93' }}
                d='M69.51,64.06s.84,11.25,4.41,18.43l2.23-.77s-1.9-4.23-.66-15.37l-5.97-2.29Z' />
          <path className='cls-7' style={{ fill: '#5d7e93' }}
                d='M75.71,81.11s2.5-.09,3.86,.82c1.36,.91,1.06,2.79,1.06,2.79h-6.52s-.81-1.33-.47-2.98l2.06-.64Z' />
          <path className='cls-8' style={{ fill: '#4c6672' }}
                d='M78.06,83.8s.03,0,.05,0c.18-.03,.3-.19,.27-.37-.13-.89-.02-1.63,.1-2.1-.22-.05-.43-.09-.64-.12-.13,.53-.24,1.34-.1,2.31,.02,.16,.16,.28,.32,.28Z' />
          <path className='cls-8' style={{ fill: '#4c6672' }}
                d='M76.48,83.8s.03,0,.05,0c.18-.03,.3-.19,.27-.37-.15-1.01,.01-1.83,.14-2.27-.25-.02-.47-.04-.66-.04-.14,.53-.27,1.37-.12,2.41,.02,.16,.16,.28,.32,.28Z' />
          <path className='cls-7' style={{ fill: '#5d7e93' }}
                d='M84.26,43.25s6.9,6,17.43,4.88c10.53-1.12,12-8.41,10.52-14.21s-4.05-6.69-4.05-6.69l1.86-2.34s9.23,6.92,3.83,19.63c-4.6,10.82-23.5,9.43-30.59,4.04l1.02-5.31Z' />
          <polygon className='cls-9' style={{ fill: '#bad8d3' }}
                   points='70.21 32.09 68.1 35.33 84.94 44.12 86.62 41.25 70.21 32.09' />
          <path className='cls-10' style={{ fill: '#daf2f0' }}
                d='M68.92,32.19c-4.74-2.66-8.82-7.34,3.08-15.14,11.9-7.8,21.04-3.08,28.69,3.66,7.65,6.74,3.96,16.03-.33,19.45-4.3,3.42-7.54,5.41-14.16,1.76-6.63-3.65-17.27-9.73-17.27-9.73Z' />
          <path className='cls-2' style={{ fill: '#fff' }}
                d='M76.96,19.1c5.39-3.53,10.27-4.74,14.73-4.43-5.67-2.47-12.12-2.58-19.69,2.38-11.9,7.8-7.81,12.49-3.08,15.14,0,0,.17,.1,.49,.28-2.21-3.07-1.56-7.4,7.55-13.37Z' />
          <path className='cls-5' style={{ fill: '#eff' }}
                d='M78.82,20.83c-4.55,4.32-7.22,9.9,5.19,17.38,10.49,6.32,15.49,1.33,17.12-1.94s2.43-8.35-4.09-14.3c-6.52-5.95-12.68-6.42-18.22-1.14Z' />
          <path className='cls-1' style={{ fill: '#fcd51a' }}
                d='M89.27,60.04l-9.9,13.37c-.5,.68-.3,1.64,.42,2.06h0c.62,.37,1.42,.2,1.85-.38l10.02-13.54-2.39-1.51Z' />
          <path className='cls-1' style={{ fill: '#fcd51a' }}
                d='M107.41,39.33c-3.79-3.45-12.5-3.06-18.3,4.74-5.43,7.31-3.59,13.98,1.71,16.99,4.67,2.65,11.28,.69,15.29-5.24s4.89-13.21,1.29-16.48Zm-2.78,15.21c-3.21,4.75-9.05,6.5-12.06,4.89-3.01-1.61-4.19-6.94,.19-13.25,4.43-6.39,10.82-6.41,13.25-4.31,2.43,2.1,1.83,7.92-1.38,12.67Z' />
          <path className='cls-10' style={{ fill: '#daf2f0' }}
                d='M67.07,33.13c4.53,2.33,14.15,8.59,16.84,9.93s2.11,3.45,1.03,5.73c-1.07,2.28-5.18,8.46-6.8,14.92-1.63,6.46-6.31,4.76-11.5,4.41s-8.74-.63-11.09-.81c-2.81-.22-5.81-.17-6.22-8.14-.41-7.96,1.89-17.92,5.2-23.24,3.31-5.32,6.64-5.84,12.54-2.81Z' />
          <path className='cls-2' style={{ fill: '#fff' }}
                d='M55.13,61.48c-.41-7.96,1.89-17.92,5.2-23.24,2.3-3.7,4.61-5.07,7.78-4.56-.37-.2-.72-.39-1.04-.56-5.9-3.04-9.23-2.51-12.54,2.81-3.31,5.32-5.61,15.27-5.2,23.24,.41,7.96,3.4,7.92,6.22,8.14,.29,.02,.6,.05,.92,.07-.7-1.18-1.19-3.01-1.34-5.9Z' />
          <path className='cls-7' style={{ fill: '#5d7e93' }}
                d='M61.47,35.16c1.28,.21,2.11,1.45,1.83,2.71-.89,4-1.75,12.34,4.58,18.31,7.85,7.41,14.73,6.66,14.73,6.66l-.88,2.89s-12.31-.66-19.89-10.38c-5.72-7.34-4.02-15.26-2.85-18.7,.35-1.03,1.4-1.66,2.48-1.48h0Z' />
          <path className='cls-7' style={{ fill: '#5d7e93' }}
                d='M88.27,61.51l-1.72,2.2s3.6,.63,3.05,2.02c-.44,1.12-2.01,.78-2.01,.78,0,0,1.15,.78,.33,1.9-.46,.62-1.96,.2-1.96,.2,0,0,.36,.56-.13,1.16s-3.12,.45-4.82-.57c-1.7-1.02-1.42-2.76-.39-4.55,1.03-1.79,2.72-4.52,7.64-3.13Z' />
          <path className='cls-7' style={{ fill: '#5d7e93' }}
                d='M101.22,21.34l1.44-4.79,4.03-1.06,4.65,4.66s1.59,5.41-2.12,7.81c0,0-4.05,.11-6.01-3.1h0c0-.93,.95-1.56,1.81-1.19l2.24,.96,.84-2.95-2.52-3.23-1.06,.15s-.75,3.33-3.3,2.75Z' />
          <path className='cls-11' style={{ fill: '#83aaa3' }}
                d='M87.17,30.08c-.53,.52-1.39,.52-1.91-.02-.52-.53-.52-1.39,.02-1.91,.53-.52,1.39-.52,1.91,.02,.52,.53,.52,1.39-.02,1.91Z' />
          <path className='cls-11' style={{ fill: '#83aaa3' }}
                d='M94.85,35.79c-.53,.52-1.39,.52-1.91-.02-.52-.53-.52-1.39,.02-1.91,.53-.52,1.39-.52,1.91,.02,.52,.53,.52,1.39-.02,1.91Z' />
          <path className='cls-9' style={{ fill: '#bad8d3' }}
                d='M78.09,48.35h0c-.38-.18-.55-.64-.37-1.02l2.92-6.23c.18-.38,.64-.55,1.02-.37h0c.38,.18,.55,.64,.37,1.02l-2.92,6.23c-.18,.38-.64,.55-1.02,.37Z' />
        </g>
        <path className='cls-10' style={{ fill: '#daf2f0' }}
              d='M81.79,4.58c-.93-1.2-1.91-2.06-2.62-2.61,.21-.76-.18-1.56-.93-1.86-.8-.31-1.7,.08-2.01,.87-.31,.8,.08,1.7,.87,2.01,.55,.22,1.15,.1,1.58-.26,.67,.52,1.57,1.33,2.42,2.43,2.12,2.73,2.89,5.86,2.29,9.28l.89,.16c.8-4.61-.91-7.99-2.49-10.02Z' />
      </g>
    </g>
  </svg>
);

export const FallbackRelaxedImage: any = (props: any) => (
  <svg viewBox='0 0 201.31 89.62' {...props}>
    <defs>
      <clipPath id='clippath'>
        <path className='cls-4' style={{ fill: '#dff8f8' }}
              d='M111.19,4.05c0,2.24-1.81,4.05-4.05,4.05s-4.05-1.81-4.05-4.05,1.81-4.05,4.05-4.05,4.05,1.81,4.05,4.05Z' />
      </clipPath>
    </defs>
    <g id='_Ñëîé_1' data-name='Ñëîé 1'>
      <g>
        <g>
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M117.72,8.12c-.73,1.16-2.25,1.51-3.41,.78-1.16-.73-1.51-2.25-.78-3.41,.73-1.16,2.25-1.51,3.41-.78s1.51,2.25,.78,3.41Z' />
          <path className='cls-4' style={{ fill: '#dff8f8' }}
                d='M117.72,8.12c-.73,1.16-2.25,1.51-3.41,.78-1.16-.73-1.51-2.25-.78-3.41,.73-1.16,2.25-1.51,3.41-.78s1.51,2.25,.78,3.41Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M115.78,6.6c-.53,.26-1.14,.09-1.36-.36s.04-1.03,.57-1.29c.53-.26,1.14-.09,1.36,.36,.22,.46-.04,1.03-.57,1.29Z' />
        </g>
        <g>
          <path className='cls-27' style={{ fill: '#2eccd3' }}
                d='M9.15,70.52c-.04-1.77,.22-10.3,8.72-13.91,8.49-3.6,15.23-1.13,18.22,.44-2.28-3.2-5.26-9.31-2.56-17.23,3.89-11.41,15.57-10.5,17.85-10.2,.09-.93,.49-3.38,2.19-6.08,1.94-3.1,6.04-7.1,14.5-8.58,9.45-1.66,15.66,.78,19.21,3.11,3.13,2.06,4.77,4.36,5.31,5.22,1.79-1.26,10.72-6.88,20.46-1.49,1.85,1.02,3.35,2.2,4.56,3.45,1.72-1.03,3.73-1.62,5.89-1.62,2.66,0,5.11,.91,7.06,2.43,.82-1.11,2-2.2,3.68-3.12,5.6-3.05,10.82-.95,11.99-.4,.88-.87,4.78-4.21,10.39-2.17,5.27,1.91,6.05,6.62,6.16,8.26,1.08-.51,11.86-2.74,18.07,5.44,6.23,8.21,3.64,16.42,2.28,18.42,2.81,.84,4.72,2.35,5.67,4.48,1.61,3.61,0,7.9-.62,9.3,12.98,1.39,13.13,12.93,13.13,13.05,0,.19-.16,.35-.35,.35H.74c-.19,0-.35-.16-.35-.35,0-6.76,7.15-8.49,8.77-8.79Z' />
          <path className='cls-19' style={{ fill: '#45e5e2' }}
                d='M9.15,70.52c-.04-1.67,.19-9.37,7.38-13.26,2.78-1.71,7.91-4.3,14.12-4.87,0,0-2.59-8.42,5.75-17.72,5.04-5.92,13.13-5.28,14.99-5.04,.09-.93,.49-3.38,2.19-6.08,1.94-3.1,6.04-7.1,14.5-8.58,9.45-1.66,15.66,.78,19.21,3.11,3.13,2.06,4.77,4.36,5.31,5.22,1.79-1.26,10.72-6.88,20.46-1.5,4.39,2.42,6.78,5.71,8.08,8.76,0,0-5.98-3.64-18.16,1.68-11.21,4.89-10.71,13.01-10.71,13.01,0,0-10.76-4.63-21.07,1.85-10.31,6.48-6.31,15.86-6.31,15.86,0,0-5.1-7.05-16.58-5.75-8.37,.95-11.45,5.75-13.21,8.01,0,0-12.63-2.39-16.78,5.54,0,0-10.62-.84-11.63,8.9H.74c-.19,0-.35-.16-.35-.35,0-6.76,7.15-8.49,8.77-8.79Z' />
          <path className='cls-17' style={{ fill: '#00b2bb' }}
                d='M91.84,77.97c.04-1.67,.33-3.48,.99-5.4,3.6-10.57,14.43-9.73,16.55-9.46,.09-.86,.45-3.14,2.03-5.64,1.8-2.87,5.6-6.58,13.44-7.96,8.76-1.54,14.51,.72,17.8,2.88,2.9,1.91,4.42,4.04,4.93,4.84,1.66-1.16,9.93-6.37,18.96-1.39,4.07,2.25,6.29,5.29,7.49,8.12,13.72,1.26,12.8,10.9,12.76,14H91.84Z' />
          <path className='cls-21' style={{ fill: '#f1f1f1' }}
                d='M.04,78.94s-.89,.88,5.35,1.84c7.31,1.13,19.9,.28,21.64,2.37,1.9,2.28-6.92,1.63-6.76,4.29,.14,2.26,9.66,2.18,20.4,2.18h100.85c8.7,0,7.12-1.97,5.89-2.87-1.79-1.3-1-2.51,6.82-2.67,7.6-.16,13.16-.3,17.31-.3,11.06,0,8.89-2.73,17.5-2.73,5.57,0,12.27-1.05,12.27-2.26-28.28-2.51-59.83-4.24-94.16-4.41-39.48-.2-75.43,1.69-107.11,4.56Z' />
        </g>
        <g>
          <path className='cls-15' style={{ fill: '#62e0db' }}
                d='M132.81,80.53c-17.11-.17-55.31-.55-55.31-.55h0s-.08,0-.11,0c-2.28-.05-4.53-.18-6.61,.37-2.2,.58-1.69,2.01,.16,2.75,1.09,.43,2.41,.59,3.69,.68,2.68,.19,5.4,.21,8.09,.3,.31,.01,3.25-.12,2.13,.74-.22,.18-.58,.2-.75,.44-.37,.53,.18,1.11,.92,1.41,1.37,.55,3.67,.54,5.21,.59,4.04,.12,18.11,.18,23.71-.26,1.01-.08,2.29-.28,2.51-.97,.05-.16,.05-.32-.04-.47-.1-.17-.88-.5-.91-.54-1.3-1.9,13.58-1.11,16.64-1.03,4.84,.12,9.98,.04,10.01-1.34,.03-1.54-3.31-2.06-9.33-2.11Z' />
          <path className='cls-7' style={{ fill: '#7feeea' }}
                d='M133.75,80.54c-.53,1.4-13.49,2.53-29.41,2.53s-29.43-1.17-29.43-2.62c0-.18,.21-.36,.59-.53-1.64-.03-3.24,.02-4.74,.41-2.2,.58-1.69,2.01,.16,2.75,1.09,.43,2.41,.59,3.69,.68,2.68,.19,5.4,.21,8.09,.3,.31,.01,3.25-.12,2.13,.74-.22,.18-.58,.2-.75,.44-.37,.53,.18,1.11,.92,1.41,1.37,.55,3.67,.54,5.21,.59,4.04,.12,18.11,.18,23.71-.26,1.01-.08,2.29-.28,2.51-.97,.05-.16,.05-.32-.04-.47-.1-.17-.88-.5-.91-.54-1.3-1.9,13.58-1.11,16.64-1.03,4.84,.12,9.98,.04,10.01-1.34,.03-1.46-2.97-2-8.39-2.1Z' />
          <ellipse className='cls-22' style={{ fill: '#dadad9' }} cx='49.64' cy='83.42' rx='10.04' ry='1.4' />
          <g>
            <path className='cls-4' style={{ fill: '#dff8f8' }}
                  d='M111.19,4.05c0,2.24-1.81,4.05-4.05,4.05s-4.05-1.81-4.05-4.05,1.81-4.05,4.05-4.05,4.05,1.81,4.05,4.05Z' />
            <g className='cls-28' style={{ clipPath: 'url(#clippath)' }}>
              <path className='cls-4' style={{ fill: '#dff8f8' }}
                    d='M111.19,4.05c0,2.24-1.81,4.05-4.05,4.05s-4.05-1.81-4.05-4.05,1.81-4.05,4.05-4.05,4.05,1.81,4.05,4.05Z' />
            </g>
          </g>
          <circle className='cls-4' style={{ fill: '#dff8f8' }} cx='83.6' cy='25.62' r='5.25' />
          <circle className='cls-4' style={{ fill: '#dff8f8' }} cx='83.6' cy='25.62' r='5.25' />
          <path className='cls-25'
                d='M140.11,6.67c-.74,0-1.36,.5-1.56,1.17-1.69,.15-7.89,1.22-8.74,8.84l.96,.11c.77-6.91,6.23-7.86,7.79-7.99,.21,.65,.82,1.13,1.54,1.13,.9,0,1.63-.73,1.63-1.63s-.73-1.63-1.63-1.63Z' />
          <path className='cls-11' style={{ fill: '#c9eaed' }} d=' M92.54
          ,45.59c-.96-3.77,2.15-15.84,4.11-22.3s5.19-7.29,13.32-7.6c8.13-.31,13.74-.29,20.5-.12,6.76,.18,13.14,3.56,11.41,12.19-1.72,8.63-1.48,16.5-4.46,19.85-2.98,3.35-16.29,4.05-27.55,3.53-11.26-.52-15.87,.21-17.33-5.56Z' />
          <path className='cls-4' style={{ fill: '#dff8f8' }}
                d='M130.48,15.58c-6.76-.18-12.38-.19-20.5,.12-4.86,.19-7.97,.56-10.1,2.2,1.44-.21,3.11-.31,5.06-.39,8.13-.31,13.74-.29,20.5-.12,6.76,.18,13.14,3.56,11.41,12.19-1.72,8.63-1.48,16.5-4.46,19.85-.21,.24-.48,.46-.78,.67,2.76-.58,4.85-1.39,5.82-2.49,2.98-3.35,2.74-11.22,4.46-19.85,1.72-8.63-4.65-12.01-11.41-12.19Z' />
          <path className='cls-24' style={{ fill: '#e8f8f7' }}
                d='M95.5,36.79s.78-4.22,2.57-9.66c1.78-5.44,3.65-8.07,11.11-8.14,7.46-.07,10.96-.04,16.44,0,5.48,.04,8.53,1.81,7.17,8.3-1.36,6.49-1.52,6.63-2.49,10.41-.97,3.77-1.23,5.79-7.37,6.03-6.14,.24-17.23-.21-21.66-.61-4.43-.4-6.71-3.02-5.77-6.33Z' />
          <path className='cls-6' style={{ fill: '#6f7e84' }}
                d='M125.11,28.44c-.14,0-.28-.04-.39-.13-.5-.39-1.57-1.05-3.04-1.07-1.54-.03-2.69,.66-3.22,1.07-.28,.22-.69,.16-.9-.12-.22-.28-.17-.69,.12-.9,.67-.52,2.07-1.37,4.03-1.34,1.84,.03,3.17,.85,3.8,1.34,.28,.22,.33,.62,.12,.9-.13,.16-.32,.25-.51,.25Z' />
          <path className='cls-6' style={{ fill: '#6f7e84' }}
                d='M102.13,27.77c-.19,0-.38-.09-.51-.25-.22-.28-.17-.69,.12-.9,.65-.51,2.02-1.34,3.92-1.34,.04,0,.08,0,.12,0,1.84,.03,3.17,.85,3.8,1.34,.28,.22,.33,.62,.11,.9-.22,.28-.62,.33-.9,.11-.5-.39-1.56-1.05-3.04-1.07-1.55-.03-2.69,.66-3.22,1.07-.12,.09-.26,.13-.39,.13Z' />
          <path className='cls-6' style={{ fill: '#6f7e84' }}
                d='M112.27,36.68c-.29,0-.58-.03-.87-.1-1.64-.39-2.84-1.82-2.99-3.57-.03-.35,.23-.67,.59-.7,.34-.03,.67,.23,.7,.59,.1,1.17,.93,2.17,2.01,2.43,1.27,.3,2.61-.43,3.11-1.72,.13-.33,.5-.49,.83-.36,.33,.13,.49,.5,.36,.83-.62,1.59-2.14,2.6-3.73,2.6Z' />
          <path className='cls-1' style={{ fill: '#ffb6b6' }}
                d='M100.43,31.89c-.23,.03-.46-.06-.61-.26-.21-.29-.15-.69,.14-.9,1.67-1.22,4.01-1.01,4.11-1,.35,.03,.61,.35,.58,.7-.03,.35-.34,.61-.7,.58h0s-1.95-.17-3.22,.76c-.09,.07-.19,.1-.29,.12Z' />
          <path className='cls-1' style={{ fill: '#ffb6b6' }}
                d='M100.87,33.28c-.18,.02-.37-.05-.48-.21-.17-.23-.12-.55,.11-.72,1.33-.98,3.2-.81,3.28-.8,.28,.03,.49,.28,.46,.56-.03,.28-.28,.49-.56,.46-.02,0-1.56-.13-2.57,.61-.07,.05-.15,.08-.23,.09Z' />
          <path className='cls-1' style={{ fill: '#ffb6b6' }}
                d='M121.9,31.26c-.22-.06-.4-.24-.45-.48-.08-.35,.14-.69,.49-.77,2.02-.45,4.08,.68,4.16,.73,.31,.17,.42,.57,.25,.88-.17,.31-.56,.42-.88,.25h0s-1.72-.94-3.26-.6c-.11,.02-.22,.02-.32,0Z' />
          <path className='cls-1' style={{ fill: '#ffb6b6' }}
                d='M122.46,33.27c-.18-.05-.32-.19-.36-.38-.06-.28,.11-.55,.39-.61,1.61-.36,3.25,.54,3.32,.58,.25,.14,.34,.45,.2,.7-.14,.25-.45,.34-.7,.2-.02-.01-1.38-.75-2.6-.48-.09,.02-.17,.02-.25,0Z' />
          <path className='cls-4' style={{ fill: '#dff8f8' }}
                d='M68.94,41.94c.19-1.37,1.23-2.48,2.57-2.76,1.4-.29,2.86,.39,3.55,1.67,.63-1.73,2.07-3.01,3.79-3.3,2.21-.37,4.54,.97,5.47,3.3,.44-1.22,1.59-2.05,2.88-2.07,1.33-.02,2.54,.81,2.99,2.07,1.28-2.6,4.34-3.76,6.92-2.74,2.4,.96,3.82,3.59,3.28,6.22,.14-.09,2.21-1.42,4.45-.43,1.69,.75,2.77,2.5,2.72,4.43l.85,7.81-40.48-8.64,1.01-5.56Z' />
          <g>
            <path className='cls-3' style={{ fill: '#fff' }}
                  d='M91,42.96c1.28-2.6,4.34-3.76,6.92-2.74,.78,.31,1.45,.8,1.99,1.4-.59-1.05-1.51-1.89-2.67-2.35-2.55-1.02-5.57,.12-6.88,2.65,.27,.3,.49,.64,.63,1.03Z' />
            <path className='cls-3' style={{ fill: '#fff' }}
                  d='M85.12,42.96c.44-1.22,1.59-2.05,2.88-2.07,.83-.01,1.61,.31,2.2,.86-.52-1.11-1.64-1.83-2.87-1.8-1.23,.02-2.32,.78-2.81,1.91,.24,.34,.44,.71,.6,1.11Z' />
            <path className='cls-3' style={{ fill: '#fff' }}
                  d='M69.75,44.05c.19-1.37,1.23-2.48,2.57-2.76,1.4-.29,2.86,.39,3.55,1.67,.63-1.73,2.07-3.01,3.79-3.3,1.63-.27,3.32,.38,4.47,1.68-1.08-1.9-3.15-2.96-5.14-2.62-1.71,.29-3.16,1.57-3.79,3.3-.69-1.28-2.14-1.96-3.55-1.67-1.34,.28-2.37,1.39-2.57,2.76l-1.01,5.56,.82,.17,.87-4.79Z' />
          </g>
          <path className='cls-20' style={{ fill: '#ffe570' }}
                d='M62.74,41.95c0-.15-.16-14.83,0-20.52,.21-7.42,1.77-12.33,4.92-15.44,5-4.94,11.91-1.55,12.2-1.41l-.87,1.73c-.24-.12-5.97-2.9-9.98,1.06-2.77,2.74-4.16,7.23-4.35,14.12-.16,5.66,0,20.3,0,20.45l-1.93,.02Z' />
          <path className='cls-5' style={{ fill: '#fdf7ab' }}
                d='M63.28,21.97c.21-7.42,1.77-12.33,4.92-15.44,4.17-4.12,9.66-2.45,11.54-1.7l.12-.25c-.29-.15-7.2-3.53-12.2,1.41-3.15,3.11-4.71,8.02-4.92,15.44-.16,5.7,0,20.37,0,20.52h.53c-.03-2.71-.14-14.91,0-19.98Z' />
          <path className='cls-8' style={{ fill: '#8ed3d1' }}
                d='M77.55,74.53s-1.5,4.44-2.45,6.79c-.95,2.35,1.94,2.67,3.45,.78s3.87-5.01,3.87-5.01l-4.87-2.57Z' />
          <path className='cls-8' style={{ fill: '#8ed3d1' }}
                d='M133.53,74.53s1.5,4.44,2.45,6.79c.95,2.35-1.94,2.67-3.45,.78s-3.87-5.01-3.87-5.01l4.87-2.57Z' />
          <path className='cls-16' style={{ fill: '#dbf7fb' }}
                d='M60.81,47.38s.4,12.62,9.11,23.85c8.71,11.23,21.68,10.23,26.29,10.22s14.16-.34,19.88-.34c14.41,0,20.62-5.75,23.41-12.34,2.78-6.59,5.48-20.31,5.48-20.31,0,0,5.55-1.48,4.48-7.03-.89-4.63-9.74-1.34-13.59,.81-3.86,2.16-19.88,6.64-34.82,6-14.94-.64-30.58-5.28-35.03-7.8s-7.57-2.87-8.91,0c-1.93,4.12,3.72,6.93,3.72,6.93Z' />
          <path className='cls-14' style={{ fill: '#bcedef' }}
                d='M60.81,47.38s.4,12.62,9.11,23.85c8.71,11.23,21.68,10.23,26.29,10.22s14.16-.34,19.88-.34c14.41,0,20.62-5.75,23.41-12.34,2.78-6.59,5.48-20.31,5.48-20.31,0,0,5.55-1.48,4.48-7.03-.89-4.63-9.74-1.34-13.59,.81-3.86,2.16-19.88,6.64-34.82,6-14.94-.64-30.58-5.28-35.03-7.8s-7.57-2.87-8.91,0c-1.93,4.12,3.72,6.93,3.72,6.93Z' />
          <path className='cls-9' style={{ fill: '#9cdddd' }}
                d='M135.86,42.25c-3.86,2.16-19.88,6.64-34.82,6-14.94-.64-30.58-5.28-35.03-7.8-3.37-1.91-5.98-2.57-7.63-1.53,4.57,4.23,11.37,8.85,21.49,13.46,27.92,12.73,53.8,6.82,63.4,3.79,1.02-4.26,1.7-7.7,1.7-7.7,0,0,5.55-1.48,4.48-7.03-.89-4.63-9.74-1.34-13.59,.81Z' />
          <path className='cls-20' style={{ fill: '#ffe570' }}
                d='M79.05,4.02l11.68-2.21-9.17,14.91-3.85-10.31c-.39-1.04,.25-2.18,1.35-2.39Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M75.05,20.2c0,1.18-.96,2.14-2.14,2.14s-2.14-.96-2.14-2.14,.96-2.14,2.14-2.14,2.14,.96,2.14,2.14Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M74.23,31.81c0,1.74-1.41,3.15-3.15,3.15s-3.15-1.41-3.15-3.15,1.41-3.15,3.15-3.15,3.15,1.41,3.15,3.15Z' />
          <path className='cls-4' style={{ fill: '#dff8f8' }}
                d='M75.05,20.2c0,1.18-.96,2.14-2.14,2.14s-2.14-.96-2.14-2.14,.96-2.14,2.14-2.14,2.14,.96,2.14,2.14Z' />
          <path className='cls-4' style={{ fill: '#dff8f8' }}
                d='M74.23,31.81c0,1.74-1.41,3.15-3.15,3.15s-3.15-1.41-3.15-3.15,1.41-3.15,3.15-3.15,3.15,1.41,3.15,3.15Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M70.76,31.39c-.34,.54-1,.74-1.46,.45-.46-.29-.56-.97-.22-1.51,.34-.54,1-.74,1.46-.45,.46,.29,.56,.97,.22,1.51Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M81.75,23.99c-.34,.54-1,.74-1.46,.45s-.56-.97-.22-1.51c.34-.54,1-.74,1.46-.45,.46,.29,.56,.97,.22,1.51Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M72.94,19.98c-.27,.43-.79,.59-1.16,.36-.37-.23-.45-.77-.17-1.2,.27-.43,.79-.59,1.16-.36,.37,.23,.45,.77,.18,1.2Z' />
          <g>
            <path className='cls-2' style={{ fill: '#d43f0c' }}
                  d='M54.69,63.72c0,.96-.78,1.73-1.73,1.73s-1.73-.78-1.73-1.73,.78-1.73,1.73-1.73,1.73,.78,1.73,1.73Z' />
            <path className='cls-2' style={{ fill: '#d43f0c' }}
                  d='M58.65,82.81c-.51,.63-1.69,.53-2.63-.23s-1.28-1.89-.76-2.52,1.69-.53,2.63,.23c.94,.76,1.28,1.89,.76,2.52Z' />
            <path className='cls-2' style={{ fill: '#d43f0c' }}
                  d='M43.82,83.31c-.58-.58-.35-1.74,.5-2.59s2.01-1.08,2.59-.5,.35,1.74-.5,2.59-2.01,1.08-2.59,.5Z' />
            <path className='cls-20' style={{ fill: '#ffe570' }}
                  d='M57.88,75.07c1.16,3.16-.88,6.81-4.56,8.16s-7.6-.12-8.75-3.28,.88-6.81,4.56-8.16,7.6,.12,8.75,3.28Z' />
            <path className='cls-20' style={{ fill: '#ffe570' }}
                  d='M58.11,68.38c0,2.6-2.28,4.71-5.1,4.71s-5.1-2.11-5.1-4.71,2.28-4.71,5.1-4.71,5.1,2.11,5.1,4.71Z' />
            <path className='cls-26' style={{ fill: '#a0843d' }}
                  d='M52.96,67.53c0,.72-.58,1.3-1.3,1.3s-1.3-.58-1.3-1.3,.58-1.3,1.3-1.3,1.3,.58,1.3,1.3Z' />
            <path className='cls-2' style={{ fill: '#d43f0c' }}
                  d='M55.29,68.38c-.61,1.25,.41,2.33,3.51,2.66,2.12,.22,3.16-.09,2.91-1.21-.25-1.11-5.62-3.08-6.42-1.45Z' />
            <path className='cls-20' style={{ fill: '#ffe570' }}
                  d='M46.75,75.44l-1.26-.42s.04-.07,.05-.11c.32-.89-.34-1.94-1.45-2.33-1.12-.4-2.28,0-2.6,.9,0,.03-.01,.06-.02,.09-.16-.11-.33-.22-.52-.3-1.18-.51-2.43-.22-2.81,.65-.38,.87,.27,1.99,1.44,2.5,.44,.19,.89,.27,1.3,.25-.46,.42-.7,.97-.58,1.5,.2,.92,1.38,1.45,2.64,1.17,1.15-.26,1.96-1.1,1.91-1.96h.96l.95-1.93Z' />
          </g>
          <path className='cls-14' style={{ fill: '#bcedef' }}
                d='M149.45,41.44c-.89-4.63-9.74-1.34-13.59,.81-3.86,2.16-19.88,6.64-34.82,6-14.94-.64-30.58-5.28-35.03-7.8-4.45-2.52-7.57-2.87-8.91,0-1.93,4.12,3.72,6.93,3.72,6.93,0,0,13.96,8.76,41.22,8.76s42.95-7.67,42.95-7.67c0,0,5.55-1.48,4.48-7.03Z' />
          <path className='cls-4' style={{ fill: '#dff8f8' }}
                d='M93.68,48.42c17.61,2.15,36.97-1.66,41.73-3.85,3.61-1.66,10.67-3.82,14.14-2.34-.02-.25-.05-.52-.11-.79-.89-4.63-9.74-1.34-13.59,.81-3.86,2.16-19.88,6.64-34.82,6-14.94-.64-30.58-5.28-35.03-7.8-3.26-1.84-5.8-2.52-7.45-1.63,7.97,3.52,21.68,7.96,35.13,9.6Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M105.94,3.45c-.34,.54-1,.74-1.46,.45-.46-.29-.56-.97-.22-1.51,.34-.54,1-.74,1.46-.45,.46,.29,.56,.97,.22,1.51Z' />
          <path className='cls-5' style={{ fill: '#fdf7ab' }}
                d='M79.05,4.02c-.57,.11-1.01,.47-1.26,.94l9.35,1.49,1.34-.99,2.25-3.65-11.68,2.21Z' />
          <path className='cls-10' style={{ fill: '#ffd761' }} d=' M90.84
          ,12.14c-2.72,4.32-6.51,6.83-8.45,5.61-1.94-1.23-1.31-5.72,1.41-10.05,2.72-4.32,6.51-6.83,8.45-5.61,1.94,1.23,1.31,5.72-1.41,10.05Z' />
          <g>
            <path className='cls-18' style={{ fill: '#fcbf4f' }}
                  d='M84.37,14.08c-.48,.12-.94,.69-1.02,1.26-.09,.57,.23,.94,.71,.81s.94-.69,1.02-1.26-.23-.94-.71-.81Z' />
            <path className='cls-18' style={{ fill: '#fcbf4f' }}
                  d='M86.99,11.56c.54-.14,1.05-.77,1.15-1.42,.1-.64-.26-1.05-.8-.91-.54,.14-1.05,.77-1.15,1.42-.1,.64,.26,1.05,.8,.91Z' />
            <path className='cls-18' style={{ fill: '#fcbf4f' }}
                  d='M89.92,6.79c.48-.12,.94-.69,1.02-1.26,.09-.57-.23-.93-.71-.81s-.94,.69-1.02,1.26c-.09,.57,.23,.93,.71,.81Z' />
          </g>
          <path className='cls-12' style={{ fill: '#f4dc4f' }}
                d='M66.41,21.45c0,1.49-1.21,2.7-2.7,2.7s-2.7-1.21-2.7-2.7,1.21-2.7,2.7-2.7,2.7,1.21,2.7,2.7Z' />
          <path className='cls-20' style={{ fill: '#ffe570' }}
                d='M66.41,21.45c0,1.49-1.21,2.7-2.7,2.7s-2.7-1.21-2.7-2.7,1.21-2.7,2.7-2.7,2.7,1.21,2.7,2.7Z' />
          <path className='cls-5' style={{ fill: '#fdf7ab' }}
                d='M64.28,20.85c0,.71-.58,1.29-1.29,1.29s-1.29-.58-1.29-1.29,.58-1.29,1.29-1.29,1.29,.58,1.29,1.29Z' />
          <path className='cls-9' style={{ fill: '#9cdddd' }}
                d='M126.58,68.66s4.98,.66,5.09-4.91c0,0,6.28-4.5,8.17-8.47l-5.27-1.2-6.34,7.95-1.66,6.64Z' />
          <path className='cls-28' style={{ fill: '#99abaf' }}
                d='M135.4,39.23l.18,.13,.12,.1,.22,.18c.14,.12,.27,.24,.39,.36,.25,.24,.49,.49,.71,.74,.44,.51,.84,1.06,1.18,1.64,.69,1.16,1.17,2.46,1.38,3.78,.22,1.32,.18,2.66-.04,3.91-.11,.62-.26,1.24-.47,1.85-.2,.6-.45,1.17-.71,1.72-1.09,2.17-2.54,3.94-4.09,5.54-.78,.8-1.59,1.55-2.42,2.26-.85,.72-1.68,1.37-2.63,2.04-.73,.51-1.73,.33-2.24-.4-.48-.69-.35-1.63,.29-2.16l.05-.04c.72-.59,1.49-1.3,2.18-2,.71-.71,1.38-1.44,2.01-2.2,1.25-1.51,2.34-3.14,3-4.79,.15-.41,.3-.82,.39-1.22,.1-.4,.16-.8,.2-1.22,.07-.82,.02-1.62-.17-2.35-.19-.73-.51-1.41-.95-2.02-.22-.3-.47-.59-.73-.85-.13-.13-.27-.25-.41-.36-.07-.06-.14-.11-.2-.15l-.09-.06-.04-.02s-.03-.02,.01,.01c-1.22-.79-1.56-2.42-.77-3.64s2.42-1.56,3.64-.77Z' />
          <g>
            <path className='cls-23' style={{ fill: '#a6b7b7' }}
                  d='M126.77,62.46c.72-.65,1.48-1.41,2.17-2.16,.7-.76,1.37-1.55,1.98-2.36,.83-1.09,1.57-2.23,2.16-3.39-.48,.73-1.01,1.44-1.58,2.12-.63,.76-1.3,1.49-2.01,2.2-.7,.7-1.46,1.41-2.18,2l-.05,.04c-.47,.39-.67,1.01-.54,1.58l.04-.03Z' />
            <path className='cls-23' style={{ fill: '#a6b7b7' }}
                  d='M139.58,46.16c-.22-1.32-.69-2.62-1.38-3.78-.34-.58-.74-1.13-1.18-1.64-.22-.26-.46-.5-.71-.74-.12-.12-.26-.24-.39-.36l-.22-.18-.12-.1-.18-.13c-.97-.63-2.2-.53-3.07,.15,.62-.11,1.28-.02,1.87,.33l.19,.12,.13,.09,.23,.18c.15,.12,.29,.23,.42,.35,.27,.24,.52,.48,.77,.74,.48,.51,.92,1.06,1.3,1.65,.76,1.17,1.31,2.49,1.6,3.86,.29,1.36,.3,2.74,.13,4.05-.09,.64-.22,1.3-.41,1.93-.18,.63-.42,1.23-.66,1.81,0,.02-.02,.04-.03,.06,.17-.3,.34-.6,.5-.92,.26-.55,.52-1.11,.71-1.72,.21-.6,.36-1.23,.47-1.85,.22-1.25,.27-2.58,.04-3.91Z' />
          </g>
          <path className='cls-28' style={{ fill: '#99abaf' }}
                d='M130.1,62.42l-1.97-2.56c-1.38-.89-3.76-1.15-5-1.08-1.24,.06-3.24,.74-2.96,1.79,.27,1.05,1.11,1.11,2.64,.85,1.53-.25,2.39,.77,1.69,2.27-.7,1.5-3.87,.45-3.81,1.96,.07,1.5,1.66,1.41,1.66,1.41,0,0,1.29-.08,2.21-.55,0,0-.56,.64-2.17,.89,.19,.95,1.14,1.18,1.71,1.23,.21-.02,1.34-.12,2.16-.54,0,0-.44,.5-1.64,.79,.7,1.25,3.37,1.04,4.97-.99,1.69-2.15,.53-5.47,.53-5.47Z' />
        </g>
        <g>
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M53.73,52.66c-.73,1.16-2.25,1.51-3.41,.78-1.16-.73-1.51-2.25-.78-3.41,.73-1.16,2.25-1.51,3.41-.78s1.51,2.25,.78,3.41Z' />
          <path className='cls-4' style={{ fill: '#dff8f8' }}
                d='M53.73,52.66c-.73,1.16-2.25,1.51-3.41,.78-1.16-.73-1.51-2.25-.78-3.41,.73-1.16,2.25-1.51,3.41-.78s1.51,2.25,.78,3.41Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M51.79,51.14c-.53,.26-1.14,.09-1.36-.36-.22-.46,.04-1.03,.57-1.29s1.14-.09,1.36,.36c.22,.46-.04,1.03-.57,1.29Z' />
        </g>
      </g>
    </g>
  </svg>
);

export const FallbackWorkingImage: any = (props: any) => (
  <svg viewBox='0 0 182.78 88.58' {...props}>
    <g id='_Ñëîé_1' data-name='Ñëîé 1'>
      <g>
        <g>
          <path style={{ fill: '#00ccd3' }}
                d='M8.31,71.24c-.04-1.61,.2-9.35,7.92-12.63,7.71-3.27,13.83-1.02,16.55,.4-2.07-2.9-4.77-8.46-2.33-15.64,3.53-10.36,14.14-9.53,16.21-9.26,.08-.85,.45-3.07,1.98-5.52,1.76-2.81,5.48-6.45,13.17-7.79,8.58-1.5,14.22,.71,17.44,2.83,2.84,1.87,4.33,3.96,4.83,4.74,1.62-1.14,9.73-6.24,18.57-1.36,1.68,.93,3.04,2,4.14,3.13,1.56-.94,3.39-1.47,5.34-1.47,2.42,0,4.64,.82,6.41,2.2,.75-1.01,1.82-2,3.35-2.83,5.08-2.77,9.82-.86,10.89-.37,.8-.79,4.34-3.82,9.43-1.97,4.78,1.74,5.49,6.01,5.59,7.5,.98-.47,10.77-2.49,16.41,4.94,5.66,7.46,3.3,14.91,2.07,16.72,2.55,.77,4.28,2.13,5.15,4.07,1.46,3.28,0,7.17-.56,8.44,11.79,1.26,11.92,11.74,11.92,11.85,0,.18-.14,.32-.32,.32H.67c-.18,0-.32-.14-.32-.32,0-6.14,6.5-7.71,7.96-7.98Z' />
          <path style={{ fill: '#00f3e8' }}
                d='M8.31,71.24c-.04-1.52,.17-8.51,6.7-12.04,2.53-1.55,7.18-3.9,12.82-4.42,0,0-2.35-7.65,5.22-16.09,4.58-5.38,11.92-4.79,13.61-4.57,.08-.85,.45-3.07,1.98-5.52,1.76-2.81,5.48-6.45,13.17-7.79,8.58-1.5,14.22,.71,17.44,2.83,2.84,1.87,4.33,3.96,4.83,4.74,1.62-1.14,9.73-6.24,18.57-1.36,3.98,2.2,6.16,5.18,7.34,7.96,0,0-5.43-3.3-16.49,1.53-10.18,4.44-9.73,11.81-9.73,11.81,0,0-9.77-4.21-19.13,1.68-9.37,5.89-5.73,14.4-5.73,14.4,0,0-4.63-6.4-15.06-5.22-7.6,.86-10.4,5.22-11.99,7.27,0,0-11.47-2.17-15.24,5.03,0,0-9.65-.76-10.56,8.08H.67c-.18,0-.32-.14-.32-.32,0-6.14,6.5-7.71,7.96-7.98Z' />
          <path style={{ fill: '#00e5e2' }}
                d='M168.6,78.97c-.03-1.24-.24-2.57-.73-3.99-2.66-7.81-10.66-7.19-12.23-6.99-.06-.64-.34-2.32-1.5-4.17-1.33-2.12-4.13-4.86-9.93-5.88-6.47-1.14-10.72,.53-13.15,2.13-2.15,1.41-3.27,2.99-3.64,3.57-1.22-.86-7.34-4.71-14.01-1.02-3,1.66-4.65,3.91-5.54,6-10.13,.93-9.46,8.06-9.43,10.34h70.15Z' />
          <path style={{ fill: '#efefef' }}
                d='M.04,78.88s-.8,.8,4.85,1.67c6.64,1.03,18.07,.26,19.64,2.15,1.72,2.07-6.28,1.48-6.14,3.9,.12,2.05,8.77,1.98,18.53,1.98h91.57c7.9,0,6.47-1.79,5.35-2.61-1.62-1.18-.91-2.28,6.2-2.43,6.9-.15,11.95-.27,15.71-.27,10.04,0,8.07-2.48,15.89-2.48,5.06,0,11.14-.95,11.14-2.06-25.68-2.28-54.32-3.85-85.49-4-35.85-.18-68.48,1.54-97.25,4.14Z' />
        </g>
        <g>
          <polygon style={{ fill: '#7fc1c1' }} points='62.27 40.69 61.1 43.93 101.83 53.42 102.83 48.75 62.27 40.69' />
          <path style={{ fill: '#b5b5b4' }}
                d='M130.69,79.92c0,1.94-19.32,3.52-43.15,3.52s-43.15-1.58-43.15-3.52,19.32-3.52,43.15-3.52,43.15,1.58,43.15,3.52Z' />
          <path style={{ fill: '#2f7274' }}
                d='M120.06,81.47h-27s-3.41-.97-2.87-4.69c.54-3.72,3.99-3.35,8.07-2.7,4.07,.65,20.36,2.36,20.36,2.36l1.45,5.03Z' />
          <path style={{ fill: '#aacbcc' }}
                d='M92.89,.05c-.49,.13-.83,.53-.93,1-3.53,.64-13.94,3.75-13.15,18.23h1.78c-.75-13.92,8.31-16.73,11.57-17.26,.3,.45,.87,.69,1.42,.54,.69-.19,1.1-.91,.91-1.6-.19-.69-.91-1.1-1.6-.91Z' />
          <path style={{ fill: '#b5eeea' }}
                d='M99.64,50.85l-39.28-9.4s-4.6-1.35-1.75-9.48c2.85-8.13,6.55-18.73,10.44-21.74s14.21-.93,19.02,.19,15.18,3.6,19.8,5.19c3.93,1.36,6.09,4.61,4.4,10.45-1.23,4.24-5.31,15.92-7.44,21.97-.75,2.13-2.99,3.35-5.18,2.82Z' />
          <path style={{ fill: '#95d5d6' }}
                d='M68.99,43.51s-4.68-1.37-1.78-9.63c2.9-8.26,3.73-17.17,8.25-21.15,2.72-2.39,8.41-2.74,12.39-2.36-4.9-1.12-14.96-3.1-18.79-.14-3.9,3.01-7.59,13.61-10.44,21.74-2.85,8.13,1.75,9.48,1.75,9.48l43.14,10.32s0,0,0,0l-34.51-8.26Z' />
          <path style={{ fill: '#eff' }}
                d='M75.62,20.14c-1.17,3.94-3.66,12.66-4.17,15.71s.56,5.66,4.17,6.68c2.57,.73,15.65,2.95,23,4.18,2.88,.48,5.66-1.26,6.5-4.05,1.59-5.29,3.97-13.23,4.52-15.05,.84-2.8,1.54-6.25-3.61-7.93-5.15-1.68-16.99-4.27-21.61-5.12-4.62-.85-7.44,1.02-8.79,5.59Z' />
          <path style={{ fill: '#60bfb8' }}
                d='M106.95,20.02c3.34,1.82,2.71,4.78,1.98,7.24-.55,1.83-2.93,9.76-4.52,15.05-.67,2.22-2.56,3.77-4.75,4.07-.49,.11-1.02,.17-1.58,.17-.17,0-.33-.01-.49-.02,.35,.06,.7,.12,1.03,.17,2.88,.48,5.66-1.26,6.5-4.05,1.59-5.29,3.97-13.23,4.52-15.05,.79-2.62,1.45-5.8-2.68-7.58Z' />
          <path style={{ fill: '#b5eeea' }}
                d='M61.25,42.62l38.07,9.19c2.35,.57,3.86,2.86,3.45,5.25l-3.66,21.29c-.46,1.76-2.05,2.99-3.87,2.99H58.37c-4.25,0-7.52-3.76-6.92-7.97l3.79-26.59c.41-2.87,3.18-4.79,6.01-4.16Z' />
          <path style={{ fill: '#95d5d6' }}
                d='M63.52,74.49l3.95-27.7c.14-.97,.54-1.84,1.12-2.54l-7.33-1.63c-2.83-.63-5.6,1.29-6.01,4.16l-3.79,26.59c-.6,4.21,2.67,7.97,6.92,7.97h7.99c-2.04-1.53-3.23-4.1-2.84-6.85Z' />
          <g>
            <path style={{ fill: '#2f7274' }}
                  d='M80.93,29.05c.58-.82,1.17-1.4,1.63-1.07,.46,.34,.37,1.27-.21,2.1s-1.42,1.22-1.88,.88c-.46-.34-.11-1.09,.47-1.91Z' />
            <path style={{ fill: '#2f7274' }}
                  d='M96.47,32.56c.55-.84,1.12-1.43,1.59-1.11,.47,.32,.4,1.27-.15,2.1s-1.38,1.25-1.85,.93c-.47-.32-.14-1.09,.41-1.92Z' />
          </g>
          <path style={{ fill: '#2f7274' }}
                d='M89,38.54c-.1,.05-.21,.07-.33,.05-1.56-.22-2.65-.66-2.7-.67-.3-.12-.44-.46-.32-.76,.12-.3,.46-.44,.76-.32,.01,0,1.01,.41,2.41,.6,.32,.04,.54,.34,.5,.66-.03,.2-.15,.36-.32,.44Z' />
          <path style={{ fill: '#2f7274' }}
                d='M95.97,81.47h-27s-3.41-.97-2.87-4.69c.54-3.72,3.99-3.35,8.07-2.7,4.07,.65,20.36,2.36,20.36,2.36l1.45,5.03Z' />
          <path style={{ fill: '#c9e2e2' }}
                d='M68.21,72.16l20.67,.22,9.3-33.66c.33-1.19,1.49-1.94,2.71-1.75l34.27,3.51c1.16,.18,1.9,1.32,1.59,2.45l-8.47,33.34c-.36,1.33-1.6,2.22-2.97,2.13l-57.2-3.54c-.74-.05-1.31-.68-1.27-1.43h0c.04-.73,.64-1.29,1.37-1.28Z' />
          <path style={{ fill: '#9fc6c4' }}
                d='M68.11,74.87l21.63,1.34,10.84-39.25c-1.1-.04-2.11,.68-2.41,1.76l-9.3,33.66-20.67-.22c-.73,0-1.33,.56-1.37,1.28-.04,.74,.53,1.38,1.27,1.43Z' />
          <path style={{ fill: '#2f7274' }}
                d='M104.33,63.59s-5.73-2.67-8.51,5.3c-1.37,3.93-2.16,7.05-2.6,9.2-.36,1.75,.97,3.38,2.75,3.38h.61l1.91-3.43,5.83-14.46Z' />
          <path style={{ fill: '#236363' }}
                d='M96.23,81.47h1.7c.98,0,1.88-.55,2.32-1.42,2.04-3.99,7.39-15.14,4.08-16.47-3.3-1.32-7.81,11.47-9.34,16.18-.27,.84,.35,1.7,1.24,1.7Z' />
          <path style={{ fill: '#e8f1f1' }}
                d='M116.41,58.01c-.72,1.71-2.31,2.67-3.56,2.14-1.25-.52-1.67-2.33-.95-4.04,.72-1.71,2.31-2.67,3.56-2.14,1.25,.52,1.67,2.33,.95,4.04Z' />
          <path style={{ fill: '#2f7274' }}
                d='M127.78,63.59s-5.73-2.67-8.51,5.3c-1.37,3.93-2.16,7.05-2.6,9.2-.36,1.75,.97,3.38,2.75,3.38h.61l1.91-3.43,5.83-14.46Z' />
          <path style={{ fill: '#236363' }}
                d='M119.68,81.47h1.7c.98,0,1.88-.55,2.32-1.42,2.04-3.99,7.39-15.14,4.08-16.47-3.3-1.32-7.81,11.47-9.34,16.18-.27,.84,.35,1.7,1.24,1.7Z' />
          <path style={{ fill: '#2f7274' }}
                d='M87.71,66.75l.03,4.34c.06,.7-.45,1.32-1.15,1.39h0c-.63,.06-1.21-.34-1.37-.95l-1.28-3.7-.27,.15,.17,3.24c.08,.73-.5,1.38-1.23,1.38h0c-.58,0-1.09-.41-1.21-.97l-.96-3.59-.41,.23,.13,2.92c.08,.76-.51,1.42-1.28,1.42h0c-.57,0-1.07-.37-1.23-.92l-.6-1.64s-2.91,1.23-4.11-1.59c0,0-.85-2.16,1.63-3.27,1.79-.81,5.88-2.07,9.35-2.01,2,.04,3.64,1.6,3.81,3.59Z' />
          <path style={{ fill: '#2f7274' }}
                d='M61.19,50.23c-1.75,2.51-3.18,5.34-3.92,8.19-.37,1.42-.54,2.86-.42,4.19,.12,1.33,.53,2.54,1.26,3.55,.18,.25,.39,.5,.61,.73,.11,.11,.24,.23,.34,.33,.09,.08,.19,.16,.29,.23,.42,.29,.98,.53,1.62,.69,.64,.16,1.35,.25,2.08,.27,.73,.03,1.49,0,2.26-.06,1.54-.14,3.1-.42,4.66-.8,.78-.19,1.55-.4,2.32-.64,.38-.12,.77-.24,1.15-.36l.56-.2,.28-.1,.26-.1,.05-.02c.95-.36,2.02,.13,2.37,1.08,.35,.95-.12,2.01-1.07,2.37l-.33,.13-.31,.11-.62,.22c-.41,.14-.82,.28-1.23,.42-.83,.27-1.66,.51-2.5,.74-1.68,.45-3.4,.82-5.19,1.03-.89,.11-1.8,.17-2.74,.17-.94,0-1.9-.07-2.89-.28-.99-.21-2.03-.55-3.01-1.17-.24-.15-.48-.33-.71-.51-.21-.17-.4-.33-.59-.51-.38-.35-.73-.73-1.06-1.13-.66-.81-1.2-1.73-1.6-2.69-.4-.96-.65-1.97-.79-2.96-.14-.99-.17-1.98-.12-2.94,.05-.96,.17-1.91,.35-2.82,.36-1.84,.92-3.59,1.61-5.26,.68-1.68,1.5-3.29,2.42-4.85h0c.77-1.3,2.45-1.74,3.75-.97,1.3,.77,1.74,2.45,.97,3.75-.03,.06-.07,.12-.11,.17Z' />
          <path style={{ fill: '#95d5d6' }}
                d='M73.25,45.52l-1.21,8.26c-.06,.42,.24,.81,.66,.86h0c.4,.05,.77-.23,.84-.63l1.37-8.09-1.66-.4Z' />
        </g>
      </g>
    </g>
  </svg>

);

export const FallbackNooneHereImage: any = (props: any) => (
  <svg viewBox='0 0 161.17 80.56' {...props}>
    <defs>
      <linearGradient id='_Áåçûìÿííûé_ãðàäèåíò_183' data-name='Áåçûìÿííûé ãðàäèåíò 183' x1='37.09' y1='25.57'
                      x2='100.94' y2='92.08' gradientUnits='userSpaceOnUse'>
        <stop offset='.24' stopColor='#6bdbc8' />
        <stop offset='1' stopColor='#66bbc3' />
      </linearGradient>
      <linearGradient id='_Áåçûìÿííûé_ãðàäèåíò_183-2' data-name='Áåçûìÿííûé ãðàäèåíò 183' x1='50.89' y1='36.23'
                      x2='95.45' y2='36.23' xlinkHref='#_Áåçûìÿííûé_ãðàäèåíò_183' />
    </defs>
    <g id='_Ñëîé_1' data-name='Ñëîé 1'>
      <g>
        <path className='cls-3' style={{ fill: 'url(#_Áåçûìÿííûé_ãðàäèåíò_183)' }}
              d='M146.8,69.16s5.74-8.9,.86-19.11c-4.89-10.2-21.03-6.88-21.03-6.88,0,0,3.55-10.97-2.85-17.03-6.4-6.06-15.92-3.23-15.92-3.23,0,0,2.5-7.05-4.04-11.01-2.18-1.32-4.32-1.43-6.07-1.13,0,0-.87-6.62-8.79-8.16-9.37-1.81-12.28,4.04-12.28,4.04,0,0-13.78-13.14-30-2.51-6.27,4.11-8.58,9.09-9.06,13.66-4.12,.25-15.61,1.94-20.66,12.69-4.06,8.64-3.12,16.22-1.71,20.92-3.22,.57-6.89,2-10.31,5.12-9,8.2-2.92,24-2.92,24H161.17c.19-12.17-14.37-11.38-14.37-11.38Z' />
        <g>
          <path className='cls-5' style={{ fill: '#c7e0dd' }}
                d='M59.25,11.59c.61-1.86,1.42-3.34,2.05-4.32-.59-.83-.46-1.98,.31-2.66,.83-.73,2.1-.65,2.83,.18,.73,.83,.65,2.1-.18,2.83-.58,.51-1.36,.62-2.03,.37-.58,.92-1.33,2.3-1.88,4-1.38,4.25-.96,8.38,1.27,12.28l-1.02,.58c-2.99-5.25-2.38-10.11-1.35-13.27Z' />
          <path className='cls-4' style={{ fill: '#daf2f0' }}
                d='M55.44,54.57h36.77c7.85,0,10.53-3.91,10.53-14.96s-6.29-25.89-26-25.89-27.1,11.86-28.72,22.8c-1.61,10.94-.33,18.06,7.42,18.06Z' />
          <path className='cls-1' style={{ fill: '#fff' }}
                d='M54.48,37.12c1.56-10.58,9.54-19.46,28.63-19.46,3.6,0,6.68,.16,9.33,.46-3.88-2.69-9.02-4.41-15.71-4.41-19.7,0-27.1,11.86-28.72,22.8-1.61,10.94-.33,18.06,7.42,18.06h6.23c-7.5,0-8.75-6.88-7.18-17.45Z' />
          <path className='cls-2' style={{ fill: 'url(#_Áåçûìÿííûé_ãðàäèåíò_183-2)' }}
                d='M95.45,39.42c0-.05,0-.11,0-.16-.05-9.25-7.63-16.7-16.89-16.7h-11.62c-10.33,0-18.11,10.95-15.57,19.76,2.54,8.8,13.2,7.51,22.18,7.51h11.57c5.68,0,10.32-4.59,10.33-10.28,0-.05,0-.09,0-.14Z' />
          <path className='cls-4' style={{ fill: '#daf2f0' }}
                d='M102.03,80.56v-13.91s1.25-9.12-13.24-9.12-25.3,.72-33.2,2.09-9.19,8.37-9.19,14.09c0,1.93,.02,4.28,.04,6.85h55.59Z' />
          <path className='cls-1' style={{ fill: '#fff' }}
                d='M53.64,80.56c-.02-2.57-.04-4.93-.04-6.85,0-5.72,1.3-12.72,9.19-14.09,6.84-1.18,15.87-1.88,27.58-2.05-.5-.03-1.03-.04-1.58-.04-14.49,0-25.3,.72-33.2,2.09-7.89,1.37-9.19,8.37-9.19,14.09,0,1.93,.02,4.28,.04,6.85h7.19Z' />
          <path className='cls-4' style={{ fill: '#daf2f0' }}
                d='M111.53,80.44h5.45c-.17-.61-.36-1.7-.57-2.32-3.09-9.1-10.23-18.92-21.75-19.83l1.13,7.51s10.15,2.51,15.74,14.64Z' />
          <path className='cls-1' style={{ fill: '#fff' }}
                d='M40.61,80.44h-5.45c.17-.61,.36-1.7,.57-2.32,3.09-9.1,10.23-18.92,21.75-19.83l10.9-.15-9.93,1.97-4.65,5.69s-7.6,2.51-13.19,14.64Z' />
          <path className='cls-4' style={{ fill: '#daf2f0' }}
                d='M90.93,30.85c-.72,.4-1.23,1-1.67,1.53-.69,.82-1.23,1.47-2.35,1.47s-1.66-.65-2.35-1.47c-.73-.87-1.64-1.96-3.4-1.96s-2.67,1.09-3.4,1.96c-.69,.82-1.23,1.47-2.35,1.47s-1.66-.65-2.35-1.47c-.73-.87-1.64-1.96-3.4-1.96s-2.66,1.09-3.39,1.96c-.69,.82-1.23,1.47-2.34,1.47s-1.66-.65-2.35-1.47c-.73-.87-1.64-1.96-3.4-1.96s-2.66,1.09-3.4,1.96c-.36,.43-.68,.81-1.06,1.07-.05,.51-.09,1.04-.13,1.58,1.04-.34,1.69-1.12,2.24-1.78,.69-.82,1.23-1.47,2.34-1.47s1.66,.65,2.35,1.47c.73,.87,1.64,1.96,3.4,1.96s2.66-1.09,3.4-1.96c.69-.82,1.23-1.47,2.34-1.47s1.66,.65,2.35,1.47c.73,.87,1.64,1.96,3.4,1.96s2.67-1.09,3.4-1.96c.69-.82,1.23-1.47,2.35-1.47s1.66,.65,2.35,1.47c.73,.87,1.64,1.96,3.4,1.96s2.67-1.09,3.4-1.96c.39-.46,.73-.86,1.15-1.13-.13-.45-.3-.88-.53-1.28Z' />
          <path className='cls-4' style={{ fill: '#daf2f0' }}
                d='M89.71,41.62c-.2,.06-.42,.1-.68,.1-1.03,0-1.54-.56-2.29-1.46-.72-.87-1.61-1.96-3.35-1.96s-2.63,1.09-3.35,1.96c-.75,.91-1.26,1.46-2.29,1.46s-1.54-.56-2.29-1.46c-.72-.87-1.61-1.96-3.35-1.96s-2.63,1.09-3.34,1.96c-.75,.91-1.25,1.46-2.29,1.46s-1.54-.56-2.29-1.46c-.72-.87-1.61-1.96-3.35-1.96s-2.63,1.09-3.35,1.96c-.75,.91-1.25,1.46-2.29,1.46-.72,0-1.19-.28-1.66-.76,.04,.61,.1,1.22,.18,1.8,.42,.2,.9,.32,1.49,.32,1.73,0,2.63-1.09,3.35-1.96,.75-.91,1.25-1.46,2.29-1.46s1.54,.56,2.29,1.46c.72,.87,1.61,1.96,3.35,1.96s2.63-1.09,3.34-1.96c.75-.91,1.25-1.46,2.29-1.46s1.54,.56,2.29,1.46c.72,.87,1.61,1.96,3.35,1.96s2.63-1.09,3.35-1.96c.75-.91,1.26-1.46,2.29-1.46s1.54,.56,2.29,1.46c.67,.81,1.48,1.8,2.98,1.94,.4-.46,.74-.94,1.05-1.45Z' />
        </g>
      </g>
    </g>
  </svg>
);

export const FallbackReadBookImage: any = (props: any) => (
  <svg viewBox='0 0 208.91 92.75' {...props}>
    <g id='_Ñëîé_1' data-name='Ñëîé 1'>
      <g>
        <g>
          <path className='cls-26' style={{ fill: '#c7efeb' }}
                d='M118.9,3.84c.8,.45,1.08,1.45,.67,2.26,3,2.64,9.77,10.46,2.11,22.37l-1.57-.89c7.38-11.47,1.51-18.4-1.25-20.76-.5,.27-1.12,.28-1.64-.02-.82-.46-1.1-1.5-.64-2.32,.46-.82,1.5-1.1,2.32-.64Z' />
          <g>
            <path className='cls-17' style={{ fill: '#00ccd3' }}
                  d='M8.42,73.17c-.04-1.63,.2-9.47,8.02-12.79,7.81-3.31,14.01-1.04,16.76,.41-2.1-2.94-4.84-8.57-2.36-15.85,3.58-10.49,14.32-9.66,16.42-9.38,.09-.86,.45-3.11,2.01-5.6,1.79-2.85,5.55-6.53,13.34-7.9,8.69-1.52,14.4,.72,17.67,2.86,2.88,1.9,4.39,4.01,4.89,4.8,1.64-1.16,9.86-6.33,18.82-1.38,1.7,.94,3.08,2.02,4.19,3.17,1.58-.95,3.43-1.49,5.41-1.49,2.45,0,4.7,.84,6.49,2.23,.76-1.02,1.84-2.03,3.39-2.87,5.15-2.81,9.95-.87,11.03-.37,.81-.8,4.39-3.87,9.56-1.99,4.84,1.76,5.57,6.09,5.67,7.6,.99-.47,10.91-2.52,16.62,5.01,5.73,7.55,3.35,15.11,2.09,16.94,2.59,.78,4.34,2.16,5.22,4.12,1.48,3.32,0,7.26-.57,8.55,11.94,1.28,12.08,11.89,12.08,12,0,.18-.15,.32-.32,.32H.68c-.18,0-.32-.14-.32-.32,0-6.22,6.58-7.81,8.06-8.09Z' />
            <path className='cls-14' style={{ fill: '#58f4e9' }}
                  d='M8.42,73.17c-.04-1.54,.17-8.62,6.79-12.2,2.56-1.57,7.27-3.95,12.99-4.48,0,0-2.38-7.75,5.29-16.3,4.64-5.45,12.08-4.85,13.78-4.63,.09-.86,.45-3.11,2.01-5.6,1.79-2.85,5.55-6.53,13.34-7.9,8.69-1.52,14.4,.72,17.67,2.86,2.88,1.9,4.39,4.01,4.89,4.8,1.64-1.16,9.86-6.33,18.82-1.38,4.03,2.23,6.24,5.25,7.44,8.06,0,0-5.5-3.34-16.71,1.55-10.32,4.5-9.86,11.97-9.86,11.97,0,0-9.9-4.26-19.38,1.7-9.49,5.97-5.8,14.59-5.8,14.59,0,0-4.69-6.49-15.26-5.29-7.7,.88-10.54,5.29-12.15,7.36,0,0-11.62-2.2-15.44,5.09,0,0-9.77-.77-10.7,8.19H.68c-.18,0-.32-.15-.32-.32,0-6.22,6.58-7.81,8.06-8.09Z' />
            <path className='cls-25' style={{ fill: '#00e5e2' }}
                  d='M170.81,81c-.03-1.25-.25-2.61-.74-4.04-2.7-7.92-10.8-7.28-12.39-7.08-.06-.65-.34-2.35-1.52-4.22-1.35-2.15-4.19-4.93-10.06-5.96-6.55-1.15-10.86,.54-13.33,2.16-2.17,1.43-3.31,3.02-3.69,3.62-1.24-.87-7.44-4.77-14.19-1.04-3.04,1.68-4.71,3.96-5.61,6.08-10.27,.94-9.58,8.16-9.55,10.48h71.07Z' />
            <path className='cls-15' style={{ fill: '#f0f0f0' }}
                  d='M.04,80.91s-.81,.81,4.92,1.69c6.73,1.04,18.3,.26,19.9,2.18,1.75,2.1-6.37,1.5-6.22,3.95,.13,2.08,8.89,2.01,18.77,2.01h92.77c8,0,6.55-1.81,5.42-2.64-1.64-1.2-.92-2.31,6.28-2.46,6.99-.15,12.11-.28,15.92-.28,10.17,0,8.18-2.51,16.1-2.51,5.12,0,11.29-.96,11.29-2.08-26.01-2.31-55.03-3.9-86.62-4.05-36.32-.18-69.38,1.56-98.53,4.19Z' />
          </g>
          <g>
            <path className='cls-16' style={{ fill: '#b5b5b4' }}
                  d='M30.04,83.01s11.09,1.01,27.26,.71c16.17-.3,22.1-.71,22.1-.71,0,0-19.52-1.1-27.7-1.43-13.5-.55-21.66,1.43-21.66,1.43Z' />
            <path className='cls-21' style={{ fill: '#ba2b1a' }}
                  d='M62.96,82.81h1.29s1.5-10.23-2.09-18.5l-2.79,9.36s3.03,3.44,3.59,9.14Z' />
            <path className='cls-21' style={{ fill: '#ba2b1a' }}
                  d='M45.07,64.53s-4.5,8.9-2.19,18.28h.95s.78-5.78,3.7-8.56l-2.47-9.72Z' />
            <path className='cls-24' style={{ fill: '#ee2e1b' }}
                  d='M46.74,74.22s1.55,2.39,6.43,2.39,6.57-2.4,6.57-2.4c0,0,4-7.55,3.08-19.06-.75-9.35-6.81-19.31-9.06-22.73-.37-.56-1.2-.5-1.48,.11-1.84,3.91-7.05,15.55-7.79,23.3-.9,9.44,2.26,18.39,2.26,18.39Z' />
            <path className='cls-21' style={{ fill: '#ba2b1a' }}
                  d='M62.82,55.16c-.75-9.35-6.81-19.31-9.06-22.73-.11-.17-.27-.28-.44-.34,2.45,6.79,4.86,15.31,5.34,23.52,.48,8.23-.23,15.14-1.12,20.19,1.58-.69,2.21-1.58,2.21-1.58,0,0,4-7.55,3.08-19.06Z' />
            <g>
              <path className='cls-22' style={{ fill: '#ffdf00' }}
                    d='M49.07,58.72c-.02,.56-.49,1-1.05,.98-.56-.02-1-.49-.98-1.05,.02-.56,.49-1,1.05-.98,.56,.02,1,.49,.98,1.05Z' />
              <path className='cls-22' style={{ fill: '#ffdf00' }}
                    d='M49.47,53.28c-.02,.44-.38,.78-.82,.76s-.78-.38-.76-.82c.02-.44,.38-.78,.82-.76,.44,.02,.78,.38,.76,.82Z' />
              <path className='cls-22' style={{ fill: '#ffdf00' }}
                    d='M50.09,48.48c-.01,.34-.3,.6-.64,.59-.34-.01-.6-.3-.59-.64s.3-.6,.64-.59,.6,.3,.59,.64Z' />
              <path className='cls-22' style={{ fill: '#ffdf00' }}
                    d='M50.84,44.29c0,.25-.22,.44-.47,.44-.25,0-.44-.22-.44-.47,0-.25,.22-.44,.47-.44,.25,0,.44,.22,.44,.47Z' />
            </g>
            <path className='cls-22' style={{ fill: '#ffdf00' }}
                  d='M46.97,70.6s-.14-2.93-.14-4.23-.02-3.73,1.15-3.73,1.25,2.3,1.25,3.72-.02,4.24-.02,4.24h-2.25Z' />
            <polygon className='cls-18' style={{ fill: '#9b1609' }}
                     points='49.1 70.6 47.08 70.6 46.01 70.6 46.01 72.62 43.99 72.62 43.99 74.63 41.97 74.63 41.97 76.65 39.95 76.65 39.95 78.7 37.91 78.7 37.91 80.72 35.89 80.72 35.89 82.81 36.76 82.81 49.22 70.6 49.1 70.6' />
            <path className='cls-21' style={{ fill: '#ba2b1a' }}
                  d='M53.38,64.35l-1.09,12.17,2.96,6.29h1.07s.9-10.12-2.26-18.5l-.68,.04Z' />
          </g>
          <path className='cls-20' style={{ fill: '#cececc' }}
                d='M176.29,89.37c0,1.86-19.34,3.37-43.2,3.37s-43.2-1.51-43.2-3.37,19.34-3.37,43.2-3.37,43.2,1.51,43.2,3.37Z' />
          <path className='cls-1' style={{ fill: '#4b7173', fillRule: 'evenodd' }}
                d='M156.1,90.46h-27.36s-3.46-.99-2.91-4.76c.55-3.77,4.05-3.39,8.17-2.73,4.13,.66,20.62,2.39,20.62,2.39l1.47,5.1Z' />
          <path className='cls-10' style={{ fill: '#abe1dd', fillRule: 'evenodd' }}
                d='M149.46,61.44l-43.7-10.46s-4.66-1.37-1.77-9.6c2.89-8.23,6.63-18.97,10.58-22.02,3.95-3.05,14.4-.94,19.27,.19,4.87,1.13,15.38,3.65,20.06,5.26,3.98,1.37,6.17,4.68,4.45,10.58-1.72,5.91-8.88,26.05-8.88,26.05Z' />
          <path className='cls-12' style={{ fill: '#c7efeb', fillRule: 'evenodd' }}
                d='M114.5,53.07s-4.74-1.39-1.8-9.76c2.93-8.37,3.78-17.4,8.36-21.42,2.76-2.43,8.53-2.77,12.55-2.39-4.96-1.14-15.15-3.14-19.04-.14-3.95,3.05-7.69,13.79-10.58,22.02-2.89,8.23,1.77,9.6,1.77,9.6l43.7,10.46s0,0,0,0l-34.96-8.37Z' />
          <path className='cls-6' style={{ fill: '#eff', fillRule: 'evenodd' }}
                d='M121.22,29.39c-1.19,3.99-3.7,12.83-4.23,15.91s.57,5.73,4.23,6.77c2.6,.74,15.86,2.99,23.31,4.23,2.92,.49,5.74-1.27,6.59-4.1,1.61-5.36,4.02-13.4,4.58-15.25,.85-2.84,1.56-6.33-3.66-8.03s-17.21-4.32-21.9-5.19c-4.68-.86-7.54,1.03-8.91,5.66Z' />
          <path className='cls-4' style={{ fill: '#84bfb8', fillRule: 'evenodd' }}
                d='M152.96,29.27c3.39,1.85,2.75,4.84,2,7.33-.56,1.85-2.97,9.89-4.58,15.25-.85,2.83-3.67,4.59-6.59,4.1-.21-.03-.42-.07-.64-.11,0,.08,.02,.15,.03,.23,.46,.08,.9,.15,1.33,.22,2.92,.49,5.74-1.27,6.59-4.1,1.61-5.36,4.02-13.4,4.58-15.25,.8-2.65,1.47-5.88-2.72-7.68Z' />
          <path className='cls-10' style={{ fill: '#abe1dd', fillRule: 'evenodd' }}
                d='M106.66,51.1l45.2,10.04-6.85,26.16c-.47,1.78-2.08,3.03-3.92,3.03h-37.35c-4.31,0-7.61-3.81-7.01-8.08l3.84-26.94c.41-2.91,3.22-4.85,6.09-4.22Z' />
          <path className='cls-8' style={{ fill: '#80ada8', fillRule: 'evenodd' }}
                d='M144.08,81.53c-.45-.01-.91-.04-1.38-.05-.56,.53-1.09,1.09-1.65,1.62-.78,.74-1.54,1.49-2.25,2.3-.24,.36-.45,.74-.65,1.12-.45,1.25-.8,2.53-1.15,3.81h4.08c1.84,0,3.46-1.24,3.92-3.03l1.47-5.63c-.8-.07-1.6-.11-2.4-.13Z' />
          <path className='cls-12' style={{ fill: '#c7efeb', fillRule: 'evenodd' }}
                d='M108.96,83.39l4-28.06c.14-.99,.55-1.86,1.13-2.58l-7.43-1.65c-2.87-.64-5.68,1.3-6.09,4.22l-3.84,26.94c-.61,4.26,2.7,8.08,7.01,8.08h8.1c-2.06-1.55-3.27-4.15-2.88-6.94Z' />
          <path className='cls-1' style={{ fill: '#4b7173', fillRule: 'evenodd' }}
                d='M135.97,90.46h-27.36s-3.46-.99-2.91-4.76c.55-3.77,4.05-3.39,8.17-2.73,4.13,.66,20.62,2.39,20.62,2.39l1.47,5.1Z' />
          <polygon className='cls-11' style={{ fill: '#bfbba9', fillRule: 'evenodd' }}
                   points='175.72 45.67 160.69 53.76 149.46 83.86 161.57 80.57 175.72 45.67' />
          <polygon className='cls-9' style={{ fill: '#ada999', fillRule: 'evenodd' }}
                   points='177.77 47.38 160.69 54.19 149.15 83.98 164.41 79.96 177.77 47.38' />
          <polygon className='cls-11' style={{ fill: '#bfbba9', fillRule: 'evenodd' }}
                   points='137.44 43.83 160.69 54.19 149.86 83.98 127.9 75.2 137.44 43.83' />
          <polygon className='cls-2' style={{ fill: '#ffd008', fillRule: 'evenodd' }}
                   points='134.7 45.77 123.28 77.37 146.77 84.88 158.59 54.01 134.7 45.77' />
          <polygon className='cls-7' style={{ fill: '#f2c403', fillRule: 'evenodd' }}
                   points='163.32 54.23 150.88 85.08 165.88 80.9 179.12 49.89 163.32 54.23' />
          <polygon className='cls-3' style={{ fill: '#ffa20d', fillRule: 'evenodd' }}
                   points='158.59 54.01 163.32 54.23 150.88 85.08 146.77 84.88 158.59 54.01' />
          <path className='cls-1' style={{ fill: '#4b7173', fillRule: 'evenodd' }}
                d='M130.25,58.08l-1.16,3.2,3.04,.89c.74,.22,1.2,.96,1.05,1.72h0c-.15,.75-.86,1.15-1.63,1.08l-3.65-.12,.21,.27,3,.48c.82,.22,1.25,.98,1.01,1.79h0c-.2,.67-.83,1-1.53,.96l-3.54-.09,.31,.24,2.17,.37c.64,.16,.98,.7,.83,1.34h0c-.12,.56-.62,.96-1.19,.96-.95,0-2.41-.04-3.56-.25-1.86-.33-4.1-2.81-2.83-6.86,1.27-4.05,3.29-6.29,7.48-6Z' />
          <path className='cls-1' style={{ fill: '#4b7173', fillRule: 'evenodd' }}
                d='M175.95,64.36h0s.22-2.63-.84-5.1l-1.27,2.81-6.06,.72c-.49,.06-.85,.47-.85,.96s.39,.93,.9,.96l2.87,.2-.27,.17-3.35,.59c-.63,.1-1.2,.47-1.2,1.12,0,.72,.58,1.3,1.3,1.31l2.72,.02-.16,.24-1.98,.61c-.56,.18-.98,.59-.93,1.18,.06,.62,.57,1.11,1.2,1.13,1.88,.08,5.66-.07,7.03-2.43,.82-1.41,1.11-3.13,.89-4.49Z' />
          <path className='cls-13' style={{ fill: '#4b7173' }}
                d='M109.23,58.48s-.12,.21-.18,.32l-.2,.4c-.13,.27-.25,.55-.36,.83-.22,.56-.41,1.14-.56,1.7-.3,1.14-.42,2.27-.31,3.28,.11,1.01,.45,1.87,1.06,2.61,.6,.74,1.5,1.37,2.57,1.84,1.06,.47,2.11,.7,3.2,.69,1.09-.02,2.2-.3,3.28-.82,1.08-.51,2.12-1.22,3.08-2.07,.48-.42,.94-.88,1.38-1.36,.22-.24,.43-.48,.63-.73,.2-.24,.42-.53,.55-.72l.05-.07c.57-.81,1.69-.99,2.5-.42,.77,.55,.98,1.61,.48,2.4-.25,.4-.45,.69-.69,1.02-.23,.32-.47,.62-.71,.93-.49,.6-1.01,1.18-1.57,1.74-1.11,1.11-2.38,2.11-3.84,2.91-1.45,.8-3.13,1.36-4.89,1.51-1.76,.15-3.58-.16-5.16-.76-1.56-.58-3.17-1.48-4.47-2.87-1.31-1.38-2.17-3.24-2.48-5.05-.32-1.82-.2-3.58,.12-5.21,.16-.82,.37-1.62,.63-2.4,.13-.39,.27-.78,.42-1.16l.24-.58c.09-.21,.16-.36,.3-.64l.1-.19c.71-1.38,2.4-1.93,3.78-1.22,1.38,.71,1.93,2.4,1.22,3.78-.05,.11-.12,.22-.19,.31Z' />
          <path className='cls-1' style={{ fill: '#4b7173', fillRule: 'evenodd' }}
                d='M144.43,72.34s-5.81-2.7-8.62,5.37c-1.39,3.99-2.19,7.15-2.63,9.32-.36,1.77,.98,3.43,2.79,3.43h.62l1.94-3.47,5.9-14.65Z' />
          <path className='cls-5' style={{ fill: '#406363', fillRule: 'evenodd' }}
                d='M136.22,90.46h1.72c.99,0,1.9-.55,2.35-1.44,2.07-4.05,7.48-15.34,4.14-16.68-3.34-1.34-7.91,11.62-9.47,16.39-.28,.85,.36,1.72,1.26,1.72Z' />
          <path className='cls-1' style={{ fill: '#4b7173', fillRule: 'evenodd' }}
                d='M163.92,72.34s-5.81-2.7-8.62,5.37c-1.39,3.99-2.19,7.15-2.63,9.32-.36,1.77,.98,3.43,2.79,3.43h.62l1.94-3.47,5.9-14.65Z' />
          <path className='cls-5' style={{ fill: '#406363', fillRule: 'evenodd' }}
                d='M155.71,90.46h1.72c.99,0,1.9-.55,2.35-1.44,2.07-4.05,7.48-15.34,4.14-16.68-3.34-1.34-7.91,11.62-9.47,16.39-.28,.85,.36,1.72,1.26,1.72Z' />
          <g>
            <path className='cls-1' style={{ fill: '#4b7173', fillRule: 'evenodd' }}
                  d='M131.6,36.94c-.38,.95-.84,1.65-1.39,1.43-.55-.22-.69-1.17-.3-2.12,.38-.95,1.14-1.53,1.7-1.31,.55,.22,.38,1.05,0,1.99Z' />
            <path className='cls-1' style={{ fill: '#4b7173', fillRule: 'evenodd' }}
                  d='M145.66,41.68c.45-.92,.95-1.59,1.49-1.33,.53,.26,.6,1.22,.15,2.13-.45,.92-1.25,1.45-1.78,1.18s-.31-1.07,.14-1.99Z' />
          </g>
          <path className='cls-19' style={{ fill: '#305660' }}
                d='M131.58,48.62c-.11,0-.22-.03-.32-.1-1.33-.88-2.13-1.76-2.16-1.8-.22-.24-.2-.61,.04-.83,.24-.22,.61-.2,.83,.04,.01,.01,.74,.81,1.94,1.61,.27,.18,.35,.55,.17,.82-.11,.17-.3,.26-.49,.26Z' />
        </g>
      </g>
    </g>
  </svg>
);

export const FallbackPartyImage: any = (props: any) => (
  <svg viewBox='0 0 204.79 97.05' {...props}>
    <g id='_Ñëîé_1' data-name='Ñëîé 1'>
      <g>
        <g>
          <path className='cls-5' style={{ fill: '#00ccd3' }}
                d='M9.31,77.62c-.04-1.8,.22-10.47,8.87-14.15,8.63-3.66,15.49-1.15,18.54,.45-2.32-3.25-5.35-9.47-2.61-17.52,3.96-11.61,15.84-10.68,18.16-10.38,.09-.95,.5-3.44,2.22-6.19,1.98-3.15,6.14-7.22,14.75-8.73,9.61-1.69,15.93,.79,19.54,3.17,3.19,2.1,4.85,4.43,5.41,5.31,1.82-1.28,10.9-7,20.81-1.52,1.88,1.04,3.41,2.24,4.64,3.51,1.75-1.05,3.8-1.65,5.99-1.65,2.71,0,5.2,.92,7.18,2.47,.84-1.13,2.04-2.24,3.75-3.17,5.69-3.1,11-.97,12.2-.41,.9-.88,4.86-4.28,10.57-2.21,5.36,1.95,6.16,6.74,6.27,8.41,1.1-.52,12.07-2.79,18.38,5.54,6.34,8.35,3.7,16.71,2.32,18.74,2.86,.86,4.8,2.39,5.77,4.56,1.64,3.68,0,8.03-.63,9.46,13.2,1.42,13.36,13.15,13.36,13.27,0,.2-.16,.36-.36,.36H.75c-.2,0-.36-.16-.36-.36,0-6.88,7.28-8.64,8.92-8.94Z' />
          <path className='cls-4' style={{ fill: '#00f3e8' }}
                d='M9.31,77.62c-.04-1.7,.19-9.53,7.51-13.49,2.83-1.74,8.04-4.37,14.36-4.96,0,0-2.64-8.57,5.85-18.03,5.13-6.03,13.35-5.37,15.24-5.12,.09-.95,.5-3.44,2.22-6.19,1.98-3.15,6.14-7.22,14.75-8.73,9.61-1.69,15.93,.79,19.54,3.17,3.19,2.1,4.85,4.43,5.41,5.31,1.82-1.28,10.9-7,20.81-1.52,4.46,2.47,6.9,5.8,8.22,8.91,0,0-6.08-3.7-18.48,1.71-11.41,4.98-10.9,13.24-10.9,13.24,0,0-10.94-4.71-21.44,1.89-10.49,6.6-6.42,16.13-6.42,16.13,0,0-5.18-7.18-16.87-5.85-8.51,.97-11.65,5.85-13.44,8.14,0,0-12.85-2.43-17.07,5.63,0,0-10.81-.85-11.83,9.06H.75c-.2,0-.36-.16-.36-.36,0-6.88,7.28-8.64,8.92-8.94Z' />
          <path className='cls-16' style={{ fill: '#00e5e2' }}
                d='M188.9,86.28c-.03-1.39-.27-2.88-.82-4.47-2.98-8.75-11.95-8.05-13.7-7.83-.07-.72-.38-2.6-1.68-4.67-1.49-2.38-4.63-5.45-11.13-6.59-7.25-1.27-12.01,.6-14.74,2.39-2.4,1.58-3.66,3.34-4.08,4-1.37-.96-8.22-5.28-15.7-1.15-3.37,1.86-5.21,4.38-6.2,6.72-11.35,1.04-10.6,9.03-10.56,11.59h78.59Z' />
          <path className='cls-15' style={{ fill: '#efefef' }}
                d='M.04,86.18s-.9,.89,5.44,1.87c7.44,1.15,20.24,.29,22.01,2.41,1.93,2.32-7.04,1.66-6.88,4.37,.14,2.3,9.83,2.22,20.76,2.22h102.59c8.85,0,7.25-2.01,5.99-2.92-1.82-1.33-1.02-2.55,6.94-2.72,7.73-.16,13.39-.31,17.61-.31,11.25,0,9.04-2.78,17.8-2.78,5.67,0,12.48-1.07,12.48-2.3-28.77-2.56-60.86-4.31-95.79-4.48-40.16-.2-76.73,1.72-108.96,4.64Z' />
        </g>
        <g>
          <ellipse className='cls-11' style={{ fill: '#cececc' }} cx='105.85' cy='88.03' rx='54.49' ry='2.84' />
          <path className='cls-14' style={{ fill: '#93cecc' }}
                d='M62.09,60.63l-4.35,2.27c-1.66,.86-2.74,2.53-2.85,4.4l-1.05,17.65c-.11,1.83,1.34,3.37,3.17,3.38h10.27s-5.2-27.69-5.2-27.69Z' />
          <path className='cls-13' style={{ fill: '#bcedef' }}
                d='M78.56,88.31h-13.59c-2.88,0-5.2-2.35-5.16-5.23l.25-19.03c.03-2.03,1.57-3.72,3.6-3.92,2.18-.21,4.5-.37,6.93-.44,2.38-.07,4.64-.06,6.78,.02,1.94,.07,3.54,1.51,3.81,3.43,.4,2.93,.75,5.97,1.03,9.13,.36,4,.59,7.85,.71,11.53,.08,2.47-1.89,4.51-4.36,4.51Z' />
          <path className='cls-10' style={{ fill: '#6a8286', fillRule: 'evenodd' }}
                d='M138.88,15.13s3.07,11.37-6.77,17.09l2.86,3.62s9.77-5.98,7.18-20.14l-.39-2.19-2.87,1.62Z' />
          <path className='cls-10' style={{ fill: '#6a8286', fillRule: 'evenodd' }}
                d='M140.37,16.02s-2.77,.79-6.41-.85-2.33-5.19,.39-3.25,3.69-.93,2.53-2.59c-1.15-1.66-3.92-4.53-2.49-5.75s4.26,2.7,4.84,4.58c0,0-.38-2.02-1.25-3.16,0,0,2.1-1.01,3.01,1.17s.92,3.44,.92,3.44c0,0,.21-1.45,.01-2.47,0,0,2.17,.21,2.38,3.2s-.96,4.79-3.94,5.68Z' />
          <path className='cls-10' style={{ fill: '#6a8286', fillRule: 'evenodd' }}
                d='M78.44,38.41s1.86-2.19,1.89-6.18c.03-3.99-3.75-4.28-3.12-1,.64,3.28-2.38,2.97-3.41,1.23-1.03-1.74-2.48-5.45-4.19-4.66-1.71,.79,.69,5,2.15,6.31,0,0-1.68-1.18-2.35-2.45,0,0-1.79,1.49-.19,3.22,1.6,1.73,2.74,2.27,2.74,2.27,0,0-1.41-.41-2.25-1.01,0,0-.71,2.06,1.92,3.49,2.63,1.43,4.76,1.12,6.81-1.21Z' />
          <path className='cls-10' style={{ fill: '#6a8286', fillRule: 'evenodd' }}
                d='M78.08,35.67s6.85,12.55,21.41,12.54l1.35,5.62s-14.67,1.29-24.89-15.93l2.13-2.23Z' />
          <path className='cls-8' style={{ fill: '#bcedef', fillRule: 'evenodd' }}
                d='M104.14,.02c.63-.11,1.23,.18,1.55,.69,7.77-2.82,8.8,5.43,8.8,5.43l-1.07,.32c-1.23-6.87-5.86-5.74-7.51-5.1,.08,.78-.44,1.49-1.22,1.63-.82,.15-1.61-.4-1.75-1.22-.15-.82,.4-1.61,1.22-1.75Z' />
          <path className='cls-10' style={{ fill: '#6a8286', fillRule: 'evenodd' }}
                d='M102.67,62.72c-4.5,10.91,.83,21.14,.83,21.14-4-.07-5.63,1.37-6.87,2.66-.7,.73-.17,1.95,.84,1.95h10.58c-5.16-13.68,.17-22.17,.17-22.17l-5.56-3.58Z' />
          <path className='cls-10' style={{ fill: '#6a8286', fillRule: 'evenodd' }}
                d='M140.57,58.7c4.46,11.14,3.01,24.71,3.01,24.71,3.96-.07,6.24,1.34,7.48,2.62,.72,.74,.18,1.99-.86,1.99h-10.52c2.3-15.86-5.96-25.75-5.96-25.75l6.86-3.58Z' />
          <path className='cls-9' style={{ fill: '#bce1dd', fillRule: 'evenodd' }}
                d='M97.05,46.57s-3.65,1.31-6.23-3.16c-2.58-4.47-6.89-11.53-6.24-16.3,.65-4.77,3.78-8.95,9.59-12.41,5.81-3.47,20.49-10.67,26.31-9.83,6.31,.91,10.4,10.31,12.34,14.53,1.94,4.22,2.98,8.62-.59,11.09s-26.64,12.32-35.18,16.08Z' />
          <path className='cls-8' style={{ fill: '#bcedef', fillRule: 'evenodd' }}
                d='M97.05,46.57s-3.65,1.31-6.23-3.16c-2.58-4.47-6.89-11.53-6.24-16.3,.65-4.77,3.78-8.95,9.59-12.41,5.81-3.47,20.49-10.67,26.31-9.83,6.31,.91,10.4,10.31,12.34,14.53,1.94,4.22,2.98,8.62-.59,11.09s-26.64,12.32-35.18,16.08Z' />
          <path className='cls-6' style={{ fill: '#d7f8f8', fillRule: 'evenodd' }}
                d='M132.82,19.4c-1.94-4.22-6.03-13.62-12.34-14.53-3.83-.55-11.5,2.38-17.95,5.44,5.2-2.3,10.79-4.16,13.85-3.55,5.81,1.14,9.92,10.53,11.85,14.76,1.94,4.22,3.06,8.58-.11,10.87-.98,.71-3.63,2.03-7.06,3.63,5.35-2.43,9.76-4.54,11.16-5.51,3.57-2.48,2.53-6.87,.59-11.09Z' />
          <path className='cls-8' style={{ fill: '#bcedef', fillRule: 'evenodd' }}
                d='M103.98,43.39s-3.12,1.06-5.63-3.44-6.69-11.62-6.39-16.23c.3-4.6,2.8-8.49,7.68-11.53,1.53-.95,3.79-2.21,6.34-3.45-4.65,2.02-9.15,4.37-11.8,5.95-5.82,3.47-8.94,7.65-9.59,12.41-.65,4.77,3.66,11.82,6.24,16.3,2.58,4.47,6.23,3.16,6.23,3.16,5.91-2.59,18.75-8.1,27.3-12.08-7.03,3.18-15.97,6.98-20.38,8.9Z' />
          <path className='cls-7' style={{ fill: '#eaf3f2', fillRule: 'evenodd' }}
                d='M90.06,35c2.66,5.65,5.23,11.21,10.02,9.02,4.79-2.2,23.2-10.81,28.15-14.37,4.95-3.56,1.11-9.65-.9-14.03s-4.88-8.47-13.09-6.15c-7.39,2.09-15.49,5.43-21.28,9.76-3.88,2.9-6.06,9.05-2.89,15.77Z' />
          <path className='cls-7' style={{ fill: '#eaf3f2', fillRule: 'evenodd' }}
                d='M94.79,34.29c-2.55-4.96-2.62-10.62-.91-15.73-.31,.22-.63,.44-.93,.67-3.88,2.9-6.06,9.05-2.89,15.77,2.66,5.65,5.23,11.21,10.02,9.02,.57-.26,1.34-.62,2.25-1.04-3.09-2.18-5.71-5.13-7.53-8.68Z' />
          <path className='cls-2' style={{ fill: '#a5e0e0', fillRule: 'evenodd' }}
                d='M127.32,15.61c-1.69-3.7-4-7.18-9.6-6.79,5,0,7.17,3.31,8.78,6.82,2.01,4.38,5.85,10.47,.9,14.03-4.95,3.56-23.36,12.17-28.15,14.37-.53,.24-1.03,.39-1.51,.45,.72,.05,1.49-.09,2.33-.48,4.79-2.2,23.2-10.81,28.15-14.37,4.95-3.56,1.11-9.65-.9-14.03Z' />
          <path className='cls-6' style={{ fill: '#d7f8f8', fillRule: 'evenodd' }}
                d='M135.53,66.4l-24.78,6.39c-5.31,1.37-10.72-1.82-12.09-7.13l-2.66-13.65c-1.07-2.33-.05-5.08,2.28-6.14l31.66-14.52c2.33-1.07,5.08-.05,6.14,2.28l5.62,22.32c1.18,4.59-1.58,9.27-6.17,10.45Z' />
          <path className='cls-8' style={{ fill: '#bcedef', fillRule: 'evenodd' }}
                d='M132.74,35.16c-1.07-2.33-3.82-3.35-6.14-2.28l-24.98,11.46c-2.33,1.07-3.35,3.82-2.28,6.14l2.88,14.26c1.37,5.31,6.78,8.5,12.09,7.13l8.83-2.28,8.83-2.28c4.59-1.18,7.35-5.86,6.17-10.45l-5.4-21.7Z' />
          <g>
            <path className='cls-8' style={{ fill: '#bcedef', fillRule: 'evenodd' }}
                  d='M123.14,69.6l-8.83,2.28c-5.31,1.37-10.72-1.82-12.09-7.13l-2.88-14.26c-1.07-2.33-.05-5.08,2.28-6.14l9.34-4.29-12.68,5.82c-2.33,1.07-3.35,3.82-2.28,6.14l2.66,13.65c1.37,5.31,6.78,8.5,12.09,7.13l20.88-5.39-8.49,2.19Z' />
            <path className='cls-6' style={{ fill: '#d7f8f8', fillRule: 'evenodd' }}
                  d='M141.7,55.95l-5.62-22.32c-1.07-2.33-3.82-3.35-6.14-2.28l-3.34,1.53c2.33-1.07,5.08-.05,6.14,2.28l5.4,21.7c1.18,4.59-1.58,9.27-6.17,10.45l3.56-.92c4.59-1.18,7.35-5.86,6.17-10.45Z' />
          </g>
          <path className='cls-1' style={{ fill: '#a5e0e0' }}
                d='M125.64,41.08c.2,.44,.71,.63,1.15,.43,.44-.2,.63-.71,.43-1.15l-2.94-6.4-1.58,.72,2.94,6.4Z' />
          <path className='cls-10' style={{ fill: '#6a8286', fillRule: 'evenodd' }}
                d='M99.39,26.02c.43,.93,.49,1.82-.32,2.19-.8,.36-1.47-.24-1.9-1.17-.43-.93-.46-1.83,.35-2.2,.81-.37,1.44,.25,1.87,1.19Z' />
          <path className='cls-10' style={{ fill: '#6a8286', fillRule: 'evenodd' }}
                d='M114.97,18.87c.43,.93,.49,1.82-.32,2.19-.8,.36-1.47-.24-1.9-1.17s-.46-1.83,.35-2.2c.81-.37,1.44,.25,1.87,1.19Z' />
          <path className='cls-12' style={{ fill: '#6a8286' }}
                d='M109.39,30.82c-1.56,.65-3.42,.22-4.61-1.12-.3-.34-.26-.85,.07-1.15,.34-.3,.85-.26,1.15,.07,.78,.89,2.11,1.12,3.05,.56,.82-.49,1.26-1.48,1.1-2.48-.07-.44,.23-.86,.67-.93,.44-.07,.86,.23,.93,.67,.27,1.65-.49,3.31-1.87,4.14-.16,.1-.32,.18-.49,.25Z' />
          <path className='cls-17' style={{ fill: '#f2c403' }}
                d='M71.89,52.66h0s-1.12-5.51-1.84-7.27c0,0-3.25,1.72-6.17,2.55,0,0,1.1,4.23,1.41,5.79-.56-.09-1.18,.23-1.54,.84-.44,.75-.29,1.66,.33,2.01,.62,.36,1.47,.04,1.91-.72,.27-.48,.32-1.01,.16-1.43h0s-1.12-5.24-1.12-5.24c0,0,2.27-.09,4.58-1.71,0,0,.89,2.76,1.16,4.3-.5,.01-1.02,.32-1.33,.86-.44,.75-.29,1.66,.33,2.01,.62,.36,1.47,.04,1.91-.72,.24-.42,.3-.88,.2-1.27Z' />
          <path className='cls-17' style={{ fill: '#f2c403' }}
                d='M63.15,43.43h0s-1.12-5.51-1.84-7.27c0,0-3.25,1.72-6.17,2.55,0,0,1.1,4.23,1.41,5.79-.55-.09-1.18,.23-1.54,.84-.44,.75-.29,1.66,.33,2.01,.62,.36,1.47,.04,1.91-.72,.27-.48,.32-1.01,.16-1.43h0s-1.12-5.24-1.12-5.24c0,0,2.27-.09,4.58-1.71,0,0,.89,2.76,1.16,4.3-.5,.01-1.02,.32-1.33,.86-.44,.75-.29,1.66,.33,2.01,.62,.36,1.47,.04,1.91-.72,.24-.42,.3-.88,.2-1.27Z' />
          <path className='cls-17' style={{ fill: '#f2c403' }}
                d='M50.34,67.12s-.07,0-.1,0c-.35-.06-.6-.39-.54-.74,1.16-7.34,7.62-9.22,7.9-9.3,.34-.1,.7,.11,.8,.45,.1,.35-.11,.7-.45,.8-.25,.07-5.93,1.75-6.96,8.25-.05,.32-.33,.55-.64,.55Z' />
          <path className='cls-17' style={{ fill: '#f2c403' }}
                d='M48.55,62.9c-.05,0-.11,0-.17-.02-.35-.09-.56-.45-.46-.79,1.3-4.96,5.22-6.26,5.39-6.31,.34-.11,.71,.08,.82,.42,.11,.34-.08,.71-.42,.82-.14,.05-3.42,1.16-4.53,5.4-.08,.29-.34,.49-.63,.49Z' />
          <path className='cls-14' style={{ fill: '#93cecc' }}
                d='M77.99,78.52c0,3.55-2.87,6.42-6.42,6.42s-6.42-2.87-6.42-6.42,2.87-6.42,6.42-6.42,6.42,2.87,6.42,6.42Z' />
          <path className='cls-14' style={{ fill: '#93cecc' }}
                d='M72.91,66.02c0,1.61-1.31,2.92-2.92,2.92s-2.92-1.31-2.92-2.92,1.31-2.92,2.92-2.92,2.92,1.31,2.92,2.92Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M69.99,67.76c-.28,0-.51-.23-.51-.51s.23-.51,.51-.51c.4,0,.73-.33,.73-.73,0-.28,.23-.51,.51-.51s.51,.23,.51,.51c0,.96-.78,1.74-1.74,1.74Z' />
          <path className='cls-3' style={{ fill: '#fff' }}
                d='M71.57,83c-.37,0-.67-.3-.67-.67s.3-.67,.67-.67c1.73,0,3.13-1.41,3.13-3.13,0-.37,.3-.67,.67-.67s.67,.3,.67,.67c0,2.47-2.01,4.48-4.48,4.48Z' />
        </g>
      </g>
    </g>
  </svg>
);

export const FallbackSpaceshipDogImage: any = (props: any) => (
  <svg viewBox='0 0 175.55 92.23' {...props}>
    <defs>
      <linearGradient id='_Áåçûìÿííûé_ãðàäèåíò_183' data-name='Áåçûìÿííûé ãðàäèåíò 183' x1='.15' y1='76.27' x2='175.55'
                      y2='76.27' gradientUnits='userSpaceOnUse'>
        <stop offset='.24' stopColor='#6bdbc8' />
        <stop offset='1' stopColor='#66bbc3' />
      </linearGradient>
      <linearGradient id='_Áåçûìÿííûé_ãðàäèåíò_183-2' data-name='Áåçûìÿííûé ãðàäèåíò 183' y1='76.35' x2='68.16'
                      y2='76.35' xlinkHref='#_Áåçûìÿííûé_ãðàäèåíò_183' />
    </defs>
    <g id='_Ñëîé_1' data-name='Ñëîé 1'>
      <g>
        <ellipse style={{ fill: '#95f9f1' }} cx='87.77' cy='59.98' rx='87.77' ry='22.09' />
        <ellipse style={{ fill: '#5eaaa8' }} cx='83.04' cy='48.01' rx='59.49' ry='8.55' />
        <path style={{ fill: '#b5eeea' }}
              d='M39.6,14.27c.33,.18,.71,.11,.97-.13,1.53,.63,5.06,2.63,5.38,7.55,.34-.09,.69-.16,1.04-.21-.59-5.02-4.53-7.22-6.15-7.92,0-.31-.15-.6-.44-.76-.41-.22-.91-.07-1.13,.34-.22,.41-.07,.91,.34,1.13Z' />
        <path className='cls-5' style={{ fill: '#eff' }}
              d='M67.41,36.4c0,9.72-7.88,15.5-17.59,15.5s-17.59-5.79-17.59-15.5,7.88-17.59,17.59-17.59,17.59,7.88,17.59,17.59Z' />
        <path style={{ fill: '#b5eeea' }}
              d='M60.82,60.63l-5.48-15.84,4.72-.15,2.14-5.63-8.58-8.3-2.26-7.99h-.85l-1.74,4.98h-5.14l-1.84-4.43h-.98s-6.44,34.44-6.44,34.44c0,0,11.75,3.87,26.47,2.91Z' />
        <g>
          <polygon className='cls-8' style={{ fill: '#cff9f5' }}
                   points='51.84 24.48 51.35 22.73 50.5 22.73 48.76 27.71 50.71 27.71 51.84 24.48' />
          <path className='cls-8' style={{ fill: '#cff9f5' }}
                d='M36.31,57.73l6.14-32.82-.67-1.62h-.98s-6.44,34.44-6.44,34.44c0,0,9.79,3.22,22.69,3.05-11.92-.15-20.74-3.05-20.74-3.05Z' />
        </g>
        <path style={{ fill: '#b5eeea' }}
              d='M80.17,1.9c.39,.21,.86,.13,1.17-.16,1.85,.76,6.12,3.18,6.5,9.13,.42-.1,.84-.19,1.26-.26-.71-6.07-5.48-8.73-7.44-9.58,0-.37-.18-.73-.53-.92-.49-.27-1.11-.08-1.37,.41-.27,.49-.08,1.11,.41,1.37Z' />
        <path className='cls-14' style={{ fill: '#2f7274' }}
              d='M121.08,35.97c1.08,.05,1.91,.33,2.7,.65,.78,.33,1.48,.74,2.15,1.22,1.31,.94,2.45,2.15,3.27,3.61,.82,1.45,1.27,3.19,1.19,4.88-.03,.85-.19,1.67-.43,2.44-.11,.4-.26,.75-.41,1.14-.17,.4-.39,.8-.65,1.18-.51,.76-1.21,1.43-2.01,1.91-.8,.48-1.67,.76-2.53,.89-1.72,.25-3.35-.06-4.82-.65-1.47-.6-2.81-1.5-3.87-2.75-.53-.62-.98-1.34-1.3-2.14-.32-.8-.48-1.68-.46-2.56,.02-.81,.7-1.44,1.5-1.41,.78,.02,1.4,.66,1.42,1.43v.02c.01,.93,.44,1.85,1.16,2.62,.71,.77,1.69,1.38,2.72,1.74,1.03,.37,2.15,.5,3.09,.31,.95-.19,1.64-.66,2.08-1.38,.11-.18,.21-.38,.29-.6,.08-.24,.18-.51,.24-.76,.13-.51,.21-1.02,.2-1.52,0-1-.29-1.95-.82-2.81-.53-.86-1.31-1.63-2.2-2.21-.44-.29-.92-.54-1.38-.71-.23-.09-.46-.15-.67-.2-.21-.04-.4-.06-.53-.06-1.18,.03-2.16-.91-2.19-2.09s.91-2.16,2.09-2.19c.05,0,.09,0,.14,0h.03Z' />
        <path className='cls-14' style={{ fill: '#2f7274' }}
              d='M80.87,40.25c-.13,0-.32,.02-.53,.06-.21,.05-.44,.11-.67,.2-.47,.17-.94,.42-1.38,.71-.89,.58-1.67,1.35-2.2,2.21-.53,.86-.82,1.81-.82,2.81,0,.5,.07,1.01,.2,1.52,.05,.25,.16,.52,.24,.76,.08,.22,.18,.42,.29,.6,.44,.72,1.13,1.19,2.08,1.38,.94,.19,2.05,.06,3.09-.31,1.03-.37,2.01-.97,2.72-1.74,.72-.77,1.14-1.68,1.16-2.62h0c.01-.82,.67-1.46,1.48-1.45,.78,.01,1.41,.64,1.44,1.42,.03,.87-.14,1.76-.46,2.56-.32,.8-.77,1.52-1.3,2.14-1.07,1.25-2.41,2.15-3.87,2.75-1.47,.59-3.1,.9-4.82,.65-.85-.13-1.73-.41-2.53-.89-.8-.47-1.49-1.15-2.01-1.91-.26-.38-.47-.78-.65-1.18-.15-.39-.3-.74-.41-1.14-.24-.78-.4-1.6-.43-2.44-.08-1.69,.37-3.43,1.19-4.88,.82-1.46,1.96-2.67,3.27-3.61,.66-.47,1.37-.88,2.15-1.22,.79-.32,1.62-.6,2.7-.65h.02c1.18-.06,2.18,.86,2.24,2.04,.06,1.18-.86,2.18-2.04,2.24-.05,0-.1,0-.15,0Z' />
        <path style={{ fill: '#b5eeea' }}
              d='M117.87,66.64h-40.73v-27.12c0-2.69,2.18-4.87,4.87-4.87h35.86c2.55,0,4.62,2.07,4.62,4.62v22.75c0,2.55-2.07,4.62-4.62,4.62Z' />
        <path style={{ fill: '#b5eeea' }}
              d='M117.34,34.65h-34.9c-2.99,0-5.42-2.48-5.42-5.53v-9.35c0-6.97,4.23-10.62,9.45-10.62h24.91c6.76,0,11.38,3.89,11.38,10.3v9.68c0,3.05-2.43,5.53-5.42,5.53Z' />
        <path className='cls-8' style={{ fill: '#cff9f5' }}
              d='M79.72,29.12v-9.35c0-6.97,3.98-10.62,8.89-10.62h-2.14c-5.22,0-9.45,3.66-9.45,10.62v9.35c0,3.05,2.43,5.53,5.42,5.53h2.38c-2.82,0-5.1-2.48-5.1-5.53Z' />
        <path className='cls-5' style={{ fill: '#eff' }}
              d='M115.62,32.22h-29.6c-2.54,0-4.6-2.06-4.6-4.6v-7.77c0-5.79,3.59-8.83,8.01-8.83h21.13c5.73,0,9.65,3.23,9.65,8.56v8.05c0,2.54-2.06,4.6-4.6,4.6Z' />
        <path style={{ fill: '#24b2b2' }}
              d='M112.83,38.66c-.64,.06-1.11,.63-1.11,1.28v7.78c0,1.76-1.43,3.19-3.19,3.19h-14.72c-1.76,0-3.19-1.43-3.19-3.19v-7.78c0-.64-.47-1.21-1.11-1.28-.73-.07-1.35,.5-1.35,1.22v7.83c0,3.12,2.53,5.65,5.65,5.65h4.86c.72,0,1.31,.59,1.31,1.31v10.64h2.91v-10.64c0-.72,.59-1.31,1.31-1.31h4.32c3.12,0,5.65-2.53,5.65-5.65v-7.83c0-.72-.62-1.29-1.35-1.22Z' />
        <path className='cls-6' style={{ fill: '#2f7274' }}
              d='M93.97,20.08c0,.82-.5,1.48-1.12,1.48s-1.12-.66-1.12-1.48,.5-1.48,1.12-1.48,1.12,.66,1.12,1.48Z' />
        <ellipse className='cls-14' style={{ fill: '#2f7274' }} cx='45.67' cy='33.01' rx='.84' ry='1.26' />
        <ellipse className='cls-14' style={{ fill: '#2f7274' }} cx='51.28' cy='33.01' rx='.84' ry='1.26' />
        <path className='cls-6' style={{ fill: '#2f7274' }}
              d='M109.51,20.08c0,.82-.5,1.48-1.12,1.48s-1.12-.66-1.12-1.48,.5-1.48,1.12-1.48,1.12,.66,1.12,1.48Z' />
        <path className='cls-14' style={{ fill: '#2f7274' }}
              d='M100.58,27.64c-1.76,0-3.32-1.3-3.63-3.05-.05-.3,.14-.58,.44-.63,.3-.05,.58,.14,.63,.44,.22,1.23,1.31,2.15,2.55,2.15,.01,0,.02,0,.03,0,1.24-.02,2.3-.92,2.52-2.15,.05-.3,.34-.5,.63-.44,.3,.05,.49,.34,.44,.63-.31,1.74-1.82,3.03-3.58,3.05-.02,0-.03,0-.05,0Z' />
        <path className='cls-6' style={{ fill: '#2f7274' }}
              d='M88.16,40.66v2.41l2.53-.25c.44-.04,.84,.26,.92,.7h0c.06,.39-.16,.78-.53,.92l-2.23,.86,.14,.27,2.11-.45c.56-.12,1.1,.26,1.18,.83h0c.07,.5-.26,.97-.76,1.09l-2.33,.53,.06,.27,1.82-.21c.57-.07,1.07,.38,1.07,.95h0c0,.43-.28,.8-.69,.92-.79,.23-2.13,.6-3.29,.8-1.77,.31-3.85-2.15-3.25-5.75s3.25-3.89,3.25-3.89Z' />
        <path className='cls-6' style={{ fill: '#2f7274' }}
              d='M114.15,40.66v2.41l-2.53-.25c-.44-.04-.84,.26-.92,.7h0c-.06,.39,.16,.78,.53,.92l2.23,.86-.14,.27-2.11-.45c-.56-.12-1.1,.26-1.18,.83h0c-.07,.5,.26,.97,.76,1.09l2.33,.53-.06,.27-1.82-.21c-.57-.07-1.07,.38-1.07,.95h0c0,.43,.28,.8,.69,.92,.79,.23,2.13,.6,3.29,.8,1.77,.31,3.85-2.15,3.25-5.75-.6-3.6-3.25-3.89-3.25-3.89Z' />
        <path className='cls-14' style={{ fill: '#2f7274' }}
              d='M129.56,45.2c-1.08,0-2.13-.13-3.14-.37,.13,.46,.2,.93,.2,1.41,0,.5-.07,1.01-.2,1.52-.05,.25-.16,.52-.24,.76-.08,.22-.18,.42-.29,.6-.44,.72-1.13,1.19-2.08,1.38-.94,.19-2.05,.06-3.09-.31-1.03-.37-2.01-.97-2.72-1.74-.72-.77-1.14-1.68-1.16-2.62v-.02c-.01-.77-.63-1.41-1.42-1.43-.81-.02-1.48,.61-1.5,1.41-.03,.87,.14,1.76,.46,2.56,.32,.8,.77,1.52,1.3,2.14,1.07,1.25,2.41,2.15,3.87,2.75,1.47,.59,3.1,.9,4.82,.65,.85-.13,1.73-.41,2.53-.89,.8-.47,1.49-1.15,2.01-1.91,.26-.38,.47-.78,.65-1.18,.15-.39,.3-.74,.41-1.14,.24-.78,.4-1.6,.43-2.44,.02-.38,0-.77-.03-1.16-.27,.02-.54,.03-.8,.03Z' />
        <path className='cls-8' style={{ fill: '#cff9f5' }}
              d='M79.87,40.34c0-2.69,2.18-4.87,4.87-4.87h35.75c-.75-.52-1.65-.82-2.62-.82h-35.86c-2.69,0-4.87,2.18-4.87,4.87v27.12h2.73v-26.3Z' />
        <path className='cls-14' style={{ fill: '#2f7274' }}
              d='M86.52,44.38c-.81-.01-1.47,.63-1.48,1.44h0c-.01,.95-.44,1.86-1.16,2.63-.71,.77-1.69,1.38-2.72,1.74-1.03,.37-2.15,.5-3.09,.31-.95-.19-1.64-.66-2.08-1.38-.11-.18-.21-.38-.29-.6-.08-.24-.18-.51-.24-.76-.13-.51-.21-1.02-.2-1.52,0-.44,.06-.87,.17-1.3-1.28-.25-2.5-.69-3.65-1.27-.23,.87-.33,1.77-.29,2.66,.03,.85,.19,1.67,.43,2.44,.11,.4,.26,.75,.41,1.14,.17,.4,.39,.8,.65,1.18,.51,.76,1.21,1.43,2.01,1.91,.8,.48,1.67,.76,2.53,.89,1.72,.25,3.35-.06,4.82-.65,1.47-.6,2.81-1.5,3.87-2.75,.53-.62,.98-1.34,1.3-2.14,.32-.8,.48-1.68,.46-2.56-.02-.77-.65-1.4-1.44-1.42Z' />
        <path className='cls-6' style={{ fill: '#2f7274' }}
              d='M58.67,38.77h1.7c.51,0,.82,.56,.54,.99l-.82,1.28c-.25,.39-.81,.4-1.07,.02l-.88-1.28c-.29-.43,.01-1.01,.53-1.01Z' />
        <path className='cls-7' style={{ fill: '#98f4e9' }}
              d='M130.5,40.69c7.55,1.66,12.03,3.73,12.03,5.97,0,5.47-26.64,9.9-59.49,9.9s-59.49-4.43-59.49-9.9c0-.71,.46-1.41,1.31-2.07C9.48,48.56,0,53.99,0,59.98c0,12.2,39.3,22.09,87.77,22.09s87.77-9.89,87.77-22.09c0-8.29-18.17-15.52-45.05-19.29Z' />
        <path className='cls-10' style={{ fill: '#d4fcf7' }}
              d='M3.87,66.49c5.49-3.6,11.43-6.44,17.81-8.52,4.32-2.27,8.77-4.09,13.34-5.47-7.21-1.64-11.48-3.66-11.48-5.85,0-.71,.46-1.41,1.31-2.07C9.48,48.56,0,53.99,0,59.98c0,2.26,1.36,4.45,3.87,6.51Z' />
        <path style={{ fill: '#95f9f1' }}
              d='M130.5,40.69c7.55,1.66,12.03,3.73,12.03,5.97,0,4.7-19.7,8.64-46.12,9.65,4.68,1.85,9.27,4.07,13.75,6.67l20.12,15.54c.23,.23,.45,.46,.67,.69,26.63-3.79,44.6-10.98,44.6-19.23s-18.17-15.52-45.05-19.29Z' />
        <path className='cls-10' style={{ fill: '#d4fcf7' }}
              d='M142.54,46.66c0,1.09-1.06,2.14-3.03,3.12,.07,.04,.15,.08,.22,.12,6.47,1.64,12.7,4.2,18.67,7.67,4.13,3.19,8.27,6.39,12.4,9.58,3.07-2.25,4.73-4.66,4.73-7.17,0-8.29-18.17-15.52-45.05-19.29,7.55,1.66,12.03,3.73,12.03,5.97Z' />
        <path className='cls-11' style={{ fill: 'url(#_Áåçûìÿííûé_ãðàäèåíò_183)' }}
              d='M87.77,82.07C41.02,82.07,2.81,72.87,.15,61.27c.7,17.14,39.69,30.96,87.69,30.96s87.7-14.07,87.7-31.42c0-.16-.01-.32-.02-.48-.72,12.04-39.73,21.74-87.76,21.74Z' />
        <path style={{ fill: 'url(#_Áåçûìÿííûé_ãðàäèåíò_183-2)' }}
              d='M63.34,88.81l-11.18-8.64C22.91,76.9,2.09,69.72,.15,61.27c.6,14.72,29.45,26.99,68,30.16-1.62-.83-3.23-1.7-4.82-2.63Z' />
        <path style={{
          fill: 'none',
          stroke: '#2f7274',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '5.67px',
        }} d='M46.59,41.5l.08,.44c.18,1.01,1.01,1.78,2.03,1.89l1.54,.16' />
        <path style={{ fill: '#b5eeea' }}
              d='M46.99,19.42c.05,.25-.33,.55-.86,.66-.53,.12-1.01,0-1.06-.24-.05-.25,.33-.55,.86-.66,.53-.12,1.01,0,1.06,.24Z' />
        <path className='cls-10' style={{ fill: '#d4fcf7' }}
              d='M23.75,47.48c-.13,.27-.2,.54-.2,.82,0,5.47,26.64,9.9,59.49,9.9s59.49-4.43,59.49-9.9c0-.28-.07-.55-.2-.82-2.5,5.09-28.09,9.08-59.29,9.08s-56.78-4-59.29-9.08Z' />
      </g>
    </g>
  </svg>
);

export const Fallback404Version1: any = (props: any) => (
  <svg viewBox='0 0 178.75 87.04' {...props}>
    <g id='_Ñëîé_1' data-name='Ñëîé 1'>
      <g>
        <g>
          <path
            style={{ fill: '#00f3e8' }}
            d="M169.21,66.6c.05-1.93-.06-8.32-6.07-14.55-5.61-5.81-14.31-6.57-17.57-4.86,2.48-3.48,1.63-11.97-2.82-17.72-7.17-9.26-17.02-7.69-19.5-7.37-.1-1.02-.53-3.68-2.38-6.62-2.11-3.37-6.57-7.73-15.78-9.34-10.28-1.8-17.04,.85-20.9,3.39-3.41,2.24-5.19,4.74-5.78,5.68-1.95-1.37-11.66-7.48-22.26-1.63-4.77,2.64-7.38,6.21-8.8,9.54-3.89-1.12-6.93,.18-8.19,.89,0,0-10.03-3.94-15.97,4.51-5.94,8.44-1.08,15.43-1.08,15.43,0,0-8.26,.22-15.31,8.42C-3.52,64.34,.98,76.55,.98,76.55H178.37c.21,0,.38-.17,.38-.38,0-7.36-7.78-9.24-9.54-9.57Z"
          />
          <path
            style={{ fill: '#42b0bc' }}
            d="M23.11,76.32h122.46s.39-5.16-4.28-7.26c-4.67-2.11-8.82,1.19-8.82,1.19,0,0-.25-3.01-3.7-3.84-3.45-.83-5.11,1.72-5.11,1.72,0,0,1.83-8.24-5.81-12.62-8.25-4.73-16.14,2.05-16.14,2.05,0,0-8.25-7.27-19.78-3.84-10.45,3.11-12.27,12.28-12.27,12.28,0,0-.89-3.54-4.88-4.64-4.62-1.28-7.66,1.04-7.66,1.04,0,0-2.46-9.07-13.8-7.22-12.35,2.02-10.93,11.3-10.93,11.3,0,0-4.41-1.84-7.87,1.34-3.46,3.19-1.4,8.49-1.4,8.49Z"
          />
          <path
            style={{ fill: '#aeccc6' }}
            d="M165.33,76.69s.64,.85-3.87,1.78c-5.3,1.09-18.18,.27-19.44,2.29-1.38,2.21,8.78,1.58,8.66,4.16-.1,2.19-7,2.11-14.79,2.11H48.64c-6.3,0-5.16-1.91-4.27-2.78,1.3-1.26,.73-2.43-4.95-2.59-5.51-.16-9.54-.29-12.54-.29-8.01,0-6.44-2.64-12.68-2.64-4.04,0-8.89-1.02-8.89-2.19,22.62-1.99,47-3.28,72.96-3.46,31.33-.22,60.47,1.21,87.07,3.61Z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#daf2f0' }}
            d="M95.07,78.13l-.33-1.44c-.02-.17-.06-.34-.12-.5h0s0,0,0,0c-.35-1.03-1.33-1.78-2.48-1.78h-11.15c-1.19,0-2.19,.79-2.51,1.88h0s-.47,2.05-.47,2.05c-.05,.2-.07,.4-.07,.61,0,1.52,1.24,2.76,2.76,2.76h11.74c1.52,0,2.76-1.24,2.76-2.76,0-.29-.04-.56-.13-.82Z"
          />
          <path
            style={{ fill: '#7aabc4' }}
            d="M92.43,81.27h-11.74c-1.28,0-2.32-1.05-2.32-2.32h0c0-1.28,1.05-2.32,2.32-2.32h11.74c1.28,0,2.32,1.05,2.32,2.32h0c0,1.28-1.05,2.32-2.32,2.32Z"
          />
          <path
            style={{ fill: '#5d94af' }}
            d="M92.43,76.19h-11.74c-1.52,0-2.76,1.24-2.76,2.76s1.24,2.76,2.76,2.76h11.74c1.52,0,2.76-1.24,2.76-2.76s-1.24-2.76-2.76-2.76Zm0,5.08h-11.74c-1.28,0-2.32-1.05-2.32-2.32s1.05-2.32,2.32-2.32h11.74c1.28,0,2.32,1.05,2.32,2.32s-1.05,2.32-2.32,2.32Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M84.87,71.21v4.03c0,.43,.83,.77,1.85,.77s1.85-.35,1.85-.77v-4.03h-3.69Z"
          />
          <path
            style={{ fill: '#7aabc4' }}
            d="M92.37,67.18c-1.59-.34-3.54-.54-5.65-.54-1.91,0-3.7,.17-5.2,.45l-4.56-.04v2.53c-.01,1.62,4.36,2.94,9.76,2.94s9.78-1.32,9.78-2.94v-2.37l-4.13-.03Z"
          />
          <ellipse
            style={{ fill: '#daf2f0' }}
            cx="86.72"
            cy="67.21"
            rx="9.78"
            ry="2.94"
          />
          <path
            style={{ fill: '#7aabc4' }}
            d="M80.59,63.55v2.36c0,.94,2.74,1.69,6.13,1.69s6.13-.76,6.13-1.69v-2.36h-12.26Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M92.85,63.55c0,.94-2.74,1.69-6.13,1.69s-6.13-.76-6.13-1.69,2.74-1.69,6.13-1.69,6.13,.76,6.13,1.69Z"
          />
          <path
            style={{ fill: '#bad8d3' }}
            d="M82.21,57.17v5.53c0,.59,2.02,1.06,4.51,1.06s4.51-.48,4.51-1.06v-5.53h-9.01Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M46.93,41.32s.06-1.35,.57-2.05,1.25-.39,1.25-.39l-.04,2.88-1.79,2.68-1.48,.13s-3.53-.33-5.92-1.21c-2.4-.89-3.31-3.16-3.31-3.16h0c.34-.26,.83-.21,1.1,.13,.54,.67,1.54,1.65,2.72,1.64,0,0-1.32-1.41-1.83-3.31-.05-.18,.04-.36,.21-.43h0c.38-.15,.82,.02,.99,.4,.31,.66,.85,1.64,1.51,2.16,0,0-.19-1.79,.24-3.62,.04-.18,.22-.28,.39-.22h0c.35,.12,.61,.43,.64,.8l.07,2.73,2.31,1.36,2.36-.5Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M116.73,52.66c-7.16,0-10.62-5.66-10.77-5.9l1.67-1c.13,.21,3.18,5.13,9.44,4.95,6.15-.18,10.41-5.34,10.45-5.39l1.52,1.23c-.2,.24-4.87,5.9-11.91,6.11-.14,0-.27,0-.41,0Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M55.31,51.67c-7.31,0-10.78-7.85-10.93-8.19l1.79-.77-.9,.39,.9-.39c.03,.07,3.12,7.01,9.15,7.01,.08,0,.16,0,.24,0,6.15-.18,10.41-5.34,10.45-5.39l1.52,1.23c-.2,.24-4.87,5.9-11.91,6.11-.1,0-.21,0-.31,0Z"
          />
          <path
            style={{ fill: '#daf2f0' }}
            d="M65.37,48.13c0-6.82,5.6-18.05,21.44-18.05s19.41,12.51,19.41,19.09-7.39,10.05-19.78,10.05-21.08-3.52-21.08-11.09Z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M68.14,49.71c0-6.82,5.6-18.05,21.44-18.05,3.66,0,6.66,.67,9.12,1.77-2.86-2.02-6.72-3.36-11.89-3.36-15.84,0-21.44,11.23-21.44,18.05,0,3.99,2.41,6.85,6.51,8.67-2.39-1.76-3.74-4.11-3.74-7.09Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M80.97,41.87c0,.7-.57,1.27-1.27,1.27s-1.27-.57-1.27-1.27,.57-1.27,1.27-1.27,1.27,.57,1.27,1.27Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M92.4,41.87c0,.7-.57,1.27-1.27,1.27s-1.27-.57-1.27-1.27,.57-1.27,1.27-1.27,1.27,.57,1.27,1.27Z"
          />
          <path
            style={{ fill: '#daf2f0' }}
            d="M67.04,41.56s-3.17,1.24-4.22,4.34c0,0,1.26,3.03,4.39,3.76,0,0,2.26-4.07-.17-8.09Z"
          />
          <path
            style={{ fill: '#bad8d3' }}
            d="M104.52,41.56s3.17,1.24,4.22,4.34c0,0-1.26,3.03-4.39,3.76,0,0-2.26-4.07,.17-8.09Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M127.2,46.41s-2.1-3.15-2.14-3.99c-.04-.83,1.23-.61,1.87,.44,.64,1.05,.97,2.45,3.09,2.81,2.12,.35,3.81-1.25,3.81-1.25,0,0,.13-2.54,.36-3.06s1.06-.58,1.06-.58c0,0,.31,1.64,.08,3.03l1.34-1.99c.26-.38,.69-.6,1.15-.57h0c.23,.01,.38,.24,.31,.46-.19,.58-.61,1.65-1.38,2.58l1.97-1.08c.21-.11,.46-.05,.59,.15h0c.12,.19,.08,.44-.09,.58-.69,.56-2.43,1.92-3.86,2.56-2.4,1.07-4.22,1.52-7.94,1.49l-.23-1.58Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M82.86,78.97c0,1.14-.92,2.06-2.06,2.06s-2.06-.92-2.06-2.06,.92-2.06,2.06-2.06,2.06,.92,2.06,2.06Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M94.45,78.97c0,1.14-.92,2.06-2.06,2.06s-2.06-.92-2.06-2.06,.92-2.06,2.06-2.06,2.06,.92,2.06,2.06Z"
          />
          <path
            style={{ fill: '#5d7e93' }}
            d="M86.34,79.68c0,.74-.6,1.35-1.35,1.35s-1.35-.6-1.35-1.35,.6-1.35,1.35-1.35,1.35,.6,1.35,1.35Z"
          />
          <circle style={{ fill: '#5d7e93' }} cx="88.27" cy="79.68" r="1.35" />
        </g>
        <path
          style={{ fill: '#42b0bc' }}
          d="M138.37,0h-39.89c-3.13,0-5.7,2.56-5.7,5.7v11.85c0,3.13,2.56,5.7,5.7,5.7h5.62c.32,.93,.59,2.03,.74,3.29,.37,3.27-.37,5.9-.89,7.34,1.27-.81,3.76-2.64,5.54-5.93,.93-1.73,1.4-3.37,1.64-4.69h27.25c3.13,0,5.7-2.56,5.7-5.7V5.7c0-3.13-2.56-5.7-5.7-5.7Z"
        />
        <path
          style={{ fill: '#ffe340' }}
          d="M79.09,7.3l-.72-.43,.43-.72c.11-.19,.05-.44-.14-.56-.19-.11-.44-.05-.56,.14l-.43,.72-.72-.43c-.19-.11-.44-.05-.56,.14-.11,.19-.05,.44,.14,.56l.72,.43-.43,.72c-.11,.19-.05,.44,.14,.56,.19,.11,.44,.05,.56-.14l.43-.72,.72,.43c.19,.11,.44,.05,.56-.14,.11-.19,.05-.44-.14-.56Z"
        />
        <path
          style={{ fill: '#ffe340' }}
          d="M15.35,31.27l-.72-.43,.43-.72c.11-.19,.05-.44-.14-.56-.19-.11-.44-.05-.56,.14l-.43,.72-.72-.43c-.19-.11-.44-.05-.56,.14-.11,.19-.05,.44,.14,.56l.72,.43-.43,.72c-.11,.19-.05,.44,.14,.56,.19,.11,.44,.05,.56-.14l.43-.72,.72,.43c.19,.11,.44,.05,.56-.14,.11-.19,.05-.44-.14-.56Z"
        />
        <path
          style={{ fill: '#ffe340' }}
          d="M151.97,43.24l-.46-.27,.27-.46c.07-.12,.03-.28-.09-.36-.12-.07-.28-.03-.36,.09l-.27,.46-.46-.27c-.12-.07-.28-.03-.36,.09s-.03,.28,.09,.36l.46,.27-.27,.46c-.07,.12-.03,.28,.09,.36s.28,.03,.36-.09l.27-.46,.46,.27c.12,.07,.28,.03,.36-.09,.07-.12,.03-.28-.09-.36Z"
        />
        <path
          style={{ fill: '#ffe340' }}
          d="M38.54,20.83l-.6-.35,.35-.6c.09-.16,.04-.37-.12-.46-.16-.09-.37-.04-.46,.12l-.35,.6-.6-.35c-.16-.09-.37-.04-.46,.12-.09,.16-.04,.37,.12,.46l.6,.35-.35,.6c-.09,.16-.04,.37,.12,.46s.37,.04,.46-.12l.35-.6,.6,.35c.16,.09,.37,.04,.46-.12,.09-.16,.04-.37-.12-.46Z"
        />
        <g>
          <path
            style={{ fill: '#ffe340' }}
            d="M55.12,6.16c1.15,2.32,.2,5.12-2.12,6.27-2.32,1.15-5.12,.2-6.27-2.12-1.15-2.32-.2-5.12,2.12-6.27,2.32-1.15,5.12-.2,6.27,2.12Z"
          />
          <path
            style={{ fill: '#fcd51a' }}
            d="M49.04,5.75c-7.58,3.75-9.78,6.4-9.02,7.95,.76,1.55,4.21,1.4,11.79-2.35,7.25-3.59,9.78-6.4,9.02-7.95-.76-1.55-3.46-1.77-11.79,2.35Zm2.28,4.61c-4.91,2.43-7.14,2.52-7.64,1.52s.93-2.72,5.84-5.15c5.39-2.67,7.14-2.52,7.64-1.52,.5,1-1.14,2.82-5.84,5.15Z"
          />
          <path
            style={{ fill: '#ffe340' }}
            d="M47.41,11.32c.79-.85,1.82-1.52,2.78-2.08,.92-.54,1.89-.67,2.88-1.02,.83-.3,1.6-.74,2.4-1.12-.08-.31-.19-.62-.34-.93-1.15-2.32-3.95-3.27-6.27-2.12-2.32,1.15-3.27,3.95-2.12,6.27,.18,.37,.41,.7,.67,1Z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#ffe340' }}
            d="M154.65,32.36c-.76,1.12-2.29,1.41-3.41,.65-1.12-.76-1.41-2.29-.65-3.41,.76-1.12,2.29-1.41,3.41-.65s1.41,2.29,.65,3.41Z"
          />
          <path
            style={{ fill: '#fcd51a' }}
            d="M153.27,29.48c-3.66-2.49-5.44-2.82-5.95-2.07-.51,.75,.44,2.28,4.1,4.77,3.5,2.39,5.44,2.82,5.95,2.07,.51-.75-.08-2.03-4.1-4.77Zm-1.52,2.23c-2.37-1.62-2.99-2.61-2.66-3.09,.33-.48,1.48-.27,3.85,1.34,2.61,1.77,2.99,2.61,2.66,3.09s-1.58,.2-3.85-1.34Z"
          />
          <path
            style={{ fill: '#ffe340' }}
            d="M150.31,30.17c.59,.14,1.16,.44,1.66,.73,.48,.28,.79,.69,1.21,1.05,.35,.3,.75,.54,1.13,.81,.12-.12,.24-.25,.33-.39,.76-1.12,.47-2.64-.65-3.41s-2.64-.47-3.41,.65c-.12,.18-.21,.37-.28,.56Z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#fff' }}
            d="M110.53,14.04h-1.03v1.78h-1.77v-1.78h-3.67v-1.26l3.77-5.55h1.67v5.4h1.03v1.41Zm-2.81-1.41v-1.46c0-.24,0-.6,.03-1.06,.02-.46,.04-.73,.05-.81h-.05c-.15,.32-.32,.63-.52,.94l-1.57,2.38h2.07Z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M117.16,11.52c0,1.5-.25,2.61-.74,3.33-.49,.72-1.25,1.08-2.27,1.08s-1.74-.37-2.24-1.12c-.5-.74-.76-1.84-.76-3.3s.24-2.63,.73-3.35c.49-.72,1.24-1.08,2.26-1.08s1.74,.38,2.25,1.13c.51,.75,.76,1.85,.76,3.3Zm-4.2,0c0,1.05,.09,1.81,.27,2.27,.18,.46,.49,.68,.92,.68s.73-.23,.92-.69c.19-.46,.28-1.21,.28-2.26s-.1-1.81-.28-2.27-.49-.69-.91-.69-.73,.23-.92,.69c-.18,.46-.28,1.22-.28,2.27Z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M124.27,14.04h-1.03v1.78h-1.77v-1.78h-3.67v-1.26l3.77-5.55h1.67v5.4h1.03v1.41Zm-2.81-1.41v-1.46c0-.24,0-.6,.03-1.06,.02-.46,.04-.73,.05-.81h-.05c-.15,.32-.32,.63-.52,.94l-1.57,2.38h2.07Z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M125.14,14.98c0-.33,.09-.58,.26-.75,.18-.17,.43-.25,.77-.25s.58,.09,.76,.26c.18,.17,.27,.42,.27,.74s-.09,.55-.27,.73c-.18,.18-.43,.27-.75,.27s-.58-.09-.76-.26c-.18-.17-.27-.42-.27-.74Z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M128.58,14.98c0-.33,.09-.58,.26-.75,.18-.17,.43-.25,.77-.25s.58,.09,.76,.26c.18,.17,.27,.42,.27,.74s-.09,.55-.27,.73c-.18,.18-.43,.27-.75,.27s-.58-.09-.76-.26c-.18-.17-.27-.42-.27-.74Z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M132.01,14.98c0-.33,.09-.58,.26-.75,.18-.17,.43-.25,.77-.25s.58,.09,.76,.26c.18,.17,.27,.42,.27,.74s-.09,.55-.27,.73c-.18,.18-.43,.27-.75,.27s-.58-.09-.76-.26c-.18-.17-.27-.42-.27-.74Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const FallbackRocketAndBoyImage: any = (props: any) => (
  <svg viewBox="0 0 171.71 95.85" {...props}>
    <g xmlns="http://www.w3.org/2000/svg" id="_Ñëîé_1" data-name="Ñëîé 1">
      <g>
        <g>
          <path
            style={{ fill: '#2eccd3' }}
            d="M7.81,79.56c-.04-1.51,.18-8.78,7.44-11.86,7.24-3.07,12.99-.96,15.54,.38-1.95-2.73-4.49-7.94-2.18-14.69,3.32-9.73,13.28-8.95,15.23-8.7,.08-.8,.42-2.89,1.86-5.19,1.66-2.64,5.15-6.06,12.37-7.32,8.06-1.41,13.36,.66,16.38,2.65,2.67,1.76,4.07,3.72,4.53,4.45,1.52-1.07,9.14-5.87,17.45-1.28,1.58,.87,2.86,1.88,3.89,2.94,1.47-.88,3.18-1.39,5.02-1.39,2.27,0,4.36,.77,6.02,2.07,.7-.94,1.71-1.88,3.14-2.66,4.77-2.6,9.23-.81,10.23-.34,.75-.74,4.07-3.59,8.86-1.85,4.49,1.63,5.16,5.65,5.26,7.05,.92-.44,10.12-2.34,15.42,4.64,5.31,7.01,3.1,14.01,1.94,15.71,2.4,.72,4.03,2,4.84,3.82,1.38,3.08,0,6.73-.53,7.93,11.07,1.19,11.2,11.03,11.2,11.13,0,.17-.13,.3-.3,.3H.63c-.17,0-.3-.13-.3-.3,0-5.77,6.1-7.24,7.48-7.5Z"
          />
          <path
            style={{ fill: '#45e5e2' }}
            d="M7.81,79.56c-.03-1.43,.16-7.99,6.3-11.31,2.37-1.46,6.75-3.67,12.04-4.15,0,0-2.21-7.18,4.9-15.12,4.3-5.05,11.2-4.5,12.78-4.3,.08-.8,.42-2.89,1.86-5.19,1.66-2.64,5.15-6.06,12.37-7.32,8.06-1.41,13.36,.66,16.38,2.65,2.67,1.76,4.07,3.72,4.53,4.45,1.52-1.07,9.14-5.87,17.45-1.28,3.74,2.07,5.79,4.87,6.9,7.47,0,0-5.1-3.1-15.49,1.43-9.56,4.17-9.14,11.1-9.14,11.1,0,0-9.18-3.95-17.97,1.58-8.8,5.53-5.38,13.53-5.38,13.53,0,0-4.35-6.02-14.15-4.9-7.14,.81-9.77,4.9-11.27,6.83,0,0-10.77-2.04-14.32,4.72,0,0-9.06-.71-9.92,7.59H.63c-.17,0-.3-.13-.3-.3,0-5.77,6.1-7.24,7.48-7.5Z"
          />
          <path
            style={{ fill: '#45e5e2' }}
            d="M158.39,86.82c-.03-1.16-.23-2.42-.68-3.75-2.5-7.34-10.02-6.75-11.48-6.56-.06-.6-.32-2.18-1.41-3.91-1.25-1.99-3.88-4.57-9.33-5.52-6.08-1.07-10.07,.5-12.36,2-2.02,1.33-3.07,2.8-3.42,3.36-1.15-.81-6.9-4.42-13.16-.96-2.82,1.56-4.37,3.67-5.2,5.64-9.52,.87-8.89,7.57-8.86,9.72h65.9Z"
          />
          <path
            style={{ fill: '#f1f1f1' }}
            d="M.04,86.73s-.76,.75,4.56,1.57c6.24,.96,16.97,.24,18.45,2.02,1.62,1.94-5.9,1.39-5.77,3.66,.12,1.93,8.24,1.86,17.4,1.86H120.71c7.42,0,6.08-1.68,5.03-2.45-1.53-1.11-.85-2.14,5.82-2.28,6.48-.14,11.23-.26,14.76-.26,9.43,0,7.58-2.33,14.93-2.33,4.75,0,10.47-.89,10.47-1.93-24.12-2.14-51.03-3.61-80.32-3.76-33.68-.17-64.34,1.44-91.36,3.89Z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#dcdcdb' }}
            d="M75.78,86.7s18.86,1.72,46.37,1.21c27.51-.51,37.59-1.21,37.59-1.21,0,0-33.21-1.87-47.11-2.43-22.97-.94-36.85,2.43-36.85,2.43Z"
          />
          <path
            style={{ fill: '#64aba7' }}
            d="M131.78,86.37h2.2s2.55-17.41-3.56-31.47l-4.74,15.92s5.16,5.85,6.1,15.55Z"
          />
          <path
            style={{ fill: '#64aba7' }}
            d="M101.35,55.26s-7.65,15.14-3.72,31.1h1.62s1.33-9.84,6.3-14.57l-4.2-16.54Z"
          />
          <path
            style={{ fill: '#64aba7' }}
            d="M147.54,52.15V16.42c0-.29-.23-.52-.52-.52s-.52,.23-.52,.52v2.86h-8.65v-2.86c0-.29-.23-.52-.52-.52s-.52,.23-.52,.52v18.43h-5.64c-.29,0-.52,.23-.52,.52s.23,.52,.52,.52h5.64v15.64h-5.64c-.29,0-.52,.23-.52,.52s.23,.52,.52,.52h5.64v16.07h-3.05c-.29,0-.52,.23-.52,.52s.23,.52,.52,.52h3.05v16.51c0,.29,.23,.52,.52,.52s.52-.23,.52-.52v-15.55l8.65,13.83v1.72c0,.29,.23,.52,.52,.52s.52-.23,.52-.52V52.15h0Zm-1.04,16.49h-7.99l7.99-14.47v14.47Zm-8.65-48.33h8.24l-8.24,13.53v-13.53Zm8.65,14.53h-8.05l8.05-13.2v13.2Zm0,1.04v14.29l-8.08-14.29h8.08Zm-8.65,1.1l8.22,14.54h-8.22v-14.54Zm8.35,15.58l-8.35,15.12v-15.12h8.35Zm-7.72,17.11h8.02v12.83l-8.02-12.83Z"
          />
          <path
            style={{ fill: '#bcedef' }}
            d="M104.19,71.75s2.64,4.07,10.93,4.07,11.18-4.08,11.18-4.08c0,0,6.81-12.84,5.24-32.42-1.27-15.9-11.58-32.85-15.42-38.67-.62-.95-2.04-.85-2.52,.18-3.13,6.66-12,26.45-13.25,39.63-1.53,16.05,3.84,31.29,3.84,31.29Z"
          />
          <path
            style={{ fill: '#d7f8f8' }}
            d="M131.54,39.32c-1.27-15.9-11.58-32.85-15.42-38.67-.19-.29-.46-.48-.75-.58,4.17,11.55,8.27,26.04,9.08,40.01,.81,13.99-.39,25.75-1.91,34.34,2.69-1.18,3.76-2.68,3.76-2.68,0,0,6.81-12.84,5.24-32.42Z"
          />
          <g>
            <path
              style={{ fill: '#fff' }}
              d="M108.14,45.39c-.04,.95-.84,1.7-1.79,1.66-.95-.04-1.7-.84-1.66-1.79,.04-.95,.84-1.7,1.79-1.66,.95,.04,1.7,.84,1.66,1.79Z"
            />
            <path
              style={{ fill: '#fff' }}
              d="M108.83,36.13c-.03,.74-.65,1.32-1.39,1.29s-1.32-.65-1.29-1.39c.03-.74,.65-1.32,1.39-1.29s1.32,.65,1.29,1.39Z"
            />
            <path
              style={{ fill: '#fff' }}
              d="M109.88,27.96c-.02,.58-.51,1.02-1.08,1-.58-.02-1.02-.51-1-1.08,.02-.58,.51-1.02,1.08-1,.58,.02,1.02,.51,1,1.08Z"
            />
            <path
              style={{ fill: '#fff' }}
              d="M111.16,20.83c-.02,.42-.37,.76-.8,.74-.42-.02-.76-.37-.74-.8,.02-.42,.37-.76,.8-.74,.42,.02,.76,.37,.74,.8Z"
            />
          </g>
          <path
            style={{ fill: '#fff' }}
            d="M104.57,65.59s-.23-4.99-.23-7.2-.04-6.35,1.95-6.35,2.13,3.91,2.13,6.32-.03,7.22-.03,7.22h-3.82Z"
          />
          <polygon
            style={{ fill: '#d7f8f8' }}
            points="108.21 65.59 104.77 65.59 102.94 65.59 102.94 69.03 99.5 69.03 99.5 72.46 96.07 72.46 96.07 75.89 92.63 75.89 92.63 79.37 89.16 79.37 89.16 82.8 85.72 82.8 85.72 86.37 87.2 86.37 108.4 65.59 108.21 65.59"
          />
          <path
            style={{ fill: '#64aba7' }}
            d="M115.48,54.96l-1.85,20.71,5.03,10.7h1.82s1.54-17.21-3.84-31.47l-1.16,.06Z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#accece' }}
            d="M54.31,9.27h6.69c4.62,0,8.37,3.75,8.37,8.37v6.32c0,1.97-1.6,3.57-3.57,3.57h-19.77c-1.04,0-1.89-.85-1.89-1.89v-6.21c0-5.61,4.56-10.17,10.17-10.17Z"
            transform="translate(19.76 -28.41) rotate(33.71)"
          />
          <path
            style={{ fill: '#ffd88b' }}
            d="M42.3,27.57c-.24,2.66-.35,5.7-.2,9.04,.14,3.09,.48,5.9,.9,8.37,5.28-.26,10.56-.51,15.83-.77,.44-1.07,.89-2.46,1.16-4.14,.32-1.95,.27-3.64,.15-4.91,.45-2.34,.9-4.69,1.35-7.03-6.4-.19-12.8-.38-19.2-.57Z"
          />
          <path
            style={{ fill: '#4d352b' }}
            d="M39.48,22.34s-1.09,4.65,2.53,7.08c2.3,1.55,5.99,.4,9.12-.34,3.92-.93,3.9,3,3.72,5.4h2s0-3.17,1.16-3.17c1.76,0,2.35,2.09,1.46,4.48,0,0,2.14-.02,2.45-3.68,.31-3.66,.55-5.02-.44-7.46-.99-2.44-3.34-1.78-3.34-1.78,0,0-1.16-1.91-5.17-1.06-4.01,.85-10.54,2.36-13.48,.53Z"
          />
          <path
            style={{ fill: '#625342' }}
            d="M46.01,35.08c0,.61-.3,1.11-.67,1.11s-.67-.49-.67-1.11,.3-1.11,.67-1.11,.67,.49,.67,1.11Z"
          />
          <path
            style={{ fill: '#625342' }}
            d="M52.33,35.08c0,.61-.3,1.11-.67,1.11s-.67-.49-.67-1.11,.3-1.11,.67-1.11,.67,.49,.67,1.11Z"
          />
          <path
            style={{ fill: '#625342' }}
            d="M48.81,40.09c-.08,0-.16,0-.24,0-.97-.06-1.71-.43-2.15-.74-.18-.13-.23-.37-.1-.56,.13-.18,.37-.23,.56-.1,.36,.25,.96,.56,1.75,.6,.92,.05,1.63-.29,1.97-.49,.19-.11,.43-.05,.55,.13,.11,.19,.05,.43-.13,.55-.39,.24-1.17,.61-2.19,.61Z"
          />
          <path
            style={{ fill: '#dcdcdb' }}
            d="M76.07,86.78c0,1.14-11.83,2.06-26.43,2.06s-26.43-.92-26.43-2.06,11.83-2.06,26.43-2.06,26.43,.92,26.43,2.06Z"
          />
          <path
            style={{ fill: '#587072' }}
            d="M80.23,15.86c-.61-.44-1.38-.79-2.15-.98-.77-.19-1.58-.27-2.37-.18-.2,.01-.39,.06-.59,.09l-.29,.05-.29,.09c-.19,.06-.38,.11-.57,.18l-.55,.24c-.09,.04-.19,.07-.27,.12l-.26,.15-.52,.3c-.34,.22-.66,.47-.99,.7l-.05,.03c-.22,.15-.53,.1-.68-.12s-.1-.53,.12-.68c0,0,0,0,0,0,.37-.24,.72-.5,1.1-.73l.59-.31,.29-.15c.1-.05,.21-.08,.31-.13l.62-.24c.21-.07,.43-.12,.64-.18l.32-.09,.33-.05c.22-.03,.44-.08,.66-.09,.89-.08,1.79,.03,2.65,.27,.86,.25,1.65,.62,2.39,1.19,.15,.12,.18,.34,.07,.49-.12,.15-.33,.18-.49,.07l-.03-.02Z"
          />
          <path
            style={{ fill: '#587072' }}
            d="M81.76,17.03c-.47,.37-1.15,.29-1.52-.18-.37-.47-.29-1.15,.18-1.52,.47-.37,1.15-.29,1.52,.18s.29,1.15-.18,1.52Z"
          />
          <path
            style={{ fill: '#587072' }}
            d="M39.94,48.21c-.22,.05-.35,.07-.51,.09-.15,.02-.31,.04-.46,.06-.3,.03-.6,.06-.9,.07-.59,.03-1.19,.03-1.78-.02-1.19-.07-2.37-.3-3.52-.69-1.15-.39-2.26-.96-3.24-1.7-.98-.74-1.83-1.64-2.51-2.63-.35-.49-.63-1.01-.91-1.53-.24-.53-.5-1.06-.68-1.61-.41-1.08-.64-2.19-.85-3.29-.35-2.2-.38-4.39-.27-6.54,.04-.66,.6-1.17,1.26-1.13,.64,.04,1.13,.56,1.13,1.19v.02c0,2.03,.15,4.04,.57,5.95,.24,.94,.49,1.88,.87,2.74,.17,.44,.4,.84,.61,1.25,.25,.38,.48,.78,.76,1.12,1.09,1.42,2.57,2.4,4.27,2.86,.85,.23,1.74,.35,2.64,.36,.45,0,.91-.02,1.35-.07,.22-.02,.45-.05,.66-.09l.32-.06c.09-.02,.22-.05,.26-.06,1-.34,2.08,.2,2.42,1.2,.34,1-.2,2.08-1.2,2.42-.07,.02-.14,.04-.2,.06l-.12,.03Z"
          />
          <polygon
            style={{ fill: '#587072' }}
            points="63.78 64.34 63.78 87.53 61.33 87.53 59.35 63.86 63.78 64.34"
          />
          <path
            style={{ fill: '#587072' }}
            d="M63.78,87.53h-9.14s.31-3.22,4.18-3.38c3.88-.16,4.25,1.12,4.25,1.12l.71,2.26Z"
          />
          <polygon
            style={{ fill: '#587072' }}
            points="43.7 64.34 42.1 87.53 39.65 87.53 39.26 63.86 43.7 64.34"
          />
          <path
            style={{ fill: '#587072' }}
            d="M42.1,87.53h-9.14s.31-3.22,4.18-3.38c3.88-.16,4.25,1.12,4.25,1.12l.71,2.26Z"
          />
          <path
            style={{ fill: '#96c6c4' }}
            d="M54.31,9.27h6.69c4.62,0,8.37,3.75,8.37,8.37v6.32c0,1.97-1.6,3.57-3.57,3.57h-19.77c-1.04,0-1.89-.85-1.89-1.89v-6.21c0-5.61,4.56-10.17,10.17-10.17Z"
            transform="translate(19.76 -28.41) rotate(33.71)"
          />
          <path
            style={{ fill: '#bce1dd' }}
            d="M69.55,14.05c-3.68-2.81-10.13-6.72-10.13-6.72-2.9-1.94-10.43-1.83-13.89,2.76l-4.21,6.31c-1.01,1.51-.6,3.55,.91,4.56l20.27,13.52c3.2,2.14,7.52,1.38,9.79-1.72,.68-.93,1.4-1.95,2.14-3.06,2.65-3.97,3.57-9.21-4.88-15.66Zm-1.87,10.72l-3.2,4.79c-1.27,1.9-3.83,2.41-5.73,1.14l-15.53-10.36c-1.12-.75-1.42-2.26-.67-3.38l3.14-4.71c3.12-4.67,9.43-5.93,14.1-2.81l5.57,3.72c3.85,2.57,4.88,7.77,2.32,11.61Z"
          />
          <path
            style={{ fill: '#acd3ce' }}
            d="M70.21,14.57c2.82,4.64,1.66,8.58-.49,11.8-.86,1.29-1.7,2.49-2.49,3.56-1.57,2.15-3.98,3.32-6.46,3.4l1.73,1.15c3.2,2.14,7.52,1.38,9.79-1.72,.68-.93,1.4-1.95,2.14-3.06,2.58-3.86,3.52-8.92-4.22-15.14Z"
          />
          <g>
            <path
              style={{ fill: '#bce1dd' }}
              d="M39.26,44.03h24.58c1.57,0,2.84,1.27,2.84,2.84v19.98c0,1.57-1.27,2.84-2.84,2.84h-24.58c-1.57,0-2.84-1.27-2.84-2.84v-19.98c0-1.57,1.27-2.84,2.84-2.84Z"
            />
            <path
              style={{ fill: '#acd3ce' }}
              d="M63.84,44.03h-5.31c1.41,0,2.55,1.14,2.55,2.55v20.57c0,1.41-1.14,2.55-2.55,2.55h5.31c1.57,0,2.84-1.27,2.84-2.84v-19.98c0-1.57-1.27-2.84-2.84-2.84Z"
            />
            <path
              style={{ fill: '#587072' }}
              d="M54.36,44.03v6.7c0,.33,.27,.6,.6,.6s.6-.27,.6-.6v-6.7h-1.2Z"
            />
          </g>
          <path
            style={{ fill: '#587072' }}
            d="M29.16,24.56c.97,.08,.99,3.18-.24,4.79s-4.86,2.69-6.94,1.16-2.52-4.86-1.99-5.3c.53-.45,1.11-.19,1.56,1.22,.45,1.41,.79,.78,.66-.03-.15-.91-1-3.43,.25-3.85,1.12-.38,.97,2.06,1.48,2.75s.47-.04,.38-.91c-.1-.94-.19-2.98,.9-3.16,1.08-.18,.81,1.92,.84,3.19s.15,2.92,1.32,2.68c1.17-.24,.18-2.64,1.77-2.52Z"
          />
          <path
            style={{ fill: '#587072' }}
            d="M74.06,31.08c.62,.42,.2,1.28-.66,2.71-1.04,1.73-3.15,4.5-5.72,4.35s-4.51-2.45-4.58-3.14,.84-.99,1.99-.05c1.15,.94,1.16,.27,.5-.21-.7-.51-2.37-2.18-1.78-3.36s1.86,1.01,2.66,1.31c.81,.3,.37-.29-.18-.97-.59-.73-1.79-2.4-.98-3.14,.81-.74,2.01,1.06,2.72,2.1,.72,1.04,2.06,2.75,3.14,2.22,1.23-.6,1.82-2.54,2.88-1.82Z"
          />
          <path
            style={{ fill: '#587072' }}
            d="M65.34,46.45s0,.01,0,.03v.05s.02,.1,.02,.1l.04,.22c.03,.15,.06,.29,.09,.44,.07,.29,.15,.59,.24,.87,.18,.57,.41,1.12,.68,1.59,.27,.48,.59,.89,.94,1.19,.35,.3,.71,.5,1.13,.6,.41,.1,.89,.11,1.41,.01,.54-.1,1.02-.27,1.43-.51,.41-.25,.75-.57,1.03-.97,.28-.4,.49-.89,.62-1.42,.27-1.08,.26-2.32,.04-3.55-.21-1.24-.61-2.47-1.15-3.65-1.08-2.36-2.69-4.51-4.69-6.17h0c-.53-.45-.61-1.24-.16-1.77,.43-.52,1.21-.6,1.74-.19,1.19,.92,2.26,1.99,3.2,3.15,.94,1.17,1.76,2.43,2.43,3.78,.67,1.35,1.19,2.78,1.51,4.3,.16,.76,.26,1.54,.28,2.34,.02,.8-.04,1.62-.22,2.45-.18,.83-.5,1.67-.99,2.44-.49,.77-1.16,1.46-1.94,1.96-.78,.5-1.64,.82-2.47,1-.85,.19-1.8,.24-2.76,.05-.96-.19-1.88-.65-2.6-1.24-.73-.6-1.29-1.3-1.74-2.03-.45-.73-.78-1.47-1.05-2.23-.13-.38-.25-.76-.35-1.14-.05-.19-.1-.39-.14-.58l-.06-.3-.03-.16-.03-.19c-.13-.98,.56-1.89,1.55-2.02,.98-.13,1.89,.56,2.02,1.55Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const FallbackQuestionImage: any = (props: any) => (
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.42 107.48" {...props}>
    <defs>
      <linearGradient id="_Áåçûìÿííûé_ãðàäèåíò_87" data-name="Áåçûìÿííûé ãðàäèåíò 87" x1="2.24" y1="100.98" x2="154.84" y2="100.98" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#d9d6d7"/>
        <stop offset=".53" stopColor="#aca5a7"/>
        <stop offset="1" stopColor="#d9d6d7"/>
      </linearGradient>
    </defs>
    <g id="_Ñëîé_1" data-name="Ñëîé 1">
      <g>
        <g>
          <path
            style={{ fill: '#5fe6ed' }}
            d="M141.58,30.55h15.88c5.02,0,7.27-1.61,7.27-8.78s-.38-9.01-.38-13.31-1.52-8.46-9.28-8.46-30.39,1.31-35.49,1.31-6.74,3.75-6.74,8.93,.14,11.86,.14,14.34,1.91,5.66,6.69,5.66,11.99-.16,11.99-.16c0,0,.01,4.61-5.08,8.34-.41,.3-.2,.96,.31,.96,2.52,.03,8.03-.98,14.68-8.83Z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M136.77,17.18v-.85c0-.65,.13-1.2,.4-1.64,.26-.45,.74-.88,1.41-1.31,.54-.34,.92-.64,1.16-.91,.23-.27,.35-.58,.35-.93,0-.28-.13-.5-.38-.66s-.58-.24-.98-.24c-1,0-2.17,.35-3.51,1.06l-1.38-2.7c1.65-.94,3.37-1.41,5.16-1.41,1.48,0,2.64,.32,3.48,.97,.84,.65,1.26,1.53,1.26,2.65,0,.8-.19,1.49-.56,2.08-.37,.59-.97,1.14-1.79,1.66-.7,.45-1.13,.78-1.31,.99-.18,.21-.26,.46-.26,.74v.51h-3.05Zm-.43,3.48c0-.61,.18-1.08,.53-1.41,.35-.33,.88-.5,1.56-.5s1.17,.17,1.52,.5c.35,.33,.53,.8,.53,1.41s-.18,1.07-.55,1.4c-.37,.33-.87,.5-1.5,.5s-1.17-.16-1.54-.49c-.37-.33-.56-.8-.56-1.41Z"
          />
        </g>
        <g>
          <g>
            <path
              style={{ fill: '#00ccd3' }}
              d="M161.33,87.99c.04-1.84-.06-7.93-5.79-13.87-5.34-5.54-13.64-6.27-16.75-4.64,2.37-3.32,1.55-11.41-2.69-16.89-6.84-8.82-16.23-7.33-18.59-7.03-.1-.97-.51-3.51-2.27-6.31-2.02-3.21-6.26-7.37-15.05-8.91-9.8-1.72-16.25,.81-19.93,3.23-3.25,2.14-4.95,4.52-5.51,5.41-1.85-1.3-11.12-7.14-21.22-1.55-4.55,2.51-7.04,5.92-8.39,9.09-3.71-1.07-6.61,.17-7.81,.85,0,0-9.56-3.76-15.23,4.3-5.67,8.05-1.03,14.71-1.03,14.71,0,0-7.88,.21-14.59,8.02C-3.36,85.84,.93,97.47,.93,97.47H170.06c.2,0,.37-.16,.37-.37,0-7.02-7.42-8.81-9.1-9.12Z"
            />
            <path
              style={{ fill: '#00f3e8' }}
              d="M22.03,97.26h116.75s.37-4.92-4.08-6.93-8.41,1.13-8.41,1.13c0,0-.23-2.87-3.53-3.66-3.29-.79-4.87,1.64-4.87,1.64,0,0,1.74-7.86-5.54-12.03-7.87-4.51-15.39,1.96-15.39,1.96,0,0-7.87-6.93-18.86-3.66-9.97,2.96-11.7,11.71-11.7,11.71,0,0-.85-3.37-4.65-4.42-4.41-1.22-7.3,.99-7.3,.99,0,0-2.35-8.65-13.16-6.88-11.77,1.93-10.42,10.77-10.42,10.77,0,0-4.2-1.76-7.5,1.28-3.3,3.04-1.34,8.1-1.34,8.1Z"
            />
            <g>
              <path
                style={{ fill: '#6bdbc8' }}
                d="M14.63,63.4l-.69-.41,.41-.69c.11-.18,.05-.42-.14-.53-.18-.11-.42-.05-.53,.14l-.41,.69-.69-.41c-.18-.11-.42-.05-.53,.14-.11,.18-.05,.42,.14,.53l.69,.41-.41,.69c-.11,.18-.05,.42,.14,.53,.18,.11,.42,.05,.53-.14l.41-.69,.69,.41c.18,.11,.42,.05,.53-.14,.11-.18,.05-.42-.14-.53Z"
              />
              <path
                style={{ fill: '#6bdbc8' }}
                d="M144.89,65.72l-.44-.26,.26-.44c.07-.12,.03-.27-.09-.34s-.27-.03-.34,.09l-.26,.44-.44-.26c-.12-.07-.27-.03-.34,.09s-.03,.27,.09,.34l.44,.26-.26,.44c-.07,.12-.03,.27,.09,.34s.27,.03,.34-.09l.26-.44,.44,.26c.12,.07,.27,.03,.34-.09s.03-.27-.09-.34Z"
              />
              <path
                style={{ fill: '#6bdbc8' }}
                d="M36.75,44.35l-.57-.34,.34-.57c.09-.15,.04-.35-.11-.44-.15-.09-.35-.04-.44,.11l-.34,.57-.57-.34c-.15-.09-.35-.04-.44,.11s-.04,.35,.11,.44l.57,.34-.34,.57c-.09,.15-.04,.35,.11,.44,.15,.09,.35,.04,.44-.11l.34-.57,.57,.34c.15,.09,.35,.04,.44-.11,.09-.15,.04-.35-.11-.44Z"
              />
            </g>
            <path
              style={{ fill: 'url(#_Áåçûìÿííûé_ãðàäèåíò_87)' }}
              d="M154.81,97.61s.61,.81-3.69,1.7c-5.05,1.04-17.33,.26-18.54,2.18-1.31,2.1,8.37,1.51,8.26,3.97-.09,2.09-6.68,2.01-14.1,2.01H43.56c-6.01,0-4.92-1.82-4.07-2.65,1.24-1.2,.69-2.31-4.72-2.47-5.25-.15-9.09-.28-11.96-.28-7.64,0-6.14-2.52-12.09-2.52-3.85,0-8.48-.97-8.48-2.09,24.6-1.95,51.24-3.11,79.67-2.98,25.88,.12,50.25,1.28,72.9,3.12Z"
            />
          </g>
          <path
            style={{ fill: '#728589' }}
            d="M67.19,104h9.75s-.1-3.59-3.75-4.1c-1.33-.19-2.45-.19-3.35-.13l1.22-13.24-4.33-1.45,.9,15.07c-.15,.05-.24,.08-.24,.08l-.22,3.77Z"
          />
          <path
            style={{ fill: '#728589' }}
            d="M89.68,104h9.75s-.1-3.59-3.75-4.1c-1.33-.19-2.45-.19-3.35-.13l1.22-13.24-4.33-1.45,.9,15.07c-.15,.05-.24,.08-.24,.08l-.22,3.77Z"
          />
          <path
            style={{ fill: '#daf2f0' }}
            d="M60.53,66.7c0,4.29,.11,11.01,.17,16.58,.02,1.8,1.49,3.25,3.29,3.25h31.38c1.82,0,3.29-1.47,3.29-3.29v-21.38s.82-6.25-7.12-6.25c-9.93,0-19.3,.5-24.71,1.43-5.41,.94-6.3,5.73-6.3,9.66Z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M65.63,83.28c-.06-5.57-.17-12.29-.17-16.58,0-3.92,.89-8.72,6.3-9.66,4.69-.81,10.88-1.29,18.9-1.41-.35-.02-.7-.03-1.08-.03-9.93,0-17.34,.5-22.75,1.43-5.41,.94-6.3,5.73-6.3,9.66,0,4.29,.11,11.01,.17,16.58,.02,1.8,1.49,3.25,3.29,3.25h4.93c-1.8,0-3.27-1.45-3.29-3.25Z"
          />
          <path
            style={{ fill: '#daf2f0' }}
            d="M66.24,54.35h25.2c5.38,0,7.22-2.68,7.22-10.26s-4.31-17.75-17.82-17.75-18.58,8.13-19.68,15.63c-1.11,7.5-.23,12.38,5.08,12.38Z"
          />
          <path 
            style={{ fill: '#fff' }}
            d="M65.58,42.39c1.07-7.25,6.54-13.34,19.62-13.34,2.46,0,4.58,.11,6.4,.32-2.66-1.85-6.18-3.02-10.77-3.02-13.51,0-18.58,8.13-19.68,15.63-1.11,7.5-.23,12.38,5.08,12.38h4.27c-5.14,0-6-4.71-4.92-11.96Z"
          />
          <path
            style={{ fill: '#eff' }}
            d="M96.52,44.1c0-2.46-.45-5.18-1.53-7.75-.85-2.04-2.84-3.36-5.05-3.36h-12.21c-6.81,0-11.93,7.22-10.26,13.02,1.67,5.8,8.7,4.95,14.61,4.95h9.96c2.26,0,4.16-1.72,4.35-3.97,.08-.87,.11-1.83,.11-2.89Z"
          />
          <path
            style={{ fill: '#6e868e' }}
            d="M79.67,41.58c0,1.25-1.01,2.26-2.26,2.26s-2.26-1.01-2.26-2.26,1.01-2.26,2.26-2.26,2.26,1.01,2.26,2.26Z"
          />
          <circle
            style={{ fill: '#6e868e' }}
            cx="92.26" cy="41.58" r="1.37"
          />
          <path
            style={{ fill: '#728589' }}
            d="M58.63,83.03c-.34-2.58-1.85-3.72-3.25-3.91-.01,0-.02,0-.03,0-.05-.02-.09-.04-.15-.05-1.52-.41-3.25,.12-4.11,.96-1.13,1.09-1.17,2.64-1.17,2.64-.04,.16-.05,.33-.02,.5l.26,2.01c.09,.67,.64,1.14,1.22,1.04,.29-.05,.54-.23,.7-.49v.22c.04,.68,.54,1.21,1.13,1.18,.25-.01,.47-.12,.64-.29h0c-.07,.65,.33,1.23,.88,1.3l.14,.02c.55,.08,1.06-.38,1.12-1.01l.12-1.16v-3.38s.93,.26,1.09,1.91,1.77,1.11,1.43-1.47Z"
          />
          <path
            style={{ fill: '#728589' }}
            d="M64.83,60.92c-.3-.99-1.45-1.44-2.34-.91-4.18,2.49-7.03,6.28-8.33,10.11-2.02,5.96-1.51,11.69-1.51,11.69l2.44,.28c.66-10.12,4.99-14.69,8.08-16.68,1.49-.96,2.17-2.79,1.65-4.48h0Z"
          />
          <path
            style={{ fill: '#728589' }}
            d="M79.07,59.94l-3.18-1.68c-.49-.26-.68-.87-.42-1.37h0c.26-.49,.87-.68,1.37-.42l3.18,1.68c.49,.26,.68,.87,.42,1.37h0c-.26,.49-.87,.68-1.37,.42Z"
          />
          <path
            style={{ fill: '#2d769e' }}
            d="M78.55,33.45c-4.96-.32-9.24,3.45-9.56,8.42-.32,4.96,3.45,9.24,8.42,9.56,4.96,.32,9.24-3.45,9.56-8.42,.32-4.96-3.45-9.24-8.42-9.56Zm-1.03,16.27c-4.02-.26-7.07-3.72-6.82-7.75,.26-4.02,3.72-7.07,7.75-6.82,4.02,.26,7.07,3.72,6.82,7.75-.26,4.02-3.72,7.07-7.75,6.82Z"
          />
          <path
            style={{ fill: '#2d769e' }}
            d="M76.15,71.24h0c-.54-.03-.96-.5-.92-1.05l1.23-19.3,1.97,.13-1.23,19.3c-.03,.54-.5,.96-1.05,.92Z"
          />
          <path
            style={{ fill: '#728589' }}
            d="M80.01,58.15c-.76-.4-1.35,.39-1.65,.94-.07-.07-.15-.13-.24-.18l-3.18-1.68c-.49-.26-1.11-.07-1.37,.42-.22,.42-.11,.94,.24,1.24-.38-.02-.76,.17-.95,.53-.26,.49-.07,1.11,.42,1.37l.34,.18c-.49-.26-1.11-.07-1.37,.42s-.07,1.11,.42,1.37l3.18,1.68s2.98,1.6,4.58-1.42,.69-4.27-.43-4.86Z"
          />
          <path
            style={{ fill: '#728589' }}
            d="M91.36,55.68h0c4.82,.42,6.48,2.55,7.04,4.2,1.28,.31,2.59,1.04,3.05,2.65,.6,2.11-.08,5.2-6.68,4.68s-14.53-6.1-14.53-6.1l-.76,2.26s4.13,3.34,10.35,5.82c5.65,2.26,15.34,1.9,15.34-5.86s-11.71-7.65-13.81-7.65Z"
          />
          <path
            style={{ fill: '#daf2f0' }}
            d="M63.43,23.77c-1.38-.62-2.66-.92-3.55-1.07-.17-.77-.9-1.29-1.69-1.2-.85,.1-1.46,.87-1.37,1.72,.1,.85,.87,1.46,1.72,1.37,.59-.07,1.06-.46,1.27-.97,.83,.15,2.02,.43,3.28,1,3.16,1.41,5.31,3.81,6.39,7.11l.86-.28c-1.46-4.45-4.57-6.62-6.92-7.67Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
