import * as Yup from 'yup';

export const REQUIRED_MESSAGE = 'Полето е задължително.';
export const REQUIRED_MESSAGE_TO_LONG = (symbol: number) =>
  `Моля, използвайте до ${symbol} символа.`;
export const REQUIRED_MESSAGE_ONE_CHOOSE =
  'Необходимо е да изберете поне една опция.';

export const NEWS_TITLE_LENGTH = 120;
export const NEWS_TITLE_LENGTH_MESSAGE = 'Полето не може да съдържа повече от 120 символа.';
export const NEWS_DESCRIPTION_LENGTH = 70;
export const NEWS_DESCRIPTION_LENGTH_MESSAGE = 'Полето не може да съдържа повече от 70 символа.';

export const validationSchemaCrud = () => ({
  news: Yup.object().shape({
    titleBg: Yup.string().required(REQUIRED_MESSAGE).max(NEWS_TITLE_LENGTH, NEWS_TITLE_LENGTH_MESSAGE),
    titleEn: Yup.string().required(REQUIRED_MESSAGE).max(NEWS_TITLE_LENGTH, NEWS_TITLE_LENGTH_MESSAGE),
    descriptionBg: Yup.string().notRequired().max(NEWS_DESCRIPTION_LENGTH, NEWS_DESCRIPTION_LENGTH_MESSAGE),
    descriptionEn: Yup.string().notRequired().max(NEWS_DESCRIPTION_LENGTH, NEWS_DESCRIPTION_LENGTH_MESSAGE),
    contentBg: Yup.string().required(REQUIRED_MESSAGE),
    contentEn: Yup.string().required(REQUIRED_MESSAGE),
    tag: Yup.number().required(REQUIRED_MESSAGE),
    roles: Yup.array().min(1, REQUIRED_MESSAGE_ONE_CHOOSE),
  }),
  notifications: Yup.object().shape({
    messageBg: Yup.string().required(REQUIRED_MESSAGE),
    messageEn: Yup.string().required(REQUIRED_MESSAGE),
    endDate: Yup.date().nullable().required(REQUIRED_MESSAGE),
    roles: Yup.array().min(1, REQUIRED_MESSAGE_ONE_CHOOSE),
  })
})