import { Link } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

import { NumericValue } from 'components/numeric-value';
import Breadcrumbs from 'components/breadcrumbs';
import LoadingSkeleton from 'components/loading-skeleton';

import { useNomenclaturesContext } from 'hooks/useNomenclaturesContext';

import { gradesPageBreadcrumbs } from './breadcrumbs';

type Props = {};

function GradesPage({}: Props) {
  const { basicClasses } = useNomenclaturesContext();

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs
          items={gradesPageBreadcrumbs()}
        />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h1'>
            Класове
          </Typography>
          <Typography variant='body1'>
            Моля, изберете клас, за да видите предметите към него.
          </Typography>
        </Grid>
        {basicClasses.isLoading && (
          <LoadingSkeleton
            margin={1}
            itemsCount={12}
            height={155}
            gridXs={6}
            gridSm={4}
            gridMd={3}
            gridLg={2}
          />
        )}
        {
          basicClasses.isLoading || (
            basicClasses.items.map(({ id, name }) => (
              <Grid item key={id} xs={6} sm={4} md={3} lg={2}>
                <Link to={`/library/${name}`}>
                  <Card sx={{ py: 1 }}>
                    <CardContent>
                      <Box display='flex' alignItems='center' flexDirection='column'>
                        <NumericValue numeric={+name} variant='circlePrimaryLarge' />
                        <Typography variant='h5' pt={2}>Клас</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))
          )
        }
      </Grid>
    </>
  );
}

GradesPage.defaultProps = {};

export default GradesPage;
