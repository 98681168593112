import React from 'react';
import { ButtonBase } from '@mui/material';
import { TooltipStyled, ContentStyled, TitleStyled } from './styles';

type Props = {
  title?: string | React.ReactElement,
  content?: string | React.ReactElement,
  children: React.ReactElement,
  [prop: string]: any
};

type TooltipContentProps = {
  title?: string | React.ReactElement,
  children?: string | React.ReactElement
}

const TooltipContent = ({ title, children }: TooltipContentProps) => {
  return (
    <>
      {title && (
        <TitleStyled p={[1.5, 2]}>
          {title}
        </TitleStyled>
      )}
      {children && (
        <ContentStyled p={[1.5, 2]}>
          {children}
        </ContentStyled>
      )}
    </>
  )
}

function Tooltip({ children, title, content, ...tooltipPropsToPropagate }: Props) {

  return (
    <TooltipStyled
      componentsProps={{
        tooltip: {
          sx: {
            padding: 0
          }
        }
      }}
      title={
        <TooltipContent title={title}>
          {content}
        </TooltipContent>
      }
      {...tooltipPropsToPropagate}
    >
      <ButtonBase>
        {children}
      </ButtonBase>
    </TooltipStyled>
  );
}

Tooltip.defaultProps = {
};

export default Tooltip;
