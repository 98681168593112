import { useEffect, useState } from 'react';
import { Box, Chip, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { Clear as ClearIcon, ExpandMore } from '@mui/icons-material';
import { Checkbox } from 'components/checkbox';
import { ClassOption } from './types';

type Props = {
  name?: string;
  options: ClassOption[];
  onSelect: (event: SelectChangeEvent<number[]>) => void;
  onRemove: (id: number) => void;
  value: number[];
};

const getSelectedOptions = (selectedIds: number[], options: ClassOption[]) => {
  return selectedIds.reduce((acc, id) => {
    const item = options.find(x => x.id == id);
    if (item) acc.push(item);
    return acc;
  }, [] as ClassOption[]);
};

const ClassesSelect = ({ onSelect, onRemove, options, name, value }: Props) => {
  const [selected, setSelected] = useState<ClassOption[]>([]);

  useEffect(() => {
    const selectedOptions = getSelectedOptions(value, options);
    setSelected(selectedOptions);
  }, [value]);

  return (
    <>
      <Select
        multiple
        displayEmpty
        disabled={!Boolean(options.length)}
        name={name}
        value={value}
        onChange={onSelect}
        renderValue={() => 'Избери'}
        input={<OutlinedInput />}
        IconComponent={ExpandMore}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <ListItemText primary={option.label} primaryTypographyProps={{ style: { whiteSpace: "normal" } }}/>
            <Checkbox checked={selected.some(x => x.id === option.id)} />
          </MenuItem>
        ))}
      </Select>
      {selected.length > 0 && (
        <Box
          alignItems="center"
          display="flex"
          flexWrap="wrap"
          gap={1}
          pt={1}
        >
          {selected.map((option) => (
            <Chip
              key={`class-${option.id}`}
              deleteIcon={<ClearIcon />}
              label={`${option.label} клас`}
              onDelete={() => onRemove(option.id)}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default ClassesSelect;