import { useState } from 'react';
import { useFormik } from 'formik';

import {
  Box, Button, Grid, FormControl, TextField, Typography, useMediaQuery, Chip,
  Tooltip, Select, ListItemText, Checkbox, MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormDialog } from 'components/form-dialog';
import { MeetingButtons } from 'views/configuration/constants';

import { MeetingClass, ShiftClass } from 'api/types';
import { useCreateMeeting } from 'services/queryHooks/meetings-hooks';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTableContext } from 'views/weekly-schedule/components/table/table-context-provider';

import { Notifications } from './constants';
import { palette } from 'theme/components';
import { validationSchema } from './validate';
import { bg } from 'date-fns/locale';
import { Check, Clear, ExpandMore } from '@mui/icons-material';
import { CheckboxIcon } from '../../components/icons/svg';

type Props = {
  subjectId: number,
  subjectName: string,
  classes: Partial<ShiftClass>[],
  date: Date,
  withClassSelect?: boolean,
  initialFormValues?: {
    date?: string;
    classes?: number[];
  },
  onSuccess?: () => void;
  onDecline?: () => void;
};

const SubmitMeeting = ({ subjectId, subjectName, classes, date, withClassSelect = false, initialFormValues = {}, onSuccess, onDecline }: Props) => {
  const snackbarContext = useSnackbarContext();
  const tableContext = useTableContext();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const addNotification = (type: 'success' | 'error') => {
    snackbarContext?.addSnackbar({
      key: crypto.randomUUID(),
      title: Notifications[type],
      severity: type
    });
  };

  const {
    mutate: mutateCreateMeeting,
    isLoading: isLoadingCreateMeeting
  } = useCreateMeeting({
    onSettled: (_: any, error: Error) => {
      if (!error) {
        addNotification('success');
        if (onSuccess) {
          onSuccess();
        }
      }
    }
  });

  const initialValues = {
    date,
    classes: withClassSelect ? [] : classes.map((c: Partial<ShiftClass>) => c.id),
    ...initialFormValues
  };

  const onSubmit = (values: any) => {
    const submitClasses: MeetingClass[] = classes
      .filter((c) => values.classes.indexOf(c.id) >= 0)
      .map((c) => ({ classId: c.id, basicClassId: c.basicClassId, name: c.name }) as MeetingClass);
    const submitValues = {
      classes: submitClasses,
      subjectId: subjectId,
      subjectName,
      date: values.date,
    };

    mutateCreateMeeting(submitValues);
    onDialogClose();
  };

  const {
    values,
    isSubmitting,
    handleSubmit,
    resetForm,
    setFieldValue,
    errors,
    touched
  } = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true
  });

  const onDialogClose = () => {
    setIsDialogOpen(false);
    resetForm();
  };

  const renderClassChip = (classId: number) => {
    const selectedClass = classes?.find(x => x.id === classId);

    if (!Boolean(selectedClass)) return null;

    const { name, className }: any = selectedClass;

    return (
      <Chip
        key={`id-${classId}`}
        deleteIcon={withClassSelect ? <Clear /> : <></>}
        label={`${name || className} клас`}
        onDelete={() => {
          if (withClassSelect) {
            const updatedClasses = values.classes.filter((c: any) => c !== classId);
            setFieldValue("classes", updatedClasses);
          }
        }}
        sx={{ background: palette.grey[50] }}
      />
    );
  };

  const renderCreateBtn = () => {
    const btn = (
      <LoadingButton
        size="medium"
        color={tableContext.meetingToolConfig ? MeetingButtons[tableContext.meetingToolConfig].id as any : 'primary'}
        disableElevation
        variant="contained"
        endIcon={tableContext.meetingToolConfig ? MeetingButtons[tableContext.meetingToolConfig].logo : null}
        onClick={() => setIsDialogOpen(true)}
        loading={isLoadingCreateMeeting}
        disabled={!tableContext.meetingToolConfig}
      >
        Създаване
      </LoadingButton>
    );

    if (!tableContext.meetingToolConfig) {
      return (
        <Tooltip title="Директорът на Вашата организация все още не е конфигурирал тази функционалност.">
          <span>{btn}</span>
        </Tooltip>
      );
    }

    return btn;
  }

  return (
    <>
      {renderCreateBtn()}
      <FormDialog
        open={isDialogOpen}
        onClose={onDialogClose}
        maxWidth="sm"
        title="Създаване на виртуална класна стая"
        actions={(
          <>
            <LoadingButton
              disableElevation
              variant="contained"
              type="submit"
              loading={isLoadingCreateMeeting || isSubmitting}
            >
              Създай
            </LoadingButton>
            <Button
              disableElevation
              variant="outlined"
              onClick={() => { 
                onDialogClose(); 
                if (onDecline) onDecline(); 
              }}
              disabled={isLoadingCreateMeeting || isSubmitting}
            >
              Откажи
            </Button>
          </>
        )}
        handleSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="secondary" fontWeight={600} pb={1}>Дата</Typography>
            <FormControl fullWidth sx={{ my: 2 }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={bg}
              >
                <StaticDatePicker
                  displayStaticWrapperAs={tabletAndUp ? "desktop" : "mobile"}
                  value={values.date}
                  onChange={(e) => {
                    setFieldValue('date', e);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} variant="standard" />
                  )}
                  disabled={!withClassSelect}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography color="secondary" fontWeight={600} pb={1}>Класове</Typography>
            <FormControl fullWidth>
              {withClassSelect && (
                <Select
                  displayEmpty
                  multiple
                  value={values.classes}
                  onChange={(e) => {
                    setFieldValue('classes', e.target.value);
                  }}
                  name='classes'
                  renderValue={() => 'Избери'}
                  IconComponent={ExpandMore}
                >
                  {classes?.map(({ id, className }: any) => {
                    return (
                      <MenuItem key={id} value={id}>
                        <ListItemText primary={className} />
                        <Checkbox
                          checked={values.classes?.indexOf(id) > -1}
                          checkedIcon={<Check sx={{ border: `1px solid ${palette.grey.dark}` }} />}
                          icon={<CheckboxIcon sx={{ border: `1px solid ${palette.grey.dark}` }} />}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              <Box
                alignItems="center"
                display="flex"
                flexWrap="wrap"
                gap={1}
                mt={withClassSelect ? 1 : 0}
              >
                {values.classes?.map((classId: any) => renderClassChip(classId))}
              </Box>
              {
                touched.classes && errors.classes && (
                  <Typography variant='caption' color='error' className='validation-error'>{errors.classes}</Typography>
                )
              }
            </FormControl>
          </Grid>
        </Grid>
      </FormDialog>
    </>
  );
};

export default SubmitMeeting;
