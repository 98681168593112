import React, { ReactNode } from "react";
import {
  Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography
} from "@mui/material";

export type Item = {
  id: string | number;
  name: string;
  [key: string]: any;
};

type Props = {
  name: string;
  items: Item[];
  startIcon: Function;
  renderEndIcon: (item: Item) => ReactNode;
  noItemsLabel: string;
  onItemClick?: (item: Item) => void;
};

const ItemsList = ({ name, items, startIcon, renderEndIcon, noItemsLabel, onItemClick }: Props) => {
  const renderItem = (item: Item) => (
    <>
      <ListItemIcon sx={{ minWidth: 37 }}>
        {startIcon(item)}
      </ListItemIcon>
      <ListItemText primary={item.name} />      
    </>
  );

  const hasAnyItem = items && items.length > 0;
  return (
    <Box sx={{ width: '100%' }}>
      <>
        {hasAnyItem && (
          <List>
            {items.map((item, index) => {
              const isLastItem = index < items.length - 1;
              return (
                <React.Fragment key={item.id}>
                  <ListItem
                    key={`${name}-${item.id}`}
                    secondaryAction={renderEndIcon(item)}
                    disableGutters
                  >
                    {onItemClick ? (
                      <ListItemButton onClick={() => onItemClick(item)} dense disableGutters>
                        {renderItem(item)}
                      </ListItemButton>
                    ) : renderItem(item)}
                  </ListItem>
                  {isLastItem && <Divider />}
                </React.Fragment>
              );
            })}
          </List>
        )}
        {hasAnyItem || (
          <Typography variant="body1" textAlign="center" pt={2}>{noItemsLabel}</Typography>
        )}
      </>
    </Box>
  )
};

export default ItemsList;
