import { getHomePageUrl } from 'utils/navigation';

export const breadcrumbs = (): any => [
  {
    href: getHomePageUrl(),
    label: 'Начало'
  },
  {
    href: '/homeworks',
    label: 'Домашни',
  },
];