import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { DEFAULT_NOTIFICATIONS_PAGINATION, REFETCH_NOTIFICATIONS_COUNTS_INTERVAL } from "components/header/components/constants";
import { LIST_NOTIFICATIONS_PAGINATION } from "routes/notifications/constants";
import { getNotificationCounts, getNotifications, postSeenNotifications } from "api/api";
import { PaginatedResult } from 'types/global';
import { NotificationItem, NotificationsCounts } from "api/types";

import { useSnackbarContext } from 'hooks/useSnackbarContext';

export const useUserNotificationsList = (paging?: { page: number, itemsPerPage: number }, isSeen?: boolean, options: Object = {}) =>
  useInfiniteQuery<PaginatedResult<NotificationItem>, Error>(
    ['notifications', { paging: paging, isSeen: isSeen }],
    ({ pageParam = paging || DEFAULT_NOTIFICATIONS_PAGINATION }) => getNotifications(pageParam, isSeen),
    {
      ...options,
      getNextPageParam: getNextPage,
    }
  );

export const useUserNotificationsCounts = (options: any = { refetchInterval: REFETCH_NOTIFICATIONS_COUNTS_INTERVAL.milliseconds }) =>
  useQuery<NotificationsCounts, Error>(
    ['notificationscounts'],
    () => getNotificationCounts(),
    {
      ...options,
    }
  );

export const useUpdateNotificationSeenStatus = (openMenu?: boolean, options: any = {}) => {
  const queryClient = useQueryClient();
  const snackbarContext = useSnackbarContext();
  return useMutation(
    (notifications: Object) => postSeenNotifications(notifications),
    {
      ...options,
      onSuccess: (response, request: any) => {
        if (request?.notificationsIds.length === 0) {
          snackbarContext?.addSnackbar({
            key: crypto.randomUUID(),
            title: 'Всички нотификации са маркирани като прочетени',
            severity: 'success'
          });
        }
        if (options.onSuccess) {
          options.onSuccess();
        }
        queryClient.invalidateQueries(['notificationscounts']);
        Boolean(openMenu) ?
          queryClient.invalidateQueries(['notifications']) :
          queryClient.invalidateQueries(['notifications', { paging: LIST_NOTIFICATIONS_PAGINATION }])
      }
    }
  );
};

const getNextPage = (response: any) => response?.paging?.hasMore ? { ...response?.paging, page: response?.paging?.page + 1 } : undefined;