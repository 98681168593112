import { Roles } from 'enums/userRoles';
import { useUserRoleContext } from 'hooks/useUserRoleContext';

export const useSharedViewContext = (): string => {
  const role = useUserRoleContext();
  let viewName = '';
  switch(role){
    case Roles.AcademicTeacher:
    case Roles.ClassMaster:
    case Roles.Teacher:
    case Roles.Director:
    case Roles.MOE:
    case Roles.MOEExpert:
    case Roles.RME:
    case Roles.RMEExpert:
    case Roles.Publisher:
      viewName = 'teacher';
      break;
    case Roles.Student:
    case Roles.Parent:
      viewName = 'student';
      break;
    default:
      console.warn('Could not resolve view name by user\'s role.')
      break;
  }

  return viewName;
};
