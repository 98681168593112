import { Link } from 'react-router-dom';
import { Grid, CardContent, Typography, Box, Card } from '@mui/material';

import Breadcrumbs from 'components/breadcrumbs';
import { CardIconAvatar } from 'components/card';
import LoadingSkeleton from 'components/loading-skeleton';
import FallbackNotFound from 'components/fallback/fallback-not-found';

import { getSubjectConfig } from 'config/subject-config-data';
import { useGetProfAreas } from 'services/queryHooks/study-aids-hooks';
import { studyAidBreadCrumbs as profStudyAidBreadCrumbs } from './utils';

const AVATAR_ICON_ID = 999; /* Since we dont have icons for profAreas, use default icon */
const SUBJECT_CONFIG = getSubjectConfig(AVATAR_ICON_ID);

export const ProfArea = () => {
  const { data: profAreas, isLoading: isProfAreasLoading, error: profAreasError } = useGetProfAreas();

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={profStudyAidBreadCrumbs()} />
      </Box>
      {isProfAreasLoading && <LoadingSkeleton item itemsCount={6} height={83}  />}
      {!isProfAreasLoading && profAreas && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1">
              Моите професионални направления
            </Typography>
            <Typography variant='body1'>
              Моля, изберете професионално направление, за да видите учебните помагала към него.
            </Typography>
          </Grid>
          {profAreas.map((profArea) => (
            <Grid item xs={12} key={`profArea-${profArea.id}`}>
              <Link to={`/study-aids/${profArea.code}`}>
                <Card sx={{ cursor: 'pointer' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center" gap={1}>
                      <CardIconAvatar
                        iconBackdrop={SUBJECT_CONFIG.color}
                        icon={SUBJECT_CONFIG.icon({
                          fontSize: 'large',
                        })}
                        withShadow
                      />
                      <Typography variant="h4">
                        {profArea.name}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
      {!isProfAreasLoading && profAreasError && <FallbackNotFound />}
    </>
  );
}

export default ProfArea;