import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SvgIconProps,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Check, Clear, ExpandMore, Info, PushPinOutlined } from '@mui/icons-material';
import { useFormik } from 'formik';
import { isWeekend } from 'date-fns';
import { bg } from 'date-fns/locale';

import { FormDialog } from 'components/form-dialog';
import { CheckboxIcon } from 'components/icons/svg';
import { Loader } from 'components/loader/loader';
import { SelectDataType } from 'components/select/types';
// TODO: remove:
import FallbackText from 'components/FallBackText';

import { useGetTeacherClassesBySubject } from 'services/queryHooks/curriculum-hooks';
import { useGetSchedule } from 'services/queryHooks/schedule-hooks';
import { useAssignContent } from 'services/queryHooks/contents-hooks';
import { ClassesContentAssignmentRequest, ScheduleType, Shift, TeacherCurriculum } from 'api/types';
import { useSnackbarContext } from 'hooks/useSnackbarContext';

import { AssignmentType, Notifications, NO_CLASS_SCHEDULE_MESSAGE, TooltipMessages } from './constants';
import { getWeekScheduleRangeByDate } from 'utils/dateFormat';
import { palette } from 'theme/components';
import { validationSchema } from './validate';

import { SelectLabelStyled } from './styles';

type Props = {
  contentId: string,
  subject: {
    subjectId: number,
    isProfileSubject?: boolean
  },
  classId?: number | undefined,
  date?: Date | undefined,
  scheduleId?: number | undefined,
  IconComponent?: SvgIconProps,
  loadCurriculumOnStart?: boolean
};

const DEFAULT_INITIAL_VALUES = {
  type: AssignmentType.AllTime,
  scheduled: {
    classId: '',
    date: new Date(),
    scheduleId: '',
  },
  allTime: {
    classes: [] as number[]
  }
};

const AssignContent = ({
  contentId, subject: { subjectId, isProfileSubject }, classId, date, scheduleId, IconComponent, loadCurriculumOnStart = true
}: Props) => {
  const currentWeekScheduleRange = getWeekScheduleRangeByDate(date ?? DEFAULT_INITIAL_VALUES.scheduled.date);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hours, setHours] = useState<SelectDataType[]>([]);
  const [fromDate, setFromDate] = useState<string>(currentWeekScheduleRange.fromDate);
  const [toDate, setToDate] = useState<string>(currentWeekScheduleRange.toDate);

  const snackbarContext = useSnackbarContext();
  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const allPropsAvailable = Boolean(classId) && Boolean(date) && Boolean(scheduleId);

  const { data: teacherClasses, isLoading: areTeacherClassesLoading } = useGetTeacherClassesBySubject(
    subjectId,
    {
      select: (classes: TeacherCurriculum[]) => classes.reduce((classesObj, c: TeacherCurriculum) => {
        if (c.subjectId === +subjectId && c.isProfileSubject === isProfileSubject) {
          return { ...classesObj, [c.classId]: c };
        }

        return { ...classesObj };
      }, {}),
      enabled: loadCurriculumOnStart
    }
  );

  const {
    mutate: mutateAssignContent,
    isLoading: isLoadingAssignContent
  } = useAssignContent(contentId, {
    onSettled: (_: any, error: Error) => {
      setSubmitting(false);

      if (!error) {
        onDialogClose();

        snackbarContext?.addSnackbar({
          key: crypto.randomUUID(),
          title: Notifications.success,
          severity: 'success'
        });
      }
    },
  });

  const onSubmit = (values: any) => {
    let submitValues = { subjectId } as Partial<ClassesContentAssignmentRequest>;

    if (values.type == AssignmentType.Scheduled) {
      const { classId: selectedClassId, scheduleId: selectedScheduleId, date } = values.scheduled;
      const scheduleHour = hours?.find(x => x.id === selectedScheduleId)?.number;

      submitValues = {
        ...submitValues,
        classes: [{
          classId: selectedClassId,
          curriculumId: teacherClasses?.[selectedClassId]?.curriculumId
        }],
        scheduleId: selectedScheduleId,
        scheduleDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        ...(Boolean(scheduleHour) || scheduleHour === 0 ? { scheduleHour: scheduleHour } : {}),
        subjectName: teacherClasses?.[selectedClassId]?.subjectName,
        subjectTypeName: teacherClasses?.[selectedClassId]?.subjectTypeName,
        subjectTypeId: teacherClasses?.[selectedClassId]?.subjectTypeId,
        assignmentPeriod: AssignmentType.Scheduled
      };
    } else if (values.type == AssignmentType.AllTime) {
      const firstSelectedClassId = values.allTime.classes?.[0];
      submitValues = {
        ...submitValues,
        classes: values.allTime.classes
          .map((classId: number) => ({ classId, curriculumId: teacherClasses?.[classId]?.curriculumId })),
        subjectName: teacherClasses?.[firstSelectedClassId]?.subjectName,
        subjectTypeName: teacherClasses?.[firstSelectedClassId]?.subjectTypeName,
        subjectTypeId: teacherClasses?.[firstSelectedClassId]?.subjectTypeId,
        assignmentPeriod: AssignmentType.AllTime
      };
    }

    mutateAssignContent(submitValues as ClassesContentAssignmentRequest);
  };

  const initialValues = {
    type: allPropsAvailable ? AssignmentType.Scheduled : AssignmentType.AllTime,
    scheduled: {
      ...DEFAULT_INITIAL_VALUES.scheduled,
      ...(Boolean(classId) ? { classId } : {}),
      ...(Boolean(date) ? { date } : {}),
      ...(Boolean(scheduleId) ? { scheduleId } : {})
    },
    allTime: {
      ...DEFAULT_INITIAL_VALUES.allTime,
      ...(Boolean(classId) ? { classes: [classId] } : {})
    }
  };

  const {
    values,
    setFieldValue,
    setSubmitting,
    isSubmitting,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const { data: teacherSchedule, isLoading: isScheduleLoading, refetch: refetchGetSchedule } = useGetSchedule(
    {
      fromDate,
      toDate,
      type: ScheduleType.Teacher,
      subjectId,
      subjectTypeId: teacherClasses?.[values?.scheduled?.classId]?.subjectTypeId
    },
    {
      onSettled: (data: any[], error: Error) => {
        if (error) {
          setHoursOptionsSorted([]);
        } else {
          setHoursOptionsSorted(data);
        }
      },
      select: (shifts: Shift[]) => (
        shifts
          ?.flatMap((s: Shift) => s.days
            .flatMap((d) => d.hours
              .map((h) => ({ ...h, day: d.number, date: d.date, shift: s.name }))))
          .filter(x => x.subjectId === +subjectId)
      ),
      enabled: isDialogOpen && Boolean(Object.keys(teacherClasses).length) && Boolean(values?.scheduled?.classId)
    }
  );

  useEffect(() => {
    if (Boolean(teacherSchedule?.length)) {
      setHoursOptionsSorted(teacherSchedule);
    } else {
      setHours([]);
    }
  }, [values.scheduled.classId, values.scheduled.date, setFieldValue]);

  const setHoursOptionsSorted = (schedule: any[] = []) => {
    const hoursOptions: SelectDataType[] = [];

    const { classId: selectedClass, date: selectedDate } = values.scheduled;
    if (Boolean(selectedClass)) {
      schedule.forEach((h) => {
        const classSchedule = h?.classes.find((x: any) => x.id === selectedClass);
        if (classSchedule && h?.day === selectedDate.getDay()) {
          hoursOptions.push({
            id: classSchedule.neispuoScheduleId,
            label: `${(h?.number || h?.number === 0) ? h.number : ''} час ${h?.shift ? `(${h.shift})` : ''}`,
            number: h?.number
          });
        }
      });

      var sorted = hoursOptions.sort((a, b) => +a.label - +b.label);
      setHours(sorted);

      if (sorted.length === 1) {
        setFieldValue("scheduled.scheduleId", sorted[0].id);
      }
    }
  };

  const onChangeDate = (newDate: Date) => {
    setFieldValue("scheduled.scheduleId", '');

    const { fromDate: newFromDate, toDate: newToDate } = getWeekScheduleRangeByDate(newDate);
    const isSameWeek = newFromDate === fromDate;
    if (!isSameWeek) {
      setFromDate(newFromDate);
      setToDate(newToDate);
    }

    setFieldValue("scheduled.date", newDate);
  };

  const renderClassChip = (classId: number) => {
    const label: string = teacherClasses?.[classId]?.className;

    if (!Boolean(label)) return null;

    return (
      <Chip
        key={`id-${classId}`}
        deleteIcon={<Clear />}
        label={label}
        onDelete={() => {
          const updatedClasses = values.allTime.classes.filter((c: any) => c !== classId);
          setFieldValue("allTime.classes", updatedClasses);
        }}
      />
    );
  };

  const onDialogClose = () => {
    setIsDialogOpen(false);
    resetForm();
    setHours([]);
  };

  const hasCurriculum = (!areTeacherClassesLoading && Boolean(teacherClasses) && Boolean(Object.keys(teacherClasses)?.length));

  return hasCurriculum ? (
    <>
      <IconButton
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          setIsDialogOpen(true);
          refetchGetSchedule();
        }}
        disabled={isLoadingAssignContent}
      >
        <PushPinOutlined
          fontSize={tabletAndUp ? 'large' : 'medium'}
          sx={{ color: palette.grey.main }}
          {...IconComponent}
        />
      </IconButton>
      <FormDialog
        open={isDialogOpen}
        onClose={onDialogClose}
        onClick={(e: any) => e.stopPropagation()}
        maxWidth="sm"
        disableCloseOnBackdropClick
        title="Задаване на съдържание за този предмет"
        actions={(
          <>
            <LoadingButton
              disableElevation
              variant="contained"
              type="submit"
              loading={isLoadingAssignContent || isSubmitting}
            >
              Запиши
            </LoadingButton>
            <Button
              disableElevation
              variant="outlined"
              onClick={onDialogClose}
              disabled={isLoadingAssignContent || isSubmitting}
            >
              Откажи
            </Button>
          </>
        )}
        handleSubmit={handleSubmit}
      >
        <FormControl>
          <RadioGroup
            value={values.type}
            name="type"
            onChange={(event) => {
              resetForm();
              const newType = event.target.value;
              if (newType === AssignmentType.Scheduled.toString() && hours.length === 1) {
                setFieldValue("scheduled.scheduleId", hours[0].id);
              }

              handleChange(event);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12} display="flex" alignItems="center">
                  <FormControlLabel value={AssignmentType.Scheduled} control={<Radio color="primary" />} label="За конкретен учебен час" />
                  <Tooltip title={TooltipMessages.Scheduled}>
                    <Info sx={{ color: palette.primary[500] }} />
                  </Tooltip>
                </Grid>
                <Grid item xs={12} mx={4}>
                  {+values.type === AssignmentType.Scheduled && (
                    <>
                      <Grid item xs={12}>
                        <SelectLabelStyled>Паралелка</SelectLabelStyled>
                        <FormControl fullWidth>
                          <Select
                            displayEmpty
                            value={values.scheduled.classId}
                            onChange={handleChange}
                            name="scheduled.classId"
                            IconComponent={ExpandMore}
                            renderValue={(selected) => teacherClasses?.[selected]?.className || 'Избери'}
                          >
                            {areTeacherClassesLoading && <Loader />}
                            {Boolean(teacherClasses) && Object.keys(teacherClasses)?.map((key) => {
                              const { classId: id, className: name }: TeacherCurriculum = teacherClasses[key];
                              return (
                                <MenuItem key={id} value={id}>
                                  {name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        {Boolean(touched.scheduled?.classId) && Boolean(errors.scheduled?.classId) && (
                          <FormHelperText error>{errors.scheduled?.classId}</FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth sx={{ my: 2 }}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={bg}
                          >
                            <StaticDatePicker
                              displayStaticWrapperAs={tabletAndUp ? "desktop" : "mobile"}
                              value={values.scheduled.date}
                              onChange={(newDate: any) => onChangeDate(newDate)}
                              renderInput={(params: any) => (
                                <TextField {...params} variant="standard" />
                              )}
                              shouldDisableDate={(date: any) => isWeekend(date)}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                      {isScheduleLoading && <Loader />}
                      {(!isScheduleLoading && Boolean(hours?.length)) && (
                        <Grid item xs={12}>
                          <SelectLabelStyled>Учебен час</SelectLabelStyled>
                          <FormControl fullWidth>
                            <Select
                              displayEmpty
                              value={values.scheduled.scheduleId}
                              onChange={handleChange}
                              name="scheduled.scheduleId"
                              IconComponent={ExpandMore}
                              renderValue={(selected) => {
                                const name = hours?.find((x) => x.id === selected)?.label;
                                return name ? `${name}` : 'Избери';
                              }}
                            >
                              {hours.map(({ id, label }: SelectDataType) => (
                                <MenuItem key={id} value={id}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {Boolean(touched.scheduled?.scheduleId) && Boolean(errors.scheduled?.scheduleId) && (
                            <FormHelperText error>{errors.scheduled?.scheduleId}</FormHelperText>
                          )}
                        </Grid>
                      )}
                      {Boolean(values.scheduled.classId) && Boolean(values.scheduled.date) && !isScheduleLoading && !Boolean(hours?.length) && (
                        <FallbackText text={NO_CLASS_SCHEDULE_MESSAGE} />
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={12} display="flex" alignItems="center">
                  <FormControlLabel value={AssignmentType.AllTime} control={<Radio color="primary" />} label="За този предмет" />
                  <Tooltip title={TooltipMessages.AllTime}>
                    <Info sx={{ color: palette.primary[500] }} />
                  </Tooltip>
                </Grid>
                {+values.type === AssignmentType.AllTime && (
                  <Grid item xs={12} mx={4}>
                    <SelectLabelStyled>Паралелки</SelectLabelStyled>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        multiple
                        value={values.allTime.classes}
                        onChange={handleChange}
                        name="allTime.classes"
                        renderValue={() => 'Избери'}
                        IconComponent={ExpandMore}
                      >
                        {areTeacherClassesLoading && <Loader />}
                        {Boolean(teacherClasses) && Object.keys(teacherClasses)?.map((key) => {
                          const { classId: id, className: name }: TeacherCurriculum = teacherClasses[key];
                          return (
                            <MenuItem key={id} value={id}>
                              <ListItemText primary={name} />
                              <Checkbox
                                checked={values.allTime.classes?.indexOf(id) > -1}
                                checkedIcon={<Check sx={{ border: `1px solid ${palette.grey.dark}` }} />}
                                icon={<CheckboxIcon sx={{ border: `1px solid ${palette.grey.dark}` }} />}
                              />
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Box
                        alignItems="center"
                        display="flex"
                        flexWrap="wrap"
                        gap={1}
                        pt={3}
                      >
                        {values.allTime.classes?.map((classId: any) => renderClassChip(classId))}
                      </Box>
                      {Boolean(touched.allTime?.classes) && Boolean(errors.allTime?.classes) && (
                        <FormHelperText error>{errors.allTime?.classes}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </FormDialog>
    </>
  ) : null;
};

export default AssignContent;
