import { useEffect, useRef, useState } from 'react';
import { ExpandMoreRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, FormControl, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import Breadcrumbs from 'components/breadcrumbs';
import { Loader } from 'components/loader/loader';
import { useGetMeetingsToolConfiguration, useSetMeetingsToolConfiguration } from 'services/queryHooks/meetings-hooks';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { palette } from 'theme/components';
import { breadcrumbs, MeetingButtons, Notifications } from './constants';

import { ItemButton, SelectInputStyled } from './styles';

const Configuration = () => {
  const snackbarContext = useSnackbarContext();

  const selectRef = useRef<any>(null);
  const [configuredTool, setConfiguredTool] = useState<number | undefined>();

  const addNotification = (type: 'success' | 'error', message?: string) => {
    snackbarContext?.addSnackbar({
      key: crypto.randomUUID(),
      title: message || Notifications[type],
      severity: type
    });
  };

  const { data: meetingConfiguration, isLoading } = useGetMeetingsToolConfiguration();
  const {
    mutate: setConfiguration,
    isLoading: isSetLoading
  } = useSetMeetingsToolConfiguration({
    onSettled: (_: any, error: Error) => {
      if (error) {
        addNotification('error');
      } else {
        addNotification('success');
      }
    }
  });

  useEffect(() => {
    setConfiguredTool(meetingConfiguration?.externalSystem);
  }, [meetingConfiguration]);

  const renderMeetingBtn = (button: any, fullWidth = true) => (
    <ItemButton
      color={button.id as any}
      endIcon={button.logo}
      size="medium"
      variant="outlined"
      fullWidth={fullWidth}
    >
      {button.label}
    </ItemButton>
  );

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={breadcrumbs()} />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">
            Конфигуриране
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ background: palette.background.default }}>
            <CardContent>
              <Grid
                container
                alignItems="center"
                paddingX={6}
                paddingY={4}
              >
                <Grid item xs={12} md={9}>
                  <Typography variant="body1" fontWeight={500}>
                    Използвано приложение за срещи в рамките на организацията
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  {isLoading && (
                    <Box display="flex" justifyContent="center">
                      <Loader isLoading={isLoading} />
                    </Box>
                  )}
                  {!isLoading && (
                    <FormControl variant="standard" fullWidth>
                      <Select
                        ref={selectRef}
                        value={configuredTool || ''}
                        autoWidth
                        displayEmpty
                        input={<SelectInputStyled selected={configuredTool} />}
                        MenuProps={{
                          MenuListProps: {
                            sx: { width: selectRef.current?.offsetWidth || 'unset' }
                          }
                        }}
                        IconComponent={ExpandMoreRounded}
                        renderValue={(selected: any) => selected
                          ? renderMeetingBtn(MeetingButtons[selected as keyof typeof MeetingButtons], false)
                          : 'Избор на приложение'
                        }
                        onChange={(event) => setConfiguredTool(+event.target.value)}
                      >

                        {Object.keys(MeetingButtons).map((key: any) => (
                          <MenuItem key={key} value={key} sx={{ margin: 1 }} autoFocus={false}>
                            {renderMeetingBtn(MeetingButtons[key as keyof typeof MeetingButtons])}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Stack direction="row" justifyContent="center" paddingY={3} spacing={2}>
            <LoadingButton
              variant="contained"
              onClick={() => {
                if (configuredTool) {
                  if (configuredTool === meetingConfiguration?.externalSystem) {
                    addNotification('error', Notifications.duplicate);
                  } else {
                    setConfiguration(configuredTool);
                  }
                }
              }}
              loading={isSetLoading}
              disabled={isSetLoading}
            >
              Запази
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={() => setConfiguredTool(meetingConfiguration?.externalSystem)}
              disabled={isSetLoading}
            >
              Откажи
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Configuration;
