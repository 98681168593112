import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Hidden, ListItem, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import {
  RootStyled, MainTextStyled, MarqueeContainerStyled, TextContainerStyled, SubTextStyled, ListStyled, ListBulletStyled
} from './styles';

function Marquee({ onActionButtonClick }: any) {
  const { t } = useTranslation();

  return (
    <RootStyled>
      <MarqueeContainerStyled maxWidth="lg">
        <TextContainerStyled>
          <MainTextStyled variant="h2">
            {t('landingPage.marquee.title.line1')}
          </MainTextStyled>
          <Hidden smDown={true}>
            <SubTextStyled variant="h5">
              {t('landingPage.marquee.title.line2')}
            </SubTextStyled>
          </Hidden>
          <ListStyled>
            <ListItem>
              <ListBulletStyled />
              <Typography variant="body1">{t('landingPage.marquee.title.list.item1')}</Typography>
            </ListItem>
            <ListItem>
              <ListBulletStyled />
              <Typography variant="body1">{t('landingPage.marquee.title.list.item2')}</Typography>
            </ListItem>
            <ListItem>
              <ListBulletStyled />
              <Typography variant="body1">{t('landingPage.marquee.title.list.item3')}</Typography>
            </ListItem>
            <ListItem>
              <ListBulletStyled />
              <Typography variant="body1">{t('landingPage.marquee.title.list.item4')}</Typography>
            </ListItem>
          </ListStyled>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<ArrowForward />}
            onClick={onActionButtonClick}
          >
            {t('common.learnMore')}
          </Button>
        </TextContainerStyled>
      </MarqueeContainerStyled>
    </RootStyled>
  );
}

Marquee.defaultProps = {
};

export default Marquee;
