import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { BORDER_RADIUS } from 'theme/constants';

export const BoxStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'navi',
})(({ theme, navi }: any) => {
  const { breakpoints, spacing } = theme;
  let naviStyles = {};

  if (navi) {
    naviStyles = {
      flexDirection: 'column',
      margin: 0,

      '& > button': {
        marginBottom: spacing(1),
        paddingLeft: spacing(1),
        paddingRight: spacing(1),

        [breakpoints.up('sm')]: {
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
        }
      },

      [breakpoints.up('lg')]: {
        flexDirection: 'row',
        '& > button': {
          marginBottom: 0
        },
      }
    }
  }

  return {
    overflow: 'hidden',
    ...naviStyles
  }
});

export const StaticInformationContainerStyled = styled(Box)(({ theme }: any) => {
  const { spacing, palette } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    background: palette.grey[50],
    borderRadius: BORDER_RADIUS,
    padding: spacing(2)
  }
});
