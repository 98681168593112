import { MeetingExternalSystem } from 'api/types';
import { GoogleIcon, TeamsIcon } from 'components/icons/svg';
import { getHomePageUrl } from 'utils/navigation';
import { theme } from 'theme/theme';

export const breadcrumbs: any = () => [
  {
    href: getHomePageUrl(),
    label: 'Начало'
  }
];

export const MeetingButtons = {
  [MeetingExternalSystem.MicrosoftTeams]: {
    id: 'teams',
    logo: <TeamsIcon />,
    label: 'Microsoft Teams',
    color: theme.palette.teams.main
  },
  [MeetingExternalSystem.GoogleHangout]: {
    id: 'google',
    logo: <GoogleIcon />,
    label: 'Google Hangout',
    color: theme.palette.google.main
  },
};

export const Notifications = {
  success: 'Конфигурацията беше запазена успешно.',
  error: 'Възникна грешка при запазване на конфигурацията. Моля, опитайте отново.',
  duplicate: 'Конфигурацията вече е запазена.'
};
