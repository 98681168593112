import styled from '@emotion/styled';
import { transparentize } from 'polished';

export const RootStyled = styled('div')(({ theme, numeric, variant }: any) => {
  const { palette, spacing, typography } = theme;

  let background = '#EFEFEF';
  let borderColor = palette.grey[900];
  let color = 'inherit';
  let borderRadius =  '100%';
  let minWidth = '36px';
  let height = '36px';
  let fontSize = typography.pxToRem(typography.htmlFontSize);
  let fontWeight = 400;
  let cursor = ""

  if (variant === 'currentGrade') {
    background = '#FFF5D2';
    borderColor = '#E5D084';
    cursor = "pointer";
  }

  if (variant === 'finalGrade') {
    if (numeric === '-') {
      background = palette.common.white;
      borderColor = palette.grey[400];
      cursor = "";
    }
    if (numeric === 2) {
      background = palette.error.main;
      borderColor = palette.error.main;
      color = palette.common.white;
      cursor = "pointer";
    }
    if (numeric === 3 ) {
      background = palette.info.main;
      borderColor = palette.info.main;
      color = palette.common.white;
      cursor = "pointer";
    }
    if (numeric === 4 ) {
      background = palette.warning.main;
      borderColor = palette.warning.main;
      color = palette.common.white;
    }
    if (numeric === 5) {
      background = palette.success.main;
      borderColor = palette.success.main;
      color = palette.common.white;
      cursor = "pointer";
    }
    if (numeric === 6 ) {
      background = palette.primary.main;
      borderColor = palette.primary.main;
      color = palette.common.white;
      cursor = "pointer";
    }
  }

  if (variant === 'squarePrimary' || variant === 'circlePrimary' ) {
    borderColor = palette.primary.main;
    background = transparentize(0.85, palette.primary.main)
  }

  if (variant === 'squareInfo' || variant === 'circleInfo' ) {
    borderColor = palette.info.main;
    background = transparentize(0.85, palette.info.main)
  }

  if (variant === 'squarePrimary' || variant === 'squareInfo' ) {
    borderRadius = '2px';
  }

  if (variant === 'circlePrimaryLarge' ) {
    background = palette.primary.main;
    minWidth = '49px';
    height = '49px';
    borderColor = palette.primary.main;
    color = palette.common.white;
    fontWeight = 100;
    fontSize = '36px'
  }

  if (variant === 'circleWarning') {
    background = palette.warning[400];
    borderColor = palette.warning[400];
  }

  return ({
    background,
    color,
    minWidth: minWidth,
    height: height,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    border: `2px solid ${borderColor}`,
    padding: spacing(0, 0.2),
    borderRadius,
    fontWeight,
    fontFamily: typography.fontFamily,
    fontSize,
    letterSpacing: '-1px',
    cursor
  });
});
