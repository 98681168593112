import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Paper, Typography } from '@mui/material';
import { Container } from '@mui/system';

import Breadcrumbs from 'components/breadcrumbs';
import CreateCrudForms from './components/create-crud-forms';

import { BlockingNotification, Crud, HyperlinksType, News } from 'api/types';
import { createCrud, editCrud } from 'api/api';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useGetItemCrud } from 'services/queryHooks/crud-hooks';

import { crudBreadcrumbs } from './common/breadcrumbs';

import { INITIAL_FORM_VALUES, notification } from './constants';
import { TypeCrud } from './config';
import 'easymde/dist/easymde.min.css';

function CreateEditCrud() {
  const { t } = useTranslation();
  const { id, type } = useParams();
  const typeCrudPage = type as TypeCrud;
  const navigate = useNavigate();
  const snackbarContext = useSnackbarContext();
  const [submitting, setSubmitting] = useState(false);
  const [defaultCrudValue, setDefaultCrudValue] = useState<
    News | BlockingNotification
  >(INITIAL_FORM_VALUES()[typeCrudPage]);
  const hasCreateNewCrud = id && id === 'new';
  const { data: getItemEditCrud }: any = useGetItemCrud(typeCrudPage, id, {
    select: (itemCrud: any) => {
      if (typeCrudPage === TypeCrud.news && itemCrud) {
        return {
          ...itemCrud,
          files: itemCrud.hyperlinks?.filter((x: HyperlinksType) => x.isFile)?.map((x: HyperlinksType) => ({
            id: x.titleBg,
            name: x.titleBg,
            blobStorageReference: x.link
          })) || []
        };
      }

      return itemCrud;
    }
  });

  useEffect(() => {
    !hasCreateNewCrud && setDefaultCrudValue(getItemEditCrud);
  }, [getItemEditCrud]);

  const displayNotification = (type: 'success' | 'error', title: string) => {
    snackbarContext?.addSnackbar({
      key: crypto.randomUUID(),
      title: title,
      severity: type,
    });
  };

  const submitCrud = (submitFunction: any) => {
    submitFunction
      .then((res: Crud) => {
        if (res.id) {
          displayNotification(
            'success',
            Boolean(hasCreateNewCrud)
              ? t(notification(type)?.success.create)
              : t(notification(type)?.success.edit)
          );
          navigate(`/administration/crud/${type}`);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleSubmitForm = (values: News | BlockingNotification) => {
    setSubmitting(true);
    Boolean(hasCreateNewCrud)
      ? submitCrud(createCrud(typeCrudPage, values))
      : submitCrud(editCrud(typeCrudPage, values, id));
  };

  if (!Object.values(TypeCrud).includes(typeCrudPage)) {
    navigate('/not-found');
  }

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={crudBreadcrumbs()} />
      </Box>
      <Paper>
        <Container>
          <Grid item xs={12} py={2}>
            <Typography variant="h1" pb={1}>
              {Boolean(hasCreateNewCrud)
                ? t(`${type}.create`)
                : t(`${type}.edit`)}
            </Typography>
          </Grid>
          <CreateCrudForms
            onSubmit={(value: any) => handleSubmitForm(value)}
            submitting={submitting}
            crudType={typeCrudPage}
            initialValues={defaultCrudValue}
            isEdit={!hasCreateNewCrud}
          />
        </Container>
      </Paper>
    </>
  );
}

CreateEditCrud.defaultProps = {};

export default CreateEditCrud;
