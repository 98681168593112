import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const GuideStyled = styled(Grid)(({ theme }: any) => {
  const { breakpoints } = theme;

  return ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '0 !important',

    '&:nth-of-type(even)': {
      flexDirection: 'row-reverse'
    },

    '& img': {
      display: 'block',
      width: '100%'
    },

    '& h3': {
      fontSize: '20px',
      lineHeight: '1.2',
      fontWeight: 600,
      marginBottom: '30px',

      [breakpoints.up('md')]: {
        fontSize: '40px'
      }
    },
  })
});
