import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import { Fallback404Version1 } from "components/icons/fallback";
import { getHomePageUrl } from "utils/navigation";

function NotFoundFallback() {
  const { t } = useTranslation();
  return (
    <Box mt={3} alignItems="center" display="flex" flexDirection="column">
      <Typography variant="h1" fontWeight="700" mb={4}>{t('fallback.404.title')}</Typography>
      <Fallback404Version1 />
      <Typography variant="h2" mt={4} fontWeight="400">{t('fallback.404.subtitle')}</Typography>
      <Typography variant="body1" mb={4}>{t('fallback.404.description')}</Typography>
      <Link to={getHomePageUrl()}>
        <Button variant="contained" color="primary">
          {t('fallback.404.button')}
        </Button>
      </Link>
    </Box>
  )
};

export default NotFoundFallback;