import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MenuItem, Tooltip, Typography } from '@mui/material';
import { KeyboardArrowDown, PictureAsPdfSharp } from '@mui/icons-material';
import { AuthManager } from 'authentication/auth-manager';
import { SelectButtonData } from 'api/types';
import { getSubjectConfig } from 'config/subject-config-data';
import { ProsvetaPublishers } from './constants';
import { darken } from 'polished';
import { DARKEN } from 'theme/constants';
import { palette } from 'theme/components';

import { LoadingButtonStyled, MenuStyled } from './styles';

type Props = {
  title: string;
  data: SelectButtonData[];
  tooltipText?: any;
  isLoading?: boolean;
  onSelect?: Function;
  startIcon?: React.ReactElement;
  buttonColorOverride?: string;
};

function SelectButton({ title, data, tooltipText, isLoading = false, onSelect, startIcon, buttonColorOverride }: Props) {
  const { subjectId }: any = useParams();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const accessToken = AuthManager.getAzureAccessToken();

  const subjectConfig = getSubjectConfig(subjectId);
  const buttonColor = buttonColorOverride ? buttonColorOverride : subjectConfig.color;

  const menuButton = () => (
    <>
      <LoadingButtonStyled
        loading={isLoading}
        onClick={(event: any) => {
          setAnchorEl(event.currentTarget)
        }}
        variant="contained"
        startIcon={startIcon}
        backgroundcolor={buttonColor}
        hoverstyles={{
          backgroundColor: darken(DARKEN, buttonColor)
        }}
        endIcon={<KeyboardArrowDown />}
        menu={openMenu ? 1 : 0}
      >
        {title}
      </LoadingButtonStyled>
      {anchorEl && (
        <MenuStyled
          open={openMenu}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          aria-haspopup="true"
          color={buttonColor}
        >
          {data.map((item) => {
            let { id, name, link, publisherId, description } = item;

            return (
              <Tooltip
                placement={'right'}
                title={<Typography>{description ?? name}</Typography>}
                key={id}
              >
                <MenuItem
                  key={id}
                  onClick={() => {
                    if (onSelect) {
                      onSelect(item);
                    }
                    if (link) {
                      if (publisherId && Boolean(ProsvetaPublishers.find(x => x === publisherId))) {
                        link = `${link}&accessToken=${accessToken}`;
                      }
                      window.open(link, '_blank');
                    }
                    setAnchorEl(null);
                  }}
                >
                  <Typography variant='inherit' noWrap>{name}</Typography>
                </MenuItem>
              </Tooltip>
            );
          })}
        </MenuStyled>
      )}
    </>
  );

  const renderSelect = (
    <span>
        {data.length > 0 ? (
          <>
            {data.length === 1 ? (
              <LoadingButtonStyled
                loading={isLoading}
                href={data[0].publisherId && Boolean(ProsvetaPublishers.find(x => x === data[0]?.publisherId)) ? `${data[0].link}&accessToken=${accessToken}` : data[0].link}
                target="_blank"
                variant="contained"
                startIcon={<PictureAsPdfSharp />}
                backgroundcolor={subjectConfig.color}
                hoverstyles={{ backgroundColor: darken(DARKEN, buttonColor) }}
              >
                ПРЕГЛЕДАЙ УЧЕБНИК
              </LoadingButtonStyled>
            ) : (menuButton()
            )}
          </>
        ) : (
          <LoadingButtonStyled
            loading={isLoading}
            disabled
            variant="contained"
            backgroundcolor={subjectConfig.color}
            hoverstyles={buttonColor}
            sx={{
              '&.Mui-disabled': {
                backgroundColor: darken(DARKEN, buttonColor),
                ...(isLoading ? {} : { color: palette.common.white }),
                opacity: 0.6,
              }
            }}
          >
            НЕ Е НАЛИЧЕН УЧЕБНИК ПО ИЗБРАНИЯ ПРЕДМЕТ
          </LoadingButtonStyled>
        )}
      </span>
  );

  if (tooltipText) {
    return (
      <Tooltip
        open={!openMenu && tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        onOpen={() => setTooltipOpen(true)}
        placement={data.length > 1 ? "top" : "bottom"}
        title={<Typography>{tooltipText}</Typography>}
      >
        {renderSelect}
      </Tooltip>
    );
  }

  return renderSelect;
}

SelectButton.defaultProps = {
  startIcon: <PictureAsPdfSharp />
};

export default SelectButton;
