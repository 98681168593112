import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Button, Container, Typography } from '@mui/material';
import Background from 'components/background';
import Footer from 'components/footer';
import { PublicLayout, PrivateLayout } from 'components/layout';
import { Fallback404Version1 } from 'components/icons/fallback';

import { AuthManager } from 'authentication/auth-manager';
import { getHomePageUrl } from 'utils/navigation';

type Props = {
};

function PageNotFound({ }: Props) {
  const { t } = useTranslation();

  /* we have to decide the wrapper of this page, based on whether you are logged in or not */
  const isAuthenticated = AuthManager.isAuthenticated();

  const render404 = (
    <Box mt={3} alignItems="center" display="flex" flexDirection="column">
      <Typography variant="h1" fontWeight="700" mb={4}>{t('fallback.404.title')}</Typography>
      <Fallback404Version1 />
      <Typography variant="h2" mt={4} fontWeight="400">{t('fallback.404.subtitle')}</Typography>
      <Typography variant="body1" mb={4}>{t('fallback.404.description')}</Typography>
      <Link to={getHomePageUrl()}>
        <Button variant="contained" color="primary">
          {t('fallback.404.button')}
        </Button>
      </Link>
    </Box>
  )

  if (!isAuthenticated) {
    return (
      <>
        <Background variant={3}>
          <PublicLayout>
            <Container maxWidth="lg">
              <Box pt={[2, 4]} pb={[16, 20, 30]}>
                {render404}
              </Box>
            </Container>
          </PublicLayout>
        </Background>
        <Footer />
      </>
    );
  }

  return (
    <PrivateLayout useAsStaticWrapper>
      {render404}
    </PrivateLayout>
  );
}

PageNotFound.defaultProps = {
};

export default PageNotFound;
