// @ts-nocheck
import { useState } from 'react';

// Instructions:
// pass 'handleSearch' to any text field onChange event
// use 'list' as the list which elements you want to render
// use setList to initialy populate the local state with some api data
// 'key' is the objects key that you are searching against. e.g: subjectName, name, etc.

const useTextFieldSearch = (key: string) => {
  const [list, setList] = useState<Object[]>([]);
  const [listCopy, setListCopy] = useState<Object[]>([]);
  const [inputValue, setInputValue] = useState('');

  const search = (query, items) => {
    const queryParts = query.split(/[\s,.,!,?\,,]/).filter(Boolean);
    const results = items.filter((element) =>{
      const elementLower = element[key].toLowerCase();
      return queryParts.every(x => elementLower.includes(x.toLowerCase()));
    });

    return results;
  };

  const handleSearch = (query: string) => {
    setInputValue(query);
    if (!query.length) {
      setList(listCopy);
    } else {
      const results = search(query, listCopy);
      setList(results);
    }
  };

  const handleSetList = (listData, resetInputValue = false) => {
    setListCopy(listData);
    // if something was searched then we should filter new list by input value when resetInputValue is not specified
    if (!resetInputValue && inputValue && inputValue.length) {
      const results = search(inputValue, listData);
      setList(results);
    } else {
      setInputValue('');
      setList(listData);
    }
  };

  return { list, setList: handleSetList, handleSearch, inputValue };
};

export default useTextFieldSearch;
