import { useQuery } from 'react-query';
import { getProfAreas, getProfStudyAidsByCode, getProfAreaByCode } from 'api/api';
import { ProfArea, ProfStudyAid } from 'api/types';

export const useGetProfStudyAids = (
  areaCode: string, options = {}
) =>
  useQuery<ProfStudyAid[], Error>(
    ['profArea/study-aids', { areaCode }],
    () => getProfStudyAidsByCode(areaCode),
    {
      ...options,
    }
  );

export const useGetProfAreas = (
  options = {}
) =>
  useQuery<ProfArea[], Error>(
    ['profArea/all'],
    () => getProfAreas(),
    {
      ...options,
    }
  );

export const useGetProfAreaByCode = (
  areaCode: string, options = {}
) =>
  useQuery<ProfArea, Error>(
    ['profArea', { areaCode }],
    () => getProfAreaByCode(areaCode),
    {
      ...options,
    }
  );