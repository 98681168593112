import { Routes, Route } from 'react-router-dom';
import LandingPage from 'views/public/landing-page';
import GuidePage from 'views/assessment';
import PublicAppLayout from 'views/public/app';

import WeeklySchedule from 'views/weekly-schedule';
import {
  LibrarySubject,
  LibraryTopic,
  LibraryDigitalContent,
} from 'views/library';
import Grades from 'views/grades';
import Configuration from 'views/configuration';
import { GlobalSearch } from 'views/search';

import Subject from 'routes/subject';
import Subjects from 'routes/subjects/subjects';
import {
  Homeworks,
  Homework,
  CreateHomework,
  HomeworkAssessment,
} from 'routes/homeworks';
import Library from 'routes/library';
import Mozaik from 'routes/mozaik/mozaik';
import {
  Applications,
  Instructions,
  License,
  NewsCrud,
  NotificationsCrud,
  CreateEditCrud,
} from 'routes/administration';
import Notifications from 'routes/notifications';
import ManagingAssignedContents from 'routes/contents';
import ProfStudyAids from 'routes/study-aids';
import PageNotFound from 'routes/fallback/404';

import { NavigationLinks } from 'enums/routes';
import { RequireAuth } from 'authentication/require-auth';
import { PrivateLayout } from 'components/layout';
import { Roles, UserGroups } from 'enums/userRoles';

export const Routing = () => {
  const {
    weeklySchedule,
    mySubjects,
    subjectV1,
    subjectV2,
    homework,
    homeworks,
    createHomework,
    grades,
    library,
    content,
    librarySubject,
    libraryTopic,
    libraryDigitalContent,
    homeworkAssessment,
    mozaik,
    notifications,
    globalSearch,
    managingAssignedContent,
    administrationApplications,
    administrationLicense,
    administrationInstructions,
    administrationNews,
    administrationNotifications,
    administrationCreateEditCrud,
    configuration,
    profStudyAids,
  } = NavigationLinks;

  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />

      <Route path="/" element={<PublicAppLayout />}>
        <Route index element={<LandingPage />} />
      </Route>

      <Route path="/" element={<PrivateLayout />}>
        <Route
          path={weeklySchedule}
          element={
            <RequireAuth
              requiredRoles={[
                ...UserGroups.Teacher,
                Roles.Student,
                Roles.Parent,
              ]}
            >
              <WeeklySchedule />
            </RequireAuth>
          }
        />

        <Route
          path={mySubjects}
          element={
            <RequireAuth
              requiredRoles={[Roles.Teacher, Roles.Student, Roles.Parent]}
            >
              <Subjects />
            </RequireAuth>
          }
        />

        <Route
          path={subjectV1}
          element={
            <RequireAuth requiredRoles={[Roles.Teacher, Roles.Student, Roles.Parent]}>
              <Subject />
            </RequireAuth>
          }
        />
        <Route
          path={subjectV2}
          element={
            <RequireAuth requiredRoles={[Roles.Teacher, Roles.Student, Roles.Parent]}>
              <Subject />
            </RequireAuth>
          }
        />

        <Route
          path={grades}
          element={
            <RequireAuth requiredRoles={[Roles.Student, Roles.Parent]}>
              <Grades />
            </RequireAuth>
          }
        />

        <Route path={notifications} element={<Notifications />} />

        <Route
          path={managingAssignedContent}
          element={
            <RequireAuth requiredRoles={[Roles.Teacher, Roles.ClassMaster]}>
              <ManagingAssignedContents />
            </RequireAuth>
          }
        />

        {/* Homeworks */}
        <Route
          path={homeworks}
          element={
            <RequireAuth
              requiredRoles={[
                Roles.Student,
                Roles.Teacher,
                Roles.ClassMaster,
                Roles.Parent,
              ]}
            >
              <Homeworks />
            </RequireAuth>
          }
        />
        <Route
          path={createHomework}
          element={
            <RequireAuth requiredRoles={[Roles.Teacher, Roles.ClassMaster]}>
              <CreateHomework />
            </RequireAuth>
          }
        />
        <Route
          path={homework}
          element={
            <RequireAuth
              requiredRoles={[
                Roles.Student,
                Roles.Teacher,
                Roles.ClassMaster,
                Roles.Parent,
              ]}
            >
              <Homework />
            </RequireAuth>
          }
        />
        <Route
          path={homeworkAssessment}
          element={
            <RequireAuth requiredRoles={[Roles.Teacher, Roles.ClassMaster]}>
              <HomeworkAssessment />
            </RequireAuth>
          }
        />

        <Route
          path={content}
          element={
            <RequireAuth
              requiredRoles={[
                ...UserGroups.Teacher,
                Roles.Director,
                Roles.AcademicTeacher,
                ...UserGroups.Admin,
                ...UserGroups.RUO,
              ]}
            >
              <GuidePage />
            </RequireAuth>
          }
        />

        <Route
          path={`${profStudyAids}/*`}
          element={
            <RequireAuth requiredRoles={[Roles.Teacher, Roles.Student, Roles.Parent]}>
              <ProfStudyAids />
            </RequireAuth>
          }
        />

        <Route
          path={`${library}/*`}
          element={
            <RequireAuth>
              <Library />
            </RequireAuth>
          }
        />

        <Route
          path={librarySubject}
          element={
            <RequireAuth>
              <LibrarySubject />
            </RequireAuth>
          }
        />

        <Route
          path={libraryTopic}
          element={
            <RequireAuth>
              <LibraryTopic />
            </RequireAuth>
          }
        />

        <Route
          path={libraryDigitalContent}
          element={
            <RequireAuth>
              <LibraryDigitalContent />
            </RequireAuth>
          }
        />

        <Route path={mozaik} element={<Mozaik />} />

        <Route path={globalSearch} element={<GlobalSearch />} />

        <Route
          path={administrationApplications}
          element={
            <RequireAuth
              requiredRoles={[
                Roles.Student,
                Roles.Parent,
                Roles.AcademicTeacher,
                Roles.Director,
                ...UserGroups.Teacher,
                ...UserGroups.RUO,
                ...UserGroups.Admin,
              ]}
            >
              <Applications />
            </RequireAuth>
          }
        />
        <Route
          path={administrationLicense}
          element={
            <RequireAuth
              requiredRoles={[...UserGroups.Teacher, Roles.Director]}
            >
              <License />
            </RequireAuth>
          }
        />

        <Route
          path={administrationInstructions}
          element={
            <RequireAuth
              requiredRoles={[
                Roles.Student,
                Roles.Parent,
                Roles.Director,
                Roles.AcademicTeacher,
                Roles.Publisher,
                ...UserGroups.Teacher,
                ...UserGroups.RUO,
                ...UserGroups.Admin,
              ]}
            >
              <Instructions />
            </RequireAuth>
          }
        />

        <Route path={administrationNews} element={<NewsCrud />} />
        <Route
          path={administrationNotifications}
          element={<NotificationsCrud />}
        />

        <Route
          path={administrationCreateEditCrud}
          element={<CreateEditCrud/>}
        />

        <Route
          path={configuration}
          element={
            <RequireAuth requiredRoles={[Roles.Director]}>
              <Configuration />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  );
};
