import { StudentGradeCategoryType } from 'enums/grades.enum';
import { GradeEnum } from './enums';

export const getGradeTypeLabel = (gradeType: GradeEnum) => {
  const gradeTypeEnum = [
    {
      id: 1,
      name: 'Текуща оценка',
    },
    {
      id: 2,
      name: 'Контролна работа',
    },
    {
      id: 3,
      name: 'Класна работа',
    },
    {
      id: 4,
      name: 'Тест',
    },
    {
      id: 5,
      name: 'Домашна работа',
    },
    {
      id: 11,
      name: 'Входно ниво',
    },
    {
      id: 12,
      name: 'Изходно ниво',
    },
    {
      id: 21,
      name: 'Срочна',
    },
    {
      id: 22,
      name: 'Годишна',
    },
    {
      id: 99,
      name: 'От друго училище',
    },
  ];

  const gradeTypeLabel = gradeTypeEnum.find((s) => s.id === gradeType);
  return gradeTypeLabel ? gradeTypeLabel.name : '';
}

export interface SubjectWithGrades {
  subjectId: number;
  subjectName: string;
  teacherName: string;
  firstSemGrades: GradeItem[];
  firstSemFinalGrade: GradeItem;
  secondSemGrades: GradeItem[];
  secondSemFinalGrade: GradeItem;
  annualGrade: GradeItem | undefined;
}

export interface GradeItem {
  id: number;
  grade: number;
  type: GradeEnum;
  date: string;
  subject: string;
  teacherName: string;
  comment: string;
  className: string;
  category: StudentGradeCategoryType;
}
