import { LinearProgress } from '@mui/material';
import { useLoaderContext } from 'hooks/useLoaderContext';

export const GlobalLoader: React.FC = () => {
  const loaderContext = useLoaderContext();
  if (loaderContext?.loading) {
    return (
      <LinearProgress
        color="secondary"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 9999
        }}
      />
    )
  }

  return null;
};