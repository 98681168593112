import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createMeeting, deleteMeeting, getMeetings, getMeetingsToolConfiguration, setMeetingsToolConfiguration
} from 'api/api';
import { Meeting, MeetingExternalSystem, MeetingRequest } from 'api/types';
import { getWeekScheduleRangeByDate } from 'utils/dateFormat';

export const useCreateMeeting = (options = {} as any) => {
  const queryClient = useQueryClient();
  return useMutation(
    (meeting: MeetingRequest) => createMeeting(meeting),
    {
      ...options,
      onSuccess: (createdMeeting: Meeting) => {
        if (createdMeeting?.date) {
          const currentWeekRange = getWeekScheduleRangeByDate(new Date(createdMeeting.date));
          let updatedMeetings = queryClient.getQueryData<Meeting[]>(['meetings', currentWeekRange]);
          updatedMeetings?.push(createdMeeting);

          queryClient.setQueryData(['meetings', currentWeekRange], updatedMeetings || []);
          queryClient.invalidateQueries(['meetings']);
        } else {
          queryClient.invalidateQueries(['meetings']);
        }

        if (options.onSuccess) {
          options.onSuccess();
        }
      }
    }
  );
};

export const useDeleteMeeting = (date?: string, options = {} as any) => {
  const queryClient = useQueryClient();
  return useMutation(
    (meetingId: string) => deleteMeeting(meetingId),
    {
      ...options,
      onSuccess: (data: any, meetingId: string) => {
        if (date) {
          const currentWeekRange = getWeekScheduleRangeByDate(new Date(date));
          const updatedMeetings = queryClient.getQueryData<Meeting[]>(['meetings', currentWeekRange])
            ?.filter((x) => x.id !== meetingId) || [];

          queryClient.setQueryData(['meetings', currentWeekRange], updatedMeetings);
          queryClient.invalidateQueries(['meetings']);
        } else {
          queryClient.invalidateQueries(['meetings']);
        }

        if (options.onSuccess) {
          options.onSuccess();
        }
      }
    }
  );
};

export const useGetMeetings = (
  fromDate: any,
  toDate: any,
  options: any = {}
) =>
  useQuery<Meeting[], Error>(
    ['meetings', { fromDate, toDate }],
    () => getMeetings(fromDate, toDate),
    {
      ...options,
    }
  );
 
export const useGetMeetingsToolConfiguration = (options: any = {}) =>
  useQuery(
    ['meetingsToolConfiguration'],
    () => getMeetingsToolConfiguration(),
    {
      ...options,
    }
  );

export const useSetMeetingsToolConfiguration = (options = {} as any) => {
  const queryClient = useQueryClient();
  return useMutation(
    (externalSystem: MeetingExternalSystem) => setMeetingsToolConfiguration(externalSystem),
    {
      ...options,
      onSuccess: (_, externalSystem) => {
        queryClient.setQueryData(['meetingsToolConfiguration'], { externalSystem });

        if (options.onSuccess) {
          options.onSuccess();
        }
      }
    }
  );
};
 
