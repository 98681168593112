import { useQuery } from 'react-query';
import { getSchoolbooksBySubjectAndBasicClass } from 'api/api';
import { SchoolbookType } from 'api/types';

export const useGetSchoolbooks = (
  subjectId: number, basicClassId: number, options = {}
) =>
  useQuery<SchoolbookType[], Error>(
    ['schoolbooks', { subjectId, basicClassId }],
    () => getSchoolbooksBySubjectAndBasicClass(subjectId, basicClassId),
    {
    ...options,
    }
  );
