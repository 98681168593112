import { useQuery } from 'react-query';
import { getTopic, getTopicsBySubjectAndBasicClass, getTopicsBySubjectAndBasicClassPaginated } from 'api/api';
import { Topic } from 'api/types';
import { PaginatedResult } from '../../types/global';

export const useGetTopicsBySubjectAndBasicClass = (
  subjectId: number,
  basicClassId: number,
  options = {}
) =>
  useQuery<Topic[], Error>(
    ['topics', { subjectId, basicClassId }],
    () => getTopicsBySubjectAndBasicClass(subjectId, basicClassId),
    { ...options }
  );

// TODO: @Simona, да го обединим в библиотека някой ден
export const useGetTopicsBySubjectAndBasicClassPaginated = (
  subjectId: number,
  basicClassId: number,
  offset: number,
  page: number,
  options = {}
) =>
  useQuery<PaginatedResult<Topic>, Error>(
    ['topics', { subjectId, basicClassId, page }],
    () => getTopicsBySubjectAndBasicClassPaginated(subjectId, basicClassId, offset, page),
    { ...options }
  );

export const useGetTopicById = (topicId: number, options = {}) =>
  useQuery<any, Error>(
    ['topicById', topicId],
    () => getTopic(topicId),
    {
      ...options
    }
  );
