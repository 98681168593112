import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tooltip, Typography } from '@mui/material';
import { ShiftClass } from 'api/types';
import { ChipStyled } from './styles';

type Props = {
  classes: ShiftClass[];
  subjectId: number;
  date?: string;
  noTooltip?: boolean;
  activeId?: number;
};

function ClassPicker({ classes, subjectId, date, noTooltip, activeId }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const renderClassChip = (classInfo: ShiftClass) => {
    let color = 'warning';
    if (activeId !== undefined) {
      if (activeId !== classInfo.neispuoScheduleId) {
        color = 'secondary';
      }
    }

    return (
      <ChipStyled
        key={classInfo.id}
        isActive={activeId === classInfo.neispuoScheduleId}
        label={`${classInfo.name} клас`}
        color={color}
        variant="outlined"
        size="small"
        disabled={!classInfo.hasSubjectCurriculum}
        onClick={(e: any) => {
          e.stopPropagation();
          const returnPath = location?.state?.returnPath ? location.state.returnPath : `${location.pathname}${location.search}`;

          if (classInfo.hasSubjectCurriculum) {
            const classCurriculumId = classInfo.isProfileSubject ? classInfo.profCurriculumId : classInfo.curriculumId;
            navigate(`/subject/${subjectId}/${classInfo.subjectTypeId}/${classCurriculumId}?scheduleId=${classInfo.neispuoScheduleId}&scheduleDate=${date}&classId=${classInfo.id}`, {
              state: { returnPath }
            });
          }
        }}
      />
    )
  };

  return (
    <>
      {
        classes.map((c) => {
            if (c.hasSubjectCurriculum || noTooltip) {
              return renderClassChip(c)
            }

            return (
              <Tooltip
                placement={'right'}
                title={(
                  <Typography>
                    Предметът от този час не е част от ООП и не е в обхвата на Дигиталната раница. Показва се само с цел цялостност на седмичното разписание.
                  </Typography>
                )}
              >
                {renderClassChip(c)}
              </Tooltip>
            );
          }
        )
      }
    </>
  )
}

ClassPicker.defaultProps = {
};

export default ClassPicker;
