import { ChangeEvent, useEffect, useState } from 'react';
import {
  Autocomplete, Box, Button, Chip, Grid, ListItem, ListItemText, TextField,
} from '@mui/material';
import { Clear, ExpandMore } from '@mui/icons-material';
import { useDebounce } from 'use-debounce';
import { ContentIcon, PrivateContentIcon } from 'components/icons/svg';
import { FormDialog } from 'components/form-dialog';
import { Checkbox } from 'components/checkbox';
import { ItemsList } from 'components/items-list';
import LoadingSkeleton from 'components/loading-skeleton';
import { ContextSearch } from 'components/search';
import { getContentsByFilter, getTopicsBySubjectAndBasicClasses } from 'api/api';
import { PaginationType } from 'types/global';
import { Content } from 'api/types';
import { ContentVisibilityLevelEnum } from 'enums/contents-type.enum';
import { trimText } from 'utils/text';
import { getUniqueItems } from 'utils/helpers';
import { palette } from 'theme/components';
import { BASE_APP_URL } from 'windows';

type Props = {
  open: boolean;
  onClose: () => void;
  onAttach: (contents: Content[]) => void;
  attachedContents: Content[];
  subjectId: number;
  isProfileSubject: boolean;
  basicClassesIds: number[];
};

type TopicOption = {
  label: string;
  id: number;
  basicClassId: number;
  subjectId: number;
  unit?: string;
};

const FIRST_PAGE = 1;

const AttachContentsDialog = ({
  open, onClose, subjectId, isProfileSubject, basicClassesIds, attachedContents, onAttach,
}: Props) => {
  const [topicOptions, setTopicOptions] = useState<TopicOption[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<TopicOption[]>([]);
  const [selectedContents, setSelectedContents] = useState<Content[]>([]);
  const [contentOptions, setContentOptions] = useState<Content[]>([]);
  const [contentsPagination, setContentsPagination] = useState<PaginationType>();
  const [contentsLoading, setContentsLoading] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [nameFilterValue, setNameFilterValue] = useState('');
  const [debouncedNameFilterValue] = useDebounce(nameFilterValue, 500);

  useEffect(() => {
    setSelectedContents(attachedContents);
  }, [attachedContents]);

  const reset = (subjectId: number, basicClassesIds: number[], isProfileSubject: boolean) => {
    // we have to filter selected topics by new subject and basic classes
    // only if selected subject is not profile
    // otherwise we would like to reset all collections
    const newSelectedTopics = selectedTopics
      .filter(x => x.subjectId === subjectId && basicClassesIds.includes(x.basicClassId) && !isProfileSubject);
    setSelectedTopics(newSelectedTopics);

    // then if there are selected topics, we have to refetch contents and set previously selected contents
    if (Boolean(newSelectedTopics.length)) {
      getContents(FIRST_PAGE, newSelectedTopics);
      const newAttachedContents = attachedContents.filter(x =>
        x.topics.some(t => newSelectedTopics.some(nt => nt.id === t.id)));
      onAttach(newAttachedContents);
    } else {
      // if there aren't any selected topics, we have to reset content options and selected ones
      setContentOptions([]);
      setContentsPagination(undefined);
      onAttach([]);
    }
  };

  useEffect(() => {
    // when subject or classes are changed we need to get new topics
    // and reset selected contents and topics by filtering
    if (Boolean(selectedTopics.length) || Boolean(selectedContents.length)) {
      reset(subjectId, basicClassesIds, isProfileSubject);
    } 

    if (subjectId && Boolean(basicClassesIds?.length)) {
      if (isProfileSubject) {
        getProfileContents(FIRST_PAGE);
      } else {
        getTopicsBySubjectAndBasicClasses(subjectId, basicClassesIds)
          .then((suggestedTopics) => {
            const options = getUniqueItems(suggestedTopics, (a, b) => a.id === b.id)
              .map((t) => ({
                label: t.name,
                id: t.id,
                basicClassId: t.basicClassId,
                subjectId: t.subjectId,
                unit: t.unit
              }));
            setTopicOptions(options);
          });
      }
    }
  }, [subjectId, basicClassesIds, isProfileSubject]);

  useEffect(() => {
    if (Boolean(selectedTopics.length)) {
      getContents(FIRST_PAGE);
    } else if (isProfileSubject) {
      getProfileContents(FIRST_PAGE)
    }
  }, [debouncedNameFilterValue]);

  const getProfileContents = (page: number, name?: string) => {
    setContentsLoading(true);
    getContentsByFilter({ subjectId, isProfileSubject: true, classIds: basicClassesIds, name: name || debouncedNameFilterValue, page,})
      .then((response) => {
        if (page > FIRST_PAGE) {
          setContentOptions([...contentOptions, ...response.items]);
        } else {
          setContentOptions(response.items);
        }
        setContentsPagination(response.pagination);
      })
      .finally(() => {
        setContentsLoading(false);
        setNextPageLoading(false);
      });
  };

  const getContents = (page: number, topics?: TopicOption[], name?: string) => {
    const topicIds = (topics || selectedTopics).map(t => t.id);
    if (topicIds.length) {
      setContentsLoading(true);
      getContentsByFilter({ topicIds, name: name || debouncedNameFilterValue, page })
        .then((response) => {
          if (page > FIRST_PAGE) {
            setContentOptions([...contentOptions, ...response.items]);
          } else {
            setContentOptions(response.items);
          }
          setContentsPagination(response.pagination);
        })
        .finally(() => {
          setContentsLoading(false);
          setNextPageLoading(false);
        });
    } else {
      setContentOptions([]);
      setContentsPagination(undefined);
    }
  };

  const getNextPage = () => {
    if (contentsPagination) {
      setNextPageLoading(true);
      getContents(contentsPagination.page + 1);
    }
  };

  const onTopicRemove = (topic: TopicOption) => {
    const newSelectedTopics = selectedTopics.filter(x => x.id !== topic.id);
    setSelectedTopics(newSelectedTopics);
    getContents(FIRST_PAGE, newSelectedTopics);
  };

  const onContentSelect = (event: ChangeEvent<HTMLInputElement>, content: Content) => {
    if (event.target.checked) {
      setSelectedContents([...selectedContents, content]);
    } else {
      const newItems = selectedContents.filter(x => x.id !== content.id);
      setSelectedContents(newItems);
    }
  };

  const onCancel = () => {
    // restore previously selected contents
    setSelectedContents(attachedContents);
    onClose();
  };

  const renderContentIcon = ({ visibilityLevel }: Content) => {
    const isContentPrivate = visibilityLevel === ContentVisibilityLevelEnum.Organization;

    if (isContentPrivate) {
      return <PrivateContentIcon sx={{ fontSize: '21px', color: palette.secondary.main }} />;
    }

    return <ContentIcon sx={{ color: palette.secondary.main }} />;
  };

  return (
    <FormDialog
      open={open}
      onClose={onCancel}
      maxWidth='md'
      disableCloseOnBackdropClick
      title='Избор на съдържание'
      actions={(
        <>
          <Button
            autoFocus
            variant='contained'
            onClick={() => {
              onAttach(selectedContents);
              onClose();
            }}
            sx={{ width: 112 }}
          >
            Ок
          </Button>
          <Button
            variant='outlined'
            onClick={onCancel}
            sx={{ width: 112 }}
          >
            Откажи
          </Button>
        </>
      )}
    >
      <Grid container px={0}>
        {isProfileSubject || (
          <Grid item xs={12} pr={1}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              value={selectedTopics}
              options={topicOptions}
              getOptionLabel={option => `${option.label}`}
              fullWidth
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Моля изберете една или повече теми, за да видите съответното съдържание.'
                />
              )}
              popupIcon={<ExpandMore />}
              renderOption={(props, option, { selected }) => (
                <ListItem {...props} key={option.id}>
                  <ListItemText
                    primary={option.label}
                    secondary={`Раздел: ${option.unit}`}
                    primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                  />
                  <Checkbox checked={selected} />
                </ListItem>
              )}
              onClose={() => getContents(FIRST_PAGE)}
              onChange={(event, value) => {
                setSelectedTopics(value);
                if (!Boolean(value.length)) {
                  setContentOptions([]);
                  setContentsPagination(undefined);
                }
              }}
            />
            <Box
              alignItems='center'
              display='flex'
              flexWrap='wrap'
              gap={1}
              pt={1}
            >
              {selectedTopics.map((option) => (
                <Chip
                  key={`topic-${option.id}`}
                  deleteIcon={<Clear />}
                  label={trimText(`${option.label}`, 30)}
                  onDelete={() => onTopicRemove(option)}
                />
              ))}
            </Box>
          </Grid>
        )}
        <Grid item xs={12} py={2}>
          {(Boolean(topicOptions.length) || isProfileSubject) && (debouncedNameFilterValue || contentOptions.length > 0) && (
            <Grid xs={12} display='flex' justifyContent='end' py={2} mr={1}>
              <Grid xs={12} md={3}>
                <ContextSearch
                  withMobile={false}
                  value={nameFilterValue}
                  onChange={(value: string) => setNameFilterValue(value)}
                />
              </Grid>
            </Grid>
          )}
          {contentsLoading && !nextPageLoading && (
            <LoadingSkeleton itemsCount={3} height={40} />
          )}
          {(contentsLoading && !nextPageLoading) || (
            <Box pr={1}>
              <ItemsList
                name='contents-by-topic'
                items={contentOptions}
                startIcon={renderContentIcon}
                renderEndIcon={(content) => (
                  <Checkbox
                    sx={{ padding: 0 }}
                    onChange={(event) => onContentSelect(event, content as Content)}
                    checked={Boolean(selectedContents?.find((c) => c.id === content.id))}
                  />
                )}
                onItemClick={(i) => window.open(`${BASE_APP_URL}library/content/${i.id}`)}
                noItemsLabel={Boolean(selectedTopics.length) ? 
                  'Няма налични съдържания за избраните теми.' : 
                  isProfileSubject ? 'Няма налични съдържания.' : ''}
              />
              {contentsPagination?.hasMore && (
                <Box display="flex" justifyContent="center">
                  <Button
                    disableElevation
                    color="primary"
                    variant="outlined"
                    onClick={getNextPage}
                    disabled={nextPageLoading}
                  >
                    Покажи още
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default AttachContentsDialog;
