import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { getCurrentLng, onLanguageChange } from 'i18n';
import { LanguageLinkStyled } from './styles';

function Language() {

  return (
    <LanguageLinkStyled
      type="button"
      onClick={onLanguageChange}
    >
      {
        getCurrentLng() === 'en' ?
          <ReactCountryFlag countryCode="BG" svg style={{ fontSize: '16px' }} /> :
          <ReactCountryFlag countryCode="GB" svg style={{ fontSize: '16px' }} /> 
      }
    </LanguageLinkStyled>
  );
}

Language.defaultProps = {
};

export default Language;
