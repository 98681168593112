import { Roles } from 'enums/userRoles';
import { AuthManager } from './auth-manager';

export const hasRequiredRoles = (requiredRoles: Roles[]) => {
  let hasRequiredRoles = true;
  if (requiredRoles && requiredRoles.length > 0) {
    const userRoles = AuthManager.getUser()?.roles;
    hasRequiredRoles = requiredRoles.reduce((acc, requiredRole) => {
      const hasRole = userRoles.includes(requiredRole);
      return acc || hasRole;
    }, false);
  }
  return hasRequiredRoles;
};
