import styled from '@emotion/styled';
import { Chip } from '@mui/material';

export const ChipStyled = styled(Chip)(({ theme, tag }: any) => {
  const { palette } = theme;
  let background = palette.common.white;
  let color = palette.text.primary;

  if (tag === 'administrative') {
    background = palette.warning.main;
  }
  if (tag === 'dnevnik') {
    background = '#1dc295';
  }
  if (tag === 'eLearning') {
    background = '#14C6EB';
  }
  if (tag === 'eMaterials') {
    background = '#ab47bc';
    color = palette.common.white;
  }
  if (tag === 'eTextbook') {
    background = '#ff5722';
  }

  return {
    background,
    color
  };
});
