import React from 'react';
import { getStringLocalization } from 'i18n';
import { useTranslation } from 'react-i18next';
import {
  dateFormat,
  normalizeDateBecauseOfLocalization,
} from 'utils/dateFormat';
import { trimText } from 'utils/text';
import { TypeCrud } from './config';
import Crud from './crud';

function NotificationsCrud() {
  const { t } = useTranslation();
  const Lng = getStringLocalization();
  return (
    <Crud
      type={TypeCrud.notifications}
      headers={[
        {
          field: 'endDate',
          label: t('notifications.table.endDate'),
          renderer: (value: any) => {
            return normalizeDateBecauseOfLocalization(value);
          },
        },
        {
          field: `message${Lng}`,
          label: t('notifications.table.message'),
          renderer: (value: any) => trimText(value, 100),
        },
        {
          field: 'dateCreated',
          label: t('notifications.table.dateCreated'),
          renderer: (value: any) => dateFormat(value),
        },
      ]}
    />
  );
}

NotificationsCrud.defaultProps = {};

export default NotificationsCrud;
