import { TermTypes } from 'enums/termTypes.enum';
import {
  NumericGradesEnum, SOPGradesEnum, StudentGradeCategoryEnum, StudentGradesExtModel
} from 'enums/grades.enum';
import type { SubjectWithGrades, GradeItem } from './types';
import { GradeEnum, specialGradeToIcon } from './enums';
import { getGrades } from 'api/api';
import { getGradeTypeLabel } from './types';

const normaliseListOfGrades = (grades: any, term: TermTypes) => {
  const listOfGrades = grades
    .filter((grade: StudentGradesExtModel) => grade.term === term)
    .map((grade: StudentGradesExtModel) => mapGradeItemFromExternal(grade));

  return {
    list: listOfGrades.filter((grade: GradeItem) => grade.type !== GradeEnum.TermMark && grade.type !== GradeEnum.AnnualMark),
    finalTermGrade: listOfGrades.find((grade: GradeItem) => grade.type === GradeEnum.TermMark)
  }
}

export const normalizeSubjectsGradesData = (gradesGrouping: any) => {
  if (!gradesGrouping.length) {
    return {
      annualGrade: undefined,
      firstSemFinalGrade: undefined,
      firstSemGrades: [],
      secondSemFinalGrade: undefined,
      secondSemGrades: [],
      subjectId: 0,
      subjectName: '',
      teacherName: ''
    }
  }

  const gradeInfo = gradesGrouping[0];
  const subjectWithData = <SubjectWithGrades>{};
  subjectWithData.subjectId = gradeInfo.subjectId;
  subjectWithData.subjectName = gradeInfo.subjectName;
  subjectWithData.teacherName = gradeInfo.teacherName;

  /* --- FIRST TERM --- */
  // First term grades:
  const firstSemGrades = normaliseListOfGrades(gradesGrouping, TermTypes.FirstTerm);
  subjectWithData.firstSemGrades = firstSemGrades.list;
  subjectWithData.firstSemFinalGrade = firstSemGrades.finalTermGrade;

  /* --- SECOND TERM --- */
  // Second term grades:
  const secondSemGrades = normaliseListOfGrades(gradesGrouping, TermTypes.SecondTerm);
  subjectWithData.secondSemGrades = secondSemGrades.list;
  // Second term final grade:
  subjectWithData.secondSemFinalGrade = secondSemGrades.finalTermGrade;

  /* --- ANNUAL GRADE --- */
  subjectWithData.annualGrade = mapGradeItemFromExternal(gradesGrouping.find((grade: GradeItem) => grade.type === GradeEnum.AnnualMark));

  return subjectWithData;
}

export const apiGetStudentGradesBySubject = async () => {
  const allGrades: StudentGradesExtModel[] = await getGrades('Student');

  const gradesBySubject = allGrades.reduce((res, mark) => {
    res[mark.subjectName] = res[mark.subjectName] || [];
    res[mark.subjectName].push(mark);
    return res;
  }, Object.create(null));

  const subjectsWithData = Object.values(gradesBySubject)
    .map(normalizeSubjectsGradesData)
    .sort((prevSubject: any, nextSubject: any) => prevSubject.subjectId - nextSubject.subjectId);

  return subjectsWithData;
};

const mapGradeItemFromExternal = (item: StudentGradesExtModel) => {
  if (!item) {
    return undefined;
  }

  let grade: any = item.decimalGrade;

  if (item.category === StudentGradeCategoryEnum.Qualitative) {
    grade = item.qualitativeGrade;
  }

  if (item.category === StudentGradeCategoryEnum.SOP) {
    const { specialGrade } = item;
    /*
      special grades are 1, 2 and 3
        * среща затруднения
        * справя се
        * постига изискванията
      in our color icon schemes, sop 1 = numeric 4, sop 2 = numeric 5, sop 3 = numeric 6
      https://www.figma.com/file/pNXmEAOe1jOAwqNC3kefXr/Digital-Backpack_V3?node-id=5%3A15049&t=12JxCUCMwGrtO20y-0
    */
    if (specialGrade === SOPGradesEnum.Bad) {
      grade = NumericGradesEnum.Normal
    }
    if (specialGrade === SOPGradesEnum.Normal) {
      grade = NumericGradesEnum.Good
    }
    if (specialGrade === SOPGradesEnum.Good) {
      grade = NumericGradesEnum.VeryGood
    }
  }


  let gradeItem: GradeItem = {
    id: item.id,
    className: item.className,
    date: item.date,
    subject: item.subjectName,
    type: item.type,
    teacherName: item.teacherName,
    comment: item.comment || "",
    category: item.category,
    grade,
  };
  return gradeItem;
};

export const normaliseTermGrade = (grade: GradeItem) => ({
  id: grade.id,
  numeric: grade.grade,
  variant: 'currentGrade',
  date: grade.date,
  gradeType: getGradeTypeLabel(grade.type),
  teacherName: grade.teacherName,
  comment: grade.comment,
  ...mapNumericToSpecialGrade(grade)
});

export const mapNumericToSpecialGrade = (grade: GradeItem | undefined) => {
  if (grade?.category === StudentGradeCategoryEnum.Qualitative || grade?.category === StudentGradeCategoryEnum.SOP) {
    return {
      children: specialGradeToIcon[grade.grade]
    }
  }

  return {};
}
