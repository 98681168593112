import React, { useState } from 'react';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Box, MenuItem, Typography } from '@mui/material';
import { Checkbox } from 'components/checkbox';
import type { SelectDataType } from './types';
import {
  MultipleLabelStyled,
  MultipleMenuStyled,
  RootStyled,
  MultipleSelectValuesHolderStyled,
} from './styles';
import palette from '../../theme/components/palette';

type Props = {
  data: SelectDataType[];
  value?: SelectDataType[];
  onSelect: Function;
  defaultLabel?: string;
  open?: boolean;
};

type ContainerProps = {
  children?: React.ReactNode;
};

export const MultipleSelectValuesHolder = ({ children }: ContainerProps) => {
  return (
    <MultipleSelectValuesHolderStyled my={2}>
      {children}
    </MultipleSelectValuesHolderStyled>
  );
};

function MultipleSelect({ data, onSelect, value, defaultLabel, open }: Props) {
  const [selectOpen, setSelectOpen] = useState(open);
  const openMenu = Boolean(selectOpen);

  const handleClick = (event: any) => {
    setSelectOpen(!selectOpen);
  };

  const handleClose = () => {
    setSelectOpen(false);
  };

  return (
    <RootStyled>
      <MultipleLabelStyled open={openMenu} onClick={handleClick}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography
            p={3}
            variant="subtitle1"
            noWrap
            color={openMenu ? palette.primary.main : palette.secondary.main}
          >
            {defaultLabel}
          </Typography>
          {!openMenu && <ExpandMoreRounded />}
          {openMenu && (
            <ExpandLessRounded
              sx={{
                color: openMenu ? palette.primary.main : palette.secondary.main,
              }}
            />
          )}
        </Box>
      </MultipleLabelStyled>

      <MultipleMenuStyled
        in={openMenu}
        onClose={handleClose}
        anchorEl={selectOpen}
      >
        {data.map((item) => {
          const { id, label } = item;
          return (
            <MenuItem
              key={id}
              value={label}
              onClick={() => {
                onSelect(item);
                handleClose();
              }}
            >
              <Checkbox
                checked={Boolean(
                  value?.find((valueItem) => valueItem.id === id)
                )}
              />
              <Typography variant="subtitle2" noWrap>
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MultipleMenuStyled>
    </RootStyled>
  );
}

MultipleSelect.defaultProps = {
  defaultLabel: 'Choose one',
  open: false,
};

export default MultipleSelect;
