import React, { useCallback, useState, createContext, useMemo } from 'react';
import {
  CustomSnackbarProps,
  CustomSnackbar,
} from '../components/snackbar/Snackbar';

interface SnackbarContextInterface {
  addSnackbar: (toast: CustomSnackbarProps) => void;
}

export const ToastContext = createContext<SnackbarContextInterface | null>(
  null
);

export const ToastContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<CustomSnackbarProps[]>([]);

  const addSnackbar = useCallback(
    (newToast: CustomSnackbarProps) => {
      setToasts((currentToasts) => [...currentToasts, newToast]);
      setTimeout(
        () => setToasts((currentToasts) => currentToasts.slice(1)),
        5000
      );
    },
    [setToasts]
  );

  const value = useMemo(() => ({ addSnackbar }), [addSnackbar]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      {toasts.map((toast) => (
        <CustomSnackbar
          severity={toast.severity}
          title={toast.title}
          key={toast.key}
        />
      ))}
    </ToastContext.Provider>
  );
};
