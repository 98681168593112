import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { Box, Button, MenuItem, Select, Typography, FormHelperText } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { InstitutionIcon1, StudentAvatar } from 'components/icons/svg';

import { AuthManager } from 'authentication/auth-manager';
import { B2CInstitutionsType, B2CStudentsType } from 'authentication/types';
import { validationSchema } from './constants';

import { StaticInformationContainerStyled } from 'components/login/styles';

type Props = {
  onFormSubmit: Function;
};

const findInstitutionInStudentsArray = (studentsArray: B2CStudentsType[], selectedStudent: string) => (
  studentsArray.find(({ Id }: B2CStudentsType) => Id === selectedStudent)?.Institutions || []
)

function B2CForm({ onFormSubmit }: Props) {
  const { t } = useTranslation();
  const students: B2CStudentsType[] = AuthManager.b2cData;

  const {
    handleChange, handleSubmit, setFieldValue, errors, values, touched, isSubmitting
  }: any = useFormik<any>({
    initialValues: {
      student: students.length === 1 ? students[0].Id : '',
      institution: ''
    },
    validationSchema: validationSchema(),
    onSubmit: ({ student, institution }, { setSubmitting }) => {
      onFormSubmit(student, institution, setSubmitting);
    },
  });

  const institutionsPerStudent: B2CInstitutionsType[] = values.student ? findInstitutionInStudentsArray(students, values.student) : [];
  const isOneStudent = students.length === 1;
  const isOneStudentInstitution = institutionsPerStudent.length === 1;
  const findStudentNameById = (selectedId: number) => students.find(({ Id }: any) => Id === selectedId)?.Name;
  const findStudentInstitutionNameById = (selectedId: number) => institutionsPerStudent.find(({ InstitutionId }: any) => InstitutionId === selectedId)?.InstitutionName;

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={3}>
        {
          isOneStudent && (
            <StaticInformationContainerStyled>
              <StudentAvatar sx={{ fontSize: 20, mr: 2 }} />
              <Typography variant="body1">
                {findStudentNameById(values.student)}
              </Typography>
            </StaticInformationContainerStyled>
          )
        }
        {
          isOneStudent || (
            <>
              <Select
                displayEmpty
                name="student"
                disabled={students.length === 1}
                onChange={(e) => {
                  // if we manipulate this field, and we have already selected an institution, we must clear it:
                  if (Boolean(values.institution)) {
                    setFieldValue('institution', '')
                  }

                  // if we have only one institution for this student, we will just preselect it:
                  const institutions = findInstitutionInStudentsArray(students, e.target.value);
                  if (institutions.length === 1) {
                    setFieldValue('institution', institutions[0].InstitutionId)
                  }
                  handleChange(e);
                }}
                value={values.student}
                IconComponent={ExpandMore}
                fullWidth
                renderValue={(selected) => findStudentNameById(selected) || t('login.selectStudentDialog.defaultSelectLabel')}
              >
                {students.map((option: any) => (
                  <MenuItem value={option.Id} key={option.Id}>
                    {option.Name}
                  </MenuItem>
                ))}
              </Select>
              {
                errors?.student && touched?.student && (
                  <FormHelperText error>{errors.student}</FormHelperText>
                )
              }
            </>
          )
        }
      </Box>
      <Box mt={3}>
        {
          // if we have selected student, and they have only one institution
          (Boolean(values.student) && isOneStudentInstitution) && (
            <StaticInformationContainerStyled>
              <InstitutionIcon1 sx={{ fontSize: 20, mr: 2 }} />
              <Typography variant="body1">
                {findStudentInstitutionNameById(values.institution)}
              </Typography>
            </StaticInformationContainerStyled>
          )
        }
        {
          // if we have selected student, and they have more than one institution
          (Boolean(values.student) && !isOneStudentInstitution) && (
            <>
              <Select
                displayEmpty
                name="institution"
                disabled={institutionsPerStudent.length === 1}
                onChange={handleChange}
                value={values.institution}
                IconComponent={ExpandMore}
                fullWidth
                renderValue={(selected) => findStudentInstitutionNameById(selected) || t('login.selectStudentDialog.defaultSelectLabel')}
              >
                {institutionsPerStudent.map(({ InstitutionId, InstitutionName }) =>  (
                  <MenuItem value={InstitutionId} key={InstitutionName}>
                    {InstitutionName}
                  </MenuItem>
                ))}
              </Select>
              {
                errors?.institution && touched?.institution && (
                  <FormHelperText error>{errors.institution}</FormHelperText>
                )
              }
            </>
          )
        }
      </Box>
      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
        >
          {t('login.selectStudentDialog.submitButton')}
        </Button>
      </Box>
    </form>
  );
}

B2CForm.defaultProps = {
};

export default B2CForm;
