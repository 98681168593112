import React from 'react';
import { Alert, Box, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GuideStyled } from './styles';

type Props = {
  containers: any[]
}

function GuideSection({ containers }: Props) {
  const { t } = useTranslation();

  const renderImage = (title: string, image: any) => {
    return (
      <Grid xs={12} sm={6} item>
        {Array.isArray(image) ?
          image.map((img, index) =>
            <img key={t<string>(`${title}-${index}`)} alt={title ? t(`${title}-${index}`) : t(`common.noImageAlt-${index}`)} src={img} />
          ) : (
            <img alt={title ? t(title) : t('common.noImageAlt')} src={image} />
          )
        }
      </Grid>
    );
  };

  return (
    <Container maxWidth='lg'>
      <Card>
        <CardContent>
      {
        containers.map(({ id, image, title, description }: any, index) => (
          <React.Fragment key={id}>
            {index !== 0 && <Divider sx={{ marginTop: [2, 4] }} />}

            <GuideStyled
              container
              key={id}
              spacing={[3, 6]}
            >
              {image && renderImage(title, image)}
              <Grid xs={12} sm={image ? 6 : 12} item>
                {title && (<Typography variant='h3'>{t(title)}</Typography>)}
                {
                  description.map(({ type, content }: any, index: any) => {
                    let cnt: any = '';
                    if (type === 'text') {
                      cnt = (<Typography variant='body1'>{t(content)}</Typography>);
                    }

                    if (type === 'warning') {
                      cnt = (<Alert severity='warning'>{t(content)}</Alert>);
                    }

                    return (
                      <Box key={index} my={2}>
                        {cnt}
                      </Box>
                    );
                  })
                }
              </Grid>
            </GuideStyled>

          </React.Fragment>
        ))
      }
        </CardContent>
      </Card>
    </Container>
  );
}

GuideSection.defaultProps = {};

export default GuideSection;
