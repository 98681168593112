import React from 'react';

import {
  Table as TableMui,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  Mode,
  Delete,
  ArrowBackIos,
  ArrowForwardIos,
} from '@mui/icons-material';

import { BlockingNotification, News } from 'api/types';

import palette from 'theme/components/palette';

interface Props {
  headers: HeaderTable[];
  data: News[] | BlockingNotification[];
  onDeleteConfirmationDialogOpen: Function;
  onEditOpen: Function;
  onClickPreviousPage: (event: any) => void;
  onClickNextPage: (event: any) => void;
  disabledPreviousPage?: boolean;
  disabledNextButton?: boolean;
}
export interface HeaderTable {
  field: string;
  label: string | undefined;
  renderer?: (value: any) => void;
}

export default function Table({
  headers,
  data,
  onDeleteConfirmationDialogOpen,
  onEditOpen,
  onClickPreviousPage,
  onClickNextPage,
  disabledPreviousPage,
  disabledNextButton,
}: Props) {
  const crudHeaders = [
    ...headers,
    {
      field: 'id',
      label: undefined,
      renderer: (id: any) => {
        return (
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => onEditOpen(id)}>
              <Mode fontSize="small" sx={{ color: palette.primary.main }} />
            </IconButton>
            <IconButton onClick={() => onDeleteConfirmationDialogOpen(id)}>
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <TableMui sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          {crudHeaders.map((header) => (
            <TableCell key={header.field}>{header.label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((row: any) => (
          <TableRow hover key={row.id}>
            {crudHeaders.map((col: any, index: number) => {
              return (
                <TableCell key={index}>
                  {col.renderer
                    ? col.renderer(row[col.field], row.id)
                    : row[col.field]}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right">
            <IconButton
              onClick={onClickPreviousPage}
              disabled={disabledPreviousPage}
            >
              <ArrowBackIos
                fontSize="small"
                sx={{ color: disabledPreviousPage ? palette.grey[200] : '' }}
              />
            </IconButton>
            <IconButton onClick={onClickNextPage} disabled={disabledNextButton}>
              <ArrowForwardIos
                fontSize="small"
                sx={{ color: disabledNextButton ? palette.grey[200] : '' }}
              />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </TableMui>
  );
}
