import { useState, createContext, useMemo } from 'react';

type LoaderContextProps = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const LoaderContext = createContext<LoaderContextProps | null>(null);

export const LoaderContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const contextValue = useMemo(() => ({
    loading,
    setLoading: (l: boolean) => setLoading(l)
  }), [loading]);
  
  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  );
};
