import { useUserRoleContext } from 'hooks/useUserRoleContext';
import { WEEKLY_SCHEDULE_ROLES } from 'system-settings';
import { AuthManager } from 'authentication/auth-manager';

export const getHomePageUrl = () => {
  // are we logged in?
  const isAuthenticated = AuthManager.isAuthenticated();
  if (!isAuthenticated) {
    return '/';
  }

  // if we are logged in, check the role and based on it, redirect to
  // internal "front-page"
  const role = useUserRoleContext();
  return WEEKLY_SCHEDULE_ROLES.includes(role) ? '/weekly-schedule' : '/library';
}
