import { BORDER_RADIUS, BORDER_RADIUS_LARGE, SCROLL_WIDTH } from '../constants';
import palette from './palette';
import shadows from './shadows';

export default {
  styleOverrides: {
    root: {
      boxShadow: shadows[4],
      borderRadius: BORDER_RADIUS_LARGE,
      '&::-webkit-scrollbar': {
        width: SCROLL_WIDTH / 2,
        borderRadius: BORDER_RADIUS,
      },
      '&::-webkit-scrollbar-track': {
        background: palette.grey[100],
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: palette.grey.main,
        borderRadius: BORDER_RADIUS,
      },
    },
  },
};
