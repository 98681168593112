import { lazy, Suspense } from 'react';
import { useSharedViewContext } from 'hooks/useSharedViewContext';

const Homework = () => {
  const viewName = useSharedViewContext();

  const Component = lazy(() => import(`../../views/homeworks/homework/${viewName}`));

  return (
    <Suspense fallback={<></>}>
      <Component />
    </Suspense>
  );
};

export default Homework;
