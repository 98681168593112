
import styled from "@emotion/styled";
import { Box, IconButton } from '@mui/material';
import { BORDER_RADIUS } from 'theme/constants';

export const RootStyled = styled(Box)(({ theme }: any) => {
  const { palette } = theme;

  return {
    borderRadius: BORDER_RADIUS,
    background: palette.common.white,
    gap: 10,
    '& .MuiFormControl-root': {
      width: '100%'
    },
  }
});

const commonIconStyles = (palette: any) => ({
    background: palette.grey.main,
    color: palette.common.white,
    width: 40,
    height: 40,
    '&:hover': {
        backgroundColor: palette.grey.dark
    }
});

export const IconButtonLeftStyled = styled(IconButton)(({ theme }: any) => {
    const { palette } = theme;
  
    return {
      ...commonIconStyles(palette),
      borderRadius: `${BORDER_RADIUS}px 0 0 ${BORDER_RADIUS}px`,
    }
  });

export const IconButtonRightStyled = styled(IconButton)(({ theme }: any) => {
const { palette } = theme;

return {
    ...commonIconStyles(palette),
    borderRadius: `0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0`,
}
});
