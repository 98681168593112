// TODO: refactor and delete

import axios from 'axios';
import {
  basAPI,
  authAPI,
  meetingAPI,
  assignmentAPI,
  contentAPI,
  notificationAPI,
  analythicsAPI,
  publicCommunicationsAPI
} from 'windows';
import { AuthManager } from 'authentication/auth-manager';

export const Base_Meeting_API_URL = `${meetingAPI}`;
export const Base_Auth_API_URL = `${authAPI}`;
export const BASE_API_URL = `${basAPI}`;
export const Base_Assignment_URL = assignmentAPI;
export const Base_Content_API_URL = `${contentAPI}`;
export const Public_Communications_API_URL = `${publicCommunicationsAPI}`;
export const Base_Notifications_API_URL = `${notificationAPI}`;

const DEFAULT_REQUEST_TIMEOUT = 360000;

axios.defaults.baseURL = BASE_API_URL;
axios.defaults.timeout = DEFAULT_REQUEST_TIMEOUT;
axios.defaults.headers.get['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.get['Strict-Transport-Security'] =
  'max-age=15724800; includeSubDomains';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.post['Strict-Transport-Security'] =
  'max-age=15724800; includeSubDomains';

const getAnalyticsURL = (path: string): string => `${analythicsAPI}${path}`;

//## Analytics
export const postAnalytics = async <TRequest, TResult>(
  path: string,
  request: TRequest
): Promise<TResult> => {
  const url = getAnalyticsURL(path);

  return axios.post<TRequest, TResult>(url, request, {
    headers: { Authorization: `Bearer ${AuthManager.getBearerToken()}` },
  });
};
