import styled from '@emotion/styled';
import backgrounds from './backgrounds/svg';
import svgToMiniDataURI from 'mini-svg-data-uri';

export const RootStyled = styled('div')(({ theme, variant }: any) => {
  const { content, footer, fallbackColor } = backgrounds[variant]

  return ({
    background: `url("${svgToMiniDataURI.toSrcset(content)}") center 0 repeat ${fallbackColor ? fallbackColor : 'inherit'}`,
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      ...(footer ? {
        background: `url("${svgToMiniDataURI.toSrcset(footer)}") no-repeat bottom center`
      } : {})
    },

    '& > div': {
      zIndex: 1,
      position: 'relative',
    }

  });
});
