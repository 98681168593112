import { BORDER_RADIUS_LARGE } from '../constants';

export default {
  styleOverrides: {
    root: {
      '& .MuiDrawer-paper': {
        borderRadius: `0px 0px ${BORDER_RADIUS_LARGE}px ${BORDER_RADIUS_LARGE}px`,
      },
    },
  },
};
