import React, { useState } from 'react';
import i18n from 'i18n';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Button,
  Box,
  InputAdornment,
  Input,
  IconButton,
  Drawer,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { Container } from '@mui/system';
import { Search } from '@mui/icons-material';

import RCS from 'components/responsive-control-system';
import { useSnackbarContext } from 'hooks/useSnackbarContext';

import { MIN_COUNT_SEARCH_SYMBOLS } from 'constants/global';
import { palette } from 'theme/components';
import { CloseIconStyled, GridStyled } from './styles';


type Props = {
  open: boolean;
  onClose: () => void;
};

function GlobalSearch({ open, onClose }: Props) {
  const snackbarContext = useSnackbarContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const openSearchPage = () => {
    if (value && value.length >= MIN_COUNT_SEARCH_SYMBOLS) {
      navigate(`/search?search=${value}`);
      onClose();
      setValue('');
    }
    else {
      snackbarContext?.addSnackbar({
        key: crypto.randomUUID(),
        title: `${i18n.t('errors.contentSuggestionSearch')}`,
        severity: 'error'
      });
    }
  };

  const onKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      openSearchPage();
    }
  };

  const searchTablet = (
    <Input
      size="small"
      value={value}
      onChange={(ev) => setValue(ev.target.value)}
      onKeyDown={onKeyDown}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={openSearchPage}>
            <Search
              sx={{
                color: palette.primary.light,
              }}
            />
          </IconButton>
        </InputAdornment>
      }
      fullWidth
      placeholder={t('pages.globalSearch.placeholder')}
    />
  );

  return (
    <Drawer
      anchor="top"
      open={open}
      onClose={onClose}
      hideBackdrop
      sx={{
        '& .MuiDrawer-paper': { opacity: mobile ? 1 : 0.9 },
      }}
    >
      <GridStyled
        container
        xs={12}
        display="flex"
        flexDirection="column"
        gap={3}
      >
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <CloseIconStyled onClick={onClose} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h1"
            color={palette.common.white}
            p={3}
          >
            {t('pages.globalSearch.labelMenu')}
          </Typography>
          <Container maxWidth="md">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              boxSizing="border-box"
              gap={2}
              paddingBottom={20}
            >
              <RCS hidden="tablet">
                <Input
                  value={value}
                  onChange={(ev) => setValue(ev.target.value)}
                  onKeyDown={onKeyDown}
                  fullWidth
                  startAdornment={
                    <InputAdornment position="start" sx={{ pr: 1 }}>
                      <IconButton>
                        <Search
                          fontSize="small"
                          sx={{ color: palette.grey.light }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ py: 1.5, px: 3.5 }}
                  onClick={openSearchPage}
                >
                  {t('pages.globalSearch.button')}
                </Button>
              </RCS>
              <RCS visible="tablet">{searchTablet}</RCS>
            </Box>
          </Container>
        </Grid>
      </GridStyled>
    </Drawer>
  );
}

GlobalSearch.defaultProps = {};

export default GlobalSearch;
