import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  getNews,
  getRoles,
  deleteCrud,
  getBlockingNotifications,
  requestPublicNotifications,
  getItemCrud,
} from 'api/api';
import { DEFAULT_CRUD_PAGINATION } from 'routes/administration/crud/constants';
import { TypeCrud } from 'routes/administration/crud/config';
import { DEFAULT_INSTRUCTION_PAGINATION } from 'routes/administration/instructions/config';

export const useGetAllCrudPublicItems = (
  options: Object = { keepPreviousData: true, retry: false }
) =>
  useQuery<any, Error>(
    [`allPublics`, {}],
    () => requestPublicNotifications(),
    options
  );

export const useGetAllCrudItems = (
  filter: {
    typeCrud: TypeCrud,
    enabled: boolean,
    searchTitle?: string | undefined,
    disabledErrorHandling?: boolean | undefined,
    showAll?: boolean
  },
  paging?: { page: number; itemsPerPage: number },
  options: Object = { cacheTime: 0, retry: false }
) =>
  useQuery<any, Error>(
    [`allItems-${filter.typeCrud}`, { filter, paging }],
    ({ pageParam = paging || DEFAULT_CRUD_PAGINATION }) => {
      if (filter.typeCrud === TypeCrud.news) {
        return getNews({ ...filter }, pageParam);
      } else if (filter.typeCrud === TypeCrud.notifications) {
        return getBlockingNotifications(filter, pageParam);
      }
    },
    options
  );

export const useGetItemCrud = (
  type: TypeCrud,
  id: string | undefined,
  options: Object = {}
) =>
  useQuery<any, Error>(
    ['item-crud', {}],
    () => {
      if (id !== 'new') {
        return getItemCrud(type, id);
      }
      return;
    },
    options
  );

export const useDeleteCrud = (type: TypeCrud) => {
  const queryClient = useQueryClient();
  return useMutation((id: any) => deleteCrud(type, id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`allItems-${type}`]);
    },
  });
};

export const useGetRoles = (options: Object = { keepPreviousData: true, retry: false }) =>
  useQuery(['roles', {}], () => getRoles(), options);

export const useGetNewsList = (
  filter: {
    typeCrud: TypeCrud,
    enabled: boolean,
    searchTitle?: string | undefined,
  },
  paging?: { page: number; itemsPerPage: number },
  options: Object = {}
) =>
  useInfiniteQuery<any, Error>(
    [`news`, { filter, paging }],
    ({ pageParam = paging || DEFAULT_INSTRUCTION_PAGINATION }) =>
    getNews(filter, pageParam),
    {
      ...options,
      getNextPageParam: getNextPage,
    }
  );

const getNextPage = (response: any) => {
  return response?.pagination?.hasMore
    ? { ...response?.pagination, page: response?.pagination?.page + 1 }
    : undefined;
};
