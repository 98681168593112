export const installationGuideContainers = [
  {
    id: 1,
    image: 'images/download/2.png',
    title: 'contentPage.section.install.1.title',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.install.1.paragraph1',
      },
      {
        type: 'warning',
        content: 'contentPage.section.install.1.warning',
      },
      {
        type: 'text',
        content: 'contentPage.section.install.1.paragraph2',
      },
      {
        type: 'text',
        content: 'contentPage.section.install.1.paragraph3',
      },
    ],
  },
  {
    id: 2,
    image: 'images/download/3.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.install.2.paragraph1',
      },
    ],
  },
  {
    id: 3,
    image: 'images/download/4.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.install.3.paragraph1',
      },
    ],
  },
  {
    id: 4,
    image: 'images/download/5.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.install.4.paragraph1',
      },
      {
        type: 'warning',
        content: 'contentPage.section.install.4.warning',
      },
    ],
  },
  {
    id: 5,
    image: 'images/download/6.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.install.5.paragraph1',
      },
    ],
  },
  {
    id: 6,
    image: 'images/download/7.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.install.6.paragraph1',
      },
    ],
  },
  {
    id: 7,
    image: 'images/download/8.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.install.7.paragraph1',
      },
    ],
  },
  {
    id: 8,
    image: 'images/download/9.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.install.8.paragraph1',
      },
      {
        type: 'warning',
        content: 'contentPage.section.install.8.warning',
      },
    ],
  },
  {
    id: 9,
    image: 'images/download/10.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.install.9.paragraph1',
      },
      {
        type: 'text',
        content: 'contentPage.section.install.9.paragraph2',
      },
    ],
  },
];

export const userGuideContainers = [
  {
    id: 1,
    title: 'contentPage.section.user.1.title',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.1.paragraph1',
      },
    ],
  },
  {
    id: 2,
    image: 'images/user/1.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.2.paragraph1',
      },
    ],
  },
  {
    id: 3,
    image: 'images/user/2.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.3.paragraph1',
      },
    ],
  },
  {
    id: 4,
    image: 'images/user/3.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.4.paragraph1',
      },
    ],
  },
  {
    id: 5,
    image: ['images/user/4.png', 'images/user/5.png'],
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.5.paragraph1',
      },
    ],
  },
  {
    id: 6,
    image: 'images/user/6.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.6.paragraph1',
      },
    ],
  },
  {
    id: 7,
    image: 'images/user/7.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.7.paragraph1',
      },
    ],
  },
  {
    id: 8,
    image: 'images/user/8.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.8.paragraph1',
      },
    ],
  },
  {
    id: 9,
    image: 'images/user/9.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.9.paragraph1',
      },
      {
        type: 'text',
        content: 'contentPage.section.user.9.paragraph2',
      },
    ],
  },
  {
    id: 10,
    image: 'images/user/10.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.10.paragraph1',
      },
      {
        type: 'warning',
        content: 'contentPage.section.user.10.warning',
      },
    ],
  },
  {
    id: 11,
    image: 'images/user/11.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.11.paragraph1',
      },
      {
        type: 'warning',
        content: 'contentPage.section.user.11.warning',
      },
    ],
  },
  {
    id: 12,
    image: 'images/user/12.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.12.paragraph1',
      },
    ],
  },
  {
    id: 13,
    image: 'images/user/13.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.13.paragraph1',
      },
    ],
  },
  {
    id: 14,
    image: 'images/user/14.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.14.paragraph1',
      },
      {
        type: 'warning',
        content: 'contentPage.section.user.14.warning',
      },
    ],
  },
  {
    id: 15,
    image: 'images/user/15.png',
    description: [
      {
        type: 'text',
        content: 'contentPage.section.user.15.paragraph1',
      },
      {
        type: 'text',
        content: 'contentPage.section.user.15.paragraph2',
      },
      {
        type: 'text',
        content: 'contentPage.section.user.15.paragraph3',
      },
    ],
  },
];
