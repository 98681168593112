import { getHomePageUrl } from 'utils/navigation';

export const gradesPageBreadcrumbs = (): any => [
  {
    href: getHomePageUrl(),
    label: 'Начало'
  },
  {
    href: '/library',
    label: 'Библиотека'
  }
];

export const libraryBreadcrumbs = (): any => [
  ...gradesPageBreadcrumbs(),
];


export const librarySubjectBreadcrumb = (classId: any) => [
  ...libraryBreadcrumbs(),
  {
    href: `/library/${classId}`,
    label: `${classId} клас`,
  },
];

export const libraryTopicBreadcrumb = (classId: any, subjectId: any, subjectName: any) => [
  ...librarySubjectBreadcrumb(classId),
  {
    href: `/library/${classId}/${subjectId}`,
    label: subjectName ? `${subjectName} ${classId} клас` : ''
  }
];

export const libraryDigitalContentBreadcrumb = (
  classId: any,
  subjectId: any,
  subjectName: any,
  topicId?: any,
  topicName?: any
) => [
  ...libraryTopicBreadcrumb(classId, subjectId, subjectName),
  ...(topicId && topicName ? [{
    href: `/library/${classId}/${subjectId}/${topicId}`,
    label: topicName || ''
  }] : [])
];
