import React, { createContext, useMemo } from 'react';

import { Roles, UserGroups } from 'enums/userRoles';
import { IUserModel } from 'authentication/types';
import { AuthManager } from 'authentication/auth-manager';

export interface UserContextInterface {
  user: IUserModel;
  userRole: {
    isTeacher: boolean;
    isStudent: boolean;
    isDirector: boolean;
    isParent: boolean;
  };
};

export const UserContext = createContext<UserContextInterface | null>({
  user: AuthManager.getUser(),
  userRole: { isTeacher: false, isStudent: false, isDirector: false, isParent: false },
});

const hasAnyRole = (user: IUserModel, roles: Roles[]) => 
  user?.roles?.findIndex(x => roles.includes(x)) >= 0;

const getUserRoles = (user: IUserModel) => {
  if (user == null) {
    return { isTeacher: false, isStudent: false, isDirector: false, isParent: false };
  }

  const role = { isTeacher: false, isStudent: false, isDirector: false, isParent: false };
  if (hasAnyRole(user, [...UserGroups.Teacher])) {
    role.isTeacher = true;
  } else if (hasAnyRole(user, [Roles.Director])) {
    role.isDirector = true;
  } else if (hasAnyRole(user, [Roles.Student])) {
    role.isStudent = true;
  } else if (hasAnyRole(user, [Roles.Parent])) {
    role.isParent = true;
  }

  return role;
};

export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const user = AuthManager.getUser();
  const userRole = getUserRoles(user);

  const value = useMemo(() => ({ user, userRole }), [user, userRole]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
