import { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import HeaderUserMenu from './HeaderUserMenu';
import ContextNotificationsMenu from './components/context-notifications-menu';
import GlobalSearch from './components/search-menu';
import { AuthManager } from 'authentication/auth-manager';

const HeaderActions = () => {
  const user = AuthManager.getUser();
  const [openSearch, setOpenSearch] = useState(false);

  return (
    <Box display="flex" alignItems="center">
      <IconButton onClick={() => setOpenSearch(true)}>
        <Search />
      </IconButton>
      <GlobalSearch open={openSearch} onClose={() => setOpenSearch(false)} />
      <Box pr={1.5}>
        <ContextNotificationsMenu />
      </Box>

      <Box display="flex" alignItems="center">
        <HeaderUserMenu
          onLogout={AuthManager.logout}
          name={user?.name}
          job={user?.job}
        />
      </Box>
    </Box>
  );
};

export default HeaderActions;
