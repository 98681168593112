export enum TypeCrud {
  news = 'news',
  notifications = 'notifications',
}

const SPACING = 4;

export const formStructure = () => ({
  news: [
    {
      id: 'row1',
      type: 'grid-row',
      spacing: SPACING,
      children: [
        {
          id: 'col-1',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'titleBg',
              type: 'text',
              label: 'news.form.titleBg.label',
              required: true,
            },
          ],
        },
        {
          id: 'col-2',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'titleEn',
              type: 'text',
              label: 'news.form.titleEn.label',
              required: true,
            },
          ],
        },
      ],
    },
    {
      id: 'row2',
      type: 'grid-row',
      spacing: SPACING,
      children: [
        {
          id: 'col-3',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'descriptionBg',
              type: 'text',
              label: 'news.form.descriptionBg.label',
            },
          ],
        },
        {
          id: 'col-4',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'descriptionEn',
              type: 'text',
              label: 'news.form.descriptionEn.label',
            },
          ],
        },
      ],
    },
    {
      id: 'row3',
      type: 'grid-row',
      spacing: SPACING,
      children: [
        {
          id: 'col-5',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'roles',
              type: 'select',
              label: 'news.form.roles.label',
              required: true,
            },
          ],
        },
        {
          id: 'col-6',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'tag',
              type: 'select',
              label: 'news.form.tag.label',
              required: true,
            },
          ],
        },
      ],
    },
    {
      id: 'row4',
      type: 'grid-row',
      spacing: SPACING,
      children: [
        {
          id: 'col-7',
          type: 'grid-col',
          xs: 12,
          children: [
            {
              id: 'contentBg',
              type: 'richtext',
              label: 'news.form.contentBg.label',
              required: true,
            },
          ],
        },
      ],
    },
    {
      id: 'row5',
      type: 'grid-row',
      spacing: SPACING,
      children: [
        {
          id: 'col-9',
          type: 'grid-col',
          xs: 12,
          children: [
            {
              id: 'contentEn',
              type: 'richtext',
              label: 'news.form.contentEn.label',
              required: true,
            },
          ],
        },
      ],
    },
    {
      id: 'row6',
      type: 'grid-row',
      spacing: SPACING,
      children: [
        {
          id: 'col-10',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'enabled',
              type: 'switch',
              label: 'news.form.enabled.label',
            },
          ],
        },
      ],
    },
    {
      id: 'hyperlinks',
      type: 'hyperlinks',
      label: 'news.form.hyperlinks.label',
    },
    {
      id: 'files',
      type: 'attach',
    },
  ],
  notifications: [
    {
      id: 'row1',
      type: 'grid-row',
      spacing: SPACING,
      children: [
        {
          id: 'col-1',
          type: 'grid-col',
          xs: 12,
          sm: 4,
          children: [
            {
              id: 'enabled',
              type: 'switch',
              label: 'notifications.form.enabled.label',
            },
          ],
        },
      ],
    },
    {
      id: 'row2',
      type: 'grid-row',
      spacing: SPACING,
      children: [
        {
          id: 'col-2',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'messageBg',
              type: 'textarea',
              label: 'notifications.form.messageBg.label',
              required: true,
            },
          ],
        },
        {
          id: 'col-3',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'messageEn',
              type: 'textarea',
              label: 'notifications.form.messageEn.label',
              required: true,
            },
          ],
        },
      ],
    },
    {
      id: 'row3',
      type: 'grid-row',
      spacing: SPACING,
      children: [
        {
          id: 'col-4',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'endDate',
              type: 'datePicker',
              label: 'notifications.form.endDate.label',
              required: true,
            },
          ],
        },
        {
          id: 'col-5',
          type: 'grid-col',
          xs: 12,
          sm: 12,
          md: 6,
          children: [
            {
              id: 'roles',
              type: 'select',
              label: 'notifications.form.roles.label',
              required: true,
            },
          ],
        },
      ],
    },
  ],
});
