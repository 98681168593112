import { useQuery } from 'react-query';
import { getGlobalSearchResults } from 'api/api';
import { DEFAULT_SEARCH_PAGINATION } from 'views/search/config';

export const useGlobalSearchList = (
  paging?: { page: number; itemsPerPage: number },
  input?: string | null,
  options: Object = {}
) =>
  useQuery<any, Error>(
    ['globalSearch', { paging, input }],
    ({ pageParam = paging || DEFAULT_SEARCH_PAGINATION }) => {
      if (input) {
        return getGlobalSearchResults(pageParam, input);
      }
    },
    options
  );
