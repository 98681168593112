import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import Table from './components/table';
import Breadcrumbs from 'components/breadcrumbs';
import { licenseBreadcrumbs } from './breadcrumbs';
import {
  MS_OFFICE_LICENSES,
  OFFICE_APPLICATIONS_PACKAGE,
  WINDOWS_LICENSES,
} from './config';

export default function Licenses() {
  const { t } = useTranslation();

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={licenseBreadcrumbs()} />
      </Box>
      <Typography variant="h1" pb={2}>
        {t('licenses.title')}
      </Typography>
      <Typography variant="body1" py={2}>
        {t('licenses.table.subtitle.msOffice')}
      </Typography>
      <Table data={MS_OFFICE_LICENSES} />
      <Typography variant="body1" pt={3}>
        {t('licenses.officePackageList')}
        {OFFICE_APPLICATIONS_PACKAGE.map((app) => (
          <b>{` ${app}, `}</b>
        ))}
      </Typography>
      <Typography variant="body1" py={2}>
        {t('licenses.table.subtitle.windows')}
      </Typography>
      <Table data={WINDOWS_LICENSES} />
    </>
  );
}
