export enum NavigationLinks {
  weeklySchedule = '/weekly-schedule',
  mySubjects = '/my-subjects',
  library = '/library',
  content = 'content',
  librarySubject = '/library/:classId/:subjectId',
  libraryTopic = '/library/:id/:subjectId/:topicId',
  libraryDigitalContent = '/library/content/:contentId',
  // the difference between subjectId and subjectTypeId is, that subject type id shows also subject
  // that has subjectType: ex we have Математика ООП аnd Математика ЗИП. They are both subjectID=xx,
  // but the first one has subjectTypeId = xxxxx and the second one subjectTypeId = yyyyy
  // we must support both subject routes because of the links in notifications which have been sent since now
  subjectV1 = '/subject/:subjectId/:curriculumId',
  // since we separated ООП and ПП subjects, we need to provide subjectTypeId in the url
  // if subjectTypeId is not provided then subjectV1 route will be matched
  // and Subject view will retrieve its value from the curriculum
  subjectV2 = '/subject/:subjectId/:subjectTypeId/:curriculumId',
  homeworks = '/homeworks',
  homework = '/homeworks/:id',
  createHomework = '/homeworks/new',
  homeworkAssessment = 'homeworks/:assignmentId/solutions/:solutionId',
  grades = '/my-grades',
  mozaik = 'mozaik',
  notifications = '/notifications',
  globalSearch = '/search',
  managingAssignedContent = '/assigned-contents',
  administrationApplications = 'administration/applications',
  administrationLicense = 'administration/license',
  administrationInstructions = 'administration/instructions',
  administrationNews = 'administration/crud/news',
  administrationNotifications = 'administration/crud/notifications',
  administrationCreateEditCrud = 'administration/crud/:type/:id',
  configuration = 'configuration',
  profStudyAids = 'study-aids'
}
