import { BASE_APP_URL, AAD_CLIENT_ID, AAD_AUTHORITY, B2C_CLIENT_ID, B2C_AUTHORITY } from 'windows';
import { LoginType, LoginTypeEnum } from './types';

export const authconfig: LoginType = {
  [LoginTypeEnum.aad]: {
    clientId: AAD_CLIENT_ID,
    redirectUri: BASE_APP_URL,
    authority: AAD_AUTHORITY,
    scopes: [`api://${AAD_CLIENT_ID}/default`, 'offline_access'],
    // specific options:
    postLogoutRedirectUri: BASE_APP_URL,
  },
  [LoginTypeEnum.b2c]: {
    clientId: B2C_CLIENT_ID,
    redirectUri: BASE_APP_URL,
    authority: B2C_AUTHORITY,
    scopes: [B2C_CLIENT_ID],
    // specific options:
    knownAuthorities: [`${B2C_AUTHORITY}/oauth2/v2.0`],
    navigateToLoginRequestUrl: false,
  },
};
