import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import Rating from 'components/rating';
import RCS from 'components/responsive-control-system';
import { PrivateContentIcon, PublicContentIcon } from 'components/icons/svg';
import { ContentActivity, ContentLinkSources, ContentVisibilityLevelEnum } from 'enums/contents-type.enum';
import { getSubjectConfig } from 'config/subject-config-data';
import { theme } from 'theme/theme';
import { palette } from '../theme/components';

type Props = {
  renderActionButtons?: Function;
  ratingInfo?: any;
  classes?: number[];
  variant: 'small' | 'medium' | 'large';
  [key: string]: any;
};

export const propsToPropagateByVariant: any = {
  small: {
    icon: {
      fontSize: '20px',
      color: theme.palette.primary.main,
    },
    favoriteIcon: {
      fontSize: '24px',
    },
    pinIcon: {
      fontSize: '20px',
      color: palette.grey.main,
    },
    title: {
      variant: 'h5',
    },
  },
  medium: {
    icon: {
      color: theme.palette.primary.main,
    },
    favoriteIcon: {},
    pinIcon: {
      color: palette.grey.main,
    },
    title: {
      variant: 'h4',
      // we don't have it in styleguide...
      fontSize: '24px',
    },
  },
  large: {
    icon: {
      fontSize: '30px',
      color: theme.palette.primary.main,
    },
    favoriteIcon: {
      fontSize: '30px',
    },
    pinIcon: {
      fontSize: '30px',
      color: palette.grey.main,
    },
    title: {
      variant: 'h4',
    },
  },
};

function ContentTile({
  ratingInfo,
  classes,
  renderActionButtons,
  variant,
  isFavourite,
  ...rest
}: Props) {
  const { id, name, visibilityLevel, topics, keywords, subjects, activityId, sourceCode } = rest;
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const actionButtons = () => {
    if (renderActionButtons) {
      return renderActionButtons({
        id,
        variant,
        isFavorite: isFavourite,
        ...rest,
      });
    }
    return null;
  };

  const isContentPrivate =
    visibilityLevel === ContentVisibilityLevelEnum.Organization;

  return (
    <Box
      sx={{
        width: 1,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1.5}
      >
        {ratingInfo && (
          <Rating
            of={ratingInfo.maxValue}
            value={ratingInfo.averageRating}
            usersCount={ratingInfo.usersCount}
            sx={{ mb: 0.5 }}
          />
        )}
        {renderActionButtons && <RCS visible="mobile">{actionButtons()}</RCS>}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems='flex-start' gap={1.5}>
          {isContentPrivate && (
            <PrivateContentIcon sx={propsToPropagateByVariant[variant].icon} />
          )}
          {isContentPrivate || (
            <PublicContentIcon sx={propsToPropagateByVariant[variant].icon} />
          )}
          <Box>
            <Box>
              <Typography
                {...propsToPropagateByVariant[variant].title}
                dangerouslySetInnerHTML={{ __html: name }}
              />
              {sourceCode === ContentLinkSources.Mozaik && !isContentPrivate && activityId === ContentActivity.WithPayment && (
                <Typography variant='caption'>
                  Изготвено по проект {mobile ? 'ОУД' : '"Образование за утрешния ден"'}
                </Typography>
              )}
            </Box>
            {Boolean(subjects?.length) && (
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  fontWeight={600}
                  sx={{ wordBreak: 'initial' }}
                >
                  Предмет:&nbsp;
                </Typography>
                <Grid container display="flex" alignItems="center" wrap="wrap">
                  {subjects.map((subject: any, index: number) => {
                    const { subjectId, subjectName } = subject;
                    const subjectConfig = getSubjectConfig(subjectId);
                    const iconSubject = subjectConfig.icon({
                      fontSize: 'small',
                      sx: { color: subjectConfig.color },
                    });
                    return (
                      <Grid
                        display="flex"
                        alignItems="center"
                        wrap="wrap"
                        key={index}
                      >
                        <RCS hidden="mobile">
                          {index !== 0 && <>&nbsp;|&nbsp;</>}
                        </RCS>
                        <Box display="flex" alignItems="center" gap={0.5}>
                          {iconSubject}
                          <Typography
                            component="span"
                            variant="body2"
                            dangerouslySetInnerHTML={{
                              __html: `${subjectName || subjectConfig.name}${subject.isProfileSubject ? ' ПП' : ''}`,
                            }}
                          />
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}

            {Boolean(classes?.length) && (
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  fontWeight={600}
                  sx={{ wordBreak: 'initial' }}
                >
                  Клас:&nbsp;
                </Typography>
                <Box>
                  {classes?.map((numberClass: any, index: number) => (
                    <React.Fragment key={index}>
                      {index !== 0 && <>&nbsp;|&nbsp;</>}
                      <Typography
                        component="span"
                        variant="body2"
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: numberClass + ' клас',
                        }}
                      />
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            )}

            {Boolean(topics?.length) && (
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  fontWeight={600}
                  sx={{ wordBreak: 'initial' }}
                >
                  Тема:&nbsp;
                </Typography>
                <Box>
                  {topics.map(({ id, name }: any, index: number) => {
                    return (
                      <React.Fragment key={id}>
                        {index !== 0 && <>&nbsp;|&nbsp;</>}
                        <Typography
                          component="span"
                          variant="body2"
                          key={id}
                          dangerouslySetInnerHTML={{ __html: name }}
                        />
                      </React.Fragment>
                    );
                  })}
                </Box>
              </Box>
            )}

            {Boolean(keywords?.length) && (
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  fontWeight={600}
                  sx={{ wordBreak: 'initial' }}
                >
                  Ключови думи:&nbsp;
                </Typography>
                <Box>
                  {keywords.map((keyword: any, index: number) => (
                    <React.Fragment key={index}>
                      {index !== 0 && <>&nbsp;|&nbsp;</>}
                      <Typography
                        component="span"
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: keyword }}
                      />
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {renderActionButtons && <RCS hidden="mobile">{actionButtons()}</RCS>}
      </Box>
    </Box>
  );
}

ContentTile.defaultProps = {
  variant: 'medium',
};

export default ContentTile;
