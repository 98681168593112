import { useNavigate } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs, Link } from '@mui/material';
import { Home } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = {
  items: {
    href: string;
    label: string;
    disabled?: boolean;
  }[];
};

const fontSize: any = '14px';

function Breadcrumbs({ items }: Props) {
  const showAboveMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.up('sm')
  );
  const navigate = useNavigate();

  const propsToPropagate: any = {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  };

  return (
    // This ugly implementation is due to a limitation in Mui Breadcrumbs. If you don't like it, feel free to redo it.
    <MUIBreadcrumbs>
      {
        items?.map(({ href, label, disabled }, index) => (
          <Link
            key={href}
            {...propsToPropagate}
            underline={!disabled ? 'hover' : 'none'}
            sx={{
              ...(disabled ? { cursor: 'default !important' } : {}),
            }}
            onClick={(e: any) => {
              if (disabled) {
                return;
              } else {
                navigate(href);
              }
            }}
          >
            {index === 0 && <Home fontSize={fontSize} />}
            {label}
          </Link>
        ))
      }
    </MUIBreadcrumbs>
  );
}

Breadcrumbs.defaultProps = {};

export default Breadcrumbs;
