import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider } from '@mui/material';

type Props = {
  open: boolean;
  onAccept: (event: any) => void;
  onDecline: (event: any) => void;
  message: string;
};

const ConfirmationDialog = ({ open, onAccept, onDecline, message }: Props) => {
  return (
    <Dialog open={open} onClose={onDecline}>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          onClick={onAccept}
          sx={{ marginRight: 2 }}
        >
          Продължи
        </Button>
        <Button variant='outlined' onClick={onDecline}>Откажи</Button>
      </DialogActions>
    </Dialog>
  )
};

export default ConfirmationDialog;