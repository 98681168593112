import React, { useEffect, useState } from 'react';
import {
  Box, Button, Card, CardContent, Chip, Divider, Grid, Skeleton, Typography,
} from '@mui/material';
import { ArrowForward, Clear, Star } from '@mui/icons-material';
import Breadcrumbs from 'components/breadcrumbs';
import { FallbackSection } from 'components/fallback';
import LoadingSkeleton from 'components/loading-skeleton';
import { MultipleSelect } from 'components/select';
import { MultipleSelectValuesHolder } from 'components/select/multiple-select';
import { ContextSearch } from 'components/search';
import { palette } from 'theme/components';

import { applicationsPageBreadcrumbs } from './breadcrumbs';
import useTextFieldSearch from 'hooks/useTextFieldSearch';
import { TAGS_TRANSLATIONS } from './constants';
import { requestApplications } from 'api/api';

import { ChipStyled } from './styles';

const POPULAR_TAG = 'popular';

type Props = {};

const popularTag = (
  <Box display='flex' alignItems='center' justifyContent='center'>
    <Star sx={{ fontSize: 18, color: palette.warning.main }} />
    Популярна
  </Box>
);

const loadingSkeleton = (
  <LoadingSkeleton itemsCount={9} height={180} gridXs={12} gridSm={6} gridMd={4} gridLg={4} />
);

function Applications({ }: Props) {
  const [filter, setFilter] = useState([]);
  const [activeFilter, setActiveFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const { list, setList, handleSearch, inputValue }: any =
    useTextFieldSearch('title');

  useEffect(() => {
    requestApplications().then((payload) => {
      setList(payload.applications);
      const normalizeTags: any = payload.tags.map((tag: any) => ({
        id: tag,
        label: TAGS_TRANSLATIONS[tag],
      }));
      setFilter(normalizeTags);
      setLoading(false);
    }).catch((error) => {
      // TODO add image from studio x "oh-oh image"
      console.error(error);
      setLoading(false);
    });
  }, []);

  const selectedTagIsValid = (applicationTags: any) => {
    if (activeFilter.length) {
      for (const tg of activeFilter) {
        if (applicationTags.find((i: any) => {
          const { id } = tg;
          return i === id
        })) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs
          items={applicationsPageBreadcrumbs()}
        />
      </Box>

      <Grid container mb={3}>
        <Grid item xs={12} md={6}>
          <Typography variant='h1'>
            Приложения
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} gap={3} display='flex'>
          <Grid item xs={12} md={6}>
            <MultipleSelect
              value={activeFilter}
              data={filter}
              onSelect={(item: any) => {
                const found = activeFilter.find(({ id }) => id === item.id)
                const newActiveFilterArray: any = found ? activeFilter.filter(({ id }) => id !== item.id) : [...activeFilter, item];
                setActiveFilter(newActiveFilterArray);
                handleSearch('');
              }}
              defaultLabel='Филтър'
              open={false}
            />
          </Grid>
          <Grid item xs={12} md={6} display="flex" alignItems="flex-start" justifyContent="flex-end">
            <ContextSearch value={inputValue} onChange={(value: string) => handleSearch(value)} />
          </Grid>
        </Grid>

        {Boolean(activeFilter.length) && (
          <Grid item xs>
            <MultipleSelectValuesHolder>
              <Box mr={1}>
                <Typography variant='subtitle2' fontWeight='600'>Избрани филтри: </Typography>
              </Box>
              <Box display='flex' alignItems='center' gap={2}>
                {
                  activeFilter.map(({ id, label }: any) => (
                    <Chip
                      key={id}
                      label={label}
                      onDelete={() => {
                        const newActiveFilter = activeFilter.filter((item: any) => id !== item.id);
                        setActiveFilter(newActiveFilter);
                      }}
                      deleteIcon={<Clear />}
                    />
                  ))
                }
              </Box>
            </MultipleSelectValuesHolder>
          </Grid>
        )}
      </Grid>

      {loading && loadingSkeleton}
      {
        loading || (
          <>
            {!Boolean(list.length) && (
              <FallbackSection message='Няма намерени резултати' />
            )}
            <Grid container spacing={3}>
              {
                list.map(({ id, title, href, tags }: any) => {
                  if (!selectedTagIsValid(tags)) {
                    return null;
                  }

                  return (
                    <Grid key={id} item xs={12} sm={6} md={4}>
                      <Card
                        sx={{ display: 'flex', height: '100%' }}
                      >
                        <CardContent
                          sx={{
                            display: 'grid',
                          }}
                        >
                          <Box display='flex' alignItems='center' gap={1.5}>
                            {tags !== null && tags.map((tag: any) => (
                              <ChipStyled
                                key={tag}
                                tag={tag}
                                label={tag === POPULAR_TAG ? popularTag : TAGS_TRANSLATIONS[tag]}
                              />
                            ))}
                          </Box>

                          <Box display='flex' alignItems='center' gap={1.5} my={2}>
                            <Typography variant='h5'>
                              {title}
                            </Typography>
                          </Box>

                          <Box>
                            <Divider />
                            <Box display='flex' alignItems='center' justifyContent='end' gap={1.5} mt={1}>
                              <Button
                                color='info'
                                endIcon={<ArrowForward />}
                                href={href}
                                target='_blank'
                              >
                                Виж повече
                              </Button>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                })
              }
            </Grid>
          </>
        )
      }
    </>
  );
}

Applications.defaultProps = {};

export default Applications;
