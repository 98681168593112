import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from 'components/footer';
import Background from 'components/background';

function App() {
  return (
    <>
      <Background variant={3}>
        <Outlet />
      </Background>
      <Footer />
    </>
  );
}

App.defaultProps = {
};

export default App;
