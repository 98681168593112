import styled from '@emotion/styled';
import { TextareaAutosize } from '@mui/material';
import { BORDER_RADIUS } from 'theme/constants';

export const TextareaStyled = styled(TextareaAutosize)(
  ({ theme: { palette, spacing, typography } }: any) => {
    return {
      maxWidth: '100%',
      maxHeight: '100%',
      backgroundColor: palette.background.paper,
      borderRadius: BORDER_RADIUS,
      borderColor: palette.grey[400],
      padding: spacing(2),
      fontFamily: typography.fontFamily,
      fontSize: 16,
    };
  }
);
