import { theme } from 'theme/theme';

export enum HomeworkStatuses {
  New = 100,
  Draft = 200,
  Submitted = 300,
  Evaluated = 400,
  ForCorrection = 500,
}

export type HomeworkStatusConfigType = {
  label: string;
  color: string;
  attentionRequired?: boolean;
};

export const getHomeworkStatusConfig =
  (status: HomeworkStatuses, isOverdue: boolean): HomeworkStatusConfigType => {
  if (isOverdue && status) {
    if (status === HomeworkStatuses.Submitted)
      return { label: 'Предадено извън срок', color: theme.palette.warning.main };
    else if (status === HomeworkStatuses.Evaluated)
      return { label: 'Приключено извън срок', color: theme.palette.success.main };
    else
      return { label: 'Просрочено', color: theme.palette.info.main, attentionRequired: true };
  }

  switch (status) {
    case HomeworkStatuses.New:
      return { label: 'Ново', color: theme.palette.primary.main, attentionRequired: true };
    case HomeworkStatuses.Draft:
      return { label: 'Чернова', color: theme.palette.grey[900] };
    case HomeworkStatuses.Submitted:
      return { label: 'Предадено', color: theme.palette.success.main };
    case HomeworkStatuses.Evaluated:
      return { label: 'Приключено', color: theme.palette.success.main };
    case HomeworkStatuses.ForCorrection:
      return { label: 'За поправка', color: theme.palette.grey[900] };
    default:
      return { label: '-', color: theme.palette.grey[900] };
  }
};
