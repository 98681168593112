import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';

export const PaperStyled = styled(Paper)(({ theme }: any) => {
  const { spacing } = theme;
  return {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: `${spacing(3)} 0`,
    padding: `${spacing(6)} ${spacing(4)} ${spacing(4)}`,

    '& > svg': {
      maxWidth: '100%'
    }
  };
});
