import React, { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Breadcrumbs from 'components/breadcrumbs';
import { FallbackSection } from 'components/fallback';
import LoadingSkeleton from 'components/loading-skeleton';
import { ContextSearch } from 'components/search';
import { instructionBreadcrumbs } from './breadcrumbs';
import { DEFAULT_INSTRUCTION_PAGINATION } from './config';
import { formatNewsDate } from 'utils/dateFormat';

import { useGetNewsList } from 'services/queryHooks/crud-hooks';

import {
  NewsMetaStyled,
  NewsMetaChipsStyled,
  TagStyled,
  LoadMoreContainerStyled,
  BigButtonStyled,
  DialogLinksStyled,
  DialogTitleStyled,
  TitleStyled,
  FooterStyled,
  InteractiveTitleStyled,
  MetaStyled,
  RootStyled,
  SubTitleStyled,
  TitleContainerStyled,
} from './styled';
import { TypeCrud } from '../crud/config';

type Props = {};

const loadingSkeleton = (
  <LoadingSkeleton
    itemsCount={3}
    height={220}
    gridXs={12}
    gridSm={6}
    gridMd={4}
    gridLg={4}
  />
);
const TAG: any = {
  TEAMS: 0,
  GOOGLE: 1,
  OTHERS: 2,
};

function Instructions({}: Props) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [inputValueDebounce] = useDebounce(inputValue, 500);
  const [selectedNewsItem, selectNewsItem]: any = useState(null);

  const {
    data: instructions,
    isLoading: isLoadingItems,
    fetchNextPage,
    hasNextPage,
  } = useGetNewsList(
    { typeCrud: TypeCrud.news, enabled: true, searchTitle: inputValueDebounce },
    DEFAULT_INSTRUCTION_PAGINATION  
  );

  const ReactMarkdownType: any = ReactMarkdown;

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={instructionBreadcrumbs()} />
      </Box>

      <Grid
        container
        xs={12}
        mb={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6} md={6}>
          <Typography variant="h1">{t('instructions.title')}</Typography>
        </Grid>

        <Grid item xs={6} md={6} display="flex" justifyContent="flex-end">
          <ContextSearch
            value={inputValue}
            onChange={(value: string) => {
              setInputValue(value);
            }}
          />
        </Grid>
      </Grid>

      {isLoadingItems && loadingSkeleton}
      {isLoadingItems || (
        <>
          {!Boolean(instructions?.pages[0]?.items) && (
            <FallbackSection message={t('instructions.filter.noResults')} />
          )}
          <Grid container spacing={5}>
            {Boolean(instructions?.pages.length) &&
              instructions?.pages.map((news: any) => {
                return news?.items.map((item: any) => {
                  const {
                    enabled,
                    hyperlinks,
                    id,
                    tag,
                    dateCreated,
                    ...newsItem
                  } = item;
                  const tagTypeKey = Object.keys(TAG).find(
                    (key: any) => TAG[key] === tag
                  );
                  const tagType = tagTypeKey?.toLowerCase() ?? 'others';
                  return (
                    <Grid key={id} item xs={12} sm={6} md={4}>
                      <RootStyled>
                        <MetaStyled>
                          <NewsMetaStyled>
                            {tag !== null && (
                              <NewsMetaChipsStyled>
                                <TagStyled
                                  tagtype={`${tagType}Tag`}
                                  label={t(
                                    `landingPage.section.news.tag.${tagType}`
                                  )}
                                />
                              </NewsMetaChipsStyled>
                            )}
                            <Typography>
                              {formatNewsDate(dateCreated)}
                            </Typography>
                          </NewsMetaStyled>
                        </MetaStyled>
                        <TitleContainerStyled size="medium">
                          <>
                            <InteractiveTitleStyled
                              type="submit"
                              onClick={(e: any) => {
                                e.preventDefault();
                                selectNewsItem(item);
                              }}
                            >
                              <TitleStyled size="medium">
                                {newsItem['titleBg']}
                              </TitleStyled>
                            </InteractiveTitleStyled>
                          </>

                          <SubTitleStyled size="medium">
                            {newsItem['descriptionBg']}
                          </SubTitleStyled>
                        </TitleContainerStyled>

                        <FooterStyled size="medium">
                          <Button
                            color="secondary"
                            endIcon={<ArrowForward />}
                            onClick={() => selectNewsItem(item)}
                          >
                            {t('common.seeMore')}
                          </Button>
                        </FooterStyled>
                      </RootStyled>
                    </Grid>
                  );
                });
              })}
          </Grid>
          {hasNextPage && (
            <LoadMoreContainerStyled
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <BigButtonStyled
                color="primary"
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={() => fetchNextPage()}
                disable={hasNextPage}
              >
                {t('common.loadMore')}
              </BigButtonStyled>
            </LoadMoreContainerStyled>
          )}
          <Dialog
            open={Boolean(selectedNewsItem)}
            onClose={() => selectNewsItem(null)}
          >
            {selectedNewsItem && (
              <>
                <DialogTitleStyled disableTypography>
                  <Typography variant="h6">
                    {selectedNewsItem['titleBg']}
                  </Typography>
                </DialogTitleStyled>
                <DialogContent>
                  <ReactMarkdownType plugins={[gfm]}>
                    {selectedNewsItem['contentBg']}
                  </ReactMarkdownType>
                </DialogContent>
                <DialogLinksStyled disableSpacing>
                  {selectedNewsItem.hyperlinks.map((link: any) => (
                    <Button
                      key={link.link}
                      color="secondary"
                      endIcon={<ArrowForward />}
                      href={link.link}
                      target="_blank"
                    >
                      {link['titleBg']}
                    </Button>
                  ))}
                </DialogLinksStyled>
              </>
            )}
          </Dialog>
        </>
      )}
    </>
  );
}

Instructions.defaultProps = {};

export default Instructions;
