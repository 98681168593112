import { Link, useNavigate, useParams } from 'react-router-dom';

import { ArrowBackIos } from '@mui/icons-material';
import { Grid, CardContent, Typography, Box, Card } from '@mui/material';

import Breadcrumbs from 'components/breadcrumbs';
import { FallbackNotFound, FallbackSection } from 'components/fallback';
import LoadingSkeleton from 'components/loading-skeleton';
import { MultipleSelectLibrary } from 'components/select';
import { IconSubjectDefault } from 'components/icons/icon-subjects';

import { ProfStudyAid } from 'api/types';
import { getStudyAidBreadCrumbs } from './utils';
import { useGetProfAreas, useGetProfStudyAids, useGetProfAreaByCode as useGetProfAreaByCode } from 'services/queryHooks/study-aids-hooks';
import { palette } from 'theme/components';

export const StudyAids = () => {
  const navigate = useNavigate();
  const { areaCode }: any = useParams();
  const { data: studyAids, isLoading: isStudyAidsLoading, error: studyAidsError } = useGetProfStudyAids(areaCode);
  const { data: profArea, isLoading: isProfAreaLoading, error: profAreaError } = useGetProfAreaByCode(areaCode);
  const { data: profAreas, isLoading: isProfAreasLoading } = useGetProfAreas();

  const selectData = profAreas?.filter(x => x.code !== areaCode).map((x: any) => ({ id: x.code, label: x.name }));
  const subjectCustomIcon = IconSubjectDefault({
    fontSize: 'large',
    sx: { color: palette.primary.dark }
  });

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={getStudyAidBreadCrumbs()} />
      </Box>
      {(isStudyAidsLoading || isProfAreaLoading || isProfAreasLoading) && <LoadingSkeleton item itemsCount={6} height={83} />}
      {!isStudyAidsLoading && !studyAidsError && !isProfAreaLoading && !isProfAreasLoading && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} display="flex" alignItems="center" mb={3}>
              <Link to={`/study-aids`}>
                <ArrowBackIos fontSize="large" color="secondary" />
              </Link>
              <Typography
                variant="h1"
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                {subjectCustomIcon}
                {profArea?.name}
              </Typography>
              {selectData && selectData.length > 0 && (
                <Box ml={3}>
                  <MultipleSelectLibrary
                    data={selectData}
                    onChange={(selectedProfArea: any) =>
                      navigate(`/study-aids/${selectedProfArea?.id}`)}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          {studyAids && (
            <Grid container spacing={5} pt={4}>
              {studyAids.map((aid: ProfStudyAid) => (
                <Grid item xs={12} key={aid.id}>
                  <a href={aid.link} target="_blank" rel="noopener noreferrer" key={`study-aid/${aid.id}`}>
                    <Card
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1,
                      }}
                    >
                      <CardContent>
                        <Box display="flex" alignItems="center" gap={1.5}>
                          {subjectCustomIcon}
                          <Box display="flex" flexDirection="column">
                            <Typography variant="h4">{aid.name}</Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
      {!studyAidsError && !Boolean(studyAids?.length) && !isProfAreaLoading && !isProfAreasLoading && !isStudyAidsLoading && (
        <FallbackSection message={`Няма налични учебни помагала за това направление.`} />
      )}
      {!isProfAreaLoading && !isProfAreasLoading && !isStudyAidsLoading && (studyAidsError || profAreaError) && <FallbackNotFound />}
    </>
  );
}

export default StudyAids;