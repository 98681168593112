import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { AuthManager, LOGOUT_EVENT_TYPE } from 'authentication/auth-manager';
import {
  USER_SESSION_LIFETIME_MINUTES,
  BACKPACK_SHOULD_END_SESSION_KEY,
  ASSESSMENT_SHOULD_END_SESSION_KEY
} from 'windows';

type Props = {
  children: React.ReactNode;
};

let sessionTimeout: NodeJS.Timeout;

export const UserSessionTracker = ({ children }: Props) => {
  const snackbarContext = useSnackbarContext();
  const navigate = useNavigate();

  const setShouldEndSession = (shouldEndSession: boolean) =>
    localStorage.setItem(BACKPACK_SHOULD_END_SESSION_KEY, shouldEndSession.toString());
  
  const resetSessionTimer = (sessionExpiration?: number) => {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }

    const shouldEndSession = !document.querySelector('iframe');
    setShouldEndSession(shouldEndSession);

    sessionExpiration = AuthManager.setUserSessionExpiraton(sessionExpiration);
    sessionTimeout = setTimeout(() => {
      let sessionExpiration = AuthManager.getUserSessionExpiration();
      if (localStorage.getItem(BACKPACK_SHOULD_END_SESSION_KEY) === 'false' ||
        localStorage.getItem(ASSESSMENT_SHOULD_END_SESSION_KEY) === 'false') {
        // we have to continuously extend the session expiration
        // so that the user will not be logged out while he is using the iframe
        sessionExpiration = AuthManager.setUserSessionExpiraton(Date.now() + USER_SESSION_LIFETIME_MINUTES * 60 * 1000);
        resetSessionTimer(sessionExpiration);
      } else if (Date.now() >= sessionExpiration) {
        AuthManager.logout()
          .finally(() => {
            snackbarContext?.addSnackbar({
              key: crypto.randomUUID(),
              title: "Сесията Ви изтече поради неактивност. Ако искате да продължите, впишете се отново.",
              severity: 'warning'
            });
          });
      } else {
        resetSessionTimer(sessionExpiration);
      }
    }, sessionExpiration - Date.now());
  };

  useEffect(() => {
    resetSessionTimer();
    document.onscroll = () => resetSessionTimer();
    document.onclick = () => resetSessionTimer();
    document.onkeydown = () => resetSessionTimer();
    window.addEventListener(LOGOUT_EVENT_TYPE, () => navigate('/'));
    window.addEventListener('beforeunload', () => {
      setShouldEndSession(true);
    });

    return () => {
      clearTimeout(sessionTimeout);
      setShouldEndSession(true);
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
};
