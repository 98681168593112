import { lazy, Suspense } from 'react';
import { useSharedViewContext } from 'hooks/useSharedViewContext';

const Homeworks = () => {
  const viewName = useSharedViewContext();

  const Component = lazy(() => import(`../../views/homeworks/homeworks-list/${viewName}`));

  return (
    <Suspense>
      <Component />
    </Suspense>
  );
};

export default Homeworks;
