import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Box, ClickAwayListener, Fade, Paper, Stack, Tooltip, Typography
} from '@mui/material';
import ClassPicker from './class-picker';
import { LoadingButton } from '@mui/lab';
import { DeleteForever, MoreVert } from '@mui/icons-material';
import { SubmitMeeting } from 'containers';
import JoinButton, { MeetingButtonItem } from './join-button';
import { ConfirmationDialog } from 'components/confirmation-dialog';
import { MeetingButtons } from 'views/configuration/constants';

import { useDeleteMeeting } from 'services/queryHooks/meetings-hooks';
import { Meeting, ShiftClass } from 'api/types';
import { useUserContext } from 'hooks/useUserContext';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTableContext } from './table-context-provider';

import { getSubjectConfig } from 'config/subject-config-data';
import { Notifications } from '../../constants';

import { CellStyled, PopperStyled } from './styles';

type Props = {
  subjectId: number,
  subjectName: string,
  isCurrentDay?: boolean,
  classes: ShiftClass[],
  date?: string,
  meetings?: Meeting[],
};

export const getTeachersNamesByMeetingsInClass = (meetings: Meeting[], classes: ShiftClass[]) => (
  meetings?.map((meeting) => {
    return ({
      id: meeting?.id,
      link: meeting?.meetingLink,
      name: `Преподавател: ${meeting.creatorName || ''}`,
    });
  })
);

const ScheduleCell = ({ subjectId, subjectName, isCurrentDay = false, classes = [], date, meetings }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRoles = useUserContext()?.userRole;
  const tableContext = useTableContext();
  const snackbarContext = useSnackbarContext();

  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLElement | null>(null);
  const [meetingToDelete, setMeetingToDelete] = useState<Meeting | null>(null);

  const subjectConfig = getSubjectConfig(subjectId);
  const meetingBtn: any = tableContext.meetingToolConfig ? MeetingButtons[tableContext.meetingToolConfig] : undefined;
  const hasMeeting = meetings?.length && meetings.length > 0 && tableContext.meetingToolConfig
    && tableContext.meetingToolConfig === meetings[0].externalSystem;

  const tileClass = classes?.[0];
  const subjectNameToDisplay = tileClass?.isProfileSubject ? subjectName : (subjectConfig.name || subjectName);

  const addNotification = (type: 'success' | 'error') => {
    snackbarContext?.addSnackbar({
      key: crypto.randomUUID(),
      title: Notifications.delete[type],
      severity: type
    });
  };

  const {
    mutate: mutateDeleteMeeting,
    isLoading: isLoadingDeleteMeeting
  } = useDeleteMeeting(date, {
    onSettled: (_: any, error: Error) => {
      if (error) {
        addNotification('error');
      } else {
        addNotification('success');
      }
    }
  });

  const handleContextMenuClick = (event?: any) => {
    setContextMenuAnchor(contextMenuAnchor ? null : event?.currentTarget);
    setContextMenuOpen(!contextMenuOpen);
  };

  const renderContextMenu = () => {
    const createMenu = (
      <SubmitMeeting
        subjectId={subjectId}
        subjectName={subjectNameToDisplay}
        classes={classes}
        date={date ? new Date(date) : new Date()}
        onSuccess={handleContextMenuClick}
        onDecline={handleContextMenuClick}
      />
    );

    const deleteMenu = (
      <>
        <LoadingButton
          size="medium"
          color="info"
          disableElevation
          variant="outlined"
          endIcon={<DeleteForever />}
          onClick={(event) => {
            if (hasMeeting) {
              const meeting = meetings[0];
              setMeetingToDelete(meeting);
            }
          }}
          disabled={isLoadingDeleteMeeting}
          loading={isLoadingDeleteMeeting}
        >
          Изтриване
        </LoadingButton>
        <ConfirmationDialog
          open={Boolean(meetingToDelete)}
          message='Сигурни ли сте, че искате да изтриете тази виртуална класна стая?'
          onAccept={(event: any) => { 
            mutateDeleteMeeting(meetingToDelete?.id!); 
            setMeetingToDelete(null); 
            handleContextMenuClick(event); 
          }}
          onDecline={() => { setMeetingToDelete(null); }}
        />
      </>
    );

    return userRoles?.isTeacher && (
      <>
        <MoreVert
          onClick={(event: any) => {
            event.stopPropagation();
            handleContextMenuClick(event);
          }}
        />
        <PopperStyled
          open={contextMenuOpen}
          anchorEl={contextMenuAnchor}
          placement="bottom"
          transition
          onClick={(e: any) => e.stopPropagation()}
        >
          {({ TransitionProps }: any) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener
                  onClickAway={(e) => {
                    tableContext.setDisableNavigate(true);
                    handleContextMenuClick(e);
                  }}
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                >
                  <Paper>
                    <Stack p={6} spacing={2} alignItems="center">
                      <Typography>Виртуална класна стая:</Typography>
                      {hasMeeting ? deleteMenu : createMenu}
                    </Stack>
                  </Paper>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </PopperStyled>
      </>
    );
  };

  const renderJoinButton = () => {
    // Hide the button for subjects like РП/УП-А when the cell is disabled (checkout LMS-2742)
    if (userRoles?.isParent || !hasMeeting || !tileClass?.hasSubjectCurriculum)
      return null;

    // Case when multiple meetings with different teachers
    let items: MeetingButtonItem[] = [];
    if (meetings.length > 1 && userRoles?.isStudent) {
      items = getTeachersNamesByMeetingsInClass(meetings, classes);
    } else {
      items = meetings?.map((meeting) => ({
        id: meeting?.id,
        link: meeting?.meetingLink
      }));
    }

    return (
      <JoinButton
        items={items}
        color={meetingBtn.id}
        endIcon={meetingBtn.logo}
        sx={{ alignSelf: 'flex-end' }}
      />
    );
  };

  const renderCell = () => (
    <CellStyled
      py={1.5}
      px={1}
      currentday={isCurrentDay.toString()}
      disabled={!Boolean(subjectId) || !tileClass?.hasSubjectCurriculum}
      onClick={() => {
        if (tableContext.disableNavigate) {
          tableContext.setDisableNavigate(false);
        } else if (Boolean(subjectId) && tileClass.hasSubjectCurriculum) {
          const curriculumFilter = tileClass.isProfileSubject ?
            tileClass.profCurriculumId :
            tileClass.curriculumId;
          /*
            We would like to use the original curriculumId for requesting
            student's grades, remarks and absences when isProfileSubject=true
          */
          const state = userRoles?.isStudent && tileClass.isProfileSubject ? 
            { customCurriculumId: tileClass.curriculumId } :
            {};
          return (
            navigate(`/subject/${subjectId}/${tileClass.subjectTypeId}/${curriculumFilter}?scheduleId=${tileClass.neispuoScheduleId}&scheduleDate=${date}&classId=${tileClass?.id}`,
              {
                state: { returnPath: `${location.pathname}${location.search}`, ...state }
              }
            )
          );
        }
      }}
    >
      {Boolean(subjectId) && (
        <>
          <Typography
            display="flex"
            justifyContent="space-between"
            variant="body1"
            fontWeight="600"
            component="div"
            width="100%"
          >
            <Stack direction="row">
              {subjectConfig.icon({ fontSize: 'small' })}
              <Box mb={1}>
                <Box>{subjectNameToDisplay}</Box>

                <Box>
                  {userRoles?.isTeacher && (
                    <ClassPicker
                      classes={classes}
                      subjectId={subjectId}
                      date={date}
                      noTooltip={!tileClass?.hasSubjectCurriculum} 
                    />
                  )}
                </Box>
              </Box>
            </Stack>
            {renderContextMenu()}
          </Typography>
          {renderJoinButton()}
        </>
      )}
    </CellStyled>
  );

  if (!Boolean(subjectId))
    return renderCell();

  return tileClass?.hasSubjectCurriculum ? renderCell() : (
    <Tooltip
      placement={"right"}
      title={<Typography>Предметът от този час не е част от ООП и не е в обхвата на Дигиталната раница. Показва се само с цел цялостност на седмичното разписание.</Typography>}
      followCursor
    >
      {renderCell()}
    </Tooltip>
  );
};

export default ScheduleCell;
