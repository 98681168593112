import { useEffect } from 'react';
import i18n from 'i18n';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useLoaderContext } from 'hooks/useLoaderContext';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { AuthManager } from 'authentication/auth-manager';
import { CustomAxiosRequestConfig } from 'api/api';

type RequestConfig = {
  isBackgroundRequest?: boolean;
};

const { REACT_APP_ENABLE_ERROR_NOTIFICATIONS } = window._env_ || process.env;

// component which sets global loader state, shows default error notifications when requests fail
// and logouts the user when his token is not valid
const AxiosInterceptor = ({ children }: any) => {
  const loaderContext = useLoaderContext();
  const snackbarContext = useSnackbarContext();

  const handleValidationErrors = (response: any) => {
    Object.keys(response.data.errors).forEach((key: string) => {
      const validationError = response.data.errors[key].find((_: any) => true);
      snackbarContext?.addSnackbar({
        key: crypto.randomUUID(),
        title: validationError,
        severity: 'error'
      });
    });
  };

  const handleDefaultError = (response: any, request: CustomAxiosRequestConfig<any>) => {
    const { errorHandling } = request;

    if (!Boolean(errorHandling?.disabled)) {
      let message = '';
      if (errorHandling?.message && response?.data?.ErrorCode === 500) {
        message = errorHandling.message;
      } else {
        message = Boolean(response?.data?.Message && response?.data?.ErrorCode !== 500)
          ? i18n.t(response.data.Message, response.data.Parameters)
          : i18n.t('errors.fallback');
      }

      snackbarContext?.addSnackbar({
        key: crypto.randomUUID(),
        title: message,
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    const onRequest = (config: AxiosRequestConfig<RequestConfig>) => {
      if (!config.data?.isBackgroundRequest) {
        loaderContext?.setLoading(true);
      }

      return config;
    };
    axios.interceptors.request.use(onRequest);

    const onSuccess = (res: AxiosResponse) => {
      loaderContext?.setLoading(false);
      return res;
    };
    const onError = async (error: any) => {
      loaderContext?.setLoading(false);
      const originalRequest = error.config as CustomAxiosRequestConfig<any>;
      const response = error.response;

      if (response?.status === 401) {
        AuthManager.logout()
          .finally(() => {
            const isTokenRequest = originalRequest.url?.includes('/token');
            if (!isTokenRequest) {
              snackbarContext?.addSnackbar({
                key: crypto.randomUUID(),
                title: 'Вашата сесия изтече. Моля, впишете се отново.',
                severity: 'error'
              });
            }
          });
      } else if (response?.status === 400 && response?.data?.errors &&
        process.env.NODE_ENV !== 'production' && REACT_APP_ENABLE_ERROR_NOTIFICATIONS === 'true') {
        handleValidationErrors(response);
      } else {
        if (REACT_APP_ENABLE_ERROR_NOTIFICATIONS === 'true') {
          handleDefaultError(response, originalRequest);
        }
      }
      throw error;
    };
    axios.interceptors.response.use(onSuccess, onError);
  }, []);

  return children;
}

export default AxiosInterceptor;
