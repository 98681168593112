import { spacing } from '@mui/system';
import { transparentize } from 'polished';
import { BORDER_RADIUS } from '../constants';
import palette from './palette';
import shadows from './shadows';

export const MuiTable = {
  styleOverrides: {
    root: {
      boxShadow: shadows[4],
      thead: {
        '& th:first-of-type': {
          borderRadius: `${BORDER_RADIUS}px 0 0 0`
        },
        '& th:last-child': {
          borderRadius: `0 ${BORDER_RADIUS}px 0 0`
        }
      }
    }
  }
};

export const MuiTableCell = {
  styleOverrides: {
    root: {
      border: 'none',
      '&:last-child': { borderRight: 'none' },
      borderRight: `1px dashed ${palette.grey[300]}`,
      fontWeight: 500,
      ...spacing({ px: 2, py: 1.8 })
    },
    head: {
      color: palette.common.white,
      backgroundColor: palette.primary[700],
      ...spacing({ px: 2, py: 1 }),
      borderRight: `1px  dashed ${palette.common.white}`
    }
  }
};

export const MuiTableHead = {
  styleOverrides: {
    root: {}
  }
};

export const MuiTableRow = {
  styleOverrides: {
    root: ({ ownerState }: any) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: palette.common.white
      },
      '&:nth-of-type(even)': {
        backgroundColor: palette.grey[10]
      },
      // because styles for class "hover" do not work
      '&:hover': ownerState.hover && {
        backgroundColor: `${palette.primary[50]} !important`
      }
    })
  }
};

export const MuiTableFooter = {
  styleOverrides: {
    root: {
      backgroundColor: palette.common.white,
      borderTop: `1px solid ${transparentize(0.9, palette.common.black)}`,
      '& .MuiTableCell-root': {
        borderRight: 0,
        padding: spacing({ py: 1 })
      }
    }
  }
};
