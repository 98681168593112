import React, { useState } from 'react';
import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { NumericValueWithGradeDataType } from './types';
import Tooltip from 'components/tooltip';
import NumericValue from './numeric-value';
import { dateFormat } from 'utils/dateFormat';

type Props = {
  list: NumericValueWithGradeDataType[];
  listTitle?: string;
  listSubTitle?: string;
  hasActiveTooltips?: boolean;
  NumericValueComponent?: object;
  [key: string]: any;
};

function NumericValueList({
  list,
  listTitle,
  listSubTitle,
  hasActiveTooltips,
  NumericValueComponent,
  ...propsToPropagate
}: Props) {
  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const [idInfoGrade, setIdInfoGrade] = useState<any>(null);
  const renderNumericValue = (listGrades: NumericValueWithGradeDataType) => {
    const { id, numeric, variant, children, gradeType } = listGrades;
    return (
      <NumericValue
        key={id}
        numeric={numeric}
        variant={variant}
        children={children}
        onClick={() => gradeType && setIdInfoGrade(listGrades)}
      />
    );
  };

  const renderTooltip = (listGrades: NumericValueWithGradeDataType) => {
    const {
      id,
      gradeType,
      date,
      teacherName,
      comment,
      numeric,
      variant,
      children,
    } = listGrades;
    return (
      <Tooltip
        key={id}
        title={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Typography component="span">
              <strong>Тип:</strong>
              {gradeType}
            </Typography>
            <>&nbsp;</>
            {date && (
              <Typography fontWeight={800}>{dateFormat(date)}</Typography>
            )}
          </Box>
        }
        content={
          <>
            <Typography variant="subtitle1">
              <strong>Преподавател:</strong>
              {teacherName ? teacherName : ' Липсват данни от НЕИСПУО'}
            </Typography>
            {comment && (
              <Typography variant="subtitle1">
                <strong>Коментар:</strong>
                {comment}
              </Typography>
            )}
          </>
        }
        arrow
      >
        <NumericValue numeric={numeric} variant={variant} children={children} />
      </Tooltip>
    );
  };

  const renderDialog = ({
    gradeType,
    date,
    teacherName,
    comment,
  }: NumericValueWithGradeDataType) => (
    <Dialog
      key={idInfoGrade}
      open={idInfoGrade}
      onClose={() => setIdInfoGrade(null)}
      sx={{
        '& .MuiDialog-paper': {
          p: 2,
          pt: 1,
        },
      }}
    >
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={3}
      >
        <Typography variant="body1" fontWeight="900">
          Информация за оценка
        </Typography>
        <IconButton onClick={() => setIdInfoGrade(null)}>
          <Close fontSize="small" />
        </IconButton>
      </Grid>
      <Grid container>
        <Grid
          container
          item
          gap={1}
          xs={12}
          display="flex"
          justifyContent="space-between"
          rowSpacing={2}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="subtitle1" fontWeight="900">
              Тип:
            </Typography>
            <Typography> {gradeType}</Typography>
          </Box>

          {date && (
            <Typography fontWeight={800} p={0} variant="subtitle1">
              {dateFormat(date)}
            </Typography>
          )}
        </Grid>

        <Grid container item gap={1} xs={12} display="flex">
          <Typography variant="subtitle1" fontWeight="900">
            Преподавател:
          </Typography>
          <Typography> {teacherName ? teacherName : ' Липсват данни от НЕИСПУО'}</Typography>
        </Grid>

        {comment && (
          <Grid container item gap={1} xs={12} display="flex">
            <Typography variant="subtitle1" fontWeight="900">
              Коментар:
            </Typography>
            <Typography variant="subtitle1"> {comment}</Typography>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );

  return (
    <>
      <Box
        display="flex"
        alignItems={list.length > 1 ? 'left' : 'center'}
        flexDirection="column"
        {...propsToPropagate}
      >
        {listTitle && (
          <Typography
            variant="body2"
            sx={{ wordBreak: 'inherit' }}
            textAlign={tabletAndUp ? 'left' : 'center'}
          >
            {listTitle}
          </Typography>
        )}
        {listSubTitle && (
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '14px', wordBreak: 'inherit' }}
            textAlign={tabletAndUp ? 'left' : 'center'}
          >
            {listSubTitle}
          </Typography>
        )}
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap={0.5}
          py={1}
          {...NumericValueComponent}
        >
          {hasActiveTooltips &&
            list.map((listGrades: NumericValueWithGradeDataType) => {
              if (listGrades.numeric === '-') {
                return <>{renderNumericValue(listGrades)}</>;
              }

              return (
                <>
                  {tabletAndUp && renderTooltip(listGrades)}
                  {!tabletAndUp && renderNumericValue(listGrades)}
                </>
              );
            })}
          {!hasActiveTooltips &&
            list.map((listGrades: NumericValueWithGradeDataType) => (
              <>{renderNumericValue(listGrades)}</>
            ))}
        </Box>
      </Box>
      {hasActiveTooltips && idInfoGrade && renderDialog(idInfoGrade)}
    </>
  );
}

NumericValueList.defaultProps = {};

export default NumericValueList;
