import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentLng } from 'i18n';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import {
  LogoStyled,
  LogoTitleStyled,
  PartnersHeaderStyled,
  PartnersLogoStyled,
} from './styles';

const logos: any = {
  eusef: {
    key: 'eusef',
    onClickUrl: '/',
    file: 'eusef-logo.png',
    fileen: 'eusef-logo-en.png',
  },
  oud: {
    key: 'oud',
    onClickUrl: 'https://oud.mon.bg/',
    file: 'oud-logo.png',
  },
  opnoir: {
    key: 'opnoir',
    onClickUrl: '/',
    file: 'opnoir-logo.png',
    fileen: 'opnoir-logo-en.png',
  },
};

const Logos = ({ ...props }) => {
  const { t } = useTranslation();
  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const renderLogo = (logo: any) => {
    const fileName = logo[`file${getCurrentLng()}`] || logo.file;

    return (
      <React.Fragment key={`img-${logo.key}`}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          onClick={() => window.open(logo.onClickUrl, '_blank')}
        >
          <PartnersLogoStyled
            src={`${process.env.PUBLIC_URL}/images/${fileName}`}
            alt='logo'
            sx={{
              ...(logo.key === 'oud' ? { maxWidth: '100px' } : {})
            }}
          />
        </Box>
      </React.Fragment>
    );
  };

  const mainLogo = (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        onClick={() => window.open('https://mon.bg/', '_blank')}
      >
        <LogoStyled
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt='logo'
        />
        <LogoTitleStyled>
          <h1>{t('landingPage.header.title.line1')}</h1>
          <h2>{t('landingPage.header.title.line2')}</h2>
        </LogoTitleStyled>
      </Box>
    </>
  );

  return (
    <PartnersHeaderStyled variant={6} {...props}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexDirection={tabletAndUp ? 'row' : 'column'}
      >
        {mainLogo}
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          {Object.keys(logos).map((key) => renderLogo(logos[key]))}
        </Box>
      </Box>
    </PartnersHeaderStyled>
  );
};

Logos.defaultProps = {};

export default Logos;
