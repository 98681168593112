import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { ExpandMore as IconExpandMore } from '@mui/icons-material';
import RCS from 'components/responsive-control-system';
import { StudentAvatar } from 'components/icons/svg';

type Props = {
  name?: string;
  job?: string;
  onLogout?: Function;
  onClose?: Function;
};

const handleClose = (onClose?: Function) => {
  if (onClose) {
    onClose();
  }
};

const handleLogout = (onLogout?: Function) => {
  if (onLogout) {
    onLogout();
  }
};

const HeaderUserMenu = (props: Props) => {
  const { name, job, onLogout, onClose } = props;
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<any>(null);

  return (
    <Box
      onClick={(event) => {
        if (event && event.currentTarget) {
          setAnchorElement(event.currentTarget);
          setOpen((prevOpen) => !prevOpen);
        }
      }}
      display="flex"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
    >
      <Avatar>
        <StudentAvatar sx={{ fontSize: 25 }} color="secondary" />

        <Menu
          anchorEl={anchorElement}
          open={open}
          onClick={() => handleClose(onClose)}
        >
          <MenuItem onClick={() => handleLogout(onLogout)}>Излез</MenuItem>
        </Menu>
      </Avatar>
      <Box pl={1}>
        <RCS hidden="tablet">
          <Box display="flex" alignItems="center">
            {name && (
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>
            )}
            <IconExpandMore fontSize="small" color="secondary" />
          </Box>
          {job && <Typography variant="caption">{job}</Typography>}
        </RCS>
      </Box>
    </Box>
  );
};

export default HeaderUserMenu;
