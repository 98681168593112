import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from 'components/header/MainHeader';
import HeaderActions from 'components/header/HeaderActions';
import Background from 'components/background/background';
import BlockingNotifications from 'components/blocking-notifications';
import { Box, Container } from '@mui/material';
import Footer from 'components/footer/footer';
import { UserSessionTracker } from 'authentication/user-session-tracker';
import { useGetAllCrudItems } from 'services/queryHooks/crud-hooks';
import { getLinkObjectKeysByRole } from 'utils/helpers';
import { navLinks } from 'system-settings';
import { useUserRoleContext } from 'hooks/useUserRoleContext';
import { UserContextProvider } from 'contexts/UserContext';
import { NomenclaturesContextProvider } from 'contexts/NomenclaturesContext';
import { TypeCrud } from 'routes/administration/crud/config';

type Props = {
  useAsStaticWrapper?: boolean;
  children?: any;
}

function PrivateLayout({ useAsStaticWrapper = false, children }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // getting the closest path to the base url
  const pathnameStripped = pathname.substring(1).split('/')[0];
  const active = pathnameStripped || 'weekly-schedule';
  const role = useUserRoleContext();
  const { data: crudNotifications } = useGetAllCrudItems({
    typeCrud: TypeCrud.notifications,
    enabled: true,
    searchTitle: undefined,
    disabledErrorHandling: true,
  });
  const navigationItems = getLinkObjectKeysByRole(navLinks(), role);

  return (
    <UserContextProvider>
      {Boolean(crudNotifications?.items?.length) && (
        <BlockingNotifications notifications={crudNotifications?.items} />
      )}
      <UserSessionTracker>
        <NomenclaturesContextProvider>
          <Header
            activeNavigation={active}
            onNavigate={(ev, href) => {
              navigate(href);
            }}
            topBarActions={<HeaderActions />}
            variant={6}
            navigationItems={navigationItems}
          />

          <Background variant={3}>
            <Container maxWidth="lg">
              <Box pt={[2, 4]} pb={[16, 20, 30]}>
                {!useAsStaticWrapper && <Outlet />}
                {useAsStaticWrapper && children}
              </Box>
            </Container>
          </Background>

          <Footer />
        </NomenclaturesContextProvider>
      </UserSessionTracker>
    </UserContextProvider>
  );
}

PrivateLayout.defaultProps = {};

export default PrivateLayout;
