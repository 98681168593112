import { Route, Routes } from 'react-router-dom';
import { LibraryClass, GradesPage } from 'views/library';

type Props = {
};

function Library({ }: Props) {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<GradesPage />} />
        <Route path="/:classId" element={<LibraryClass />} />
      </Route>
    </Routes>
  );
}

Library.defaultProps = {
};

export default Library;
