import React, { useEffect, useState } from 'react';

import {
  Typography,
  Grid,
  Box,
  ButtonGroup,
  Button,
  Alert,
} from '@mui/material';

import { palette } from 'theme/components';
import { Roles } from 'enums/userRoles';
import LoadingSkeleton from 'components/loading-skeleton';
import Breadcrumbs from 'components/breadcrumbs';
import { ContextSearch } from 'components/search';
import { FallbackSection } from 'components/fallback';
import Card from './components/card';

import useTextFieldSearch from 'hooks/useTextFieldSearch';
import { apiGetStudentGradesBySubject } from './middleware';
import { useUserContext } from 'hooks/useUserContext';
import { TermTypes } from 'enums/termTypes.enum';
import { gradesBreadcrumbs } from './breadcrumbs';

const Grades = () => {
  const user = useUserContext();
  const { list, setList, handleSearch, inputValue } =
    useTextFieldSearch('subjectName');
  const [schoolTerm, setSchoolTerm] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const orRolesStudentAndParent =
    user?.user?.roles[0] === Roles.Parent ||
    user?.user?.roles[0] === Roles.Student;

  useEffect(() => {
    setIsLoading(true);
    apiGetStudentGradesBySubject()
      .then((r) => {
        setList(r);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onTermChange = (event: any) => {
    setSchoolTerm(parseInt(event.target.value));
  };

  const selectedTermKey = schoolTerm === TermTypes.FirstTerm ? 'firstSem' : 'secondSem';
  const selectedTermHasGrades = Boolean(list.filter((x: any) => x[`${selectedTermKey}Grades`]?.length).length) ||
    Boolean(list.filter((x: any) => x[`${selectedTermKey}FinalGrade`]).length);
  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={gradesBreadcrumbs()} />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">Моите Оценки</Typography>
        </Grid>

        <Grid item container xs={12} spacing={2} my={2}>
          <Grid item xs={10} sm={7} md={9}>
            <ButtonGroup
              disableElevation
              onClick={(event) => {
                onTermChange(event);
                handleSearch('');
              }}
            >
              <Button
                value={TermTypes.FirstTerm}
                variant={
                  schoolTerm === TermTypes.FirstTerm ? 'contained' : 'outlined'
                }
              >
                Първи срок
              </Button>
              <Button
                value={TermTypes.SecondTerm}
                variant={
                  schoolTerm === TermTypes.SecondTerm ? 'contained' : 'outlined'
                }
              >
                Втори срок
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid
            item
            xs={2}
            sm={5}
            md={3}
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <ContextSearch
              value={inputValue}
              onChange={(value: string) => handleSearch(value)}
            />
          </Grid>
        </Grid>

        {isLoading && (
          <LoadingSkeleton
            item
            itemsCount={8}
            height={180}
            gridXs={12}
            gridMd={6}
          />
        )}
        {orRolesStudentAndParent && (
          <Alert severity="warning">
            <Typography variant="subtitle2">
              Попълват се автоматично от електронния дневник на училището.
            </Typography>
          </Alert>
        )}
        {!isLoading && list && (
          <Grid item container spacing={3} xs={12} pt={2}>
            {list?.map((subjectItem: any, index) => {
              const hasGrades = Boolean(subjectItem[`${selectedTermKey}Grades`]?.length) || 
                Boolean(subjectItem[`${selectedTermKey}FinalGrade`]);
              if (hasGrades) {
                return (
                  <Grid key={index} item xs={12} sm={6} md={6}>
                    <Card
                      subjectId={subjectItem.subjectId}
                      subjectName={subjectItem.subjectName}
                      teacherName={subjectItem.teacherName}
                      semesterName={
                        schoolTerm === TermTypes.FirstTerm
                          ? 'Първи Срок'
                          : 'Втори Срок'
                      }
                      currentSemGrades={subjectItem[`${selectedTermKey}Grades`]}
                      semesterFinalGrade={subjectItem[`${selectedTermKey}FinalGrade`]}
                      annualFinalGrade={subjectItem.annualGrade}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        )}
        <Grid item xs={12}>
          {!isLoading && (!list?.length || !selectedTermHasGrades) && !inputValue && (
            <FallbackSection message="Към момента няма налични оценки или търсените предмети не са намерени." />
          )}
          {!isLoading && !list?.length && inputValue && (
            <FallbackSection
              message={
                <>
                  Няма открити резултати за
                  <Typography
                    component="span"
                    sx={{ color: palette.primary[700] }}
                  >
                    "{inputValue}"
                  </Typography>
                </>
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Grades;
