import { Roles } from "enums/userRoles";

export interface IUserModel {
  username: string;
  name: string;
  id: string;
  job: string;
  roles: Roles[];
  azureId: string;
  subjects: string[];
  school: string;
  schoolId: number;
  students: B2CStudentsType[],
  class: string[];
  schoolYear: string;
  institutionId: string;
  institutionName: string;
  institutionAddress: string;
  azureBearerToken: string;
  accessToken: string;
  loginType: LoginTypeEnum;
  hasSpecialities: string;
}

export interface Institution {
  id: number;
  name: string;
}

export enum LoginTypeEnum {
  aad = 'aad',
  b2c = 'b2c'
}

export type LoginConfigType = {
  clientId: string;
  redirectUri: string;
  authority: string;
  scopes: string[];
  postLogoutRedirectUri?: string;
  navigateToLoginRequestUrl?: boolean;
  knownAuthorities?: string[];
};

export type LoginType = {
  [LoginTypeEnum.aad]: LoginConfigType; // standard login for roles in MON
  [LoginTypeEnum.b2c]: LoginConfigType; // parents login
}

export type B2CInstitutionsType = {
  InstitutionId: number;
  InstitutionName: string;
  Selected: boolean;
}

export type B2CStudentsType = {
  Id: string;
  Institutions: B2CInstitutionsType[];
  Name: string;
  Selected: boolean;
}
