import { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box, Button, ButtonBase, Divider, Grid, Paper, Skeleton, TextField, Tooltip, Typography
} from '@mui/material';
import { ArrowBackIos, Info } from '@mui/icons-material';
import { NumericValue } from 'components/numeric-value';
import { RichTextRenderer } from 'components/rich-text-renderer';
import Breadcrumbs from 'components/breadcrumbs';
import { FallbackSection } from 'components/fallback';
import { VariantsEnum } from 'components/fallback/types';
import { HomeworkContents } from 'views/homeworks/components/homework-attachments';
import { HomeworkBasicInfo } from 'views/homeworks/components/homework-basic-info';
import { AttachFiles } from 'containers';

import { useAttachments } from 'services/queryHooks/attachments-hooks';
import { useHomeworkSolution, useAssesSolution } from 'services/queryHooks/homeworks-hooks';
import { HomeworkStatuses } from 'enums/homeworkStatuses.enum';
import { AttachmentsType, Content, DetailedStudentSolution } from 'api/types';
import { breadcrumbs, markOptions, validationSchema } from './constants';
import { getSolutionStatusConfig } from 'utils/homeworks';
import { buildQueryParams } from '../homework/teacher/utils';
import { palette } from 'theme/components';

const HomeworkAssessment = () => {
  const { assignmentId, solutionId }: any = useParams();
  const location = useLocation();

  const [solution, setSolution] = useState<DetailedStudentSolution | undefined>();

  const { isLoading: isSolutionLoading } = useHomeworkSolution(solutionId, {
    enabled: Boolean(solutionId),
    onSuccess: (data: DetailedStudentSolution) => setSolution(data)
  });

  const { data: attachments, isLoading: areAttachmentsLoading } = useAttachments(solutionId, AttachmentsType.Solution, {
    enabled: Boolean(solutionId)
  });

  const { mutate: assessSolution, isLoading: isAssessmentSubmitting } = useAssesSolution();

  const {
    handleChange, handleSubmit, setFieldValue, errors, values, touched
  } = useFormik({
    initialValues: {
      solutionId,
      comment: '',
      mark: undefined
    },
    validationSchema,
    onSubmit: ({ solutionId, mark, comment }) => assessSolution({ solutionId, mark, comment })
  });


  const solutionSubmitted = solution?.status === HomeworkStatuses.Submitted;
  const solutionAssessed = solution?.status === HomeworkStatuses.Evaluated;

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={breadcrumbs(assignmentId, solution?.assignmentTitle, buildQueryParams(location.state))} />
      </Box>
      {isSolutionLoading && (
        <>
          <Skeleton variant='rectangular' height={20} sx={{ margin: 3 }} />
          <Skeleton variant='rectangular' height={50} sx={{ margin: 3 }} />
          <Skeleton variant='rectangular' height={80} sx={{ margin: 3 }} />
          <Skeleton variant='rectangular' height={500} sx={{ margin: 3 }} />
          <Skeleton variant='rectangular' height={300} />
        </>
      )}
      {!isSolutionLoading && solution && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} display='flex' alignItems='center'>
            <Link to={`/homeworks/${assignmentId}?${buildQueryParams(location.state)}`}>
              <ArrowBackIos fontSize='large' color='secondary' />
            </Link>
            <Typography variant='h1'>
              {solution.studentName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <HomeworkBasicInfo
              deadline={solution.assignmentDeadline}
              submittedOn={solution.submittedOn}
              statusConfig={getSolutionStatusConfig(solution.status, solution.isOverdue)}
              subjectId={solution.assignmentSubjectId}
              subjectName={solution.assignmentSubjectName}
            />
          </Grid>
          <Grid item xs={12} mb={8}>
            <Typography variant='h4' mb={1}>
              Решение на задачата
            </Typography>
            {(solutionSubmitted || solutionAssessed) && (
              <Paper>
                <Grid container px={7} py={5}>
                  <Grid item xs={12}>
                    <RichTextRenderer variant='subtitle1'>
                      {solution.content}
                    </RichTextRenderer>
                  </Grid>
                  <Grid item xs={12}>
                    <HomeworkContents
                      contents={solution.contents.map(({ id, name, visibilityLevel }) => ({
                          id, name, visibilityLevel
                        }) as Content)
                      }
                      readonly
                      titleVariant='h5'
                    />
                  </Grid>
                  {areAttachmentsLoading && (
                    <Skeleton variant='rectangular' height={200} />
                  )}
                  {!areAttachmentsLoading && attachments && (
                    <Grid item xs={12}>
                      <AttachFiles
                        attachments={attachments}
                        readonly
                        titleVariant='h5'
                        referenceId={assignmentId}
                        type={AttachmentsType.Assignment}
                      />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            )}
            {!solutionSubmitted && !solutionAssessed && (
              <FallbackSection
                variant={VariantsEnum.RELAXED_ROBOT}
                message='Решението все още не е предадено за оценка.'
              />
            )}
          </Grid>
          {(solutionSubmitted || solutionAssessed) && (
            <Grid item xs={12} mb={8}>
              <Typography variant='h4' mb={1} display='flex' alignItems='center'>
                Оценка на задачата
                <Tooltip
                  title='Поставената от Вас оценка не е задължителна и е само за Ваша информация, като тя не достига до ученика. Обратната връзка, от друга страна, е видима за ученика и е задължителна.'
                >
                  <Info sx={{ color: palette.primary[500], marginLeft: 1 }} />
                </Tooltip>
              </Typography>
              <Paper sx={({ spacing }) => ({ padding: `${spacing(5)} ${spacing(7)}`})}>
                <form onSubmit={handleSubmit}>
                  <Box mb={3} display='flex' alignItems='center'>
                    <Typography variant='h5' mr={2}>Oценка:</Typography>
                    {solutionAssessed && (
                      <NumericValue
                        numeric={solution.mark || '-'}
                        variant='circleWarning'
                      />
                    )}
                    {!solutionAssessed && markOptions.map((markOption) => (
                      <ButtonBase
                        key={`mark-${markOption}`}
                        sx={({ spacing }) => ({
                          marginRight: spacing(1)
                        })}
                        onClick={() => setFieldValue('mark', markOption)}
                        disableRipple
                        disabled={solutionAssessed}
                      >
                        <NumericValue
                          numeric={markOption}
                          variant={markOption === values.mark ? 'circleWarning' : 'currentGrade'}
                        />
                      </ButtonBase>
                    ))}
                  </Box>
                  <Divider sx={({ spacing }) => ({ margin: `${spacing(3)} 0px`})} />
                  <Box>
                    <Typography variant='h5' mb={2}>
                      Обратна връзка:
                      <Typography variant='h5' component='span' color='error'>
                        *
                      </Typography>
                    </Typography>
                    {solutionAssessed && (
                      <Typography variant='subtitle1'>{solution.comment}</Typography>
                    )}
                    {!solutionAssessed && (
                      <>
                        <TextField
                          name='comment'
                          value={values.comment}
                          onChange={handleChange}
                          placeholder='Добавете обратна връзка към ученика тук...'
                          minRows={5}
                          multiline
                          fullWidth
                          disabled={solutionAssessed}
                        />
                        {touched.comment && errors.comment && (
                          <Typography variant='caption' color='error' className='validation-error'>{errors.comment}</Typography>
                        )}
                      </>
                    )}
                  </Box>
                  {solutionAssessed || (
                    <Box my={3} display='flex' justifyContent='center'>
                      <LoadingButton
                        disableElevation
                        variant='contained'
                        sx={{ marginRight: 2 }}
                        type='submit'
                        loading={isAssessmentSubmitting}
                      >
                        Приключи
                      </LoadingButton>
                      <Button
                        variant='outlined'
                        href={`/homeworks/${assignmentId}`}
                      >
                        Откажи
                      </Button>
                    </Box>
                  )}
                </form>
              </Paper>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
export default HomeworkAssessment;
