import React from 'react';
import { RootStyled } from './styles';
import type { NumericValueType } from './types';

type Props = NumericValueType;

function NumericValue({ numeric, variant, children, onClick }: Props) {
  return (
    <RootStyled numeric={numeric} variant={variant} onClick={onClick}>
      {Boolean(children) || numeric}
      {children}
    </RootStyled>
  );
}

export default NumericValue;
