export enum SOPGradesEnum {
  Bad = 1,
  Normal = 2,
  Good = 3
}

export enum NumericGradesEnum {
  VeryBad = 2,
  Bad = 3,
  Normal = 4,
  Good = 5,
  VeryGood = 6
}

export enum StudentGradeCategoryEnum {
  Quantitative = 1, // normal grades 2 - 6
  SOP = 2, // grades for kids with special learning needs
  Qualitative = 3 // grades for kids, grades 1st to 3th
}

export type StudentGradeCategoryType =
  StudentGradeCategoryEnum.Quantitative | StudentGradeCategoryEnum.SOP | StudentGradeCategoryEnum.Qualitative;

export interface StudentGradesExtModel {
  id: number;
  institutionId: number;
  schoolYear: number;
  date: string;
  classId: number;
  className: string;
  curriculumId: number;
  curriculumGroupNum: number;
  subjectId: number;
  subjectName: string;
  subjectNameShort: string;
  subjectTypeId: number;
  subjectTypeName: string;
  type: number;
  term: number;
  category: StudentGradeCategoryType;
  decimalGrade: number;
  qualitativeGrade?: number;
  specialGrade?: SOPGradesEnum;
  teacherName: string;
  comment: string;
}
