export default {
  styleOverrides: {
    root: {},
    badge: {
      'fontSize': '10px',
      'lineHeight': '10px',
      'height': '14px',
    },
  },
};
