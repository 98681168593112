import React from 'react';
import { AvatarStyled } from './styles';

type Props = {
  iconBackdrop: string;
  icon: string;
  withShadow: boolean;
};

function IconAvatarCard({ iconBackdrop, icon, withShadow = false }: Props) {
  return (
    <AvatarStyled backdrop={iconBackdrop} withShadow={withShadow}>{icon}</AvatarStyled>
  );
}

IconAvatarCard.defaultProps = {
};

export default IconAvatarCard;
