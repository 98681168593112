/*
 * TODO:
 * 1. Refactor or remove unneeded helpers.
 * */

import { Roles } from 'enums/userRoles';
import { NavLinks } from 'system-settings-types';

export const arrayInRange = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start);

export const getAvarageNumber = (numbers: number[]): number => {
  if (numbers?.length) {
    return Math.floor(
      numbers.reduce(
        (prevNumber, currentNumber) => prevNumber + currentNumber,
        0
      ) / numbers.length
    );
  }
  return 0;
};

export const getLinkObjectKeysByRole: any = (
  links: NavLinks,
  userRole: Roles
) => {
  return Object.keys(links).reduce((previousValue: any, currentValue: any) => {
    const { id, label, href, external, children, roles, ...rest } =
      links[currentValue];

    if (!roles.includes(userRole)) {
      return previousValue;
    }

    const newObject = {
      id,
      label,
      ...(href ? { href } : {}),
      ...(external ? { external } : {}),
      ...rest,
    };

    if (children) {
      return previousValue.concat({
        ...newObject,
        children: getLinkObjectKeysByRole(children, userRole),
      });
    }

    return previousValue.concat(newObject);
  }, []);
};

export const buildFormBody = (data: any) => {
  const form = new FormData();
  let key: string;
  for (key in data) {
    const value = data[key];
    if (Array.isArray(value)) {
      value.forEach((element: any) => {
        form.append(
          key,
          typeof element === 'object' && Object.getPrototypeOf(element) === Object.prototype ? JSON.stringify(element) : element
        )
      });
    } else {
      form.append(key, `${value}`);
    }
  }

  return form;
};

export const getUniqueItems = <T>(items: T[], comparison: (x: T, y: T) => boolean): T[] => {
  return items.reduce((acc: T[], item) => {
    if (!Boolean(acc.find(x => comparison(x, item))))
      return [...acc, item];

    return acc;
  }, []);
};

export const getRandomInt = (max: number) => Math.floor(Math.random() * max);

export const isEmptyGuidString = (value: string | undefined | null): boolean => {
  let defaultValue = '00000000-0000-0000-0000-000000000000';

  if (!value) {
      return true;
  }

  if (value === defaultValue) {
      return true;
  }

  return false;
};
