import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { HyperlinksType } from 'api/types';
import { INITIAL_FORM_VALUES } from '../constants';
import { TypeCrud } from '../config';

interface Props {
  label: React.ReactNode;
  value: HyperlinksType[];
  onChange: (event: any) => void;
}
function Hyperlinks({ label, value, onChange }: Props) {
  const { t } = useTranslation();
  const initialFormNews = INITIAL_FORM_VALUES()[TypeCrud.news];
  useEffect(() => {
    const links = value?.filter((x) => !x.isFile);
    setHyperlinkSections(links);
  }, [value]);

  const [hyperlinkSections, setHyperlinkSections] =
    useState<HyperlinksType[]>(value);

  const onValueChange = (e: any, fieldKey: string, id: number) => {
    const hyperlinks = [...hyperlinkSections];
    hyperlinks[id] = {
      ...hyperlinks[id],
      [fieldKey]: e.target.value,
    };
    setHyperlinkSections(hyperlinks);

    const fileHyperlinks = value?.filter((x) => x.isFile);
    onChange([...hyperlinks, ...fileHyperlinks]);
  };

  const addNewSection = () => {
    const defaultHyperlinkValue = initialFormNews
      ?.hyperlinks[0] as HyperlinksType;
    setHyperlinkSections([...hyperlinkSections, defaultHyperlinkValue]);
  };

  const renderHyperlinkSection = (section: HyperlinksType, id: number) => {
    return (
      <React.Fragment key={`link=${id}`}>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              label={t('news.form.hyperlinks.titleBg')}
              value={section?.titleBg}
              onChange={(e) => onValueChange(e, 'titleBg', id)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              label={t('news.form.hyperlinks.titleEn')}
              value={section?.titleEn}
              onChange={(e) => onValueChange(e, 'titleEn', id)}
            />
          </Grid>
        </Grid>
        <Grid container my={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('news.form.hyperlinks.link')}
              value={section?.link}
              onChange={(e) => onValueChange(e, 'link', id)}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <>
      <Typography variant="h5">{label}</Typography>
      {hyperlinkSections?.map((section: HyperlinksType, id: number) => {
        return (
          <React.Fragment key={`link-${id}`}>
            {renderHyperlinkSection(section, id)}
            {id !== hyperlinkSections.length - 1 && <hr />}
          </React.Fragment>
        );
      })}
      <Box
        display="flex"
        alignItems="start"
        justifyContent="start"
        pt={1}
        pb={1}
      >
        <Button onClick={addNewSection} color="primary">
          {t('news.form.hyperlinks.addNewButton')}
        </Button>
      </Box>
    </>
  );
}

Hyperlinks.defaultProps = {};

export default Hyperlinks;
