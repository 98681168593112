import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogContent, DialogTitle, List, ListItem, ListItemIcon, Typography
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';

import { IconButtonStyled } from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
};

function HelpDialog({ open, onClose }: Props) {
  const { t } = useTranslation();

  const renderListItem = (index: any) => (
    <ListItem>
      <ListItemIcon>
        <Check color="success" />
      </ListItemIcon>
      <Typography>{t(`landingPage.section.contact.content.line${index}`)}</Typography>
    </ListItem>
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">
          {t('landingPage.section.contact.title')}
        </Typography>
        <IconButtonStyled onClick={onClose}>
          <Close />
        </IconButtonStyled>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('landingPage.section.contact.content.line1')}</Typography>
        <List>
          {renderListItem(2)}
          {renderListItem(3)}
          {renderListItem(4)}
          {renderListItem(5)}
          {renderListItem(6)}
          {renderListItem(7)}
          {renderListItem(8)}
        </List>
        <Typography pt={1}>{t(`landingPage.section.contact.content.line9`)}</Typography>
      </DialogContent>
    </Dialog>
  );
}

HelpDialog.defaultProps = {
};

export default HelpDialog;
