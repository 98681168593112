import { lazy, Suspense } from 'react';
import { useSharedViewContext } from 'hooks/useSharedViewContext';

const Subjects = () => {
  const viewName = useSharedViewContext();

  const Component = lazy(() => import(`../../views/subjects/${viewName}`));

  return (
    <Suspense fallback={<></>}>
      <Component />
    </Suspense>
  );
};

export default Subjects;
