import { useState } from 'react';
import { Box, Button, Divider, MenuItem, Typography } from '@mui/material';

import { useUserContext } from 'hooks/useUserContext';
import { useTableContext } from './table-context-provider';

import { MenuStyled } from './styles';

export type MeetingButtonItem = {
  id: string,
  link: string,
  name?: string,
};

type Props = {
  items: MeetingButtonItem[];
  color: any;
  [key: string]: any;
};

function JoinButton({ items, color, ...propsToPropagate }: Props) {
  const userRoles = useUserContext()?.userRole;
  const tableContext = useTableContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const commonProps: any = {
    size: 'medium',
    variant: 'contained',
    color: color,
  };

  if (items.length > 1 && userRoles?.isStudent) {
    return (
      <>
        <Button
          {...commonProps}
          onClick={(e: any) => {
            e.stopPropagation();
            tableContext.setDisableNavigate(true);
            setAnchorEl(e.currentTarget);
          }}
          {...propsToPropagate}
        >
          Вход
        </Button>
        <Box>
          {anchorEl && (
            <MenuStyled
              open={openMenu}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              aria-haspopup="true"
              color={color}
            >
              {items.map(({ id, name, link }, i) => (
                  <Box>
                    <MenuItem
                      key={id}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(link, '_blank');
                      }}
                    >
                      <Typography variant="inherit" noWrap>{name}</Typography>
                    </MenuItem>
                    {i !== items.length - 1 && <Divider />}
                  </Box>
              ))}
            </MenuStyled>
          )}
        </Box>
      </>
    );
  }

  const meeting = items[0];
  return (
    <Button
      {...commonProps}
      onClick={(e: any) => {
        e.stopPropagation();
        if (tableContext.disableNavigate) {
          tableContext.setDisableNavigate(false);
        } else {
          window.open(meeting.link, '_blank');
        }
      }}
      {...propsToPropagate}
    >
      Вход
    </Button>
  );
}

JoinButton.defaultProps = {};

export default JoinButton;
