import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NaviContainerStyled } from './styles';

type Props = {
  items: {
    id: number,
    icon: React.ReactElement,
    title: string,
    description?: string
  }[],
  onClick?: Function;
  activeIndex?: number;
};

function InnerNavi({ items, onClick, activeIndex }: Props) {
  const { t } = useTranslation();
  const naviResponsivenessBasedOnPurpose = {
    xs: onClick ? 3 : 12,
    sm: onClick ? 3 : 6,
    md: 3
  }

  return (
    <Grid container spacing={[3, 6, 10]}>
      {items.map((item, index) => (
        <NaviContainerStyled
          key={item.id}
          item
          {...naviResponsivenessBasedOnPurpose}
          onClick={onClick ? () => onClick(item, index) : null}
          active={activeIndex === index}
        >
          <span>{item.icon}</span>
          <Typography variant="h3">{t(item.title)}</Typography>
          {item.description && <Typography>{t(item.description)}</Typography>}
        </NaviContainerStyled>
      ))}
    </Grid>
  );
}

InnerNavi.defaultProps = {
};

export default InnerNavi;
