import { ExpandMore } from '@mui/icons-material';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SubjectOption } from './types';

type Props = {
  name?: string;
  options: SubjectOption[];
  onSelect: (event: SelectChangeEvent<number>, newValue: SubjectOption) => void;
  value?: string;
};

const SubjectSelect = ({ name, onSelect, options, value }: Props) => {
  const onOptionSelect = (event: any) => {
    const selected = event.target.value;
    const ids = selected.split('-').map((id: string) => parseInt(id));
    const option = options.find(x => x.subjectId === ids[0] && x.subjectTypeId === ids[1]);
    onSelect(event, option!);
  };

  return (
    <Select
      displayEmpty
      name={name}
      disabled={options.length <= 0}
      onChange={onOptionSelect}
      value={value || ''}
      IconComponent={ExpandMore}
      fullWidth
      renderValue={(selected) => options.find(x => x.id === selected)?.label || 'Избери'}
    >
      {options.map((option) => (
        <MenuItem value={option.id} key={option.id}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SubjectSelect;