import * as yup from 'yup';

const REQUIRED_FIELD = 'Полето е задължително.';
const TITLE_MAX_LENGTH = 200;
const MAX_FILE_SIZE_MB = 40;
const ALLOWED_FILE_TYPES = [
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.pdf',
];
export const FILE_SIZE_MESSAGE = `Максимален размер на файла: ${MAX_FILE_SIZE_MB}MB.`;
export const FILE_TYPE_MESSAGE = `Позволени разширения на файла: ${ALLOWED_FILE_TYPES.join(
  ', '
)}.`;

const LINK_PATTERN = /^((https?|ftp):\/\/(www\.)?|www\.)[^\s\/$.?#].[^\s]*/;

export const validationSchema = yup.object().shape(
  {
    title: yup
      .string()
      .test(
        'required',
        REQUIRED_FIELD,
        (title) => !!title && title.trim().length > 0
      )
      .test(
        'maxLength',
        `Полето може да съдържа най-много ${TITLE_MAX_LENGTH} символа.`,
        (title) => !!title && title.trim().length <= TITLE_MAX_LENGTH
      ),
    file: yup.mixed().when('link', {
      is: (link: string) => !Boolean(link),
      then: yup
        .mixed()
        .required(REQUIRED_FIELD)
        .test(
          'minFileSize',
          'Файлът не трябва да е празен.',
          (file: File) => file?.size > 0
        )
        .test(
          'fileSize',
          `Размерът на файла не трябва да надвишава ${MAX_FILE_SIZE_MB}MB.`,
          (file: File) => file?.size <= MAX_FILE_SIZE_MB * 1000 * 1000
        )
        .test(
          'fileType',
          `Файлът трябва да има едно от следните разширения: ${ALLOWED_FILE_TYPES.join(
            ', '
          )}.`,
          (file: File) =>
            Boolean(
              ALLOWED_FILE_TYPES.find((type) => file?.name.endsWith(type))
            )
        ),
      otherwise: yup.mixed(),
    }),
    link: yup.string().when('file', {
      is: (file: File) => !Boolean(file),
      then: yup
        .string()
        .required(REQUIRED_FIELD)
        .test(
          'linkFormat',
          'Форматът на линка е невалиден.',
          (link) => Boolean(link) && LINK_PATTERN.test(link!)
        ),
      otherwise: yup.string(),
    }),
  },
  [
    ['link', 'file'],
    ['file', 'link'],
  ]
);

export const initialFormValues = {
  title: '',
  file: null,
  link: '',
};

export const notifications = {
  success:
    'Съдържанието Ви беше качено успешно. Можете да го откриете в Модула за оценка.',
  error: 'Възникна грешка при качването на съдържанието. Моля опитайте отново.',
};
