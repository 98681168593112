import styled from '@emotion/styled';
import {
  Box, Button, Chip, DialogTitle, DialogActions, CircularProgress,
} from '@mui/material';

export const FilterStyled = styled('div')(({ theme }: any) => {
  const { breakpoints, spacing } = theme;

  return ({
    margin: spacing(0, 0, 4, 0),
    [breakpoints.up('md')]: {
      margin: `-${spacing(4)} 0 ${spacing(4)} 0`,
    },
    '& p': {
      fontWeight: '600',
    },
  });
});

export const NewsMetaStyled = styled('div')(({ theme }: any) => {
  const { palette } = theme;

  return ({
    '& p': {
      fontSize: '14px',
      color: palette.text.secondaryText,
    },
  });
});

export const NewsMetaChipsStyled = styled('div')(({ theme }: any) => {
  const { breakpoints, spacing } = theme;

  return ({
    marginBottom: spacing(1),
    [breakpoints.up('md')]: {
      marginBottom: spacing(3),
    },
  });
});

export const TagStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'tagType',
})(({ theme, tagType }: any) => {
  const { palette } = theme;
  let background = '';

  switch (tagType) {
    case 'teamsTag':
      background = '#ffa100 !important';
      break;
    case 'googleTag':
      background = '#1dc295 !important';
      break;
    case 'othersTag':
      background = '#14c6eb !important';
      break;
  }

  return ({
    color: `${palette.common.white} !important`,
    background,
  });
});

export const LoadMoreContainerStyled = styled(Box)(({ theme }: any) => {
  const { breakpoints, spacing } = theme;

  return ({
    padding: spacing(3),
    [breakpoints.up('md')]: {
      padding: spacing(5),
    },
  });
});

export const BigButtonStyled = styled(Button)(({ theme }: any) => {
  const { breakpoints, spacing } = theme;

  return ({
    [breakpoints.up('md')]: {
      padding: spacing(1.5, 5),
    },
  });
});

export const LoaderStyled = styled(CircularProgress)(({ theme }: any) => {
  const { spacing } = theme;

  return ({
    marginRight: spacing(1),
  });
});

export const DialogTitleStyled = styled(DialogTitle)(({ theme }: any) => {
  return ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& h6': {
      fontWeight: '600',
    },
  });
});

export const DialogLinksStyled = styled(DialogActions)(({ theme }: any) => {
  const { spacing } = theme;

  return ({
    flexDirection: 'column',
    alignItems: 'start',
    padding: spacing(2),
  });
});
