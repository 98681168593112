import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, Typography, } from '@mui/material';
import { Close } from '@mui/icons-material';

import { AccountDialogContentStyled, IconButtonStyled } from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
};

function AccountDialog({ open, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Typography variant='h5'>
          {t('landingPage.section.quickLinks.2.accountDialog.title')}
        </Typography>
        <IconButtonStyled onClick={onClose}>
          <Close />
        </IconButtonStyled>
      </DialogTitle>
      <DialogContent>
        <AccountDialogContentStyled>
          <Typography variant='h6' mb={2}>{t('landingPage.section.quickLinks.2.accountDialog.noAccount.title')}</Typography>
          <Typography>{t('landingPage.section.quickLinks.2.accountDialog.noAccount.subTitle')}</Typography>
          <ol>
            <li>
              <Typography>{t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.1')}</Typography>
            </li>
            <li>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.2', { link: 'https://oidc.mon.bg/interaction/yIogYETNmqOs8db7yeMkR' })
                }}
              />
            </li>
            <li>
              <Typography>{t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.3')}</Typography>
              <img
                src={`${process.env.PUBLIC_URL}/images/parents/no-account-1.png`}
                alt={t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.3')}
              />
            </li>
            <li>
              <Typography>{t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.4a')}</Typography>
              <img
                src={`${process.env.PUBLIC_URL}/images/parents/no-account-2.png`}
                alt={t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.4a')}
              />
              <Typography>{t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.4b')}</Typography>
            </li>
            <li>
              <Typography>{t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.5')}</Typography>
              <img
                src={`${process.env.PUBLIC_URL}/images/parents/no-account-3.png`}
                alt={t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.5')}
              />
            </li>
            <li>
              <Typography>{t('landingPage.section.quickLinks.2.accountDialog.noAccount.steps.6')}</Typography>
            </li>
          </ol>
        </AccountDialogContentStyled>
        <AccountDialogContentStyled>
          <Typography variant='h6' mb={2}>{t('landingPage.section.quickLinks.2.accountDialog.troubleLoggingIn.title')}</Typography>
          <Typography>{t('landingPage.section.quickLinks.2.accountDialog.troubleLoggingIn.subTitle')}</Typography>
          <ol>
            <li>
              <Typography>{t('landingPage.section.quickLinks.2.accountDialog.troubleLoggingIn.steps.1a')}</Typography>
              <img
                src={`${process.env.PUBLIC_URL}/images/parents/troubleshoot-1.png`}
                alt={t('landingPage.section.quickLinks.2.accountDialog.troubleLoggingIn.steps.1a')}
              />
              <Typography>{t('landingPage.section.quickLinks.2.accountDialog.troubleLoggingIn.steps.1b')}</Typography>
              <img
                src={`${process.env.PUBLIC_URL}/images/parents/troubleshoot-2.png`}
                alt={t('landingPage.section.quickLinks.2.accountDialog.troubleLoggingIn.steps.2')}
              />
            </li>
            <li>
              <Typography>{t('landingPage.section.quickLinks.2.accountDialog.troubleLoggingIn.steps.2')}</Typography>
            </li>
          </ol>
        </AccountDialogContentStyled>
      </DialogContent>
    </Dialog>
  );
}

AccountDialog.defaultProps = {};

export default AccountDialog;
