import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const DialogStyled = styled(Dialog)(({ theme }: any) => {
  const { palette } = theme;

  return {
    '& .MuiMenuItem-root:hover': {
      background: 'none',
    },
    '& .MuiPaper-root': {
      padding: '0px',
      margin: '0px',
      borderRadius: '0px',
      backgroundColor: palette.background.paper,
      overflowX: 'hidden',
    },
    '& .MuiDialogContent-root': {
      overflowX: 'hidden',
      '&::-webkit-scrollbar-track': {
        background: palette.grey[100],
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: palette.primary[600],
        borderRadius: '0px',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
      },
    },
  };
});
