import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { objectToSearchString } from "serialize-query-params";
import { format, isThisWeek, eachDayOfInterval } from "date-fns";

import {
  Alert, Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Stack, Tooltip, Typography, useMediaQuery, useScrollTrigger
} from "@mui/material";
import { Error } from "@mui/icons-material";
import Breadcrumbs from 'components/breadcrumbs';
import { WeekSelector, ScheduleTable } from "./components";
import RCS from "components/responsive-control-system";

import { useGetSchedule } from "services/queryHooks/schedule-hooks";
import { useGetMeetings } from "services/queryHooks/meetings-hooks";
import { ScheduleType, Shift } from "api/types";
import { useUserContext } from "hooks/useUserContext";
import { useGlobalModalContext } from "hooks/useGlobalModalContext";

import { breadcrumbs, DATE_STRING_FORMAT, Weekdays } from './constants';
import { getWeekScheduleRangeByDate } from "utils/dateFormat";
import { palette, shadows } from "theme/components";

import { DialogActionsStyled, DialogStyled, StickyGridStyled } from "./styles";

const WeeklySchedule = () => {
  const navigate = useNavigate();
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDate = searchParams?.get('selectedDate') || format(new Date(), DATE_STRING_FORMAT);
  const currentWeek = getWeekScheduleRangeByDate(new Date(selectedDate));

  const appBarRef = useRef<HTMLDivElement>(null);
  const [appBarThreshold, setAppBarThreshold] = useState(500);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: appBarThreshold
  });

  const [activeShift, setActiveShift] = useState<number>();
  const [fromDate, setFromDate] = useState(currentWeek.fromDate);
  const [toDate, setToDate] = useState(currentWeek.toDate);
  const [schedule, setSchedule] = useState<Shift>({} as Shift);
  const activeDayInitial = mobile ? Object.values(Weekdays)
    ?.find((weekday: any) =>  new Date(selectedDate).getDay() === weekday.id)?.id : undefined;
  const [activeDay, setActiveDay] = useState<number | undefined>(activeDayInitial);

  const globalModalContext = useGlobalModalContext();
  const user = useUserContext();
  const scheduleType = user?.userRole?.isTeacher ? ScheduleType.Teacher : ScheduleType.Student;

  const {
    data: shifts,
    isLoading: isScheduleLoading,
  } = useGetSchedule(
    { fromDate, toDate, type: scheduleType },
    {
      onSettled: (data: Shift[], error: Error) => {
        if (error || data.length <= 0) {
          const currentWeek = isThisWeek(new Date(fromDate), { weekStartsOn: 1 });
          const hasBeenRedirected = localStorage.getItem('hasScheduleRedirected');
          if (currentWeek && (!Boolean(hasBeenRedirected) || hasBeenRedirected === 'false') && !Boolean(shifts)) {
            globalModalContext?.setModal(renderRedirectDialog);
            navigate(`/my-subjects`);
          }
        }
      },
      select: (data: Shift[]) => {
        const mapped = data.map((shift) => {
          const hours = [];
          for (let i = shift.minHour; i <= shift.maxHour; i++) {
            hours.push({
              hourNumber: i,
              schedule: shift.days.map((day) => {
                const c = day.hours.find(h => h.number === i) || {}
                return {
                  dayNumber: day.number,
                  date: day.date,
                  ...c,
                };
              })
            })
          }
          return {
            ...shift,
            hours: hours
          }
        });

        return mapped;
      }
    });

  const {
    data: meetings
  } = useGetMeetings(fromDate, toDate, {
    enabled: !user?.userRole.isParent
  });

  const initializeAppBarThreshold = () => {
    if (appBarRef?.current) {
      const appBarOffset = (appBarRef.current as HTMLElement).getBoundingClientRect()?.top + window.scrollY;
      setAppBarThreshold(appBarOffset);
    }
  };

  useEffect(() => {
    if (mobile) {
      const active = Object.values(Weekdays)
      ?.find((weekday: any) =>  (new Date(selectedDate)).getDay() === weekday.id)?.id || 1;
      setActiveDay(active);
    }
  }, [mobile]);

  useEffect(() => {
    initializeAppBarThreshold();

    window.addEventListener("resize", initializeAppBarThreshold);
  }, []);

  const renderRedirectDialog = (
    <DialogStyled
      open
      onClose={() => {
        localStorage.setItem('hasScheduleRedirected', 'true');
        globalModalContext?.setModal(null);
      }}
    >
      <DialogContent>
        <DialogContentText textAlign="center">
          Бяхте пренасочени към Вашите предмети, тъй като в системата няма
          данни за седмично разписание за Вашето училище. То се попълва
          автоматично от седмичното разписание, генерирано от
          електронния дневник на училището.
        </DialogContentText>
      </DialogContent>
      <DialogActionsStyled>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            onClick={() => {
              localStorage.setItem('hasScheduleRedirected', 'true');
              globalModalContext?.setModal(null);
            }}
          >
            Продължи
          </Button>
        </Box>
      </DialogActionsStyled>
    </DialogStyled>
  );

  useEffect(() => {
    const { fromDate: newFromDate, toDate: newToDate } = currentWeek;
    const isSameWeek = newFromDate === fromDate;
    if (!isSameWeek) {
      setFromDate(newFromDate);
      setToDate(newToDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    loadCurrentShift();
  }, [shifts]);

  const loadCurrentShift = () => {
    const defaultShift = shifts?.find(d => d.current) || shifts?.[0] || {} as Shift;
    setActiveShift(defaultShift?.id);
    setSchedule(defaultShift as Shift);
  };

  const handleActiveShiftChange = (shift: any) => {
    setActiveShift(shift);

    const activeSchedule = shifts?.find((s) => s.id === shift) || {} as Shift;
    setSchedule(activeSchedule);
  };

  const handleWeekChange = (date: Date) => {
    setSearchParams(objectToSearchString({
      selectedDate: format(date, DATE_STRING_FORMAT)
    }));

    if (mobile) {
      const selectedDay = Object.values(Weekdays)
        ?.find((weekday: any) =>  date.getDay() === weekday.id)?.id || 1
      setActiveDay(selectedDay);
    }
  };

  const handleNextShift = () => {
    let shiftIndex = shifts?.findIndex((s) => s.id === activeShift);
    if (shiftIndex == undefined || (shifts && shiftIndex === shifts?.length - 1)) {
      shiftIndex = 0;
    } else {
      shiftIndex += 1;
    }

    const currentShift = shifts?.[shiftIndex]?.id;
    handleActiveShiftChange(currentShift);

    window.scrollTo(0, 0);
  };

  const hasMultipleShifts = Boolean(shifts && shifts?.length > 1);

  const renderShiftsButtons = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={mobile ? "space-around" : "flex-start"}
      spacing={1}
      ref={appBarRef}
      py={1}
    >
      <ButtonGroup disableElevation>
        {shifts?.map(({ id, name }) => (
          <Button
            key={id}
            onClick={() => handleActiveShiftChange(id)}
            variant={activeShift === id ? 'contained' : 'outlined'}
          >
            {name}
          </Button>
        ))}

      </ButtonGroup>
      {hasMultipleShifts && (
        <Tooltip
          placement={"right"}
          title={<Typography>Имате часове в друга смяна.</Typography>}
          enterTouchDelay={0}
        >
          <Error fontSize="large" color="warning" />
        </Tooltip>
      )}
    </Stack>
  );

  const handleMobileDayChange = (day: number) => {
    const thisWeekDays = eachDayOfInterval({
      start: new Date(fromDate),
      end: new Date(toDate)
    });

    const date = thisWeekDays[day - 1];
    handleWeekChange(date ? new Date(date) : new Date());
  };

  {/* This is because of the few seconds time delay
      between schedule being loaded and being set into state */}
  const setSheduleDelay = !isScheduleLoading && Boolean(shifts?.length) && !Boolean(schedule?.hours);

  return (
    <>
      <Box pb={[2, 4]}>
        <Breadcrumbs items={breadcrumbs()} />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">
            Моята програма
          </Typography>
        </Grid>
        <RCS visible="mobile">
          <Grid item xs={12} mb={2}>
            <WeekSelector selectedDate={new Date(selectedDate)} handleWeekChange={handleWeekChange} />
          </Grid>
          <StickyGridStyled
            item
            xs={12}
            ref={appBarRef}
            trigger={trigger ? 1 : 0}
          >
            {!(isScheduleLoading || setSheduleDelay) && hasMultipleShifts && renderShiftsButtons()}
            <Stack direction="row" justifyContent="space-around" py={1}>
              {Object.keys(Weekdays)?.map((key: string) => {
                const isActive = activeDay ===  Weekdays[key as keyof typeof Weekdays].id;
                return (
                  <Button
                    key={key}
                    onClick={() => handleMobileDayChange(Weekdays[key as keyof typeof Weekdays].id)}
                    variant={isActive ? 'contained' : 'text'}
                    sx={{
                      backgroundColor: !isActive ? palette.common.white : '',
                      boxShadow: shadows[6]
                    }}
                  >
                    {Weekdays[key as keyof typeof Weekdays].labelShort}
                  </Button>
                );
              })}
            </Stack>
          </StickyGridStyled>
        </RCS>
        <RCS hidden="mobile">
          <Grid item container xs={12} spacing={1} display="flex" justifyContent="space-between" alignItems="center">
            <Grid item md={7}>
              {!isScheduleLoading && hasMultipleShifts && renderShiftsButtons()}
            </Grid>
            <Grid item md={4}>
              <WeekSelector selectedDate={new Date(selectedDate)} handleWeekChange={handleWeekChange} />
            </Grid>
          </Grid>
        </RCS>
        <Grid item xs={12}>
          <Alert severity="warning">
            <Typography variant="subtitle2">
              Попълва се автоматично от седмичното разписание, генерирано от
              електронния дневник на училището.
            </Typography>
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <ScheduleTable
            data={schedule}
            meetings={meetings || []}
            activeDay={activeDay}
            isLoading={isScheduleLoading || setSheduleDelay}
            hasMultipleShifts={hasMultipleShifts}
            handleNextShift={handleNextShift}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WeeklySchedule;
