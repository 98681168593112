import { Input } from '@mui/material';
import { Search } from '@mui/icons-material';
import RCS from 'components/responsive-control-system';
import { InputAdornmentStyled } from './styles';
import MobileSearch from './mobile-search';
import { palette } from 'theme/components';

type Props = {
  value: string;
  onChange: Function;
  onSearchClick?: Function;
  onEnterDown?: Function;
  withMobile: boolean;
  placeholder?:string;
};

function ContextSearch({ value, onChange, onSearchClick, onEnterDown, withMobile, placeholder }: Props) {
  const renderDesktopSearch = (
    <Input
      fullWidth
      placeholder={placeholder || 'Търси'}
      sx={{ boxShadow: 4, minWidth: 130 }}
      endAdornment={
        <InputAdornmentStyled
          withButton={Boolean(onSearchClick)}
          position='end'
          onClick={() => {
            if (onSearchClick) {
              onSearchClick();
            }
          }}
        >
          <Search sx={{ color: palette.primary.light }} />
        </InputAdornmentStyled>
      }
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onKeyDown={(event) => {
        if (onEnterDown && event.key === 'Enter') {
          onEnterDown();
        }
      }}
      value={value}
    />
  );

  return (
    <>
      {!withMobile && (
        renderDesktopSearch
      )}
      {withMobile && (
        <>
          <RCS hidden="mobile">
            {renderDesktopSearch}
          </RCS>
          <RCS visible='mobile'>
            <MobileSearch
              initialValue={value}
              onSearch={(value: string) => {
                onChange(value);
              }}
            />
          </RCS>
        </>
      )}
    </>
  );
}

ContextSearch.defaultProps = {
  withMobile: true
};

export default ContextSearch;
