export const Roles: any = {
  0: {
    name: 'Институция (директор)',
    description: 'Институция (директор)'
  },
  1: {
    name: 'Администратор МОН',
    description: 'Администратор'
  },
  2: {
    name: 'Експерт ИО/АИ РУО',
    description: 'Експерт ИО/АИ'
  },
  3: {
    name: 'Община',
    description: 'Община'
  },
  4: {
    name: 'Финансираща институция',
    description: 'Финансираща институция'
  },
  5: {
    name: 'Учител',
    description: 'Учител'
  },
  6: {
    name: 'Ученик',
    description: 'Ученик'
  },
  7: {
    name: 'Родител',
    description: 'Родител'
  },
  8: {
    name: 'Класен ръководител Учител',
    description: 'класен ръководител'
  },
  9: {
    name: 'Експерт РУО',
    description: 'Експерт'
  },
  10: {
    name: 'ЦИОО',
    description: 'ЦИОО'
  },
  11: {
    name: 'Експерти от външни институции',
    description: 'Експерти от външни институции'
  },
  12: {
    name: 'Eксперт МОН',
    description: 'Eксперт'
  },
  13: {
    name: 'Администратор потребители МОН',
    description: 'Администратор потребители'
  },
  14: {
    name: 'Институция (IT Администратор)',
    description: 'Институция (IT Администратор)'
  },
  15: {
    name: 'ОБГУМ МОН – д.',
    description: 'ОБГУМ'
  },
  16: {
    name: '"Финанси" МОН – д.',
    description: 'ОБГУМи д. "Финанси"'
  },
  17: {
    name: 'ЧРАО МОН – д.',
    description: 'ЧРАО'
  },
  18: {
    name: 'Консорциум - Helpdesk',
    description: 'Консорциум - Helpdesk'
  },
  19: {
    name: 'НИО',
    description: 'НИО'
  },
  20: {
    name: 'Счетоводител',
    description: 'Счетоводител'
  },
  21: {
    name: 'Външни доставчици',
    description: 'Външни доставчици'
  },
  22: {
    name: 'Преподавател - ВУ',
    description: 'Преподавател - ВУ'
  },
  23: {
    name: 'Издател - МОН',
    description: 'Издател - МОН'
  },
  24: {
    name: 'Неделни училища',
    description: 'Неделни училища'
  }
}
