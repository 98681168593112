import styled from "@emotion/styled";
import RichTextEditor from "./rich-text-editor";

const TOOLBAR_HEIGHT_SMALL_SCREEN = '104px';
const TOOLBAR_HEIGHT_LARGE_SCREEN = '52px';

export default styled(RichTextEditor)(({ theme }: any) => ({
  border: '2px solid #F0F2F5',
  borderRadius: '8px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.common.white,
  '& > .rdw-editor-toolbar': {
    marginBottom: 0,
    background: '#F0F2F5',
    [theme.breakpoints.down('sm')]: {
      height: TOOLBAR_HEIGHT_SMALL_SCREEN,
    },
    [theme.breakpoints.up('sm')]: {
      height: TOOLBAR_HEIGHT_LARGE_SCREEN,
    },

    // buttons
    '& .rdw-option-wrapper': {
      background: '#F0F2F5',
      '&:hover': {
        borderRadius: '4px',
        border: '1px solid #8591A4',
      },
      '&.rdw-option-active': {
        background: '#8591A4',
        borderRadius: '4px',
        boxShadow: 'none'
      }
    },
  },
  '& > .rdw-editor-main': {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      height: `calc(100% - ${TOOLBAR_HEIGHT_SMALL_SCREEN})`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${TOOLBAR_HEIGHT_LARGE_SCREEN})`,
    },
    fontWeight: 400,
    fontSize: '14px'
  }
}));