import { Box, Card as MuiCard, CardContent, Divider, Typography, useMediaQuery } from '@mui/material';
import { NumericValueList } from 'components/numeric-value';
import { NumericValueType } from 'components/numeric-value/types';
import { mapNumericToSpecialGrade, normaliseTermGrade } from '../middleware';

import { GradeItem } from '../types';
import { getSubjectConfig } from 'config/subject-config-data';

export interface GradesBySubjectCardProps {
  subjectId: number;
  subjectName: string;
  currentSemGrades: GradeItem[];
  semesterFinalGrade: GradeItem;
  annualFinalGrade: GradeItem | undefined;
  teacherName: string;
  semesterName: string;
}

const Card: React.FC<GradesBySubjectCardProps> = (
  props: GradesBySubjectCardProps
) => {
  const {
    currentSemGrades,
    semesterFinalGrade,
    annualFinalGrade,
    subjectId,
    subjectName,
    teacherName,
    semesterName,
  } = props;

  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const propsToPropagate: any = {
    gap: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: tabletAndUp ? 'row' : 'column',
  };

  let grades: any[] = currentSemGrades.map(normaliseTermGrade);

  if (!Boolean(grades.length)) {
    // in case we have no grades, we would like to put and empty grade, so that
    // it doesn't look empty. #LMS-1975
    grades = [{
      id: crypto.randomUUID(),
      numeric: '-',
      variant: 'finalGrade'
    }];
  }

  const termGrade: NumericValueType = {
    id: semesterFinalGrade ? semesterFinalGrade.id : 0,
    numeric: semesterFinalGrade ? semesterFinalGrade.grade : '-',
    variant: 'finalGrade',
    ...mapNumericToSpecialGrade(semesterFinalGrade)
  };

  const annualGrade: NumericValueType = {
    id: annualFinalGrade ? annualFinalGrade.id : 0,
    numeric: annualFinalGrade ? annualFinalGrade.grade : '-',
    variant: 'finalGrade',
    ...mapNumericToSpecialGrade(annualFinalGrade)
  };

  const subjectConfig = getSubjectConfig(subjectId);
  return (
    <MuiCard
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
      }}
    >
      <CardContent sx={{ height: '100%' }}>
        <Box display="flex" alignItems="center" gap={1.5}>
          {subjectConfig.icon({
            fontSize: 'large',
            sx: { color: subjectConfig.color },
          })}
          <Typography variant="h4">{subjectName}</Typography>
        </Box>
        <Typography variant='subtitle2' color='textSecondary' mt={1}>
          <b>Преподавател:</b> {teacherName}
        </Typography>
        <Box {...propsToPropagate} mt={2}>
          <NumericValueList
            list={grades}
            listTitle={semesterName}
            NumericValueComponent={{
              justifyContent: 'flex-start',
              width: '100%'
            }}
            hasActiveTooltips
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            alignSelf={tabletAndUp ? "baseline" : "auto"}
            gap={3}
          >
            <NumericValueList list={[termGrade]} listTitle="Срочна" />
            <Divider orientation="vertical" flexItem />
            <NumericValueList list={[annualGrade]} listTitle="Годишна" />
          </Box>
        </Box>
      </CardContent>
    </MuiCard>
  );
};

export default Card;
