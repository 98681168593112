import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  Close,
  Favorite,
  FavoriteBorder,
  DeleteOutlined
} from '@mui/icons-material';

import ContentTile from 'components/content-tile';
import { ContextSearch } from 'components/search';
import { ConfirmationDialog } from 'components/confirmation-dialog';
import LoadingSkeleton from 'components/loading-skeleton';
import { FallbackSection } from 'components/fallback';
import AssignContent from 'containers/assign-content/assign-content';
import { AssignmentType } from 'containers/assign-content/constants';

import {
  useAddFavoriteAssignedContent,
  useDeleteAssignment,
  useManagingAssignedContentList,
  useRemoveFavoriteAssignedContent
} from 'services/queryHooks/contents-hooks';
import { useGetTeacherCurriculum } from 'services/queryHooks/curriculum-hooks';
import { AssignedContentItem, AssignmentInfo, AssignmentInfoBase } from 'api/types';

import { DEFAULT_PAGINATION } from 'views/library/config';
import { dateFormat } from 'utils/dateFormat';
import { palette } from 'theme/components';

export default function ManagingAssignedContents() {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [assignmentToDelete, setAssignmentToDelete] = useState<AssignmentInfoBase>();

  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const [debouncedInput] = useDebounce(inputValue, 500);

  const allContents = useManagingAssignedContentList({ offset: DEFAULT_PAGINATION.Offset, name: debouncedInput });
  const { data: dataCurriculum }: any = useGetTeacherCurriculum();

  const getCurriculumId = (subjectId: number, classId?: number) => {
    const curriculumDetails = dataCurriculum?.filter((x: any) => x.subjectId == subjectId && x.classId == classId);
    return Boolean(curriculumDetails) ? curriculumDetails[0]?.curriculumId : '';
  };

  const {
    mutate: mutateAddFavoriteContent,
    isLoading: isLoadingAddFavoriteContent,
  } = useAddFavoriteAssignedContent();

  const {
    mutate: mutateRemoveFavoriteContent,
    isLoading: isLoadingRemoveFavoriteContent,
  } = useRemoveFavoriteAssignedContent();

  const {
    mutate: mutateDeleteAssignment,
    isLoading: isLoadingDeleteAssignment,
  } = useDeleteAssignment();

  const onFavorite = ({ id, isFavourite }: AssignedContentItem) => {
    const isAddFavorite = !isFavourite;
    if (isAddFavorite) {
      mutateAddFavoriteContent(id);
    } else {
      mutateRemoveFavoriteContent(id);
    }
  };

  const onDeleteAssignment = (assignment: AssignmentInfoBase) => {
    mutateDeleteAssignment(assignment);
  };

  const isFavoriteProcessing = isLoadingAddFavoriteContent || isLoadingRemoveFavoriteContent;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContentActionButtons = (assignedContent: AssignedContentItem) => (
    <Box display='flex' alignContent='center' pr={[0, 3]} gap={0.5}>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setAssignmentToDelete({ contentId: assignedContent.id } as AssignmentInfoBase)
        }}
        disabled={isLoadingDeleteAssignment}
      >
        <DeleteOutlined
          fontSize={tabletAndUp ? 'large' : 'medium'}
          sx={{ color: palette.grey.main }}
        />
      </IconButton>

      <AssignContent
        contentId={assignedContent.id}
        subject={{
          subjectId: assignedContent.subjectId,
          isProfileSubject: assignedContent.isProfileSubject
        }}
        loadCurriculumOnStart={false}
      />

      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onFavorite(assignedContent);
        }}
        disabled={isFavoriteProcessing}
      >
        {assignedContent?.isFavourite ? (
          <Favorite
            fontSize={tabletAndUp ? 'large' : 'medium'}
            sx={{ color: palette.info.main }}
          />
        ) : (
          <FavoriteBorder
            fontSize={tabletAndUp ? 'large' : 'medium'}
            sx={{ color: palette.grey.main }}
          />
        )}
      </IconButton>
    </Box>
  );

  const renderAssignedContent = (assignedContent: AssignedContentItem) => (
    <Card
      key={assignedContent?.id}
      onClick={() => {
        window.open(`/library/content/${assignedContent.id}`, '_blank');
      }}
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: 1,
        justifyContent: 'space-between',
        marginBottom: 2,
        marginTop: 2,
      }}
    >
      <CardContent sx={{ cursor: 'pointer' }}>
        <Box>
          <ContentTile
            name={assignedContent?.name}
            ratingInfo={assignedContent?.ratingInfo}
            variant={'large'}
            visibilityLevel={assignedContent?.visibilityLevel}
            activityId={assignedContent?.activityId}
            sourceCode={assignedContent?.links?.[0]?.sourceCode}
            renderActionButtons={() => renderContentActionButtons(assignedContent)}
          />
          <Box pl={4}>
            {Boolean(assignedContent?.scheduleAssignments?.length) && <Typography variant='h5' component='div' pt={2}>
              {t('pages.assignedContents.toSchedule')}:
            </Typography>
            }
            {Boolean(assignedContent?.scheduleAssignments?.length) &&
              assignedContent?.scheduleAssignments.map(({
                classId, subjectTypeId, subjectTypeName, subjectId, className, scheduleId, subjectName, scheduleHour, scheduleDate
              }: AssignmentInfo) => (
              <Box key={`${subjectId}-${scheduleId}`} display='flex' alignItems='center'>
                <Link target='_blank' to={`/subject/${subjectId}/${subjectTypeId}/${getCurriculumId(subjectId, classId)}?scheduleId=${scheduleId}&scheduleDate=${scheduleDate}`} onClick={(e: any) => { e.stopPropagation() }}>
                  <Box
                    key={`${subjectId}-${className}`}
                    sx={{ color: palette.primary.main }} component='span'>
                    {Boolean(subjectName) && `${subjectName}`}{Boolean(subjectTypeName) && ` ${subjectTypeName}`}
                    {Boolean(className) && ` | ${className}`}
                    {(Boolean(scheduleHour) || scheduleHour === 0) && ` | ${scheduleHour} час`}
                    {Boolean(scheduleDate) && ` | ${dateFormat(scheduleDate)}`}
                  </Box>
                </Link>
                <IconButton
                  disabled={isLoadingDeleteAssignment}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAssignmentToDelete({ contentId: assignedContent.id, classId, subjectId, scheduleId, assignmentPeriod: AssignmentType.Scheduled } as AssignmentInfoBase)
                  }}>
                  <Close
                    fontSize='small'
                    sx={{ color: palette.info.main }} />
                </IconButton>
              </Box>
            ))}
            <Divider sx={{ paddingTop: '10px' }} />
            {Boolean(assignedContent?.alltimeAssignments?.length) && <Typography variant='h5' component='div' pt={2}>
              {t('pages.assignedContents.toSubject')}:
            </Typography>
            }
            {Boolean(assignedContent?.alltimeAssignments?.length) &&
              assignedContent?.alltimeAssignments.map(({
                classId, subjectId, className, subjectName, subjectTypeId, subjectTypeName
              }: AssignmentInfo) => (
              <Box key={`${subjectId}-${className}`} display='flex' alignItems='center'>
                <Link target='_blank' to={`/subject/${subjectId}/${subjectTypeId}/${getCurriculumId(subjectId, classId)}`} onClick={(e: any) => { e.stopPropagation() }}>
                  <Box
                    key={`${subjectId}-${className}`}
                    sx={{ color: palette.primary.main }} component='span'>
                    {Boolean(subjectName) && `${subjectName}`}{Boolean(subjectTypeName) && ` ${subjectTypeName}`}
                    {Boolean(className) && ` | ${className}`}
                  </Box>
                </Link>
                <IconButton
                  disabled={isLoadingDeleteAssignment}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAssignmentToDelete({ contentId: assignedContent.id, classId, subjectId, assignmentPeriod: AssignmentType.AllTime } as AssignmentInfoBase)
                  }}>
                  <Close
                    fontSize='small'
                    sx={{ color: palette.info.main }} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  const renderAssignedContentsList = ({ data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage }: any) => (
    <>
      <Grid container item xs={12} mb={2} display='flex' justifyContent='flex-end' alignItems='center'>
        <Grid item xs={12} sm={4} md={3}>
          <ContextSearch value={inputValue} onChange={(value: string) => setInputValue(value)} />
        </Grid>
      </Grid>

      {data?.pages?.map((page: any) => (page.items.map((content: AssignedContentItem) => renderAssignedContent(content))))}

      {!isLoading && (
        Boolean(data?.pages?.length) && (
          <>
            {hasNextPage && (
              <Box display='flex' justifyContent='center' p={3}>
                <Button variant='contained' onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
                  <Typography
                    textTransform='uppercase'
                    variant='caption'
                    color={palette.common.white}
                    px={1}
                  >
                    {t('pages.assignedContents.button.loadMore')}
                  </Typography>
                </Button>
              </Box>
            )}
          </>
        )
      )}
    </>
  );

  return (
    <>
      <Typography variant='h1' py={3}>
        {t('pages.assignedContents.title')}
      </Typography>
      {(Boolean(allContents?.data?.pages[0]?.items?.length) || inputValue) && renderAssignedContentsList(allContents)}
      {allContents?.isLoading && <LoadingSkeleton itemsCount={3} height={300} />}
      {!allContents?.isLoading && !allContents?.data?.pages[0]?.items?.length && (
        <>
          {!inputValue && (
            <FallbackSection message={t('pages.assignedContents.emptyMessage')} />
          )}
          {inputValue && (
            <FallbackSection
              message={(
                <>
                  {t('pages.assignedContents.notFoundFor')}
                  <Typography
                    component='span'
                    sx={{ color: palette.primary[700] }}
                  >
                    "{inputValue}"
                  </Typography>
                </>
              )}
            />
          )}
        </>
      )}
      <ConfirmationDialog
        open={Boolean(assignmentToDelete)}
        onDecline={() => setAssignmentToDelete(undefined)}
        onAccept={(event) => {
          event?.stopPropagation();
          event?.preventDefault();
          onDeleteAssignment(assignmentToDelete!);
          setAssignmentToDelete(undefined);
        }}
        message={t('pages.assignedContents.dialog.message')}
      />
    </>
  );
}
