import { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Routing } from './components/routing/Routing';
import { AxiosInterceptor } from './components/axios-interceptor';
import { GlobalLoader } from 'components/loader/global-loader';
import { ToastContextProvider } from './contexts/SnackbarContext';
import { GlobalModalProvider } from './contexts/GlobalModalContext';
import { LoaderContextProvider } from './contexts/LoaderContext';
import { AuthManager } from 'authentication/auth-manager';
import theme from 'theme/theme';

export const App: React.FC = () => {
  useEffect(() => {
    if (AuthManager.isAuthenticated()) {
      AuthManager.setTimerForRefreshingToken();
    }
  }, []);

  return (
    <ToastContextProvider>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <GlobalModalProvider>
          <LoaderContextProvider>
            <AxiosInterceptor>
              <GlobalLoader />
              <Routing />
            </AxiosInterceptor>
          </LoaderContextProvider>
        </GlobalModalProvider>
      </ThemeProvider>
    </ToastContextProvider>
  );
};
