import React, { useEffect, useState } from 'react';
import { Marquee } from 'components/marquee';
import { PublicLayout } from 'components/layout';
import AboutSection from './sections/about';
import QuickLinksSection from './sections/quick-links';
import FaqSection from './sections/faq';
import NewsSection from './sections/news';

import { requestPublicNews } from 'api/api';

import { SectionStyled } from 'views/public/styles';

const defaultNewsState: any = {
  items: [],
  pagination: {},
  loader: null,
};

const defaultNewsMeta = {
  itemsPerPage: 3,
  page: 1,
  tags: undefined,
  enabled: true,
};

function LandingPage({}) {
  const [newsPayload, setNewsPayload] = useState({
    meta: defaultNewsMeta,
    news: defaultNewsState,
  });

  useEffect(() => {
    // loading start
    setNewsPayload({
      ...newsPayload,
      news: {
        ...newsPayload.news,
        loader: true,
      },
    });

    requestPublicNews(newsPayload.meta).then((payload) => {
      setNewsPayload({
        ...newsPayload,
        news: {
          ...newsPayload.news,
          items:
            payload.pagination.page === 1
              ? payload.items
              : newsPayload.news.items.concat(payload.items),
          pagination: payload.pagination,
          loader: null,
        },
      });
    });
  }, [newsPayload.meta.page, newsPayload.meta.tags]);

  return (
    <>
      <PublicLayout>
        <Marquee
          onActionButtonClick={() => {
            const section: any = document.querySelector('#platform-about');
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }}
        />

        <SectionStyled id="platform-about" py={[2, 8]}>
          <AboutSection />
        </SectionStyled>

        <SectionStyled type="grey" id="classrooms" pt={0} pb={[2, 8]}>
          <QuickLinksSection />
        </SectionStyled>

        <SectionStyled py={[2, 8]}>
          <FaqSection />
        </SectionStyled>

        <SectionStyled type="grey" id="news" py={[2, 8]}>
          <NewsSection
            news={newsPayload.news}
            onLoadMore={(tags: any = null, page = null) => {
              setNewsPayload({
                ...newsPayload,
                meta: {
                  ...newsPayload.meta,
                  page: page || newsPayload.news.pagination.page + 1,
                  tags: tags,
                },
              });
            }}
          />
        </SectionStyled>
      </PublicLayout>
    </>
  );
}

LandingPage.defaultProps = {};

export default LandingPage;
