import styled from "@emotion/styled";
import { DialogContent, IconButton } from "@mui/material";

export const IconButtonStyled = styled(IconButton)(({ theme: { palette, spacing } }: any) => ({
  position: 'absolute',
  right: spacing(1),
  top: spacing(1),
  color: palette.grey.main,
}));

export const DialogContentStyled = styled(DialogContent)(({ theme: { spacing } }: any) => ({
  paddingLeft: spacing(2),
  paddingRight: spacing(2),
}));