import { Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import RCS from 'components/responsive-control-system';
import { getSubjectConfig } from 'config/subject-config-data';
import { getAssigneesLabel, HomeworkStatusConfigType } from 'utils/homeworks';
import { dateFormat } from 'utils/dateFormat';
import { HomeworkClassAssignee } from 'api/types';

export interface Props {
  statusConfig: HomeworkStatusConfigType;
  deadline: string;
  subjectId: number;
  subjectName: string;
  submittedOn?: string;
  teacherName?: string;
  comment?: string;
  classAssignees?: HomeworkClassAssignee[];
};

const HomeworkBasicInfo = ({
  statusConfig, deadline, subjectId, subjectName, teacherName, submittedOn, comment, classAssignees
}: Props) => {
  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const subjectConfig = getSubjectConfig(subjectId);
  const subjectCustomIcon = subjectConfig.icon({
    fontSize: 'small',
  });

  const typographyVariant = tabletAndUp ? 'body1' : 'body2';
  const typographyStyles = { fontWeight: 'bold '};

  return (
    <Grid container>
      <Grid item xs={12} display={tabletAndUp ? 'flex' : undefined} alignItems='center' gap={1}>
        <Typography
          variant={typographyVariant}
          component='div'
          color='secondary'
          mb={tabletAndUp ? 0 : 1}
          sx={typographyStyles}
        >
          Статус:{' '}
          <Typography
            variant={typographyVariant}
            sx={{ ...typographyStyles, color: statusConfig.color }}
            component='span'
          >
            {statusConfig.label}
          </Typography>
        </Typography>
        {submittedOn && (
          <>
            <RCS hidden='mobile'>
              <Typography variant={typographyVariant} component='div' sx={typographyStyles}>
                |
              </Typography>
            </RCS>
            <Typography
              variant={typographyVariant}
              component='div'
              sx={{ ...typographyStyles, marginBottom: tabletAndUp ? 0 : 1 }}
            >
              Дата на предаване: {dateFormat(submittedOn)}
            </Typography>
          </>
        )}
        <RCS hidden='mobile'>
          <Typography variant={typographyVariant} component='div' sx={typographyStyles}>
            |
          </Typography>
        </RCS>
        <Typography variant={typographyVariant} component='div' sx={typographyStyles}>
          Крайна дата: {dateFormat(deadline)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant={typographyVariant}
          my={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <b>Предмет:</b> {subjectCustomIcon} {subjectName}
        </Typography>
      </Grid>
      {teacherName && (
        <Grid item xs={12}>
          <Typography variant={typographyVariant}>
            <b>Преподавател:</b> {teacherName}
          </Typography>
        </Grid>
      )}
      {classAssignees && (
        <Grid item xs={12}>
        <Typography variant={typographyVariant}>
          <b>Класове:</b> {getAssigneesLabel(classAssignees)}
        </Typography>
      </Grid>
      )}
      {comment && Boolean(comment.trim().length) && (
        <Grid item xs={12}>
          <Typography variant={typographyVariant} my={1}>
            <b>Обратна връзка:</b>
          </Typography>
          <Paper>
            <Grid container px={7} py={5}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  {comment}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
export default HomeworkBasicInfo;
