// to be used by homeworks pages and subject view
import { HomeworkClassAssignee, HomeworkStudentAssignee } from 'api/types';
import { HomeworkStatuses } from 'enums/homeworkStatuses.enum';
import { palette } from 'theme/components';

export const getAssigneesLabel = (
  classes?: HomeworkClassAssignee[], students?: HomeworkStudentAssignee[]
) => {
  let label = '';
  if (classes && Boolean(classes.length)) {
    label = classes.map((c) => c.name).join(', ');
  }

  if (students && Boolean(students.length)) {
    label = `${label} | ${students.map((s) => `${s.name} (${s.className})`).join(', ')}`;
  }

  return label;
};

export type HomeworkStatusConfigType = {
  label: string;
  color: string;
};

export const getSolutionStatusConfig =
  (status: HomeworkStatuses, isOverdue: boolean): HomeworkStatusConfigType => {
  if (isOverdue && status) {
    if (status === HomeworkStatuses.Submitted)
      return { label: 'Предадено извън срок', color: palette.warning.main };
    else if (status === HomeworkStatuses.Evaluated)
      return { label: 'Приключено извън срок', color: palette.success.main };
    else
      return { label: 'Просрочено', color: palette.info.main };
  }

  switch (status) {
    case HomeworkStatuses.New:
      return { label: 'Ново', color: palette.primary.main };
    case HomeworkStatuses.Draft:
      return { label: 'Чернова', color: palette.grey.main };
    case HomeworkStatuses.Submitted:
      return { label: 'Предадено', color: palette.success.main };
    case HomeworkStatuses.Evaluated:
      return { label: 'Приключено', color: palette.success.main };
    case HomeworkStatuses.ForCorrection:
      return { label: 'За поправка', color: palette.grey.main };
    default:
      return { label: '-', color: palette.grey.main };
  }
};

export const getAssignmentStatusConfig =
  (totalSubmitted: number, totalAssignees: number): HomeworkStatusConfigType => {
    if (totalSubmitted === 0)
      return { label: 'Ново', color: palette.primary.main };
    
    if (totalSubmitted > 0 && totalSubmitted < totalAssignees)
      return { label: 'Изпълнява се', color: palette.grey.main };

    if (totalSubmitted === totalAssignees) {
      return { label: 'Изпълнено', color: palette.success.main };
    }

    return { label: '-', color: palette.grey.main };
};