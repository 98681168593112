import { startOfWeek, nextFriday, startOfISOWeek, endOfISOWeek } from 'date-fns';

export const dateFormat = (dateString: string | Date, withYear = true, withMonthFullName = false) => {
  if (!dateString) return '-';

  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();

  if (withMonthFullName) {
    mm = MONTHS[date.getMonth()];
  }

  if (withYear) {
    return dd + '.' + mm + '.' + yyyy;
  }

  return dd + '.' + mm;
};

export const dateFormatWithTime = (date: Date, time: string) => {
  let hourAndMinutes = time.split(':');

  return `${getDayOfWeek(date)},${date.getDate()}.${getMonth(
    date,
  )}.${date.getFullYear()}г. ${hourAndMinutes[0]}:${hourAndMinutes[1]}`;
};

export const dateFormatWithNamedMonth = (dateString: string | Date) => {
  if (!dateString) return '-';

  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = getMonth(date);
  const yyyy = date.getFullYear();

  return `${dd} ${mm} ${yyyy}`;
}

export const getDayOfWeek = (date: Date) => {
  const days = [
    'Неделя',
    'Понеделник',
    'Вторник',
    'Сряда',
    'Четвъртък',
    'Петък',
    'Събота',
  ];
  return days[date.getDay()];
};

export const MONTHS = [
  'Януари',
  'Февруари',
  'Март',
  'Април',
  'Май',
  'Юни',
  'Юли',
  'Август',
  'Септември',
  'Октомври',
  'Ноември',
  'Декември',
];

export const getMonth = (date: Date) => MONTHS[date.getMonth()];

export const getTime = (date: Date) => {
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  return hours + ':' + minutes;
};

export const getIsoDateWithTime = (date: Date, time: string) => {
  let hourAndMinutes = time.split(':');
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  return `${date.getFullYear()}-${month}-${date.getDate()}T${
    hourAndMinutes[0]
  }:${hourAndMinutes[1]}:00Z`;
};

export const getIsoDate = (date: string) => {
  let dateElements = date.split('T');
  return `${dateElements[0]}T`;
};

export const getSchoolWeekStartEndByIsoDate = (isoDate: string, startOfWeek = 1) => {
  const date = new Date(isoDate);
  const start = startOfISOWeek(date);
  const end = endOfISOWeek(date);

  return {
    startDate: {
      raw: start,
      formatted: start.toLocaleDateString('en-CA'),
    },
    endDate: {
      raw: end,
      formatted: end.toLocaleDateString('en-CA'),
    },
  };
};

export const getEndOfDayFormattedDate = (deadline: Date | undefined | null) => {
  let deadlineFormatted = '';
  if (deadline) {
    deadline.setHours(23, 59, 59);
    deadlineFormatted = deadline.toISOString();
  }

  return deadlineFormatted;
};

export const normalizeDateBecauseOfLocalization = (newDate: string) => {
  const date = new Date(newDate)
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - offsetMs;
  const dateLocal = new Date(msLocal);

  const iso = dateLocal.toISOString().split("T");
  const time = iso[1].slice(0, 5);

  const currentDate = dateLocal.getDate()
  const month = (dateLocal.getMonth() + 1).toString();
  const year = dateLocal.getFullYear();

  return `${currentDate}.${month}.${year}, ${time} часа`;

}

export const getWeekScheduleRangeByDate = (date: Date) => {
  const fromDate = startOfWeek(date, { weekStartsOn: 1 });
  const toDate = nextFriday(fromDate);

  return {
    fromDate: `${fromDate.getFullYear()}-${
      fromDate.getMonth() + 1
    }-${fromDate.getDate()}`,
    toDate: `${toDate.getFullYear()}-${
      toDate.getMonth() + 1
    }-${toDate.getDate()}`,
  };
};

export const formatNewsDate = (rawDate: Date, withTime = false) => {
  const dateObject = new Date(rawDate);
  const date = dateObject.getDate().toString();
  const month = (dateObject.getMonth() + 1).toString();
  const year = dateObject.getFullYear();

  const hours = dateObject.getHours().toString();
  const minutes = dateObject.getMinutes().toString();

  const fullFormattedDate = `${date.padStart(2, '0')}-${month.padStart(
    2,
    '0'
  )}-${year}`;
  const fullFormattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(
    2,
    '0'
  )}`;

  if (withTime) {
    return `${fullFormattedDate} @${fullFormattedTime}`;
  }

  return fullFormattedDate;
};

export const getTimeDate = (time: string) => {
  const timeSplit = time.split('T');
  const hourAndMinutes = timeSplit[1].split(':');
  return `${hourAndMinutes[0]}:${hourAndMinutes[1]} часа`;
};
