
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, RawDraftContentState } from 'draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type Props = {
  onChange: (value: object, rawText: string) => void;
  defaultValue?: string | object | null;
  readOnly?: boolean;
  className?: string;
};

const convertToRawContentState = (value: string | object): RawDraftContentState => {
  let objValue = value;
  if (typeof value === 'string') {
    objValue = JSON.parse(value);
  }

  return objValue as RawDraftContentState;
};

const RichTextEditor = ({ defaultValue, onChange, readOnly, className }: Props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (defaultValue) {
      const rawState = convertToRawContentState(defaultValue);
      const defaultState = EditorState.createWithContent(convertFromRaw(rawState));
      setEditorState(defaultState);
    }
  }, [defaultValue]);
  
  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={(newState: any) => {
        setEditorState(newState);
      }}
      onBlur={() => {
        const content = editorState.getCurrentContent();
        const rawContent = convertToRaw(content);
        const rawText = rawContent.blocks.reduce((acc, x) => {
          return x.text ? `${acc} ${x.text}` : acc;
        }, '');
        onChange(rawContent, rawText);
      }}
      toolbar={{
        options: ['inline', 'textAlign', 'list'],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        list: {
          options: ['unordered', 'ordered'],
        }
      }}
      readOnly={!!readOnly}
      wrapperClassName={className}
    />
  );
}

export default RichTextEditor;
