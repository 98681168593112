import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  assessSolution,
  apiGetWaitingStudentAssignmentsBySubject,
  deleteAssignment,
  getAllAssignmentSolutions,
  getHomeworkSolution,
  getStudentAssignments,
  getStudentHomework,
  getTeacherAssignment,
  getTeacherAssignments,
  saveDraftSolution,
  updateSolutionStatus,
  apiGetWaitingTeacherHomeworksBySubject,
} from 'api/api';
import { PaginatedResult } from 'types/global';
import {
  BasicStudentAssignment,
  DetailedStudentSolution,
  DetailedTeacherAssignment,
  SolutionRequest,
  StudentAssignment,
  StudentHomework,
  StudentHomeworksType,
  StudentSolution,
  SubmitSolutionRequest,
  TeacherAssignment,
  TeacherHomeworksBySubjectType,
} from 'api/types';
import { HomeworkStatuses } from 'enums/homeworkStatuses.enum';

export const useStudentAssignments = (type: StudentHomeworksType, options = {}) =>
  useQuery<StudentAssignment[], Error>(
    ['student-homeworks', type],
    () => getStudentAssignments(type),
    options
  );

export const useStudentHomework = (assignmentId: string, options = {}) =>
  useQuery<StudentHomework, Error>(
    ['student-homework', assignmentId],
    () => getStudentHomework(assignmentId),
    options
  );

export const useTeacherAssignments = (options = {}) =>
  useQuery<TeacherAssignment[], Error>(
    ['teacher-homeworks'],
    () => getTeacherAssignments(),
    options
  );

export const useTeacherAssignment = (assignmentId: string, options = {}) =>
  useQuery<DetailedTeacherAssignment, Error>(
    ['teacher-homework', assignmentId],
    () => getTeacherAssignment(assignmentId),
    options
  );

export const useHomeworkSolution = (solutionId: string, options = {}) =>
  useQuery<DetailedStudentSolution, Error>(
    ['solution', solutionId],
    () => getHomeworkSolution(solutionId),
    options
  );

export const useAssignmentSolutions = (assignmentId: string,
  filter: { studentName?: string | null, page?: number, statuses?: number[] },
  options = {}
) => useQuery<PaginatedResult<StudentSolution>, Error>(
  ['solutionsByFilter', { ...filter, assignmentId }],
  () => getAllAssignmentSolutions(assignmentId, filter),
  options
);

export const useSaveDraftSolution = (options = {} as any) => {
  return useMutation(
    (solution: SolutionRequest) => saveDraftSolution(solution),
    options
  );
};

export const useSubmitSolution = (options = {} as any) => {
  return useMutation(
    ({ solutionId, solution }: SubmitSolutionRequest) => {
      if (solution) {
        return saveDraftSolution(solution, true)
          .then(() => updateSolutionStatus(solutionId, HomeworkStatuses.Submitted, true));
      } else {
        return updateSolutionStatus(solutionId, HomeworkStatuses.Submitted, true);
      }
    },
   options
  );
};


export const useDeleteAssignment = (options = {} as any) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => deleteAssignment(id),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(['teacher-homeworks']);
        if (options.onSuccess) {
          options.onSuccess();
        }
      }
    }
  );
};

type AssessSolutionRequest = {
  solutionId: string;
  mark?: number;
  comment: string;
};

export const useAssesSolution = (options: any = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ solutionId, mark, comment }: AssessSolutionRequest) =>
      assessSolution(solutionId, { mark, comment }),
    {
      ...options,
      onSuccess: (response, request) => {
        queryClient.invalidateQueries(['solution', request.solutionId]);
        if (options?.onSuccess) {
          options.onSuccess();
        }
      }
    }
  );
};

export const useWaitingStudentAssignmentsBySubject = (subjectId: number, isProfileSubject: boolean, offset?: number, page?: number, options = {}) =>
  useQuery<BasicStudentAssignment[], Error>(
    ['studentAssignmentsBySubject', subjectId, isProfileSubject],
    () => apiGetWaitingStudentAssignmentsBySubject(subjectId, isProfileSubject, offset, page),
    {
      ...options,
    }
  );

export const useTeacherHomeworkBySubject = (
  subjectId: number,
  classId: number,
  isProfileSubject: boolean,
  offset?: number,
  page?: number,
  options = {}
) =>
  useQuery<TeacherHomeworksBySubjectType, Error>(
    ['teacherAssignmentBySubject', subjectId, classId, isProfileSubject],
    () => apiGetWaitingTeacherHomeworksBySubject(subjectId, classId, isProfileSubject, offset, page),
    {
      ...options,
    }
  );
