import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';

export const convertToHtml = (value: string) => {
  try {
    const json = JSON.parse(value);
    const html = draftToHtml(json);
    return DOMPurify.sanitize(html);
  } catch (error) {
    console.warn('Could not parse the text.');
    return '';
  }
};

type Props = {
  children: string;
  variant?: Variant;
};

const RichTextRenderer = ({ children, variant }: Props) => {
  return (
    <Typography
      variant={variant}
      component="div"
      dangerouslySetInnerHTML={{ __html: convertToHtml(children) }}
    />
  );
};

export default RichTextRenderer;