import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Typography } from '@mui/material';
import { Computer, GradeRounded, HomeWorkRounded, CastForEducationRounded } from '@mui/icons-material';
import InnerNavi from '../../../components/inner-navigation/inner-navigation';

const aboutItems = [
  {
    id: 1,
    icon: <Computer />,
    title: 'landingPage.section.about.1.title',
    description: 'landingPage.section.about.1.description'
  },
  {
    id: 2,
    icon: <GradeRounded />,
    title: 'landingPage.section.about.2.title',
    description: 'landingPage.section.about.2.description'
  },
  {
    id: 3,
    icon: <HomeWorkRounded />,
    title: 'landingPage.section.about.3.title',
    description: 'landingPage.section.about.3.description'
  },
  {
    id: 4,
    icon: <CastForEducationRounded />,
    title: 'landingPage.section.about.4.title',
    description: 'landingPage.section.about.4.description'
  }
];

function AboutSection() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <Typography variant="h2">{t('landingPage.section.about.title')}</Typography>
      <InnerNavi items={aboutItems} />
    </Container>
  );
}

AboutSection.defaultProps = {
};

export default AboutSection;
