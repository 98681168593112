import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/system';
import {
  Button,
  ButtonGroup,
  Typography,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { DoneAll, Brightness1 } from '@mui/icons-material';
import { formatDistance } from 'date-fns';
import { bg } from 'date-fns/locale';

import { FallbackSection } from 'components/fallback';
import LoadingSkeleton from 'components/loading-skeleton';
import { VariantsEnum } from 'components/fallback/types';

import { useUpdateNotificationSeenStatus, useUserNotificationsList } from 'services/queryHooks/notifications-hooks';

import { NotificationsButtonType } from './config';
import { LIST_NOTIFICATIONS_PAGINATION } from './constants';
import { palette } from 'theme/components';

import { NotificationItem } from 'api/types';

export default function Notifications() {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<any>();
  const [activeButton, setActiveButton] = useState(NotificationsButtonType.all);
  const tabletAndUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const allNotifications: any =
    useUserNotificationsList(LIST_NOTIFICATIONS_PAGINATION);
  const unseenNotifications: any =
    useUserNotificationsList(LIST_NOTIFICATIONS_PAGINATION, false);

  const { mutate: mutateUpdateSeenStatus } =
    useUpdateNotificationSeenStatus();

  useEffect(() => {
    if (activeButton === NotificationsButtonType.all) {
      setNotifications(allNotifications);
    }
    if (activeButton === NotificationsButtonType.unseen) {
      setNotifications(unseenNotifications);
    }
  }, [activeButton, allNotifications.data, unseenNotifications.data]);

  const renderButtons = () => (
    <>
      <Box display="flex" justifyContent="space-between" gap={2} mb={3}>
        <ButtonGroup>
          <Button onClick={() => setActiveButton(NotificationsButtonType.all)}
            variant={
              activeButton === NotificationsButtonType.all
                ? "contained"
                : "outlined"
            }
          >
            {t('pages.notifications.button.all')}
          </Button>
          <Button onClick={() => setActiveButton(NotificationsButtonType.unseen)}
            variant={
              activeButton === NotificationsButtonType.unseen
                ? "contained"
                : "outlined"
            }
          >
            {t('pages.notifications.button.unRead')}
          </Button>
        </ButtonGroup>
        {Boolean(unseenNotifications?.data?.pages[0]?.items?.length) && (
          <Button
            sx={{ backgroundColor: !tabletAndUp ? palette.common.white : null, p: 0 }}
            onClick={() => mutateUpdateSeenStatus({ notificationsIds: [] })}
          >
            <DoneAll />
            {tabletAndUp && (
              <Typography
                variant="caption"
                textTransform="uppercase"
                color={palette.primary.main}
                sx={{ fonWeight: 700, pl: 1 }}
              >
                {t('pages.notifications.button.markAllAsRead')}
              </Typography>
            )}
          </Button>
        )}
      </Box>
    </>
  );

  const renderNotification = ({ isSeen, title, id, content, createdOn }: NotificationItem) => (
    <ListItem
      key={id}
      onClick={() => !isSeen && mutateUpdateSeenStatus({ notificationsIds: [id] })}
      sx={{ cursor: !isSeen ? 'pointer' : null, opacity: !isSeen ? 1 : 0.5, p: 2, px: 3 }}
    >
      <ListItemText>
        <Typography variant="h5">
          <b>{title}</b>
          {!tabletAndUp && (
            <Typography
              variant="caption"
              color={palette.primary.main}
              pl={1}
            >
              {formatDistance(new Date(createdOn), new Date(), { addSuffix: true, locale: bg })}
            </Typography>
          )}
        </Typography>
        <Typography variant="subtitle1" py={1}>
          {content}
        </Typography>
        {tabletAndUp && (
          <Typography
            variant="caption"
            color={palette.primary.main}
          >
            {formatDistance(new Date(createdOn), new Date(), { addSuffix: true, locale: bg })}
          </Typography>
        )}
      </ListItemText>
      {!isSeen && (
        <Typography pl={4}>
          <Brightness1
            sx={{
              color: palette.primary.main,
              fontSize: !tabletAndUp ? 8 : 16,
            }}
          />
        </Typography>
      )}
    </ListItem>
  );

  const renderNotificationsList = ({ data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage }: any) => (
    <Paper>
      {data?.pages?.map((page: any) => (page.items.map((notification: NotificationItem, index: number) => {
        return (
          <>
            {(page?.paging.page > 1 || index > 0) && (<Divider sx={{ margin: '0px !important' }} />)}
            {renderNotification(notification)}
          </>
        )
      })))}
      {!isLoading && (
        Boolean(data?.pages?.length) && (
          <>
            {hasNextPage && (
              <Box display="flex" justifyContent="center" p={3}>
                <Button variant="contained" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
                  <Typography
                    textTransform="uppercase"
                    variant="caption"
                    color={palette.common.white}
                    px={1}
                  >
                    {t('pages.notifications.button.loadMoreNotifications')}
                  </Typography>
                </Button>
              </Box>
            )}
          </>
        )
      )}
    </Paper>
  );

  return (
    <>
      <Typography variant="h1" py={3}>
        {t('pages.notifications.title')}
      </Typography>
      {notifications?.isLoading && <LoadingSkeleton itemsCount={1} height={1188} />}
      {Boolean(allNotifications?.data?.pages[0]?.items?.length) && renderButtons()}
      {!notifications?.isLoading &&
        Boolean(notifications?.data?.pages[0]) &&
        !Boolean(notifications?.data?.pages[0]?.items?.length) && (
          <FallbackSection
            variant={VariantsEnum.NOITEMS}
            message={t('pages.notifications.emptyMessage')}
          />
        )}
      {Boolean(notifications?.data?.pages[0]?.items?.length) && renderNotificationsList(notifications)}
    </>
  );
}
