import { BORDER_RADIUS } from 'theme/constants';
import palette from './palette';
import shadows from './shadows';

export const MuiPickerStaticWrapper = {
  styleOverrides: {
    content: {
      overflow: 'unset',
      backgroundColor: 'unset'
    }
  }
};

export const MuiCalendarOrClockPicker = {
  styleOverrides: {
    root: {
      '& div': {
        overflowX: 'unset'
      }
    }
  }
};

export const MuiCalendarPicker = {
  styleOverrides: {
    root: {
      backgroundColor: palette.background.paper,
      borderRadius: BORDER_RADIUS,
      boxShadow: shadows[4]
    },
  },
};

export const MuiPickersCalendarHeader = {
  styleOverrides: {
    label: {
      color: palette.text.primary,
      fontSize: 18,
      fontWeight: 400,
      textTransform: 'capitalize',
    },
  },
};

export const MuiDayPicker = {
  styleOverrides: {
    header: {
      '& span': {
        color: palette.primary.main,
        fontWeight: 500
      }
    }
  }
};

const daySelectedCommonProps = {
  border: `1.5px solid ${palette.primary.main}`,
  backgroundColor: palette.background.paper,
  color: palette.primary.main,
};
export const MuiPickersDay = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      backgroundColor: palette.background.paper,
      color: palette.common.black,
      '&.Mui-selected': {
        ...daySelectedCommonProps,
        ':focus': {
          ...daySelectedCommonProps
        }
      },
      '&.MuiPickersDay-today': {
        border: 'none',
        color: palette.info.main,
        '&.Mui-selected': {
          ...daySelectedCommonProps,
          color: palette.info.main
        }
      }
    },
  }
};