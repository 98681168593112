import * as yup from 'yup';
import { getHomePageUrl } from "utils/navigation";
import { REQUIRED_MESSAGE } from "../common/validations";

export const breadcrumbs: any = (assignmentId: string, assignmentTitle: string, queryString: any) => [
  {
    href: getHomePageUrl(),
    label: 'Начало'
  },
  {
    href: '/homeworks',
    label: 'Домашни',
  },
  ...(assignmentTitle ? [
    {
      href: `/homeworks/${assignmentId}?${queryString}`,
      label: assignmentTitle
    }
  ] : []),
];

export const markOptions = [2, 3, 4, 5, 6];

export const validationSchema = yup.object().shape({
  comment: yup.string().required(REQUIRED_MESSAGE),
});

export const initialFormValues = {
  comment: ''
};
