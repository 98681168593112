import React from 'react';
import { Warning } from '@mui/icons-material';
import { Box, Grow, Typography } from '@mui/material';
import { getStringLocalization } from 'i18n';
import { palette } from 'theme/components';

const BlockingNotifications = ({ notifications }: any) => {
  if (!notifications) {
    return null;
  }

  const Lng = getStringLocalization();

  return (
    <Grow
      in={Boolean(notifications?.length)}
      style={{ transformOrigin: '0 0 0' }}
      {...(Boolean(notifications?.length) ? { timeout: 500 } : {})}
    >
      {
        <Box width="100%">
          {notifications.map(({ id, ...notification }: any) => (
            <Box
              key={id}
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="#e93b1fc2"
              gap={1}
              p={1}
            >
              <Warning sx={{ color: palette.common.white }} />
              <Typography
                variant="subtitle1"
                sx={{ color: palette.common.white }}
              >
                {notification[`message${Lng}`]}
              </Typography>
            </Box>
          ))}
        </Box>
      }
    </Grow>
  );
};

BlockingNotifications.defaultProps = {};

export default BlockingNotifications;
