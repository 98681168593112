export enum ContentVisibilityLevelEnum {
  Public = 'Public',
  Private = 'Private',
  Organization = 'Organization',
  None = 'None'
}

export type ContentVisibilityLevel =
  ContentVisibilityLevelEnum.Public | ContentVisibilityLevelEnum.Private |
  ContentVisibilityLevelEnum.None | ContentVisibilityLevelEnum.Organization;

export enum ContentLinkSources {
  Mozaik = 'MZK',
  Khan = 'Khan',
  Izzi = 'IZZI',
  DigitalBackpack = 'DBP',
};

export enum ContentActivity {
  WithPayment = 3
};
